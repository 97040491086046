import React from 'react'

const loadingCover = ({showLoading}) => {


            {/* <div className="ajs-modal" style={showStyle}> */}
    const showStyle = {
        display: showLoading ? "block" : "none",
    };
    

    const loading = 
        (
            <div className="show-loading" style={showStyle}>
                <div className="loading-text">
                    Saving Image...
                </div>
            </div>
        );
    return (
        loading
        )
}

export default loadingCover