const initState = {


}

const reportJournalReducer = (state = initState, action) => {

    switch (action.type) {
        case "CASE1":
            return {
                ...state,
                toDisplay: {
                    ...state.toDisplay,
                    classroomId: action.classroomId
                }
            }
        case "CASE2":
            return {
                ...state,
                usage: action.activityClassroom,
                ticks: action.ticks,
                journals: action.journals,
            }

        

        default:
            return state
    }
}

export default reportJournalReducer