const initState = {
    sectionsOriginal: [
        "Introduce",
        "Interact",
        "Story",
        "Play",
        "Measure"
    ],
    sectionsDefault: [
        "Student Files",
        "Teacher Files",
        "Story, Songs & Culture",
        "Reading & Games",
        "About"
    ],
    newChapterCreated: false, // This shows the chapterId when a new chapter is created so the app can navigate to that new chapter. Then this is set back to false.
    showPublisherFeatures: false, //Determines whether the publisher features box displays on the story page
    showSpinner: false, //display an activity spinner when this is true (while uploading epubs, audio files, etc.)
    showCodeForm: false, //this is for entering a book code on Navbar.js
    booksAddedForCode: false, //this is true when books are successfully added through the book code form on Navbar.js
    displayNoBookCode: false,
    booksLoaded: false,
    books: { //I don't think this is used any longer (3/16/2020)
        isEmpty: true
        // book1: {},
        // book2: {}
    },
    purchaseAccess: {
        // purchaseAccessId: { ...purchaseAccessObj }
    },

    //When a teacher logs in, this property stores the teacher's purchased books
    //When a student logs in, this property stores the books available to a student
    booksPurchased: {
        // bookId: { ...bookObj }
    },
    paByBookId: {
        // bookId: [ purchaseAccessId ]
    },
    paByClassroomIdBookId: {
        // classroomId: {
        //     bookId: [ purchaseAccessId ]            
        // }
    },
    chaptersByBook: {
        // bookId: {
        //     chapterId: { chapterObj }
        // }
    },
    journalsByBook: {
        // bookId: {
        //     chapterId: { chapterJournalObj }
        // }
    },
    ticks: {
        // tickId: { tickObj }
    },
    // ticksByBook: {  not used as of 4/4/20
    //     // bookId: {
    //     //     chapterId: { ...tickObject }
    //     // }
    // },
    ttsLanguages: {
        English: {
            "English(US) F 1": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-C',
                'ssmlGender': 'FEMALE'
            },
            "English(US) F 2": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-E',
                'ssmlGender': 'FEMALE'
            },
            "English(US) F 3": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-F',
                'ssmlGender': 'FEMALE'
            },
            "English(US) M 1": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-A',
                'ssmlGender': 'MALE'
            },
            "English(US) M 2": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-B',
                'ssmlGender': 'MALE'
            },
            "English(US) M 3": {
                'languageCode': 'en-US',
                'name': 'en-US-Wavenet-D',
                'ssmlGender': 'MALE'
            },
        },
        Spanish: {
            "Spanish F 1": {
                'languageCode': 'es-ES',
                'name': 'es-ES-Standard-A',
                'ssmlGender': 'FEMALE'
            },
        },
        French: {
            "French(Canada) F 1": {
                'languageCode': 'fr-CA',
                'name': 'fr-CA-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "French(Canada) F 2": {
                'languageCode': 'fr-CA',
                'name': 'fr-CA-Wavenet-C',
                'ssmlGender': 'FEMALE'
            },
            "French(Canada) M 1": {
                'languageCode': 'fr-CA',
                'name': 'fr-CA-Wavenet-B',
                'ssmlGender': 'MALE'
            },
            "French(Canada) M 2": {
                'languageCode': 'fr-CA',
                'name': 'fr-CA-Wavenet-D',
                'ssmlGender': 'MALE'
            },
            "French(France) F 1": {
                'languageCode': 'fr-CA',
                'name': 'fr-FR-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "French(France) F 2": {
                'languageCode': 'fr-CA',
                'name': 'fr-FR-Wavenet-C',
                'ssmlGender': 'FEMALE'
            },
            "French(France) M 1": {
                'languageCode': 'fr-CA',
                'name': 'fr-FR-Wavenet-B',
                'ssmlGender': 'MALE'
            },
            "French(France) M 2": {
                'languageCode': 'fr-CA',
                'name': 'fr-FR-Wavenet-D',
                'ssmlGender': 'MALE'
            },
        },
        Italian: {
            "Italian F 1": {
                'languageCode': 'it-IT',
                'name': 'it-IT-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "Italian F 2": {
                'languageCode': 'it-IT',
                'name': 'it-IT-Wavenet-B',
                'ssmlGender': 'FEMALE'
            },
            "Italian M 1": {
                'languageCode': 'it-IT',
                'name': 'it-IT-Wavenet-C',
                'ssmlGender': 'MALE'
            },
            "Italian M 2": {
                'languageCode': 'it-IT',
                'name': 'it-IT-Wavenet-D',
                'ssmlGender': 'MALE'
            },
        },
        German: {
            "German F 1": {
                'languageCode': 'de-DE',
                'name': 'de-DE-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "German F 2": {
                'languageCode': 'de-DE',
                'name': 'de-DE-Wavenet-C',
                'ssmlGender': 'FEMALE'
            },
            "German M 1": {
                'languageCode': 'de-DE',
                'name': 'de-DE-Wavenet-B',
                'ssmlGender': 'MALE'
            },
            "German M 2": {
                'languageCode': 'de-DE',
                'name': 'de-DE-Wavenet-D',
                'ssmlGender': 'MALE'
            },
        },
        Chinese: {
            "Chinese F 1": {
                'languageCode': 'cmn-CN',
                'name': 'cmn-CN-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "Chinese M 1": {
                'languageCode': 'cmn-CN',
                'name': 'cmn-CN-Wavenet-B',
                'ssmlGender': 'MALE'
            },
            "Chinese M 2": {
                'languageCode': 'cmn-CN',
                'name': 'cmn-CN-Wavenet-C',
                'ssmlGender': 'MALE'
            },
        },
        Korean: {
            "Korean F 1": {
                'languageCode': 'ko-KR',
                'name': 'ko-KR-Wavenet-A',
                'ssmlGender': 'FEMALE'
            },
            "Korean F 2": {
                'languageCode': 'ko-KR',
                'name': 'ko-KR-Wavenet-B',
                'ssmlGender': 'FEMALE'
            },
            "Korean M 1": {
                'languageCode': 'ko-KR',
                'name': 'ko-KR-Wavenet-C',
                'ssmlGender': 'MALE'
            },
            "Korean M 2": {
                'languageCode': 'ko-KR',
                'name': 'ko-KR-Wavenet-D',
                'ssmlGender': 'MALE'
            },
        }
    }
}

const bookReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_BOOKS':
            // console.log("ABOUT TO SET BOOKS IN STATE")
            // console.log(action.booksForState)
            return {
                ...state,
                books: {
                    isEmpty: false,
                    ...action.books
                }
            }
        case 'RESET_BOOKS':
            return {
                ...state,
                booksLoaded: false,
                books: { isEmpty: true },
                purchaseAccess: {},
                booksPurchased: {},
                paByBookId: {},
                paByClassroomIdBookId: {},
                chaptersByBook: {},
            }
        case 'SET_PURCHASEACCESS':
            return {
                ...state,
                purchaseAccess: action.purchaseAccess
            }
        case 'SET_PURCHASED_BOOKS':
            return {
                ...state,
                booksPurchased: action.purchasedBooks,
                booksLoaded: true
            }
        case 'UPDATE_BOOK_SECTIONS':
            return {
                ...state,
                booksPurchased: {
                    ...state.booksPurchased,
                    [action.bookId]: {
                        ...state["booksPurchased"][action.bookId],
                        chapterSections: action.sections
                    }
                }
            }
        case 'SET_PA_BYBOOK':
            return {
                ...state,
                paByBookId: { ...action.paByBookId }
            }
        case 'SET_PA_BYCLASSROOM_BYBOOK':
            return {
                ...state,
                paByClassroomIdBookId: { ...action.paByClassroomIdBookId }
            }
        case 'SET_CHAPTERS_FOR_BOOK':
            const bookId = action.bookChapters.bookId
            const chapters = action.bookChapters.chapters
            return {
                ...state,
                chaptersByBook: {
                    ...state.chaptersBybook,
                    [bookId]: chapters
                }
            }
        case 'SET_JOURNALS_FOR_BOOK':
            return {
                ...state,
                journalsByBook: {
                    ...state.journalsByBook,
                    ...action.bookJournals
                }
            }
        case 'UPDATE_JOURNAL_FOR_BOOK':
            const updateBookId = action.chapterJournalUpdate.bookId
            const updateChapterId = action.chapterJournalUpdate.chapterId
            return {
                ...state,
                journalsByBook: {
                    ...state.journalsByBook,
                    [updateBookId]: {
                        ...state.journalsByBook[updateBookId],
                        [updateChapterId]: {
                            ...state.journalsByBook[updateBookId][updateChapterId],
                            ...action.chapterJournalUpdate
                        }
                    }
                }
            }
        case 'SET_TICKS_FOR_BOOK':
            return {
                ...state,
                ticks: {
                    ...state.ticks,
                    ...action.ticks
                }
            }
        case 'UPDATE_TICK_FOR_BOOK':
            const fileId = action.tickObj.fileId
            return {
                ...state,
                ticks: {
                    ...state.ticks,
                    [fileId]: action.tickObj
                }
            }

        // case 'SETNEW_JOURNAL_FOR_BOOK':
        //     const bookId = action.chapterJournalUpdate.bookId
        //     const chapterId = action.chapterJournalUpdate.chapterId
        //     return {
        //         ...state,
        //         journalsByBook: {
        //             ...state.journalsByBook,
        //             [bookId]: {
        //                 ...state.journalsByBook[bookId],
        //                 [chapterId]: {
        //                     ...state.journalsByBook[bookId][chapterId],
        //                     ...action.chapterJournalUpdate
        //                 }
        //             }
        //         }
        //     }


        case 'SET_BOOKS_FOR_STUDENT':
            const classroom = action.booksForStudent
            const books = action.booksForStudent
            return {
                ...state,
                booksForStudent: {
                    classroom,
                    books
                }
            }
        case 'RETRIEVED_BOOKS_WITH_CODE': //this occurs when books are added through the book code form on Navbar.js
            return {
                ...state,
                showCodeForm: false,
                booksAddedForCode: true,
            }
        case 'NO_BOOK_CODE':
            return {
                ...state,
                // showCodeForm: false,
                displayNoBookCode: true
            }
        case 'RESET_NO_BOOK_CODE':
            return {
                ...state,
                displayNoBookCode: false
            }
        case 'TOGGLE_BOOK_CODE_FORM':
            return {
                ...state,
                showCodeForm: !state.showCodeForm
            }
        case 'RESET_BOOKS_ADDED_FOR_CODE':
            return {
                ...state,
                booksAddedForCode: false,
            }
        case 'UPDATE_BOOK':
            return {
                ...state,
                books: {
                    ...state.books,
                    [action.book.bookId]: action.book
                },
                booksPurchased: {
                    ...state.booksPurchased,
                    [action.book.bookId]: action.book
                }
            }

        case 'DELETE_CHAPTER':
            return {
                ...state,
                books: {
                    ...state.books,
                    [action.book.bookId]: action.book
                },
                booksPurchased: {
                    ...state.booksPurchased,
                    [action.book.bookId]: action.book
                },
                chaptersByBook: {
                    ...state.chaptersByBook,
                    [action.book.bookId]: action.bookChapters
                }
            }
        case 'SET_NEW_CHAPTERS':
            console.log("IN REDUCER")
            return {
                ...state,
                chaptersByBook: {
                    ...state.chaptersByBook,
                    [action.newBookId]: {
                        ...action.chaptersForBook
                    }
                }
            }

        case 'UPDATE_CHAPTER':
            const remdupbookId = action.chapter.bookId
            const remdupchapterId = action.chapter.chapterId
            // bookId: {
            //     chapterId: { chapterObj }
            // }
            return {
                ...state,
                chaptersByBook: {
                    ...state.chaptersByBook,
                    [remdupbookId]: {
                        ...state.chaptersByBook[remdupbookId],
                        [remdupchapterId]: action.chapter
                    }
                }
            }
        case 'NEW_CHAPTER_CREATED':
            return {
                ...state,
                newChapterCreated: action.chapter.chapterId
            }
        case 'RESET_NEW_CHAPTER_CREATED':
            console.log("RESETTING new chapter created")
            return {
                ...state,
                newChapterCreated: false
            }
        case 'SHOW_SPINNER':
            return {
                ...state,
                showSpinner: true
            }
        case 'HIDE_SPINNER':
            return {
                ...state,
                showSpinner: false,
            }
        case 'TOGGLE_PUBLISHER_FEATURES':
            return {
                ...state,
                showPublisherFeatures: !state.showPublisherFeatures
            }
        default:
            return state
    }
    //To alter the state
    // return {
    //     ...state, //This spreads the existing state
    // }
}

export default bookReducer

