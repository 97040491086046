import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { saveJournal } from '../../../store/actions/journalActions'
import firebase from '../../../config/fbConfig'
// import QuillImage from '../../other/QuillImage'


// https://www.npmjs.com/package/quill-image-drop-module
import { ImageDrop } from 'quill-image-drop-module';

// https://github.com/zenoamaro/react-quill/issues/224
import * as QuillNamespace from 'quill';
// import ImageResize from 'quill-image-resize-module';  9/8/20
let Quill = QuillNamespace;
const BackgroundClass = Quill.import('attributors/class/background');
const ColorClass = Quill.import('attributors/class/color');
const SizeStyle = Quill.import('attributors/style/size');
const AlignStyle = Quill.import('attributors/style/align');
const BaseImage = Quill.import('formats/image');
const Parchment = Quill.import('parchment');

// console.log("ok ok ok ok ok")
// console.log(BaseImage)

// Quill.register('modules/imageResize', ImageResize)  9/8/20
Quill.register('modules/imageDrop', ImageDrop);
Quill.register(BackgroundClass, true);
Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);
Quill.register(AlignStyle, true);
Quill.register(new Parchment.Attributor.Style('width', 'width', { 
    whitelist: ['200']
}));
// Quill.register(QuillImage, true)

//this replaces the quill p tag with a div and br
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);



/*
const ImageFormat = Quill.import('formats/image');
const IMAGE_FORMAT_ATTRIBUTES = [
    'alt',
    'height',
    'width',
    'style',
    'align'
];
ImageFormat.formats = function formats(domNode: any): any {
    return IMAGE_FORMAT_ATTRIBUTES.reduce(
        (formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        },
        {}
    );
};

ImageFormat.prototype.format = function format(name: string, value: any): void {
    if (IMAGE_FORMAT_ATTRIBUTES.indexOf(name) !== -1) {
        if (value) {
            this.domNode.setAttribute(name, value);
        } else {
            this.domNode.removeAttribute(name);
        }
    } else {
        this.super.format(name, value);
    }
};

Qu  ill.register(ImageFormat, true);
*/



/*
var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style',
    'align'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);
*/



// https://alligator.io/react/modal-component/
class ModalJournal extends Component {
    
    state = {
        journalHtml: "",
        text: "",
        show: false,
        chapter: null,
        typingTimeout: 0
    }

    fileName = React.createRef();
    quillRef = null;      // Quill instance
    reactQuillRef = null; // ReactQuill component
    previousDelta = null;

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    modules = {
        formula: true,
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                // ['link', 'image'],
                ['link'],
                ['clean'],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }]
            ],
            handlers: {
                // handlers object will be merged with default handlers object
                // 'image': this.imageHandler
                // 'image': function(value) {
                //     console.log("IN FUNCTION")
                //     console.log(value)
                // }
                'onDrop': function (value) {
                // console.log("IN FUNCTION")
                // console.log(value)
                }

            },

        },
        // imageResize: { modules: [ 'Resize', 'DisplaySize' ] },  9/8/20
        // imageResize: { modules: [ 'Resize', 'DisplaySize', 'Toolbar' ] },
        imageDrop: {},
    };

    // Quill.register(new Parchment.Attributor.Style('display', 'display', { 
    //     whitelist: ['width']
    // }));


    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'script',
        'align',
        'color', 'background',
        'font'
    ];


    handleChange = (value, delta, source, editor) => {
        if (source !== "user") { return }

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
     

        const deltaValue = delta.ops[1] ? 
            delta.ops[1]["insert"] :
            null
        if (deltaValue && deltaValue.image) {
            if (deltaValue.image.substring(0,4) === "data") {
                this.props.showLoading()
                this.saveImageToStorage( deltaValue.image, (downloadURL) => {
                    const quillness = this.quillRef
                    const range = quillness.getSelection();
                    quillness.deleteText(range.index, 1, "silent")
                    // const newIndex = range.index + 5
                    quillness.insertEmbed(range.index, 'image', downloadURL, "silent");
                    // callback(downloadURL);       
                    this.props.hideLoading()     
                })
            } 
        }
        this.setState({ 
            text: value,
            typingTimeout: setTimeout( () => {
                this.saveJournal()
            }, 1000)            
        })
    }


    saveJournal = () => {
        const chapterJournalId = this.props.chapterJournal.chapterJournalId ?
            this.props.chapterJournal.chapterJournalId :
            null
        const chapter = this.props.chapter
        if (chapter === null) {
            return
        }
        const bookId = chapter.bookId;
        const chapterId = chapter.chapterId;
        const editor = this.quillRef
        const delta = editor.getContents();
        const text = editor.getText();
        const htmlText = editor.root.innerHTML  //https://stackoverflow.com/questions/42058551/get-html-from-delta-on-quill
        const newContent = {
            chapterJournalId,
            bookId,
            chapterId,
            delta,
            text,
            htmlText
        }
        this.props.saveJournal(newContent)
    }

    //This function replaced 3/26/20
    // saveJournal = () => {
    //     const classroomId = this.props.classroomId
    //     const chapter = this.props.chapter
    //     if (chapter === null) {
    //         return
    //     }
    //     const editor = this.quillRef
    //     const delta = editor.getContents();
    //     const text = editor.getText();
    //     // https://stackoverflow.com/questions/42058551/get-html-from-delta-on-quill
    //     const htmlText = editor.root.innerHTML
    //     const newContent = {
    //         delta,
    //         text,
    //         htmlText
    //     }
    //     this.props.saveJournal(classroomId, chapter, newContent)
    // }

    //Set contents with quillDelta if it already exists
    setJournalText = () => {
        const editor = this.quillRef
        const chapterJournal = this.props.chapterJournal
        if (chapterJournal.quillDelta) {
            const journalQuillObj = JSON.parse(chapterJournal.quillDelta)
            editor.setContents(journalQuillObj)   
        } else {
            editor.setContents({
                "ops":[
                    {"insert":""}
                ]
            })    
        }
    }
    // This was removed on 3/26/20. This can be deleted.
    // setJournalText = () => {        
    //     const editor = this.quillRef;
    //     const chapter = this.props.chapter
    //     if (chapter !== null && chapter.journal !== undefined) {
    //         // console.log("JOURNAL HAHAHA")
    //         // console.log(chapter.journal)
    //         if (chapter.journal.quillDelta) {
    //             // const journalHtmlText = chapter.journal.htmlText
    //             const journalQuillDelta = chapter.journal.quillDelta
    //             const journalQuillObj = JSON.parse(journalQuillDelta)
    //             editor.setContents(journalQuillObj)   
    //         } else if (chapter.journal.text) {
    //             const journalText = chapter.journal.text
    //             editor.setContents({
    //                 "ops":[
    //                     {"insert": journalText}
    //                 ]
    //             })    
    //         } else {
    //             editor.setContents({
    //                 "ops":[
    //                     {"insert":""}
    //                 ]
    //             })    
    //         }
    //     } else {
    //         editor.setContents({
    //             "ops": [
    //                 { "insert": "" }
    //             ]
    //         })
    //     }
    // }


    saveImageToStorage = (b64Image, callback) => {
        console.log("THE BLOB")
        console.log(b64Image)
        const extension = b64Image.substring(11, b64Image.indexOf(";"))
        // console.log(extension)
        const imageBlob = this.b64toBlob(b64Image)
        // console.log("IN SAVE IMAGE TO STORAGE")
        // console.log(imageBlob)
        // console.log(range)
        // console.log(range.index)
        
        const chapter = this.props.chapter
        if (chapter == null) {
            return
        }
        const uid = this.props.user.uid
        const bookId = chapter.bookId;
        const chapterId = chapter.chapterId;
        const fileNameNum = Math.floor((Math.random() * 1000000) + 1);
        const fileName = fileNameNum.toString() + "." + extension
        console.log(fileName)
        const path = "journals/"+uid+"/"+bookId+"/"+chapterId+"/"+fileName
        // console.log(path)
        var metadata = {
            contentType: "image/" + extension
        };
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(path).put(imageBlob, metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                console.log("ERROR Uploading")
                console.log(error)
            }, function () {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    callback(downloadURL)
                });
            }
        );


    }

    // https://stackoverflow.com/questions/27980612/converting-base64-to-blob-in-javascript/27980815
    // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    b64toBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
    
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/png' });
    }

     // https://firebase.google.com/docs/storage/web/upload-files
     saveToFB = (file, callback) => {
        console.log("SAVE TO FB")

        const chapter = this.props.chapter
        if (chapter == null) {
            return
        }
        const uid = this.props.user.uid
        const bookId = chapter.bookId;
        const chapterId = chapter.chapterId;
        const path = "journals/"+uid+"/"+bookId+"/"+chapterId+"/"
        // console.log("IDs")
        // console.log(bookId)
        // console.log(chapterId)

         // Create a root reference
         var storageRef = firebase.storage().ref();

        // Create the file metadata
        // const extension = file.extension;
        // var metadata = {
        //     contentType: 'image/'+extension
        // };

        // console.log('HEY')
        // console.log(file.name)
        // console.log(extension)
        // Upload file and metadata to the object 'images/mountains.jpg'
        // var uploadTask = storageRef.child('images/' + file.name).put(file, metadata);
        // var uploadTask = storageRef.child('journals/'+file.name).put(file);


        let re = /(?:\.([^.]+))?$/;
        const extension = re.exec(file.name)[1];
        var metadata = {
            contentType: "image/" + extension
        };
        // promises.push(storageRef.child(storagePath).put(imageData[i], metadata));

        var uploadTask = storageRef.child(path).put(file, metadata);



        console.log("ABOUT TO SAVE")
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                console.log("ERROR Uploading")
                console.log(error)
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                // switch (error.code) {
                //     case 'storage/unauthorized':
                //         // User doesn't have permission to access the object
                //         break;

                //     case 'storage/canceled':
                //         // User canceled the upload
                //         break;
                    // ...
                    // case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                    // break;
            }, function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    callback(downloadURL);            
                });
            }
        );
    }

    // https://github.com/quilljs/quill/issues/863
    imageHandler = (e) => {
        console.log("IMAGE handler initiated")
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
  
        // Listen upload local image and save to server
        input.onchange = () => {
            console.log("IN IMAGE HANDLER")
            const file = input.files[0];
  
        //   console.log("FILE")
        //   console.log(file)
          // file type is only image.
          if (/^image\//.test(file.type)) {
            // this.saveToFB(file);

            this.saveToFB( file, (url)  => {
                console.log("CALLED BACK")
                console.log(url)
                // https://github.com/quilljs/quill/issues/863
                // push image to quill editor
                const range = this.quillRef.getSelection();
                this.quillRef.insertEmbed(range.index, 'image', url);
            });
          } else {
            console.warn('You could only upload images.');
          }
        };        
    }


    closeJournal = (e) => {
        e.preventDefault();    
        this.props.hideJournal()
    }

    componentDidMount() {
        this.attachQuillRefs()
        this.setJournalText()
        console.log("Journal DidMount STATE")
        // console.log(this.quillRef)
        console.log(this.props.state)
        
    }

    
    componentDidUpdate(prevProps) {
        console.log("Journal Didupdate")
        this.attachQuillRefs()
        if (prevProps.chapter !== this.props.chapter) {
            this.setJournalText()
            this.setState({
                chapter: this.props.chapter
            })    
        }
        if (!prevProps.chapterJournal.quillDelta && this.props.chapterJournal.quillDelta) {
            this.setJournalText()
        }
        if (prevProps.showJournal !== this.props.showJournal) {
            this.setState({
                show: this.props.showJournal
            })    
        }
    }

    render(){

        const showStyle = {
            display: "block",
            // display: this.state.show ? "block" : "none",
        };
    
        const showHideClassName = this.props.show ? "modal display-block" : "modal display-none";

        const journalPrompt = this.props.chapter ?
            this.props.chapter.journalPrompt ?
                this.props.chapter.journalPrompt :
                "" :
            ""

        const journalOLD = (
            <div className={showHideClassName}>
                <section className="modal-main">
                    {/* {children} */}
                    <p>modal contents</p>
                    <button onClick={this.props.handleClose}>close</button>
                </section>
            </div>
        )


        let saveButton = (
            <button className="btn" type="button" onClick={this.saveJournal}>
                <span>Save</span>
            </button>
        )
        const appStateHtmlText = this.props.chapterJournal.htmlText ?
            this.props.chapterJournal.htmlText :
            "<div><br></div>"
        // const editor = this.quillRef ?
        //     this.quillRef :
        //     ""
        const editorHtmlText = this.quillRef ?
            this.quillRef.root ?
                this.quillRef.root.innerHTML :
                "" :
            ""

        // console.log("HTML TEXTS")
        // console.log(appStateHtmlText)
        // console.log(editorHtmlText)
        let saveStatus = "saving..."
        if (editorHtmlText === "") {
            saveStatus = "SAVED"
        }
        if (appStateHtmlText === editorHtmlText) {
            saveStatus = "SAVED"
        }



        const journal = (
            <div className="ajs-modal" style={showStyle}>
                <div className="ajs-modal-content">
                    <div className="modal-header">
                        {/* {saveButton} */}
                        <span className="notebook-label">NOTEBOOK</span>
                        <span className="ajs-close modal-close" onClick={this.closeJournal}>&times;</span>
                    </div>

                    <div className="journal-header">
                        <span>
                            <em>Journal Prompt:</em>
                            <br />
                        </span>
                        <span>
                            {journalPrompt}
                        </span>
                    </div>

                    <ReactQuill
                        modules={this.modules}
                        formats={this.formats}
                        placeholder={'Journal here...'}
                        ref={(el) => { this.reactQuillRef = el }}
                        name="editor"
                        onChange={this.handleChange}
                        value={this.state.text}
                    />

                        <span className="save-status"> { saveStatus } </span> 



                </div>

            </div>
        )


        return (
            journal
        )
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        user: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveJournal: (classroomId, chapter, newContent) => dispatch(saveJournal(classroomId, chapter, newContent)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalJournal)
