import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClassContainer from '../containers/ClassContainer'
import ClassList from '../containers/ClassList'
import { getTeacherBooks, saveNewClassroom } from '../../store/actions/myClassroomsActions'
import { getUser, getStudentProfileFromAnonId } from '../../store/actions/authActions'
import { loginActivity, recordMount, recordWillUnmount } from '../../store/actions/userActivityActions'
import { Redirect } from 'react-router-dom' //For route guarding. Lesson 27
import Footer from "../../components/ui/navbar/Footer"

class Landing extends Component {
    
    state = {
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
    }



    render(){
        // return <Redirect to='/signin' />

        const authFB = this.props.state.firebase.auth
        const authSL = this.props.state.auth

        // console.log("AUTH FB")
        // console.log(authFB)

        // console.log("AUTH SL")
        // console.log(authSL)

        if (authFB.isEmpty) {
            return <Redirect to='/signin' />
        }
        if (authSL.isEmpty) {
            this.props.getUser()
        }
        if (authSL.uid) {
            return <Redirect to='/teacher'/>
        }
        if (authSL.uidStudent) {
            return <Redirect to='/student'/>
        }
                

        return (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )
    }
}



//These two lines give this component access to the state of the store. The state of the store is initially defined in rootReducer.js
//Reference: Complete React & Redux Tutorial #40 - Mapping State to Props
//https://www.youtube.com/watch?v=CZ2qGtAnhoE&list=PL4cUxeGkcC9ij8CfkAY2RAGb-tmkNwQHG&index=40
const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(getUser()), 
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Landing)  //mapDispatchToProps has to be the second parameter.  If there is no mapStateToProps, use null as the first parameter. 
