import React from 'react'

const ShowLink = ({fileUrl, chapterFile}) => {
    // console.log("SHOWING External Link Page")
    // console.log("Chapter FILE")
    // console.log(fileUrl)

    const message = (
        <div className="showLink-container">
            <div className="showLink-contents">
                {"Open in New Tab"}
                <p>
                    <a href={fileUrl} target="_blank" >{fileUrl}</a>
                </p>
            </div>
        </div>
    );


    return (
        message
    )
}

export default ShowLink
