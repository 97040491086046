import firebase from 'firebase/app'
const db = firebase.firestore()


//Commented out 3/28/20
// export const recordActivityFile = (activityFileNew) => {
//     return (dispatch, getState) => {
//         const activityFileOld = getState().activity.activityFile
//         // console.log("OLD ACTIVITY")
//         // console.log(activityFileOld)
//         // console.log("NEW ACTIVITY")
//         // console.log(activityFileNew)
//         if (activityFileOld.activityId === "") {
//             const activityInfo = activityFileNew
//             dispatch({ type: "ACTIVITY_ACCESS", activityInfo })    
//             return
//         }
//         if (activityFileNew === "leaving") {
//             activityFileOld["timeLeave"] = new Date()
//             recordActivityInFS(activityFileOld)    
//             dispatch({ type: "RESET_TRACKER" })
//             return
//         }
//         if (activityFileOld.activityId === activityFileNew.activityId) {return}
//         activityFileOld["timeLeave"] = new Date()
//         recordActivityInFS(activityFileOld)
//         const activityInfo = activityFileNew
//         dispatch({ type: "ACTIVITY_ACCESS", activityInfo })
//     }
// }
// const recordActivityInFS = (activityFileOld) => {
//     const collectionActivityFiles = db.collection('activityFiles')
//     console.log("about to record activity")
//     console.log(activityFileOld)
//     collectionActivityFiles.add(activityFileOld)
//     .then(function(docRef) {
//         console.log("Document written with ID: ", docRef.id);
//     })
//     .catch(function(error) {
//         console.error("Error adding Tracked Activity File: ", error);
//     });    
// }


export const recordActivityFile = (bookId, chapterId, fileId) => {
    return (dispatch, getState) => {
        const timeStamp = new Date()
        const authSL = getState().auth
        let uid = ""
        let classroomId = ""
        let activityCollection = ""
        if (authSL.uid) {
            uid = authSL.uid
            activityCollection = db.collection("activityTeacher")
            classroomId = "teacher"
        } else {
            uid = authSL.uidStudent
            activityCollection = db.collection("activityStudent")
            classroomId = authSL.classroomId ?
                authSL.classroomId :
                "none"
        }
        const activityFileOld = getState().activity.activityFile
        if (activityFileOld !== null) {
            recordLeaveInFS(activityCollection, activityFileOld.docId, timeStamp)
        }
        const activityFileNew = {
            classroomId,
            bookId,
            chapterId,
            fileId,
            uid,
            timeAccess: timeStamp,
            device: "web"
        }
        activityCollection.add(activityFileNew)
            .then(function (docRef) {
                console.log("New activity file added in FS!");
                activityFileNew["docId"] = docRef.id
                dispatch({ type: "ACTIVITY_ACCESS", activityFileNew })
            })
            .catch(function (error) {
                console.error("Error adding new activity file to FS: ", error);
            });
    }
}
const recordLeaveInFS = (activityCollection, activityId, timeStamp) => {
    activityCollection.doc(activityId).update({
        timeLeave: timeStamp
    })
        .then(function () {
            console.log("Activity leave time updated in FS!");
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating leave time: ", error);
        });
}


export const recordActivityLeave = () => {
    return (dispatch, getState) => {
        // dispatch({ type: "ACTIVITY_LEAVE" })

        const timeStamp = new Date()
        const authSL = getState().auth
        let activityCollection = ""
        if (authSL.uid) {
            activityCollection = db.collection("activityTeacher")
        } else {
            activityCollection = db.collection("activityStudent")
        }
        const activityFileOld = getState().activity.activityFile
        if (activityFileOld !== null) {
            recordLeaveInFS(activityCollection, activityFileOld.docId, timeStamp)
            dispatch({ type: "ACTIVITY_LEAVE" })
        }
    }
}




export const recordMount = (page) => {
    return (dispatch, getState) => {
        dispatch({ type: "RECORD_MOUNT", page })
        // const activityState = getState().activity;
        // console.log("ACTIVITY STATE")
        // console.log(activityState)
    };
};

export const recordWillUnmount = (page) => {
    return (dispatch, getState) => {
        const state = getState()
        const existingPage = state.activity.page
        const pageToRecord = {
            ...existingPage,
            timeLeave: new Date()
        }
        // console.log("IN recordWillUnmount")
        // console.log(existingPage)
        if (page === existingPage.page) {
            dispatch({ type: "RECORD_UNMOUNT" })
            const dayDetails = getDayDetails(state);

            let toRecord = {
                ...dayDetails,
                pages: firebase.firestore.FieldValue.arrayUnion(pageToRecord)
            }
            recordInUserActivity(toRecord);

            toRecord = {
                ...dayDetails,
                ...pageToRecord
            }
            recordInActivityPages(toRecord);
        }

        // const activityState = getState().activity;
        // console.log("ACTIVITY STATE after unmount")
        // console.log(activityState)
    };
};

const recordInUserActivity = (toRecord) => {
    const refUserAccess = toRecord.refUserAccess;
    delete toRecord.refUserAccess
    refUserAccess.set({
        ...toRecord
    }, { merge: true })
        .then(function () {
            // console.log("Document successfully written!");
        })
        .catch(function (error) {
            // console.error("Error writing document: ", error);
        });
}

const recordInActivityPages = (toRecord) => {
    const refActivityPages = db.collection('activityPages')
    delete toRecord.lastAccess
    delete toRecord.refUserAccess

    refActivityPages.add({
        ...toRecord
    })
        .then(function () {
            // console.log("Document successfully written!");
        })
        .catch(function (error) {
            // console.error("Error writing document: ", error);
        });

}

const getDayDetails = (state) => {
    const uid = state.firebase.auth.uid;
    const userEmail = state.firebase.auth.email ?? "";
    const userName = state.firebase.auth.displayName ?? "";
    const nowDate = Date.now()
    const daysSince1970 = Math.trunc(nowDate / 86400000);
    const docId = daysSince1970.toString() + '_' + uid;
    let refUserAccess = db.collection('userActivity').doc(docId);
    const dayDetails = {
        refUserAccess: refUserAccess,
        uid: uid,
        userName: userName,
        userEmail: userEmail,
        lastAccess: new Date(),
        device: "Web",
    }
    return dayDetails
}

// 1st function
export const loginActivity = () => {
    return (dispatch, getState) => {
        const state = getState()
        const uid = state.firebase.auth.uid;
        const userEmail = state.firebase.auth.email ?? "";
        const userName = state.firebase.auth.displayName ?? "";
        const nowDate = Date.now()
        const daysSince1970 = Math.trunc(nowDate / 86400000);
        const docId = daysSince1970.toString() + '_' + uid;
        let refUserAccess = db.collection('userActivity').doc(docId);

        // console.log("IN LOGIN Activity")
        // console.log(newDate)
        // console.log(nowDate)
        // console.log(daysSince1970)
        // console.log(refUserAccess)

        refUserAccess.set({
            "uid": uid,
            "userName": userName,
            "userEmail": userEmail,
            "lastAccess": new Date(),
            "device": "Web",
            pages: firebase.firestore.FieldValue.arrayUnion({
                page: "Classroom",
                accessTime: new Date(),
                leaveTime: new Date(),
            })
        }, { merge: true })
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });

        // refUserAccess.update({
        //     pages: firebase.firestore.FieldValue.arrayUnion({
        //         page: "Classroom",
        //         accessTime: new Date(),
        //         leaveTime: new Date(),
        //     })
        // })
        //         .then(function () {
        //             console.log("Document successfully written!");
        //         })
        //         .catch(function (error) {
        //             console.error("Error writing document: ", error);
        //         });
    };
};
