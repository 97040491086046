import firebase from '../../../config/fbConfig'
const db = firebase.firestore()

export const getAllUsers = () => {
    return (dispatch, getState) => {
        let usersAll = []
        const userCollection = db.collection("users")
        userCollection.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                usersAll.push({
                    ...doc.data(),
                    userId: doc.id
                })
            });
            dispatch({ type: 'SET_ALL_USERS', usersAll })
        }).catch(function(error) {
            console.log("Error getting all user documents:", error);
        });
    }
}

export const searchUsers = (searchText, targetId) => {
    return (dispatch, getState) => {
        let searchField = "user_name"
        console.log(targetId)
        if (targetId === "emailSearch") { searchField = "email" }
        let usersFound = []
        const userCollection = db.collection("users")
        const query = userCollection
            .orderBy(searchField).startAt(searchText).endAt(searchText + '~')
            .limit(10)
        query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                usersFound.push({
                    ...doc.data(),
                    userId: doc.id
                });
            })
            dispatch({ type: 'SET_USERS_FOUND', usersFound })
        }).catch(function (error) {
            console.log("Error getting all user documents:", error);
        });
    }
}

export const clearSearch = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SEARCH' })
    }
}

export const clearOrders     = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'CLEAR_ORDERS' })
    }
}

export const updateRole = (userId, value) => {
    return (dispatch, getState) => {
        const refUser = db.collection("users").doc(userId);
        refUser.update({
            role: value
        })
            .then(function () {
                console.log("User role updated")
            })
            .catch(function (error) {
                console.error("Error updating user role: ", error);
            });
    }
}

export const getOrders = (userId) => {
    return (dispatch, getState) => {

        dispatch({ type: "SHOW_SPINNER" })

        let allBooks = getState().adminData.allBooks

        if ( !allBooks ) {
            getAllBooks( (retrievedBooks) => {
                if ( !retrievedBooks ) { return }
                allBooks = retrievedBooks

                getUserOrders( userId, (userOrders) => {
                    if ( !userOrders ) { return }

                    const orders = createOrderRows(allBooks, userOrders)

                    dispatch({ type: 'SET_ALL_BOOKS', allBooks })
                    dispatch({ type: 'SET_ORDERS', orders, userId })
                    dispatch({ type: "HIDE_SPINNER" })
                })
            })
        }
        else {
            getUserOrders( userId, (userOrders) => {
                if ( !userOrders ) { return }

                const orders = createOrderRows(allBooks, userOrders)

                dispatch({ type: 'SET_ALL_BOOKS', allBooks })
                dispatch({ type: 'SET_ORDERS', orders, userId })
                dispatch({ type: "HIDE_SPINNER" })
            })
        }
    }
}

const createOrderRows = (allBooks, userOrders, existingOrderRows) => {
    const orderRows = []
    if (existingOrderRows) { orderRows.push(...existingOrderRows) }

    userOrders.forEach( (order) => {
        const book = allBooks[order.itemId]
        if ( !book ) { return }
        let createDate = "unknown"
        let expirationDate = "unknown"
        let howOrdered = order.purchaseOrderId

        if (order.createTime) {
            if (order.createTime.seconds) {
                createDate = new Date(order.createTime.seconds * 1000).toLocaleDateString("en-US")
            } else {
                createDate = (new Date(order.createTime)).toLocaleDateString("en-US")
            }
            if (order.daysPurchased) {
                expirationDate = getExpirationDate(order.createTime, order.daysPurchased)
            }
        }
    
        if ( howOrdered === "from book code" ) {
            howOrdered = "Book Code: " + order.bookCodeDetails.bookCode
        }

        const isExpired = getIsExpired(order.createTime, order.daysPurchased)

        orderRows.push({
            ...order,
            book,
            createDate,
            expirationDate,
            isExpired,
            howOrdered
        })
    })

    orderRows.sort( (a,b) => {
        const aAuthor = a.book.book_author
        const bAuthor = b.book.book_author
        const aTitle = a.book.title
        const bTitle = b.book.title

        if (aAuthor === bAuthor) {
            return (aTitle < bTitle) ? -1 : (aTitle > bTitle) ? 1 : 0;
        }
        else
        {
            return (aAuthor < bAuthor) ? -1 : 1;
        }        
    })

    return orderRows
}

const getExpirationDate = (date, daysPurchased) => {
    // 86,400 seconds/day 
    let milliseconds = date.seconds * 1000
    if (date.seconds === undefined) {
        milliseconds = (new Date(date)).getTime()
    }
    const expirationMilliseconds = milliseconds + (daysPurchased * 86400 * 1000)
    const expirationString = new Date(expirationMilliseconds).toLocaleDateString("en-US")
    return expirationString
}

const getIsExpired = (createTime, daysPurchased) => {
    let createTimeSeconds = createTime.seconds
    if (createTime.seconds === undefined) {
        createTimeSeconds =  Math.round(new Date(createTime).getTime() / 1000)
    }

    const expirationSeconds = createTimeSeconds + daysPurchased * 86400
    const todaySeconds = new Date().getTime() / 1000
    return todaySeconds > expirationSeconds
}

export const getBooks = () => {
    return (dispatch, getState) => {
        getAllBooks( (allBooks) => {
            if ( !allBooks ) { return }
            dispatch({ type: 'SET_ALL_BOOKS', allBooks })
        })
    }
}

export const updateDays = (orderId, daysPurchasedString) => {
    return (dispatch, getState) => {

        let daysPurchased = parseInt(daysPurchasedString, 10)
        if ( daysPurchasedString === "" ) { daysPurchased = 0 }

        const purchaseAccess = db.collection("purchaseAccess").doc(orderId);
        purchaseAccess.update({
            daysPurchased: daysPurchased
        })
            .then(function () {
                
                const orders = getState().adminData.orders

                const index = orders.findIndex( (order) => {
                    return order.orderId === orderId
                })
                const order = orders[index]
                const expirationDate = getExpirationDate(order.createTime, daysPurchased)
                const isExpired = getIsExpired(order.createTime, daysPurchased)

                orders[index] = {
                    ...orders[index],
                    daysPurchased,
                    expirationDate,
                    isExpired
                }

                dispatch({ type: 'SET_ORDERS', orders })

            })
            .catch(function (error) {
                console.error("Error updating days purchased: ", error);
            });
    }
}

export const getMostUsedBooks = (bookId, chapterId) => {
    return (dispatch, getState) => {
        getAllClassrooms((allClassrooms) => {
            getAllBooks((allBooks) => {
                let objs = [
                    // {
                    // bookId:
                    // count: 
                    // }
                ]
                let booksMap = {}
                const activityStudent = db.collection("activityStudent")
                activityStudent.get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        const bookId = doc.data().bookId
                        const classroomId = doc.data().classroomId
                        // if (!allClassrooms[classroomId]) {return}  
                        const classroom = allClassrooms[classroomId]
                        if (!classroom) { return }
                        const teacherId = classroom.teacherId
                        if (!booksMap[bookId]) {
                            booksMap[bookId] = {
                                count: 1,
                                teachers: {
                                    [teacherId]: {
                                        count: 1,
                                        ...classroom
                                    }
                                }
                            }
                        } else if (!booksMap[bookId]["teachers"][teacherId]) {
                            booksMap[bookId] = {
                                ...booksMap[bookId],
                                count: booksMap[bookId].count + 1,
                                teachers: {
                                    ...booksMap[bookId]["teachers"],
                                    [teacherId]: {
                                        count: 1,
                                        ...classroom
                                    }
                                }
                            }
                        } else {
                            booksMap[bookId] = {
                                ...booksMap[bookId],
                                count: booksMap[bookId].count + 1,
                                teachers: {
                                    ...booksMap[bookId]["teachers"],
                                    [teacherId]: {
                                        ...booksMap[bookId]["teachers"][teacherId],
                                        count: booksMap[bookId]["teachers"][teacherId].count + 1,
                                    }
                                }
                            }
                        }
                    });
                    const hhh = Object.entries(booksMap)
                    hhh.forEach((h) => {
                        const bookId = h[0]
                        const teachers = h[1].teachers
                        const count = h[1].count
                        const title = allBooks[bookId].title ? allBooks[bookId].title : ""
                        objs.push(
                            {
                                count,
                                title,
                                teachers,
                                bookId
                            }
                        )
                    })
                    // hhh.sort((a, b) => (a.count > b.count) ? 1 : -1)
                    objs.sort((a, b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));
                    const toDisplay = getUsedToDisplay(objs)

                    console.log("SORTED")
                    console.log(objs)
                    console.log("ARRAY")
                    console.log(toDisplay)
                    dispatch({ type: 'SET_USED', objs, toDisplay })

                });
            })

        })
    }
}
const getAllClassrooms = (callback) => {
    let teachers = {}
    const teachersCollection = db.collection("users")
    console.log("Retrieving all teachers...")
    teachersCollection.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            teachers[doc.id] = doc.data()
        });
        let allClassrooms = {}
        const classroomsCollection = db.collection("classrooms_v3")
        console.log(teachers)
        console.log("Retrieving all classrooms...")
        classroomsCollection.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                const teacherId = doc.data().teacherUid
                if (!teachers[teacherId]) { return }
                const classTitle = doc.data().title
                const teacherEmail = teachers[teacherId] ? teachers[teacherId].email : "no email"
                // console.log("EMAILSSSSS")
                // console.log(teachers[teacherId])
                const teacherName = teachers[teacherId] ? teachers[teacherId].user_name : "no name"
                allClassrooms[doc.id] = {
                    teacherEmail, teacherName, teacherId,
                    // classTitle, 
                }
            });
            callback(allClassrooms)
        });
    });
}
const getAllBooks = (callback) => {
    let allBooks = {}
    const booksCollection = db.collection("books")
    console.log("Retrieving all books...")
    booksCollection.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            allBooks[doc.id] = {
                ...doc.data(),
                bookId: doc.id
                }
        });
        callback(allBooks)
    })
    .catch(function(error) {
        console.log("Error getting all books:", error);
        callback(null)
    });
};

const getUserOrders = (userId, callback) => {
    let userOrders = []
    const userOrder = db.collection("purchaseAccess")
    const query = userOrder.where("payerUid", "==", userId)
    query.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            userOrders.push({
                ...doc.data(),
                orderId: doc.id
            });
        })
        callback(userOrders)
    }).catch(function (error) {
        console.log("Error getting user orders:", error);
        callback(null)
    });
}

const getUsedToDisplay = (objs) => {
    let toDisplay = []
    objs.forEach( (obj) => {
        const bookId = obj.bookId
        const bookTitle = obj.title
        const bookCount = obj.count
        const teacherArr = Object.values(obj.teachers)
        teacherArr.forEach( (teacher) => {
            const teacherId = teacher.teacherId
            const teacherName = teacher.teacherName
            const teacherEmail = teacher.teacherEmail
            const teacherCount = teacher.count
            toDisplay.push(
                { bookId, bookTitle, bookCount, teacherId, teacherName, teacherEmail, teacherCount }
            )
        })
    })
    return toDisplay
}


export const createOrders = (payerUid, purchaseOrderId, newDate, daysPurchased, books) => {
    return (dispatch, getState) => {

        dispatch({ type: "SHOW_SPINNER" })
        
        const batch = db.batch();
        const paRef = db.collection("purchaseAccess")
        const docs = books.map(itemId => {
            return {
                createTime: newDate,
                daysPurchased: daysPurchased,
                itemId: itemId,
                payerUid: payerUid,
                purchaseOrderId: purchaseOrderId,
                quantity: 1,
                updatedOn: newDate
            }
        })

        const orderIds = []

        docs.forEach(doc => {
            const newDoc = paRef.doc()
            orderIds.push(newDoc.id)
            batch.set(newDoc, doc)
        })

        batch.commit()
            .then(() => {
                console.log("Finished adding orders")
                const ordersWithIds = getOrdersWithIds(docs, orderIds)
                const orders = createOrderRows(getState().adminData.allBooks, ordersWithIds, getState().adminData.orders)
                const userId = payerUid
                console.log("ORDERS")
                console.log(orders)
                dispatch({ type: "SET_ORDERS", orders, userId })
                dispatch({ type: "HIDE_SPINNER" })
            })
            .catch((error) => {
                console.error("Error adding orders: ", error);
            });
    }
}
const getOrdersWithIds = (docs, orderIds) => {
    const ordersWithIds = docs.map( (doc, index) => { 
        return {
            ...doc,
            orderId: orderIds[index]
        }
    })    
    return ordersWithIds
}


export const getBookCodes = () => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        const bookCodes = []
        const bookCodeRef = db.collection("bookCodes")
        bookCodeRef.get().then( (querySnapshot) => {
            querySnapshot.forEach( (doc) => {
                bookCodes.push({
                    ...doc.data(),
                     bookCode: doc.id,
                     bookCodeLowerCase: doc.id.toLowerCase()
                })
            });
            bookCodes.sort((a, b) => (a.bookCodeLowerCase < b.bookCodeLowerCase) ? -1 : ((b.bookCodeLowerCase < a.bookCodeLowerCase) ? 1 : 0));
            dispatch({ type: "SET_BOOKCODES", bookCodes })
            dispatch({ type: "HIDE_SPINNER" })
        })
        .catch( (error) => {
            console.error("Error getting book codes:", error);
        });
    }
}


export const getOrdersForBookCode = (bookCodeObj) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })

        // // get the books for the book code

        // // get the purchaseAccess documents for the book code (this will be a lot)

        // // from the purchaseAccess documents get
        //     // payerUid
        //     // createTime
        //     // daysPurchased
        //     // Calculate expiration date

        // // Filter the purchaseAccess documents so that there is one unique document for each payerUid && bookCode


        


        // getBooksForBookCode( bookCodeObj, (books) => {

        // })


        



    




        // dispatch({ type: "SHOW_SPINNER" })

        // let allBooks = getState().adminData.allBooks

        // if ( !allBooks ) {
        //     getAllBooks( (retrievedBooks) => {
        //         if ( !retrievedBooks ) { return }
        //         allBooks = retrievedBooks

        //         getUserOrders( userId, (userOrders) => {
        //             if ( !userOrders ) { return }

        //             const orders = createOrderRows(allBooks, userOrders)

        //             dispatch({ type: 'SET_ALL_BOOKS', allBooks })
        //             dispatch({ type: 'SET_ORDERS', orders, userId })
        //             dispatch({ type: "HIDE_SPINNER" })
        //         })
        //     })
        // }
        // else {
        //     getUserOrders( userId, (userOrders) => {
        //         if ( !userOrders ) { return }

        //         const orders = createOrderRows(allBooks, userOrders)

        //         dispatch({ type: 'SET_ALL_BOOKS', allBooks })
        //         dispatch({ type: 'SET_ORDERS', orders, userId })
        //         dispatch({ type: "HIDE_SPINNER" })
        //     })
        // }





        // const bookCodes = []
        // const bookCodeRef = db.collection("bookCodes")
        // bookCodeRef.get().then( (querySnapshot) => {
        //     querySnapshot.forEach( (doc) => {
        //         bookCodes.push({
        //             ...doc.data(),
        //              bookCode: doc.id,
        //              bookCodeLowerCase: doc.id.toLowerCase()
        //         })
        //     });
        //     bookCodes.sort((a, b) => (a.bookCodeLowerCase < b.bookCodeLowerCase) ? -1 : ((b.bookCodeLowerCase < a.bookCodeLowerCase) ? 1 : 0));
        //     dispatch({ type: "SET_BOOKCODES", bookCodes })
        //     dispatch({ type: "HIDE_SPINNER" })
        // })
        // .catch( (error) => {
        //     console.error("Error getting book codes:", error);
        // });
    }
}

const getBooksForBookCode = (bookCodeObj, callback) => {
    // const promises = []
    // const books = []
    // const bookCollection = db.collection("books")
    // const bookIds = bookCodeObj.books
    // bookIds.forEach( (bookId) => {
    //     const bookDoc = bookCollection.doc(bookId)
    //     promises.push(bookDoc.get())
    // })
    // Promise.all(promises).then( (results) => {
    //     results.forEach( (doc, index) => {
    //         if (doc.exists) {
    //             purchasedBooks[doc.id] = {
    //                 ...doc.data(),
    //                 bookId: doc.id
    //             }
    //         }
    //     })
    //     callback(purchasedBooks)
    // }, function(err) {
    //     console.error('Could not retrieve the books for the book code', err);
    // })



}


// This was copied from myClassroomActions.js
const getPurchasedBooksDetail = (paByBookId, callback) => {    
    let purchasedBooks = {}
    const promises = [];
    const purchasedBookIdsArr = Object.keys(paByBookId)
    purchasedBookIdsArr.forEach( (bookId) => {
        const bookDoc = db.collection("books").doc(bookId)
        promises.push(bookDoc.get())
    })
    Promise.all(promises)
        .then((results) => {
            results.forEach((doc, index) => {
                if (doc.exists) {
                    purchasedBooks[doc.id] = {
                        ...doc.data(),
                        bookId: doc.id
                    }
                }
            })
            callback(purchasedBooks)
        }, function (error) {
            console.error('Error retrieving the books for the book code', error);
        })
        .catch(function (error) {
            console.error("Error retrieving the books for the book code", error);
    })
}

