import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { setStudentReportInfo, sortStudentReport } from '../../../store/actions/reportActions'

// const chapterId = this.props.match.params.chapter_id;

class ClassReportStudent extends Component {
    state = { 
        sortOrderIsAscending : {
            bookTitle: false,
            chapterTitle: false,
            fileTitle: false,
            hasTick: false,
            hasJournal: false,
            fileId: false,
            lastDateSecs: false,
            totalTimeSecs: false,
        }
    }

    componentDidMount() {
        this.props.setStudentReportInfo(this.props.classroomId, this.props.studentId)
    }

    componentDidUpdate(prevProps, prevState) {
    }


    //UI Functions
    sort = (sortBy) => {
        const sortOrder = !this.state.sortOrderIsAscending[sortBy]
        this.props.sortStudentReport(sortBy, sortOrder)
        // const sortOrderIsAscending = this.state.sortStudentReport
        // sortOrderIsAscending[sortBy] = sortOrder
        this.setState({ 
            sortOrderIsAscending: {
                ...this.state.sortOrderIsAscending,
                [sortBy]: sortOrder
            }
         })
    }


    //Helper Functions

    //Render Functions
    getHeader = () => {
        const classroomName = this.props.state.reports.toDisplayOneStudent.classroomTitle
        const studentName = this.props.state.reports.toDisplayOneStudent.studentName
        return (
            <div className="header-oneclassroom">
                {classroomName + " - " + studentName}
            </div>
        )
    }

    getTable = () => {
        return (
            <div className="tableWrap-oneclassroom" >
                <table className="table-oneclassroom">
                    <thead>
                        <tr>
                            {/* <th className="col-30-header" onClick={() => this.clickedSortField("name")}> */}
                            <th className="col-14-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("bookTitle")}> Book </span>
                            </th>
                            <th className="col-14-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("chapterTitle")}> Chapter </span>
                            </th>
                            <th className="col-14-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("fileTitle")}> File </span>
                            </th>
                            <th className="col-22-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("lastDateSecs")}> Last Activity </span>
                            </th>
                            <th className="col-16-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("totalTimeSecs")}> Time </span>
                            </th>
                            <th className="col-10-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("hasTick")}> Mastery </span>
                            </th>
                            <th className="col-10-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort("hasJournal")}> Note </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getUserRows()}
                    </tbody>
                </table>
            </div >
        )
    }

    getUserRows = () => {
        const rows = this.props.state.reports.toDisplayOneStudent.rows
        const userRowElements = rows.map( (row) => {
            let tick = "-"
            let journal = "-"
            if (row.hasTick) {
                tick = (<i className="material-icons">done</i>)
            }

            if (row.hasJournal) {
                journal = (<i className="material-icons">done</i>)
            }
            return (
                <tr key={row.fileId}>
                    <td className="col-14-body tdlastactivity-oneclassroom" >
                        {row.bookTitle}
                    </td>
                    <td className="col-14-body tdlastactivity-oneclassroom" >
                        {row.chapterTitle}
                    </td>
                    <td className="col-14-body" >
                        {row.fileTitle}
                    </td>
                    <td className="col-22-body" >
                        {row.lastDate}
                    </td>
                    <td className="col-16-body" >
                        {row.totalTime}
                    </td>
                    <td className="col-10-body" >
                        {tick}
                    </td>
                    <td className="col-10-body" >
                        {journal}
                    </td>
                </tr>
            )
        })
        return userRowElements            
    }


    render(){
    
        let toDisplay = (
            
            <div className="myclassrooms-container">
                {/* <div className="loader"></div> */}
                {"Hi this is One student " + this.props.studentId + " " + this.props.classroomId}
            </div>
        )

        toDisplay = (
            <div className="oneclassrooms-container">
                {this.getHeader()}
                {this.getTable()}
            </div>
        )

        

        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStudentReportInfo: (classroomId, studentId) =>  dispatch(setStudentReportInfo(classroomId, studentId)),
        sortStudentReport: (sortBy, sortOrder) =>  dispatch(sortStudentReport(sortBy, sortOrder)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassReportStudent)

