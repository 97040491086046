import firebase from '../../config/fbConfig'
const db = firebase.firestore()

let books = {};
let chapters = {};

export const getChapters = () => {
    //with thunk, we return a function instead of an object with an action
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        //make async call
        //Reference: Lesson 18/40
        //https://www.youtube.com/watch?v=JA1Z0u4dr0E&list=PL4cUxeGkcC9iWstfXntcj8f-dFZ4UtlN3&index=18

        books = getState().books.books;
        getChaptersFromFB( () => {
            dispatch({ type: "SET_CHAPTERS", chapters })
        });

    };
};


const getChaptersFromFB = (callback) => {
    const promises = [];
    const collection = db.collection('chapters_v2');
    const bookKeys = Object.keys(books);
    for (var i = 0; i < bookKeys.length; i++) {
        const query = collection.where('bookId', '==', bookKeys[i]);
        promises.push(query.get());
    }
    Promise.all(promises).then( (results) => {
        results.forEach( (querySnapshot, index) => {
            querySnapshot.forEach( (doc) => {
                chapters[doc.id] = doc.data();
            })
        })
        callback();
    }, function(err) {
        console.log('COULD NOT RETRIEVE chapters');
    });    
};
