import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/ui/navbar/Navbar'
import Policies from "./components/pages/policies/Policies"
import Landing from './components/pages/Landing'
import ClassPage from './components/pages/ClassPage'
import ClassPageStudent from './components/pages/ClassPageStudent'
import Story from './components/pages/storypage/StoryPage'
import StorePage from './components/pages/storePage/StorePage'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import SignUpStudent from './components/auth/SignUpStudent'
import ViewStats from './components/pages/viewStats/ViewStats'
import ViewJournals from './components/pages/viewStatsJournals/ViewJournals'
import ViewProgress from './components/pages/viewStatsProgress/ViewProgress'
import Publisher from './components/pages/publisherPages/Publisher'
import BookEdit from './components/pages/publisherPages/BookEdit'
import ChapterEdit from './components/pages/publisherPages/ChapterEdit'
import MyClassrooms from './components/pages/myClassrooms/MyClassrooms'
import SampleESL from './components/pagesSamples/SampleESL'
import SampleResume from './components/pagesSamples/SampleResume'
import AdminUsers from './components/pages/adminPages/AdminUsers'
import AdminUsed from './components/pages/adminPages/AdminUsed'
import AdminOrders from './components/pages/adminOrders/AdminOrders'
import AdminBookCodes from './components/pages/adminBookCodes/AdminBookCodes'
import ClassReport from './components/pages/classReport/ClassReport'
import JournalReport from './components/pages/journalReport/JournalReport'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <div style={{ backgroundColor: '#83c5be' }}> */}
        <div>
          <header className="nav-header">
            <nav>
              <Navbar />
            </nav>
          </header>
          <Switch>

            <Route exact path='/' component={Landing} />
            <Route path='/teacher' component={ClassPage} />
            <Route path='/student' component={ClassPageStudent} />
            <Route path='/Story/:book_id/:chapter_id' component={Story} />
            {/* <Route path='/Story' component={Story} /> */}
            <Route path='/SignIn' component={SignIn} />
            <Route path='/SignUp' component={SignUp} />
            <Route path='/Store' component={StorePage} />
            {/* <Route path='/CreateClass/:classroomId' component={CreateClass} /> */}
            <Route path='/Policy/:policy' component={Policies} />
            <Route path='/ViewStats/:classroomId/:bookId/:chapterId' component={ViewStats} />
            <Route path='/ViewJournals/:classroomId/:studentId' component={ViewJournals} />
            <Route path='/ViewProgress/:classroomId/:studentId/:bookId' component={ViewProgress} />
            <Route path='/Publisher' component={Publisher} />
            <Route path='/BookEdit/:bookId' component={BookEdit} />
            <Route path='/ChapterEdit/:chapterId' component={ChapterEdit} />
            <Route path='/MyClassrooms/:classroomId' component={MyClassrooms} />
            <Route path='/ESL' component={SampleESL} />
            <Route path='/resume' component={SampleResume} />
            <Route path='/joinclass/:classCode' component={SignUpStudent} />
            <Route path='/adminusers/' component={AdminUsers} />
            <Route path='/adminused/' component={AdminUsed} />
            <Route path='/adminorders/' component={AdminOrders} />
            <Route path='/adminbookcodes/' component={AdminBookCodes} />
            <Route path='/classreport/:classroomId/:studentId' component={ClassReport} />
            <Route path='/journalreport/:classroomId' component={JournalReport} />

          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

