import React from 'react'
import {Link} from "react-router-dom"

const BookCard = ({books, classroomId}) => {
// const BookCard = ({books}) => {

    // console.log(books)

    books.sort((a, b) => (a.title > b.title) ? 1 : -1)        
    const bookList = books.map(book => {
        // const imageUrl = book.bookCoverImage.firebase_storage_url
        const coverUrl = book && book.bookCoverImage ? book.bookCoverImage.firebase_storage_url : null;
        
        const chapterId = book.chapterOrderArr ? book.chapterOrderArr[0] : "Chapter";

        return (
            <div className="grid-item" key={book.bookId}>
                <Link className="wrapping-link" to={'/Story/' + book.bookId + '/' + chapterId}></Link>
                {/* <Link className="wrapping-link" to={'/Story/' + classroomId + '/' + book.bookId + '/' + chapterId}></Link> */}
                {/* <Link className="wrapping-link" to={'/'}></Link> */}
                <div className="grid-item-wrapper">
                    <div className="grid-item-container">
                        <div className="grid-image-top">
                            <img className="centeredajs" src={coverUrl} alt="Book Cover" />
                        </div>
                            {/* <span className="card-title">{book.title}</span> */}
                            {/* <a className="btn-floating halfway-fab waves-effect waves-light red"><i className="material-icons">add</i></a> */}
                        <div className="grid-item-content">
                            <span className="item-title">{book.title}</span>
                            {/* <span className="item-category">Infrastructure as Code</span> */}
                            {/* <span className="item-excerpt">REX-Ray is a container storage orchestration engine enabling persistence fo...</span> */}
                            {/* <span className="more-info">View Project <i className="fas fa-long-arrow-alt-right"></i></span> */}
                        </div>                        
                    </div>
                </div>
            </div>

        )
    })
    

    return (
        
        <div className="grid-row">
            {bookList}
        </div>
        )
}

export default BookCard


// const classList = classrooms.map(classroom => {
//     return (
//     <div className="row" key={classroom.id}>
//         <h3>{classroom.title}</h3>
//         <div className="col s4 l3"> <BookCard books={classroom.books}/> </div>
//     </div>            
//     )
// })
