const initState = {
    authError: null,
    isEmpty: true,
    joinClass: null,
    pwError: "",
    joinSuccess: false,
    showNoAccess: false,
    publishers: []
    // joinClass: "error"
    // joinClass: {
    //     classroom: {},
    //     anonId: ""
    // }
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
        // TODO - fix authError so it displays the correct error from action. will have to alater authActions as well so it sends the correct error message in each case
            return {
                authError: "Incorrect Password, Please try again"
            }
        case 'LOGIN_SUCCESS':
            return {
                ...action.user,
                authError: null
            }
        case 'BLOCK_ACCESS':
            return {
                ...state,
                showNoAccess: true
            }
        case 'CLOSE_NO_ACCESS':
            return {
                state,
                showNoAccess: false
            }
        case 'SIGNOUT_SUCCESS':
            return {
                authError: null,
                isEmpty: true,
                joinClass: null,
                pwError: "",
                joinSuccess: false,
                showNoAccess: false,            
            }    
        case 'SIGNUP_SUCCESS':
            return {
                // ...state,
                ...action.userRecorded,
                authError: null
            }
        case 'SIGNUP_ERROR':
            return {
                authError: action.err.message
            }
        case "LOGIN_STUDENT":
            return {
                ...action.studentUser,
                authError: null,
                joinClass: null,
                pwError: ""
            }

        // For joinClass
        case 'RETRIEVED_CLASS':            
            const classroom = action.classData.classroom
            const anonId = action.classData.anonId
            return {            
                authError: null,
                isEmpty: false,
                joinClass: null,
                pwError: "",
                joinSuccess: false,
                joinClass: {
                    classroom,
                    anonId    
                }
            }
        case 'RETRIEVED_CLASS_ERROR':
            return {
                authError: null,
                joinSuccess: false,
                joinClass: "error"
            }
        case 'PW_NOT_UNIQUE':
            return {
                ...state,
                pwError: "Please choose a different password"
            }
        case 'STUDENT_JOINED':
            console.log("STUDENT JOINED!!!!")
            return {
                ...state,
                ...action.studentUser,
                authError: null,
                pwError: "",
                joinSuccess: true,
            }
        case 'RESET_PW_ERROR':
            return{
                ...state,
                pwError: ""
            }
        case 'RESET_SUCCESS':
            return {
                ...state,
                joinSuccess: false
            }
        case 'SET_PUBLISHERS':
            return {
                ...state,
                publishers: action.publishers
            }

        default:
            return state
    }
}

export default authReducer