//This is just an example action
//Dispatch goes to (calls?) the reducer.
//Reference 43/44
//https://www.youtube.com/watch?v=sY1mPVaUYds&index=43&list=PL4cUxeGkcC9ij8CfkAY2RAGb-tmkNwQHG
//As an example, this is called in StoryPage
//Actions always return a type and an optional payload
export const changeTitle = (bookId) => {
    return {
        type: 'CHANGE_TITLE',
        bookId  //This is equivalent to bookId: bookId.  If you have a property and value with the same name, you can just type it once.
    }

}
