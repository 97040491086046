import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateRole } from '../adminPages/actionsAdmin'

class UserTable extends Component {
    state = {
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if ( this.props.selectedUser && this.selectRole ) {
            this.selectRole.value = this.props.selectedUser.role
        }
    }

    roleSelected = (e) => {
        e.persist()
        const userId = this.props.selectedUser.userId
        const value = e.target.value
        if (value === "Admin" || value === "Student") { 
            this.selectRole.value = this.props.selectedUser.role
            return }
        this.props.updateRole(userId, value)
    }

    getDate = () => {
        const timeStamp = this.props.selectedUser.createTime
        if ( !timeStamp ) { return "unknown" }
        const time = new Date(timeStamp.seconds * 1000).toLocaleDateString("en-US")
        return time
    }


    render(){
        if ( !this.props.selectedUser ) return null
        return (
            <div className="ao-tableWrap" >
                <table className="table-oneclassroom">
                    <thead>
                        <tr>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" > First Name </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" > Last Name </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" > Role </span>
                            </th>
                            <th className="col-40-header" >
                                <span className="colhead-adminusers" > Email </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" > Date  </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-15-body tdlastactivity-oneclassroom">
                                {this.props.selectedUser.firstName}
                            </td>
                            <td className="col-15-body tdlastactivity-oneclassroom">
                                {this.props.selectedUser.lastName}
                            </td>
                            <td className="col-15-body">
                                <select className="select-css" id="mySelect" onChange={this.roleSelected} ref={ref => (this.selectRole = ref)}>
                                    <option value="Student">
                                        Student
                                    </option>
                                    <option value="Teacher">
                                        Teacher
                                    </option>
                                    <option value="Publisher">
                                        Publisher
                                    </option>
                                    <option value="Publisher+">
                                        Publisher+
                                    </option>
                                    <option value="Admin">
                                        Admin
                                    </option>
                                </select>

                                {/* {this.state.selectedUser.role} */}
                            </td>
                            <td className="col-40-body tdlastactivity-oneclassroom">
                                {this.props.selectedUser.email}
                            </td>
                            <td className="col-15-bodys">
                                {this.getDate()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateRole: (userId, value) =>  dispatch(updateRole(userId, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTable)

