import firebase from '../../config/fbConfig'
const db = firebase.firestore()

export const setStoreBooks = () => {
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        const books = getState().books.books;
        const store = {};
        const booksArr = Object.values(books)
        // console.log("BOOKS ARR")
        // console.log(booksArr)

        booksArr.forEach((book) => {
            if (book.hasOwnProperty('book_author')) {
                const bookAuthor = book.book_author
                if (store.hasOwnProperty(bookAuthor)) {
                    store[bookAuthor].push(book.bookId)
                } else {
                    store[bookAuthor] = [book.bookId]
                }
            }
        })
        // console.log("STORE")
        // console.log(store)
        dispatch({ type: "SET_STORE", store })
        console.log("Store State after SET_STORE")
        console.log(getState().store)
    };
};


export const addBookToCart = (bookId, qty) => {
    console.log("in add book");
    const bookInfo = {
        bookId,
        qty
    }
    console.log("BOOKINFO")
    console.log(bookInfo)
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        console.log("in return");
    };
};

// if (classrooms[i].hasOwnProperty('books')) {
//     classrooms[i].books.push(book);
// } else {
//     classrooms[i].books = [book];
// }                
