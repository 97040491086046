import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ClassReportAll from './ClassReportAll'
import ClassReportStudent from './ClassReportStudent'
// import { getAllUsers } from './actionsAdmin'

// const chapterId = this.props.match.params.chapter_id;

class ClassReport extends Component {
    state = { }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }


    //UI Functions

    //Helper Functions

    //Render Functions


    render(){

        const usersAll = this.props.state.adminData.usersAll
        const userSL = this.props.state.firebase.auth
        if (!userSL.uid) return <Redirect to='/SignIn' />
    
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        const classroomId = this.props.match.params.classroomId
        const studentId = this.props.match.params.studentId
        
        if (studentId === "all") {
            toDisplay = (
                <ClassReportAll
                    classroomId={classroomId}
                    history={this.props.history}
                />
            )
        } else {
            toDisplay = (
                <ClassReportStudent
                    classroomId={classroomId}
                    studentId={studentId}
                />
            )
        }

        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getAllUsers: () =>  dispatch(getAllUsers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassReport)

