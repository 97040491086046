import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { searchUsers, clearSearch, updateRole, getOrders, clearOrders } from '../adminPages/actionsAdmin'
import UserTable from './UserTable'
import UserOrders from './UserOrders'
import CreateOrdersModal from './CreateOrdersModal'

class AdminOrders extends Component {
    state = {
        nameSearch: "",
        emailSearch: "",
        typingTimeout: 0,
        selectedUser: null,
        showAddOrderModal: false
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        // if ( this.props.state.adminData.orders ) {
        //     console.log(this.props.state.adminData.orders)
        // }
    }

    componentWillUnmount() {
        this.props.clearOrders()
    }

    // Action functions
    handleChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        const targetId = e.target.id
        const searchText = e.target.value
        if ( searchText === "" ) {
            this.setState({
                [targetId]: searchText,
            })
            clearTimeout(this.state.typingTimeout)
            this.props.clearSearch()
            return
        }

        this.setState({ 
            [targetId]: searchText, 
            typingTimeout: setTimeout( () => {
                this.props.searchUsers(searchText, targetId)
            }, 500)
        })
        
    }

    handleFocus = (e) => {
        this.setState({
            emailSearch: "",
            nameSearch: ""
        })
        this.props.clearSearch()
    }

    clickedUser = (index) => {
        const selectedUser = this.props.state.adminData.usersFound[index]
        this.setState({ 
            emailSearch: "",
            nameSearch: "",
            selectedUser
        })
        this.props.clearSearch()
        this.props.getOrders(selectedUser.userId)
    }

    clickedAddOrder = () => {
        this.setState({
            showAddOrderModal: !this.state.showAddOrderModal
        })
    }


    // Render Functions

    getHeader = () => {
        return (
            <div className="header-oneclassroom">
                Hi there admin!!!
            </div>
        )
    }

    getSearchBar = () => {
        return (
            <div className="ao-search-block">
                <div className="ao-searchline">
                    <span>
                        Search by Name
                </span>
                    <div className="ao-search-box">
                        <input
                            autoComplete="off"
                            className="input-myclassrooms"
                            type="text"
                            id="nameSearch"
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            value={this.state.nameSearch}
                        />
                    </div>
                </div>
                <div className="ao-searchline">
                    <span>
                        Search by Email
                </span>
                    <div className="ao-search-box">
                        <input
                            autoComplete="off"
                            className="input-myclassrooms"
                            type="text"
                            id="emailSearch"
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            value={this.state.emailSearch}
                        />
                    </div>
                </div>

            </div>
        )
    }

    getSearchResults = () => {
        const usersFound = this.props.state.adminData.usersFound
        if ( usersFound.length === 0 ) { return null }
        
        let style = "ao-search-results"
        if (this.state.emailSearch !== "") {
            style = style + " ao-search-resultsemail"
        }
        

        const userCol1 = usersFound.map( (user, index) => {
            let text = user.user_name
            if (this.state.emailSearch !== "") { text = user.email }
            return (
                <div 
                    key={index}
                    className="ao-result-line ao-result-line-email"
                    onClick={() => this.clickedUser(index)}
                >
                    {text}
                </div>
            )
        })

        const userCol2 = usersFound.map( (user, index) => {
            let text = user.email
            if (this.state.emailSearch !== "") { text = user.user_name }
            return (
                <div 
                    key={index}
                    className="ao-result-line"
                    onClick={() => this.clickedUser(index)}
                >
                    {text}
                </div>
            )
        })

        return (
            <div className={style}>
                <div className="ao-email-block">{userCol1}</div>
                <div className="ao-name-block">{userCol2}</div>
            </div>
        )
    }

    getSpinner = () => {
        if (this.props.state.books.showSpinner) {
            return (
                <div className="ajs-modal-spinner">
                    <div className="loader"></div>
                </div>
            )
        }
        return null
    }


    
    render(){
        const userSL = this.props.state.firebase.auth
        if (!userSL.uid) return <Redirect to='/SignIn' />
        if (userSL.uid !== "9dVqqVAwzGb0svaCUOYzNjAH3s02" && userSL.uid !== "xrsENL073ted7CB5VrorXCBMipw2") return <Redirect to="/" />
    
        let createOrders = null
        if (this.state.showAddOrderModal) {
            createOrders = (
                <CreateOrdersModal
                    clickedAddOrder={this.clickedAddOrder}
                    selectedUser={this.state.selectedUser}
                />
            )
        }

                
        return (

            <div className="oneclassrooms-container">
                {createOrders}
                {this.getSpinner()}
                {this.getHeader()}
                {this.getSearchBar()}
                {this.getSearchResults()}
                <UserTable
                    selectedUser={this.state.selectedUser}
                />
                <UserOrders
                    clickedAddOrder={this.clickedAddOrder}
                />
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchUsers: (searchText, targetId) =>  dispatch(searchUsers(searchText, targetId)),
        clearSearch: () =>  dispatch(clearSearch()),
        updateRole: (userId, value) =>  dispatch(updateRole(userId, value)),
        getOrders: (userId) =>  dispatch(getOrders(userId)),
        clearOrders: () =>  dispatch(clearOrders()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrders)

