import firebase from '../../config/fbConfig'
const db = firebase.firestore()
const collectionUsers = db.collection("users");


//TODO AJS: finish updating this function
export const saveStudents = (students, classroomId) => {
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer

        // for (const key of keys) {
        //     console.log(key)
        // }

        // let newUsers = []
        // Object.entries(students).map(student => {
        //     // let usersToReturn = []
        //     if (student[1]["isNew"]) {
        //         newUsers.push(student)
        //         // return (student)
        //     }
        //     // return (usersToReturn)
        // })
        // console.log(newUsers)

        //Batch Writes
        //https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
        /*
        // Get a new write batch
        var batch = db.batch();

        // Set the value of 'NYC'
        var nycRef = db.collection("cities").doc("NYC");
        batch.set(nycRef, { name: "New York City" });

        // Update the population of 'SF'
        var sfRef = db.collection("cities").doc("SF");
        batch.update(sfRef, { "population": 1000000 });

        // Delete the city 'LA'
        var laRef = db.collection("cities").doc("LA");
        batch.delete(laRef);

        // Commit the batch
        batch.commit().then(function () {
            // ...
        });
        */


        // let promisesNewStudents = []
        // let batchModifiedStudents = db.batch()

        let promises = []
        const studentsClone = JSON.parse(JSON.stringify(students))
        console.log("ALL STUDENTS")
        console.log(studentsClone)
        const studentsArr = Object.entries(studentsClone)
        let studentsUpdated = {}
        for (const student of studentsArr) {
            const studentId = student[0]
            if (studentId.slice(0, 3) == "zzz") {
                student[1]["isChanged"] = false
                promises.push(collectionUsers.add({ ...student[1] }))
            } else if (student[1].isChanged) {
                student[1]["isChanged"] = false
                const refUser = collectionUsers.doc(student[0])
                promises.push(refUser.update({ ...student[1] }))
                studentsUpdated[student[0]] = {
                    classroomId: student[0],
                    ...student[1]
                } 
            }
        }

        Promise.all(promises).then( (results) => {
            results.forEach((doc, index) => {
                console.log(doc)
                console.log(index)
                if (doc) {
                    studentsUpdated[doc.id] = {
                        classroomId: doc.id,
                        ...studentsArr[index][1]
                    }
                }
            })
            const studentInfo = {
                classroomId: classroomId,
                students: studentsUpdated
            }
            dispatch({ type: "CLASS_SAVE_STUDENTS", studentInfo })
        }, function (err) {
            console.log('ERROR - Not all student data written');
        })

/*        
        const promises = [];
        const collectionPurchaseAccesses = db.collection('purchaseAccess');
        for (var i = 0; i < classrooms.length; i++) {
            const usageCountField = 'usageCountIdMap.' + classrooms[i].classroomId;
            const queryPurchaseAccess = collectionPurchaseAccesses.where(usageCountField, '>', 0);
            promises.push(queryPurchaseAccess.get());
        }
    
        Promise.all(promises).then( (results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach( (querySnapshot, index) => {
                const purchaseAccesses = [];
                querySnapshot.forEach( (doc) => {
                    purchaseAccesses.push({
                        purchaseOrderId: doc.id,
                        ...doc.data()
                    });
                })
                classrooms[index].purchaseAccesses = purchaseAccesses;    
            })
            callback();
        }, function(err) {
            console.log('COULD NOT RETRIEVE purchaseAccesses');
        });    
*/    






        // refJournal.add({
        //     ...chapterJournal
        // })
        // .then(function(docRef) {        
        //     console.log("Journal successfully newly written!");
        //     chapterJournal["chapterJournalId"] = docRef.id
        //     chapter["journal"] = chapterJournal
        //     dispatch({ type: "Testing" })
        // })
        // .catch(function(error) {
        //     console.error("Error writing document: ", error);
        // });


/*


        if (chapterJournal.chapterJournalId) {
            const refJournal = db.collection("chapterJournals").doc(chapterJournal.chapterJournalId);
            refJournal.update({
                ...chapterJournal
            })
            .then(function() {        
                console.log("Journal successfully overwritten!");
                chapter.journal = chapterJournal
                dispatch({ type: "Testing" })
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });    
        } else {
            const refJournal = db.collection("chapterJournals")
            refJournal.add({
                ...chapterJournal
            })
            .then(function(docRef) {        
                console.log("Journal successfully newly written!");
                chapterJournal["chapterJournalId"] = docRef.id
                chapter["journal"] = chapterJournal
                dispatch({ type: "Testing" })
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        }

*/



    }
}


export const saveClassroom = (classroom) => {
    return (dispatch, getState) => {  

        const refClass = db.collection("classrooms_v3")

        if (classroom.classroomId == "new") {
            refClass.add({
                ...classroom,
                timeCreate: new Date()
            })
            .then(function (docRef) {
                console.log("CLASSROOM successfully written");
                console.log(docRef.id)
                classroom["classroomId"] = docRef.id
                dispatch({ type: "CLASS_SET_NEW", classroom })
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });    
        } else {
            refClass.doc(classroom.classroomId).update({
                ...classroom,
                timeUpdate: new Date()
            })
            .then(function() {
                console.log("CLASSROOM successfully updated!");
                dispatch({ type: "CLASS_UPDATE", classroom })
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating CLASSROOM: ", error);
            });


        }
    }
}



export const getStudents = (classroom) => {
    return (dispatch, getState) => {  
        // console.log("GET STUDENTS HAHAHAHA")
        // console.log(classroom)
        let students = {};
        const collection = db.collection("users");
        const query = collection.where("classroomId", "==", classroom.classroomId).where("role", "==", "Student")
        query.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log("STUDENT ID AND DATA")
                // console.log(doc.id, " => ", doc.data());
                students[doc.id] = doc.data()
            });
            const classInfo = {
                classroomId: classroom.classroomId,
                students: students
            }
            console.log("CLASS INFO")
            console.log(classInfo)
            dispatch({ type: "CLASS_SET_STUDENTS", classInfo })
        });    
    }
}


const getBookIdsForBookCode = (classCode, callback) => {
    const bookCodeRef = db.collection("bookCodes").doc(classCode);

    bookCodeRef.get().then(function (doc) {
        if (doc.exists) {
            // console.log("Document data:", doc.data());
            // const bookIds = doc.data().books
            callback(doc.data())
        } else {
            // doc.data() will be undefined in this case
            callback({disabled: true})
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
}

export const toggleBookCodeForm = () => {
    return (dispatch, getState) => {
        dispatch({ type: "TOGGLE_BOOK_CODE_FORM" })
    }
}

export const joinClassWithCode = (user, classCode) => { 
    return (dispatch, getState) => {
        
        getBookIdsForBookCode( classCode, (bookCodeObj)=> {

            const bookCodeDisabled = bookCodeObj.disabled ? bookCodeObj.disabled : false
            const bookCodeInactive = bookCodeObj.inactive ? bookCodeObj.inactive : false

            if (bookCodeDisabled || bookCodeInactive) {
                dispatch({ type: "NO_BOOK_CODE" })
                return
            }

            const bookIds = bookCodeObj.books
            let batch = db.batch();
            bookIds.forEach ( (bookId) => {
                const ref = db.collection("purchaseAccess").doc()
                const purchaseAccess = {
                    createTime: new Date(),
                    daysPurchased: 365,
                    itemId: bookId,
                    payerUid: user.uid,
                    permittedUsers: {},
                    purchaseOrderId: "from book code",
                    quantity: 1,
                    updatedOn: new Date(),
                    usageCountIdMap: {},
                    bookCodeDetails: {
                        bookCode: classCode,
                        dateBookCode: bookCodeObj.dateCreated
                    }
                }
                batch.set(ref, purchaseAccess);
            })            
            batch.commit().then(function () {
                dispatch({ type: "RETRIEVED_BOOKS_WITH_CODE" })
            })
            .catch(function (error) {
                console.error("Error saving book code books", error);
            });
        })
    }
}

export const resetBooksAddedForCode = () => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_BOOKS_ADDED_FOR_CODE" })
    }
}

export const closeNoBookCodeAlert = () => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_NO_BOOK_CODE" })
    }
}



export const resetInvalidClassCode = (user, classCode) => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_INVALID_CLASS_CODE" })
    }
}



// export const joinClassWithCodeOLD = (user, classCode) => { //Made old on 3/16/2020
//     return (dispatch, getState) => {

//         console.log("in joinClassWithCode")
//         console.log(user)
//         console.log(classCode)

//         let classrooms = [];
//         const collectionClassroom = db.collection("classrooms_v3");
//         const query = collectionClassroom.where("accessCode", "==", classCode);
//         query.get().then(function (querySnapshot) {
//             querySnapshot.forEach(function (doc) {
//                 // doc.data() is never undefined for query doc snapshots
//                 // console.log(doc.id, " => ", doc.data());
//                 classrooms.push({
//                     classroomId: doc.id,
//                     // ...doc.data()
//                 });
//             });
//             console.log("retrieved classrooms")
//             console.log(classrooms)
//             if (classrooms.length > 0) {
//                 const classroomId = classrooms[0].classroomId
//                 addUserToClass(classroomId)
//             } else {
//                 dispatch({ type: "INVALID_CLASS_CODE" })
//             }

//         });

//         function addUserToClass(classroomId) {
//             console.log("ADDING UserToClass")
//             const registered_studentPath = new firebase.firestore.FieldPath("registered_students", user.email);
//             console.log(registered_studentPath)
//             const studentDetailsMapPath = new firebase.firestore.FieldPath('studentDetailsMap', user.email);
//             const studentDetailsData = {
//                 "uid": user.uid,
//                 "user_name": user.user_name ? user.user_name : ""
//             }

//             // let newstud = {}
//             // newstud["registered_students." + "aaronicus@gmail.com"] = true
//             const ug = { [registered_studentPath]: true }

//             collectionClassroom.doc(classroomId).update(
//                 //this solution was forund at https://github.com/firebase/firebase-admin-node/issues/423
//                 new firebase.firestore.FieldPath('registered_students', user.email), true
//                 // {[registered_studentPath]: true}
//                 // registered_studentPath: true
//                 // "registered_students.aaron@hithere.com": true
//                 // [registered_studentPath]: true,

//                 // [studentDetailsMapPath]: studentDetailsData      
//             )
//                 .then(function () {
//                     console.log("THE STUDENT IS ADDED TO THE CLASS WITH A CODE");
//                     dispatch({ type: "USER_ADDED_TO_CLASSROOM" })
//                 })
//                 .catch(function (error) {
//                     console.error("Error adding student to classroom: ", error);
//                 });
//         }
//     }
// }