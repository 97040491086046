import React, { Component } from 'react'
import { connect } from 'react-redux'
import { copyBook } from '../../../store/actions/publisherActions'
import { getPublishers } from '../../../store/actions/publisherActions'


class ModalCopyBook extends Component {

    // Props passed to this class
    // toggleShowCopyBook
    // book

    state = {
        bookTitle: ""
    }

    componentDidMount() {
        this.props.getPublishers()
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }


    //EVENT FUNCTIONS
    handleChange = (e) => {
        this.setState({
            bookTitle: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.copyBook(this.props.book, this.state.bookTitle, this.selectedPublisher.value)
        this.props.toggleShowCopyBook()
    }

    getBookTitleInput = () => {
        return (
            <div>
                <div className="bd-bclabel">Set Book Title</div>
                <div className="input-filename">
                    <form onSubmit={this.handleSubmit} >
                        <input
                            className="input-myclassrooms input-uploadepub"
                            type="text"
                            id="bookTitle"
                            onChange={this.handleChange}
                            value={this.state.bookTitle}
                        />
                    </form>
                </div>
            </div>
        )
    }

    getPublisherList = () => {
        const publishers = this.props.state.auth.publishers ? this.props.state.auth.publishers : []
        const publisherList = publishers.map(publisher => {
            return (
                <option key={publisher.publisherUid} value={publisher.publisherUid}>
                    {publisher.user_name}
                </option>
            )
        })
        return publisherList
    }

    getPublishersDropDown = () => {
        return (
            <div className="chapter-dropdown">
                <br/>
                <div className="bd-bclabel">Choose Publisher</div>
                <select className="select-css" id="mySelect" ref={ref => (this.selectedPublisher = ref)}>
                    {this.getPublisherList()}
                </select>
            </div>
        )
    }

    render() {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                    <div className="pubfeat-close" onClick={this.props.toggleShowCopyBook}></div>
                    <div className="pubfeat-renametitle">Copy This Book?</div>
                    {this.getBookTitleInput()}
                    {this.getPublishersDropDown()}
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom epubupload-btn" onClick={this.handleSubmit} >
                            <span>Copy Book</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        getPublishers: () => dispatch(getPublishers()),
        copyBook: (book, bookTitle, publisherUid) => dispatch(copyBook(book, bookTitle, publisherUid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCopyBook)
