const initState = {
    // isEmpty: true,

    activityFile: null
    // {
    //     device: "web",
    //     uid: "",
    //     classroomId: "",
    //     bookId: "",
    //     chapterId: "",
    //     activityId: "",
    //     timeAccess: ""
    // }


    //the following is how it is stored in firestore
    //this is old information for how tracking was done using the ios app.
    /*
    uid: "uid",
    userName: "userName",
    userEmail: "userEmail",
    lastAccess: new Date(),
    lastAccessDevice: "Web",
    site: {
        entering: ["entering times"],
        leaving: ["leaving times"]
    },
    pages: ["pageAccessDetails"], //stored as array of objects
    pageIds: ["pageIds"],
    books: ["bookAccessDetails"],
    bookIds: ["bookdIds"],
    chapters: ["chapterAccessDetails"],
    chapterIds: ["chapterIds"],
    files: [{
      uid: "uid",
      bookid: "bookid",
      chapid: "chapid",
      fileid: "fileid",
      accessTime: "Timestamp",
      leaveTime: "Timestamp"  
    }],
    fileIds: ["fileId1", "fileId2"]
    */

}

const activityReducer = (state = initState, action) => {
    switch (action.type) {
        case 'RECORD_MOUNT':
            return {
                ...state,
                isEmpty: false,
                page: {
                    page: action.page,
                    timeAccess: new Date()
                },
            }
        case 'RECORD_UNMOUNT':
            return {
                ...state,
                page: {},
            }
        case "ACTIVITY_ACCESS":
            return {
                ...state,
                activityFile: action.activityFileNew
            }
        case "ACTIVITY_LEAVE":
            console.log("RESETING activity")
            return {
                ...state,
                activityFile: null
            }
        case "RESET_TRACKER":
            return {
                ...state,
                activityFile: {
                    device: "web",
                    uid: "",
                    classroomId: "",
                    bookId: "",
                    chapterId: "",
                    activityId: "",
                    timeAccess: ""
                }
            }
        default:
            return state
    }
}

export default activityReducer