import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { Redirect } from 'react-router-dom'
import { withFirestore } from 'react-redux-firebase';
import { toggleDone } from '../../../store/actions/statsActions'



class SideNavChapExpand extends Component {

    state = {
        progressId: "",
        completed: {},
        showExpandButton: false,

        // sectionsMap: {
        //     Introduce: "Vocabulary",
        //     Interact: "Talk about it",
        //     Story: "Story, Songs & Culture",
        //     Play: "Reading & Games",
        //     Measure: "Classroom & Conversation"
        // },

        sectionsMap: {
            Introduce: "Student Files",
            Interact: "Teacher Files",
            Story: "Story, Songs & Culture",
            Play: "Reading & Games",
            Measure: "About"
        },

        sectionsOriginal: [
            "Introduce",
            "Interact",
            "Story",
            "Play",
            "Measure"
        ],

        // sectionsNew: [
        //     "Vocabulary",
        //     "Talk about it",
        //     "Story, Songs & Culture",
        //     "Reading & Games",
        //     "Classroom & Conversation"
        // ],

    }


    componentDidMount() {
        // console.log("SideNavChapExpand DidMount")
        // console.log(this.props.user)
        // console.log("PROGRESS!!!!!")
        // console.log(this.props.progress)


        const progress = this.props.progress
        const progressKey = this.props.classId + this.props.bookId
        const thisProgress = progress[progressKey] ?
            progress[progressKey] :
            ""
        this.setState({
            progressId: thisProgress["progressId"],
            completed: thisProgress["completedFiles"]
        })
        const test = {
            progressId: thisProgress["progressId"],
            completed: thisProgress["completedFiles"]
        }
    }

    componentDidUpdate(prevProps) {
        const chapters = this.props.chapters;
        if (chapters[0] !== "Loading") {
            this.selectChapter.value = this.props.chapterId
        }
        const newFileId = this.props.chapterFile ? this.props.chapterFile.fileId : null
        const prevFileId = prevProps.chapterFile ? prevProps.chapterFile.fileId : null
        if (newFileId !== prevFileId) {
            const show = this.isOverflown()
            if (show !== this.state.showExpandButton) {
                this.setState({
                    showExpandButton: show
                })
            }
        }
    }

    componentWillUnmount() {
    }


    myRef = React.createRef()

    clickedCheckBox = (e) => {
        // console.log("Check Box Clicked")
        // console.log(e.target.checked)
        // console.log(e.target.id)
    }

    isOverflown = () => {
        // console.log("in isOverflown")
        // console.log(this.myRef)
        // console.log(this.myRef.current)
        const commentNode = this.myRef.current
        if (!commentNode) {
            return false
        }
        return commentNode.scrollHeight > commentNode.clientHeight
    }

    showCommentModal = (e) => {
        e.preventDefault()
    }


    toggleDone = (e, fileId) => {
        e.preventDefault()
        // console.log("CLICKED Toggle")
        // const fileId = e.target
        // console.log(this.props.auth)
        // console.log(this.props.user)
        // console.log(home2)
        const progress = this.props.progress
        const classId = this.props.classId
        const bookId = this.props.bookId
        // const progressKey = classId + bookId
        const progressKey = bookId
        const thisProgress = progress[progressKey] ?
            progress[progressKey] :
            {
                bookId: bookId,
                // classroomId: classId,
                uid: this.props.user.uid,
                completedDates: {
                    [fileId]: new Date(),
                },
                completedFiles: {
                    [fileId]: false
                }
            }
        this.props.toggleDone(fileId, thisProgress)
    }

    styleHighlightBoldYellow = {
        color: "yellow",
        fontWeight: "bold"
    }
    styleHighlightBoldBlue = {
        color: "lightskyblue",
        fontWeight: "bold"
    }
    styleHighlightBlue = {
        color: "lightskyblue",
    }

    styleHideExpand = {
        display: "none"
    }


    getSections = (chapterFiles) => {
        // const sectionsOriginalArr = this.state.sections
        const sectionsOriginalArr = this.state.sectionsOriginal
        const sectionsChapterArr = this.props.book.chapterSections ?
            this.props.book.chapterSections :
            []
        let sections = {}
        //If the book has custom section titles, retrieve them here
        for (let i = 0; i < sectionsOriginalArr.length; i++) {
            const sectionOriginal = sectionsOriginalArr[i]
            const sectionChapter = sectionsChapterArr[i] ?
                sectionsChapterArr[i] :
                this.state.sectionsMap[sectionOriginal]
            sections[sectionOriginal] = sectionChapter
        }
        let allFiles = []
        for (let i = 0; i < sectionsOriginalArr.length; i++) {
            const sectionOriginal = sectionsOriginalArr[i]
            const sectionLabel = sections[sectionOriginal]
            // Don't include the "Teacher Instructions" section if the user is a student
            if (sectionLabel === "Teacher Instructions") {
                if (!(this.props.user.role && this.props.user.role !== "Student")) {
                    continue
                }
            }
            const sectionName = (
                <div className="section-title" key={sectionLabel}>
                    <div className="section-title2">
                        {sectionLabel}
                    </div>
                </div>
            )
            const chapterFileList = this.getChapterFileList(chapterFiles, sectionsOriginalArr[i])
            if (chapterFileList.length > 0) {
                allFiles.push(sectionName)
                allFiles.push(chapterFileList)
            }
        }
        return allFiles
    }


    getChapterFileList = (chapterFiles, section) => {
        let roleStudent = true
        if (this.props.user.role && this.props.user.role !== "Student") {
            roleStudent = false
        }
        let chapterFileList = []

        for (let j = 0; j < chapterFiles.length; j++) {
            // if ( chapterFiles[j] === undefined || chapterFiles[j] === null ) { continue }
            if (!chapterFiles[j]) { continue }
            let newSection = chapterFiles[j]["fileSection"] ?
                chapterFiles[j]["fileSection"] :
                "Introduce"
            if (newSection !== section) {
                continue
            }
            let showFile = false
            const isStudentFile = !(chapterFiles[j].fileAccessLevel && chapterFiles[j].fileAccessLevel !== "Student")
            const isTeacherFile = (chapterFiles[j].fileAccessLevel && chapterFiles[j].fileAccessLevel === "Teacher")
            if (roleStudent) {
                if (isStudentFile) {
                    showFile = true
                }
            } else {
                // if (isStudentFile || isTeacherFile) {
                showFile = true
                // }
            }

            if (showFile) {
                //Commented out on 3/28/20. Can be deleted.
                // const progress = this.props.progress
                // const progressKey = this.props.bookId
                // const thisProgress = progress[progressKey] ?
                //     progress[progressKey] :
                //     ""
                // const isDone = thisProgress.completedFiles ?
                //     thisProgress.completedFiles[chapterFiles[j]["fileId"]] ?
                //         thisProgress.completedFiles[chapterFiles[j]["fileId"]] :
                //         false :
                //     false

                const fileId = chapterFiles[j].fileId
                const chapterId = this.props.chapterId
                // console.log("TICKS BY BOOK")
                // console.log(this.props.state.books.ticksByBook)
                // let isDone = false
                // const ticksForBook = this.props.state.books.ticksByBook[this.props.bookId]
                // if (ticksForBook) {
                //     isDone = ticksForBook[chapterId] ?
                //         ticksForBook[chapterId]["completedFiles"][fileId] ?
                //             ticksForBook[chapterId]["completedFiles"][fileId] :
                //             false :
                //         false
                // }



                const isDone = this.props.state.books.ticks[fileId] ?
                    this.props.state.books.ticks[fileId]["ticked"] ?
                        this.props.state.books.ticks[fileId]["ticked"] :
                        false :
                    false

                let fileCheck = (<img className="file-check2" src="../../../../../img/BoxWhite.svg" alt="" />)
                if (isDone) {
                    // fileCheck = (<img className="file-check2" src="../../../../../img/iconmonstr-checkedbox.svg" alt="" />)
                    fileCheck = (<img className="file-check2" src="../../../../../img/BoxGreenChecked2.svg" alt="" />)
                }

                const [chapterFileLink, fileComment] = this.getChapterFileLink(chapterFiles[j])
                const chapterFile = chapterFiles[j]
                chapterFileList.push(
                    <div key={fileId}>
                        <div className="file-name">
                            <a href="#!" >
                                {/* To pass props through onClick https://stackoverflow.com/questions/37639122/using-event-target-with-react-components */}
                                <div className="file-check-container" onClick={(e) => this.props.clickedChapterFile(e, chapterFile, true)}>
                                    {fileCheck}
                                    {/* <img className="file-check" src="../../../../../img/iconmonstr-circle.svg" alt="" /> */}
                                    {/* <img className="file-check2" src="../../../../../img/iconmonstr-check.svg" alt="" /> */}
                                    {/* <span style={{visibility: "visible"}}>{"X"}</span> */}
                                    {/* <input key={chapterFiles[j].fileId} type="checkbox" className="css-checkbox" id={chapterFiles[j].fileId} onClick={this.clickedCheckBox} /> */}
                                    {/* <label htmlFor={chapterFiles[j].fileId} name="checkbox1_lbl" className="css-label lite-green-check"  ></label> */}
                                </div>
                            </a>
                            {chapterFileLink}
                        </div>
                        {fileComment}
                    </div>
                )
            }
        }
        return chapterFileList
    }

    getChapterFileLink = (chapterFile) => {
        // console.log("chapter file name")
        // console.log(this.props.chapterFile.file_name)
        let fileTitleStyle = {}
        const fileAccessLevel = chapterFile.fileAccessLevel ?
            chapterFile.fileAccessLevel :
            "Student"
        if (fileAccessLevel === "Teacher") {
            fileTitleStyle = this.styleHighlightBlue
        }
        let fileComment = null
        const propsChapterFileId = this.props.chapterFile ? this.props.chapterFile.fileId : null
        if (chapterFile.fileId === propsChapterFileId) {
            // console.log("HERE")
            if (fileAccessLevel === "Teacher") {
                fileTitleStyle = this.styleHighlightBoldBlue
            } else {
                fileTitleStyle = this.styleHighlightBoldYellow
            }
            if (chapterFile.fileComment) {
                let fileCommentText = chapterFile.fileComment
                if (fileCommentText.substring(0, 1) === "\"") {
                    fileCommentText = fileCommentText.substring(1); // removes beginning "
                    fileCommentText = fileCommentText.slice(0, -1); // removes ending "   
                }
                fileCommentText = fileCommentText.replace(/\\n/g, "<br />")
                fileCommentText = fileCommentText.replace(/\\t/g, "")
                fileCommentText = fileCommentText.replace(/\\"/g, "&quot")
                // fileCommentText = fileCommentText.replace(/\n/g, '<br />')

                let chapTitleStyle = {}
                if (!this.state.showExpandButton) {
                    chapTitleStyle = this.styleHideExpand
                }
                fileComment = (
                    <div className="comment-container">
                        <div className="file-comment" ref={this.myRef}>
                            <div dangerouslySetInnerHTML={{ __html: fileCommentText }}></div>
                            {/* {fileCommentText} */}
                        </div>
                        <div className="expand-comment" style={chapTitleStyle}>
                            <a href="#!" onClick={this.props.toggleComments(true)}>
                                <img
                                    className="expand-image"
                                    src="../../../../../img/expand-button.svg"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                )
            }
        }

        const chapterFileLink = (
            <div className="sidenav-a">
                <a href="#!" className="sidenav-a" style={fileTitleStyle} key={chapterFile.fileId} onClick={(e) => this.props.clickedChapterFile(e, chapterFile, false)}>
                    {chapterFile.file_name}
                </a>
            </div>
        )
        return [chapterFileLink, fileComment]

        /*
        // if (fileName.includes("$") || fileName.includes("&") || fileName.includes("#")) {
        let chapterFileLink;
        const fileName = chapterFile.file_name
        if (fileName.includes("$") || fileName.includes("#")) {
            // console.log("chapterfile")
            // console.log(chapterFiles[j])
            chapterFileLink = (
                <a href={chapterFile.firebase_storage_url} className="sidenav-a" target="_blank" style={fileTitleStyle} key={chapterFile.fileId} >
                    {chapterFile.file_name}
                </a>
                </div>
            )
        } else {
            chapterFileLink = (
                <div className="sidenav-a">
                <a href="#!" className="sidenav-a" style={fileTitleStyle} key={chapterFile.fileId} onClick={(e) => this.props.clickedChapterFile(e, chapterFile, false)}>
                    {chapterFile.file_name}
                </a>
                </div>
            )
        }
        */
    }

    chapterSelected = (e) => {
        e.persist()
        // console.log("CHAPTER SELECTED")
        // console.log(e.target.value)
        this.props.clickedChapter(e.target.value)
    }

    getChapterList = () => {
        let chapterList = []
        const chapters = this.props.chapters;
        if (chapters[0] === "Loading") {
            chapterList = [
                <option key={"loading"}>
                    {"Loading"}
                </option>
            ];
        } else {
            for (let i = 0; i < chapters.length; i++) {
                if (chapters[i] === undefined) { continue }
                if (chapters[i].isBlocked) { continue }
                let chapTitleStyle = {}
                // console.log(chapters[i].chapterId)
                chapterList.push(
                    <option key={chapters[i].chapterId} value={chapters[i].chapterId}>
                        {chapters[i].title}
                    </option>
                )
            }
        }
        return chapterList
    }


    render() {
        const bookCoverUrl = this.props.bookCoverUrl;
        const bookTitle = this.props.book ? this.props.book.title : ""
        // console.log("BOOK IMAGE URL")
        // console.log(bookCoverUrl)
        const chapters = this.props.chapters;
        const classId = this.props.classId;
        const bookId = this.props.bookId;
        let chapterList = [];
        let i;


        if (chapters[0] === "Loading") {
            chapterList = [<li key={"loading"}>{"Loading"}</li>];
        } else {
            for (i = 0; i < chapters.length; i++) {
                // if (chapters[i] === undefined ) { continue }
                // if (chapters[i].isBlocked) { continue }
                // let chapTitleStyle = {}
                // if (chapters[i].chapterId === this.props.chapterId) {
                //     // console.log("CHAPTER")
                //     // console.log(chapters[i])
                //     chapTitleStyle = this.styleHighlight
                // }

                // chapterList.push(
                //     <div key={chapters[i].chapterId}>
                //         <a href="#!" className="sidenav-b" style={chapTitleStyle} key={chapters[i].chapterId} >
                //             {chapters[i].title}
                //         </a>
                //     </div>
                // )

                //If the chapter dosen't exist, then skip it.
                if (chapters[i] === undefined) {
                    continue
                }

                if (this.props.showChapterFiles && chapters[i].chapterId === this.props.chapterId) {
                    const chapterFileList = this.getSections(chapters[i].file_array)
                    chapterList.push(
                        <div className="ajs-dropdown-container" key="dropdown">
                            {chapterFileList}
                        </div>
                    )
                }
            }
        }

        let journalButtonLabel = "NOTEBOOK"

        const thisSideNav = (

            <div className="ajs-sidenav">
                <div className="book-info">
                    <div className="book-cover-container">
                        {/* <div className="book-cover"> */}
                        <img className="book-cover" src={this.props.bookCoverUrl} alt="" />
                        {/* </div> */}
                    </div>
                    <div className="nav-book-title">
                        <div  >
                            {bookTitle}
                        </div>
                    </div>
                </div>

                <div className="chapter-dropdown">
                    <div className="chapselect-label">Select Chapter</div>
                    <select className="select-css" id="mySelect" onChange={this.chapterSelected} ref={ref => (this.selectChapter = ref)}>
                        {this.getChapterList()}
                    </select>
                </div>
                <div>
                    <button className="btn-journal" onClick={this.props.showJournal} >
                        <span>{journalButtonLabel}</span>
                    </button>
                </div>

                {chapterList}

            </div>
        )

        return (
            thisSideNav
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDone: (fileId, progressKey) => dispatch(toggleDone(fileId, progressKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavChapExpand)





