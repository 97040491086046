const initState = {
    chapters: {
        isEmpty: true
        // chapter1: {},
        // chapter2: {}
    },
}

//TODO Remove this reducer and modify those files affected to reference the books reducer instead
const chapterReducer = (state = initState, action) => { 
    switch (action.type) {
        case "SET_CHAPTERS":
            // console.log('IN chapterReducer SET_CHAPTERS');
            // console.log(action.chapters);
            return {
                ...state,
                chapters: action.chapters
            }
        case "RESET_CHAPTERS":
            console.log("reset chapters")
            return {
                ...state,
                chapters: {
                    isEmpty: true
                }
            }
        case "Testing":
            console.log("In testing")
            return {
                ...state,
                showEpubAlert: true
            }
        case "UPDATE_CHAPTER2":  //TODO Remove this action and remove this reducer and modify those files affected to reference the books reducer instead
            // console.log("IN UPDATE_CHAPTER")
            // console.log(action.chapter)
            const updatedChapter = action.chapter
            const updatedChapterId = updatedChapter.chapterId
            return {
                ...state,
                chapters: {
                    ...state.chapters,
                    [updatedChapterId]: updatedChapter
                }
            }
        default:
            return state
    }
}

export default chapterReducer