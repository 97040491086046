import React, { Component } from 'react'
import { connect } from 'react-redux'
import { markTeacherFile } from '../../../store/actions/publisherActions'


class ModalMarkTeacherFiles extends Component {

// Props passed to this class
// book
// chapter
// toggleShowModalReorderFiles

    state = {
        sectionsOriginal: [],
        sectionsUser: [],
        sectionsFilesObj : {},
    }
    
    componentDidMount() {
        const sectionsOriginal = this.props.state.books.sectionsOriginal
        const sectionsUser = this.props.book.chapterSections ? this.props.book.chapterSections : this.props.state.books.sectionsDefault
        const sectionsFilesObj = this.getSectionsFilesObj()
        this.setState({ 
            sectionsOriginal,
            sectionsUser,
            sectionsFilesObj,
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    //Actions
    clickedMarkTeacher = (e, chapterFile) => {
        e.preventDefault()
        console.log(chapterFile)
        this.props.markTeacherFile(this.props.chapter, chapterFile)
    }


    //HELPER FUNCTIONS
    getSectionsFilesObj = () => {
        const fileArray = this.props.chapter.file_array
        const sectionsFilesObj = {}
        fileArray.forEach( (file) => {
            const section = file.fileSection
            if ( sectionsFilesObj[section] ) {
                sectionsFilesObj[section].push( file )
            } else {
                sectionsFilesObj[section] = [file]
            }
        })
        return sectionsFilesObj
    }


    getFilesForSection = (section, indexSection) => {
        const sectionsFilesObj = this.state.sectionsFilesObj
        if ( !sectionsFilesObj[section] ) { return null }
        const fileList = sectionsFilesObj[section].map( (file, indexFile) => {
                const isTeacherFile = file["fileAccessLevel"] ?
                    file["fileAccessLevel"] === "Teacher" ?
                        true :
                        false :
                    false
                const title = file.file_name
                const idKey = section + "-" + indexFile
                let fileCheck = (<img className="pubfeat-check" src="../../../../../img/BoxWhite.svg" alt="" />)
                if (isTeacherFile) {
                    fileCheck = (<img className="pubfeat-check" src="../../../../../img/BoxGreenChecked2.svg" alt="" />)
                }
                return (
                    <div key={idKey} className="pubfeat-filerow-mt">
                    <div className="pubfeat-checkcontainer" onClick={(e) => this.clickedMarkTeacher(e, file)}>
                        {/* <div className="pubfeat-checkcontainer" > */}
                            {fileCheck}
                        </div>
                        <div
                            id={idKey}
                            key={idKey}
                            className="pubfeat-filename-mt"
                        >
                            <span>{title}</span> 
                        </div>
                    </div>
                )
            })
        return fileList
    }

    getFileList = () => {
        const sectionsOriginal = this.state.sectionsOriginal
        const sectionsUser = this.state.sectionsUser
        const theList = sectionsOriginal.map( (section, indexSection) => {
            const sectionUser = sectionsUser[indexSection]
            // const tlabel = (indexSection === 0) ? "Teacher" : null
            const sectionsFilesObj = this.state.sectionsFilesObj
            if ( !sectionsFilesObj[section] ) {
                return null
            }
            return (
                <div key={section}>
                    <div className="pubfeat-sectiontitle-mt" >
                        {/* <span className="pubfeat-sectitle1-mt">{tlabel}</span> */}
                        <span className="pubfeat-sectitle2-mt">{sectionUser}</span>
                    </div>
                    {this.getFilesForSection(section, indexSection)}
                </div>
            )
        })

        return (
            <div className="pubfeat-reordercontainer">
                {theList}
            </div>
        )
    }




    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                    <div className="pubfeat-close" onClick={this.props.toggleShowModalMarkTeacherFiles}></div>
                    <div className="pubfeat-renametitle-mt">Mark Teacher Files</div>
                    <div className="pubfeat-tflabel">
                            Teacher<br/>File
                    </div>
                    {this.getFileList()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        markTeacherFile: (chapter, chapterFile) =>  dispatch(markTeacherFile(chapter, chapterFile)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMarkTeacherFiles)

