import React, { Component } from 'react'
import { connect } from 'react-redux'
import { replaceTicks, getStudents, updateStudentsAfterSave, saveClassCode, deleteClassroom, parseStudentCsv} from '../../../store/actions/myClassroomsActions'
import OneClassroomBooks from './OneClassroomBooks'
import firebase from "../../../config/fbConfig"
import {Link} from "react-router-dom"
import ModalUploadCsv from './ModalUploadCsv'
// import { addClassId, replaceTicks } from '../../../store/actions/temporaryActions'

// const chapterId = this.props.match.params.chapter_id;

class OneClassroom extends Component {
    state = {
        blurredStudentId: false,
        removeButtonActive: false,
        keepButtonActive: false,
        classCode: "",
        students: false,
        displayDeleteClassAlert: false,
        displayRemoveStudentAlert: false,
        // students: {
            // classroomId1: objectOfStudents,
            // classroomId2: objectOfStudents
        // }    
        showManageBooks: false, 
        showUploadCsvModal: false
    }

    componentDidMount() {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classCode = classroom.classCode ?
            classroom.classCode :
            ""
        const allStudents = this.props.state.myClassrooms.students
        const students = allStudents[classroomId] ?
            allStudents[classroomId] :
            false
        if (!students) { 
            this.props.getStudents(classroomId)
        } else {
            this.setStudentSortOrder(students)
            this.setState({ classCode, students })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        if (classroom === "deleted") {
            this.props.history.goBack()
        }

        this.advanceFocus(prevState)  
        
        if (!this.state.students) { this.updateTheState() }
        
        const prevPropsStudents = prevProps.state.myClassrooms.students[classroomId]
        const currPropsStudents = this.props.state.myClassrooms.students[classroomId]
        if (prevPropsStudents && currPropsStudents) {
            let prevStudentsCount = Object.keys(prevPropsStudents).length
            let currStudentsCount = Object.keys(currPropsStudents).length
            if (currStudentsCount > prevStudentsCount) { this.updateTheState() }
        }
        
    }


    //Form Events
    handleSubmit = (e) => {
        e.preventDefault();
        console.log("Pressed Enter")
    }

    handleSubmit2 = (e) => {
        e.preventDefault();
        this.saveClassCode()
    }

    handleKeyDown = (e) => {
        // console.log("KEY DOWN")
        // console.log(e.key)
        // console.log(e.target.tabIndex)
        if (e.shiftKey & e.key==="Tab") {return}
        if (e.key === "Tab" || e.key === "Enter") {
            e.preventDefault()
            const students = this.state.students
            const numStudents = Object.keys(students).length
            const lastTabIndex = ((numStudents-1)*2)+2
            const tabIndex = e.target.tabIndex
            if (tabIndex === lastTabIndex) {
                this.addNewRow()
            } else {
                const focusOn = "ref"+(tabIndex+1)
                this[focusOn].focus()    
            }
            return
        }
        if (e.key === "ArrowDown") {
            // const tabIndexName = (i*2)+1
            // const tabIndexPassword = (i*2)+2
            e.preventDefault()
            const tabIndexCurrent = e.target.tabIndex
            const numStudents = Object.keys(this.state.students).length
            const tabIndexMax = ((numStudents-1)*2)+2
            if (tabIndexCurrent === tabIndexMax || tabIndexCurrent === (tabIndexMax-1)) {
                this.addNewRow()
            } else {
                const focusOn = "ref"+(tabIndexCurrent+2)
                this[focusOn].focus()                    
            }
            return
        }
        if (e.key === "ArrowUp") {
            e.preventDefault()
            const tabIndexCurrent = e.target.tabIndex
            if (tabIndexCurrent === 1 || tabIndexCurrent === 2) {
                e.preventDefault()
            } else {
                const focusOn = "ref"+(tabIndexCurrent-2)
                this[focusOn].focus()                    
            }
            return
        }
        if (e.key === "ArrowLeft") {
            //this is disabled 12/19/19. I'm not sure this a desired feature
            return
            e.preventDefault()
            const tabIndexCurrent = e.target.tabIndex
            if (tabIndexCurrent === 1) {
                e.preventDefault()
            } else {
                const focusOn = "ref"+(tabIndexCurrent-1)
                this[focusOn].focus()                    
            }
            return
        }
        if (e.key === "ArrowRight") {
            //this is disabled 12/19/19. I'm not sure this a desired feature
            return
            e.preventDefault()
            const tabIndexCurrent = e.target.tabIndex
            const numStudents = Object.keys(this.state.students).length
            const tabIndexMax = ((numStudents-1)*2)+2
            if (tabIndexCurrent === tabIndexMax) {
                this.addNewRow()
            } else {
                const focusOn = "ref"+(tabIndexCurrent+1)
                this[focusOn].focus()                    
            }
            return
        }

    }

    handleChange = (e) => {
        const targetId = e.target.id
        const [studentId, fieldId] = targetId.split(" ")
        const targetValue = e.target.value
        const tabIndex = e.target.tabIndex
        // console.log("CHANGE")
        // console.log(this.state)
        // console.log(fieldId)
        // console.log(studentId)
        // console.log(targetValue)
        // console.log(tabIndex)
        this.setState({
            students: {
                ...this.state.students,
                [studentId]: {
                    ...this.state.students[studentId],
                    [fieldId]: targetValue,
                    toDelete: false
                }
            }
        })
    }

    handleChangeClassCode = (e) => {
        const targetValue = e.target.value
        this.setState ({
            classCode: targetValue
        })
    }

    handleBlur = (e) => {
        const targetId = e.target.id
        const [studentId, fieldId] = targetId.split(" ")
        this.setState({
            blurredStudentId: studentId
        })
    }

    //Button Events
    saveClassCode = () => {
        const classroomId = this.props.classroomId
        let classCode = this.state.classCode ?
            this.state.classCode :
            ""
        if (classroomId === "" || classCode === "") { return }
        if (classCode.indexOf(" ") >= 0) {
            alert("Please remove spaces from your Class Code")
            return
        }
        classCode = classCode.toLowerCase()
        // TODO - set a loading spinner while the database is checked for a unique code
        this.isClassCodeUnique( classCode, (isUnique) => {
            //TODO - remove the loading spinner
            if (isUnique) {
                console.log("IS UNIQUE")
                const classCodeToSend = {
                    classroomId: classroomId,
                    classCode: classCode
                }
                this.props.saveClassCode(classCodeToSend)
                this.setState({ classCode })
            } else {
                console.log("NOT UNIQUE")
                alert("Class Code is not unique. Try again.")
            }
        })
    }

    uploadCsv = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        console.log("THIS IS THE FILE")
        console.log(file)
        const classroomId = this.props.classroomId
        if (file) { this.props.parseStudentCsv(file, classroomId) }
    }

    toggleShowUploadCsvModal = () => {
        this.setState(
            { showUploadCsvModal: !this.state.showUploadCsvModal }
        )
    }

    saveChanges = () => {
        const db = firebase.firestore()
        const batch = db.batch();
        const classroomId = this.props.classroomId
        const allStudents = this.props.state.myClassrooms.students
        const studentsSaved = allStudents[classroomId] ?
            allStudents[classroomId] :
            false
        const studentsArr = Object.values(this.state.students)
        let nameFirstArr = []
        let passwordArr = []
        for (let i=0; i < studentsArr.length; i++) {
            nameFirstArr.push(studentsArr[i].nameFirst)
            passwordArr.push(studentsArr[i].password)
        }
        const newStudentsSaved = []
        const studentsToDelete = []
        for (let i=0; i < studentsArr.length; i++) {
            const thisStudent = studentsArr[i]
            const studentId = thisStudent.studentId
            const thisStudentSaved = studentsSaved[studentId] ?
                studentsSaved[studentId] :
                false
            if (thisStudentSaved) {
                //check if the student is marked for deletion
                if (thisStudent.toDelete) {
                    const studentToDeleteRef = db.collection("usersChild").doc(thisStudent.studentId)
                    batch.update(studentToDeleteRef, {
                        "deleted": true,
                        "dateDeleted": new Date(),
                        "password": "",
                    });
                    studentsToDelete.push(thisStudent.studentId)
                }
                //check if the name and/or password has changed
                if (thisStudentSaved.nameFirst !== thisStudent.nameFirst || thisStudentSaved.password !== thisStudent.password) {
                    //validate new name and password
                    if (!this.validateNameAndPassword(thisStudent.nameFirst, thisStudent.password, studentsArr)) { continue }
                    const existingStudentRef = db.collection("usersChild").doc(thisStudent.studentId)
                    batch.update(existingStudentRef, {
                        "nameFirst": thisStudent.nameFirst,
                        "password": thisStudent.password
                    });
                    newStudentsSaved.push(thisStudent)
                }
            } else {
                if (thisStudent.toDelete) {
                    studentsToDelete.push(thisStudent.studentId)
                    continue
                }
                //if password or name don't meet criteria, then don't save them
                const nameNew = thisStudent.nameFirst
                const passwordNew = thisStudent.password
                if (!this.validateNameAndPassword(nameNew, passwordNew, studentsArr)) { continue }

                const newStudentRef = db.collection("usersChild").doc()
                const studentIdNew = newStudentRef.id
                let newStudentToSave = {
                    classroomId: classroomId,
                    dateCreated: new Date(),
                    nameFirst: nameNew,
                    password: passwordNew
                }
                batch.set(newStudentRef, { ...newStudentToSave })
                newStudentToSave = {
                    ...newStudentToSave,
                    studentIdNew: studentIdNew,
                    studentIdOld: studentId
                }
                newStudentsSaved.push(newStudentToSave)
            }                   
        }

        this.saveChangesToFS( batch, (success) => {
            if (!success) { return }
            //Changes have been made to FS. Now update the component state and the reducer
            //Create a copy / clone of this.state.students
            const studentsUpdatedForState = Object.assign({}, this.state.students);
            //Create a copy / clone of students from the reducer
            const studentsFromReducer = this.props.state.myClassrooms.students[this.props.classroomId]
            let studentsForReducer = Object.assign({}, studentsFromReducer)
            // update the cloned copies of the state and reducer for students that were added and/or modified
            // newStudensSaved includes students that are newly added and existing students that have been modified
            newStudentsSaved.forEach( (student) => {
                //If the student is new, then the id will be the new student id, otherwise it will be the existing student id
                const studentIdForHere = student.studentIdNew ?
                    student.studentIdNew :
                    student.studentId
                    
                const studentToPassOn = {
                    nameFirst: student.nameFirst,
                    password: student.password,
                    studentId: studentIdForHere,
                    classroomId: student.classroomId,
                }
                //this gets passed on to the reducer
                studentsForReducer[studentIdForHere] = { ...studentToPassOn }
                //this gets passed on to the component state
                //only update for new or deleted students
                if (student.studentIdNew) {
                    studentsUpdatedForState[student.studentIdNew] = {
                        ...studentToPassOn,
                        sortOrder: studentsUpdatedForState[student.studentIdOld]["sortOrder"]
                    }
                    delete studentsUpdatedForState[student.studentIdOld]    
                }
            })
            // update the cloned copies of the state and reducer for students that were added and/or modified
            studentsToDelete.forEach( (studentToDeleteId) => {
                delete studentsUpdatedForState[studentToDeleteId]
                delete studentsForReducer[studentToDeleteId]
            })
            this.setState({ students: {...studentsUpdatedForState} })
            this.props.updateStudentsAfterSave({
                classroomId,
                students: studentsForReducer,
            })
        })
    }

    removeStudent = () => {
        // const studentToRemove = this.state.blurredStudentId
        // if (!studentToRemove) {return}
        // let studentToRemoveObj = this.state.students[studentToRemove]
        // studentToRemoveObj["toDelete"] = true
        // this.setState({
        //     students: {
        //         ...this.state.students,
        //         [studentToRemove]: {...studentToRemoveObj}
        //     }
        // })

        const studentToRemove = this.state.blurredStudentId
        if (!studentToRemove) {return}
        let studentToRemoveObj = this.state.students[studentToRemove]
        studentToRemoveObj["toDelete"] = true
        this.setState({
            students: {
                ...this.state.students,
                [studentToRemove]: {...studentToRemoveObj},
            },
            displayRemoveStudentAlert: true
        })
    }

    clickedDeleteClass = () => {
        this.setState({ displayDeleteClassAlert: true })
    }
    clickedKeepClass = () => {
        this.setState({ displayDeleteClassAlert: false })
    }
    clickedDeleteConfirm = () => {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        this.props.deleteClassroom(classroom)
    }    
    clickedKeepStudent = () => {
        const studentToRemove = this.state.blurredStudentId
        let studentToRemoveObj = this.state.students[studentToRemove]
        studentToRemoveObj["toDelete"] = false
        this.setState({
            students: {
                ...this.state.students,
                [studentToRemove]: {...studentToRemoveObj},
            },
            displayRemoveStudentAlert: false,
        })
    }
    clickedRemoveStudentConfirm = () => {
        this.saveChanges()
        this.setState({ displayRemoveStudentAlert: false })
    }
    clickedManageBooks = () => {
        const newShowManagedBooks = !this.state.showManageBooks
        this.setState({ showManageBooks: newShowManagedBooks })
    }
    clickedUsageReport = () => {
        const classroomId = this.props.classroomId
        this.props.history.push('/classreport/'+classroomId+'/all')
    }
    clickedJournalReport = () => {
        const classroomId = this.props.classroomId
        this.props.history.push('/journalreport/'+classroomId)
    }

    copyToClipboard = () => {
        // console.log(this.url)
        // this.url.select();
        // document.execCommand('copy');

        // const el = this.textArea
        // el.focus()
        // el.select()
        // document.execCommand("copy")
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classCodeForLink = classroom.classCode

        
        //https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        var textArea = document.createElement("textarea");
        // textArea.value = "https://storylabs.app/joinclass/" + classCodeForLink;
        textArea.value = 
`Dear Students,
 
We have some great materials for you on Storylabs!
         
To sign-up for Storylabs:
         
Follow the link below to enter your name and create a password:
         
https://storylabs.app/joinclass/` + classCodeForLink + `
        
After signing up, all future log-ins are as follows:
         
1)  Go to https://storylabs.app/SignIn
2)  Enter your class code:  ` + classCodeForLink + `
3) Enter the password you created upon sign-in.  If you forget your password, please email your teacher to remind you.
         
For further help, you may view how-to videos here:  https://www.storylabs.online/help-for-language-teachers
         
Sincerely,
         
         
Your Teacher
`
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
          
    }

    //Helper Functions
    validateNameAndPassword = (nameNew, passwordNew, studentsArr) => {
        //Check if values are entered for new name and password
        if (nameNew === "" || passwordNew === "") { return false }
        let nameNewNum = 0
        let passwordNewNum = 0
        //check if name and password are unique for new students
        for (let j = 0; j < studentsArr.length; j++) {
            if (studentsArr[j].nameFirst === nameNew) { nameNewNum++ }
            if (studentsArr[j].password === passwordNew) { passwordNewNum++ }
        }
        if (nameNewNum > 1 || passwordNewNum > 1) { return false }
        return true        
    }

    isClassCodeUnique = (classCode, callback) => {
        const db = firebase.firestore()
        const docForClassCode = db.collection("classrooms_v3").where("classCode", "==", classCode)
        docForClassCode.get().then( (querySnapshot) => {
            if (querySnapshot.empty) {
                callback(true)
            } else {
                callback(false)
            }
        })
        .catch(function(error) {
            console.log("Error checking for unique class code: ", error);
            callback(false)
        });


        db.collection("cities").where("capital", "==", true)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });


    }

    saveChangesToFS = (batch, callback)  => {
        batch.commit().then(function () {
            console.log("CHANGES SAVED")
            callback(true)
        })        
        .catch(function (error) {
            console.log("ERROR could not save changes: ", error)
            callback(false)
        })
    }

    updateTheState = () => {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classCode = classroom.classCode ?
            classroom.classCode :
            ""
        const allStudents = this.props.state.myClassrooms.students
        const students = allStudents[classroomId] ?
            allStudents[classroomId] :
            false
        if (students) {
            this.setStudentSortOrder(students)
            this.setState({ classCode, students })
        }
    }

    advanceFocus = (prevState) => {
        let prevNumStudents = Object.keys(prevState.students).length
        let newNumStudents = Object.keys(this.state.students).length
        // console.log(prevNumStudents)
        // console.log(newNumStudents)
        if (newNumStudents > prevNumStudents && prevNumStudents !== 0) {
            let newTabIndex = ((newNumStudents-1)*2)+1
            this["ref"+newTabIndex].focus()
        }
    }

    setStudentSortOrder = (studentsObj) => {
        const studentsArr = Object.values(studentsObj)
        this.sortStudentsByAlpha(studentsArr)
        for (let i=0; i < studentsArr.length; i++) {
            const studentId = studentsArr[i].studentId
            studentsObj[studentId]["sortOrder"] = i
        }
    }

    sortStudentsByAlpha = (studentsArr) => {
        function compare(a, b) {
            if (a.nameFirst < b.nameFirst) {
                return -1;
            }
            if (a.nameFirst > b.nameFirst) {
                return 1;
            }
            return 0;
        }
        studentsArr.sort(compare);
    }

    sortStudentsByOrder = (studentsArr) => {
        function compare(a, b) {
            if (a.sortOrder < b.sortOrder) {
                return -1;
            }
            if (a.sortOrder > b.sortOrder) {
                return 1;
            }
            return 0;
        }
        studentsArr.sort(compare);

    }

    addNewRow = () => {
        function getNewIds(studentId) {
            console.log("1")
            const [newStudentLabel, newStudentNum] = studentId.split("-")
            return newStudentLabel === "newStudent";
        }
        function reverseSortNewIds(a, b) {
            console.log("2")
            const [aLabel, aNumStr] = a.split("-")
            const [bLabel, bNumStr] = b.split("-")
            const aNum = Number(aNumStr)
            const bNum = Number(bNumStr)
            return (bNum-aNum)
        }          
        const classroomId = this.props.classroomId
        const students = this.state.students
        const studentIds = Object.keys(students)
        const newStudentSortOrder = studentIds.length
        const newStudentIds = studentIds.filter(getNewIds)
        let thisNewStudentId = "newStudent-1"
        if (newStudentIds.length > 0) {
            newStudentIds.sort(reverseSortNewIds)
            const lastStudentId = newStudentIds[0]
            const [label, lastStudentNumStr] = lastStudentId.split("-")
            const lastStudentNum = Number(lastStudentNumStr)
            thisNewStudentId = "newStudent-"+(lastStudentNum+1)    
        }
        this.setState({
            students: {
                ...this.state.students,
                [thisNewStudentId]: {
                    nameFirst: "",
                    password: "",
                    studentId: thisNewStudentId,
                    classroomId: classroomId,
                    sortOrder: newStudentSortOrder
                }
            }
        })
    } 


    //Render Functions

    getModalUploadCsv = () => {
        let modalUploadCsv = null
        if (this.state.showUploadCsvModal) {
            modalUploadCsv = (
                <div>
                    <ModalUploadCsv
                        toggleShowUploadCsvModal={this.toggleShowUploadCsvModal}
                    />
                </div>
            )
        }
        return modalUploadCsv
    }

    getStudentsToDisplay = () => {
        //CLASS CODE
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classCodeForLink = classroom.classCode
        let deleteClassAlert = null
        if (this.state.displayDeleteClassAlert) {
            deleteClassAlert = this.deleteClassAlert()
        }        
        let removeStudentAlert = null
        if (this.state.displayRemoveStudentAlert) {
            removeStudentAlert = this.removeStudentAlert()
        }        

        //Highlight edited class code in yellow
        let styleClassCode = "inputclasscode-myclassrooms"
        if (this.state.classCode !== classCodeForLink) {
            styleClassCode = "inputclasscode-myclassrooms input-mcnew"
        }        

        //BOOK COVERS
        const bookCovers = this.getBookCovers()

        const allStudents = this.props.state.myClassrooms.students
        const studentsSaved = allStudents[classroomId] ?
            allStudents[classroomId] :
            false
        const studentsArr = Object.values(this.state.students)
        this.sortStudentsByOrder(studentsArr)
        let studentRows = []
        for (let i=0; i < studentsArr.length; i++) {
            const thisStudent = studentsArr[i]
            const studentId = thisStudent.studentId
            const name = thisStudent.nameFirst ?
                thisStudent.nameFirst :
                ""
            const password = thisStudent.password ?
                thisStudent.password :
                ""
            const tabIndexName = (i*2)+1
            const tabIndexPassword = (i*2)+2
            let styleName = "input-myclassrooms"
            let stylePassword = "input-myclassrooms"
            //Determine if the field is edited, and if so highlight it in yellow
            const thisStudentSaved = studentsSaved[studentId] ?
                studentsSaved[studentId] :
                false
            if (thisStudentSaved) {
                if (thisStudentSaved.nameFirst !== thisStudent.nameFirst) {
                    styleName = "input-myclassrooms input-mcnew"    
                }
                if (thisStudentSaved.password !== thisStudent.password) {
                    stylePassword = "input-myclassrooms input-mcnew"
                }
            } else {
                styleName = "input-myclassrooms input-mcnew"
                stylePassword = "input-myclassrooms input-mcnew"
            }
            //Determine if the student is marked to be deleted, and if so highlight in red
            if (thisStudent.toDelete) {
                styleName = "input-myclassrooms input-mcdelete"
                stylePassword = "input-myclassrooms input-mcdelete"
            }

            studentRows.push(
                <tr
                    key={studentId}
                >
                    <td className="col-50-body row-myclassrooms">
                        <input
                            className={styleName}
                            type="text"
                            id={studentId + " nameFirst"}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            tabIndex = {tabIndexName}
                            ref={ref => (this["ref"+tabIndexName] = ref)}
                            value={name}
                        />
                    </td>
                    <td className="col-50-body row-myclassrooms">
                        <input
                            className={stylePassword}
                            type="text"
                            id={studentId + " password"}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            tabIndex = {tabIndexPassword}
                            ref={ref => (this["ref"+tabIndexPassword] = ref)}
                            value={password}
                        />
                    </td>
                </tr>
            )
        }

        //Get manage book modal
        let manageBooks = null
        if (this.state.showManageBooks) {
            manageBooks = (
                <OneClassroomBooks
                    clickedManageBooks={this.clickedManageBooks}
                    classroomId={this.props.classroomId}
                />
            )
        }

        return (
            <div>
                {deleteClassAlert}
                {removeStudentAlert}
                {manageBooks}
                <div className="oneclassrooms-container">
                    <div className="header-oneclassroom">
                        <button className="btn-oneclassroom btnreports-oneclassroom" onClick={this.clickedUsageReport} >
                            <span>Usage Report</span>
                        </button>
                        <span className="classtitle-oneclassroom">{classroom.title}</span>
                        {/* <div className="btn-placeholder"></div> */}
                        <button className="btn-oneclassroom btnreports-oneclassroom" onClick={this.clickedJournalReport} >
                            <span>Journal Report</span>
                        </button>
                    </div>
                    <div className="header-oneclassroom-linetwo">
                        <span>Begin by creating a class code and then adding a book to this classroom by clicking on "Manage books."  Students are added by providing them the student sign-up link.  Click on copy student invitation and the welcome letter is ready to be pasted into your email.  Teachers may also add students manually by typing in student names and passwords.  If students forget a password the teacher can remind them or change it. </span>
                    </div>

                    <div className="leftmenu-oneclassroom">
                        <div className="classclodeblock-oneclassroom">
                            <div className="classcodetitle-oneclassroom">Create a Class Code:</div>
                            <div className="formclasscode-oneclassroom">
                                <form onSubmit={this.handleSubmit2} >
                                    <input
                                        className={styleClassCode}
                                        type="text"
                                        onChange={this.handleChangeClassCode}
                                        value={this.state.classCode}
                                    />
                                </form>
                            </div>
                            <button className="btn-oneclassroom btnclasscode-oneclassroom" onClick={this.saveClassCode} >
                                <span>Save Code</span>
                            </button>
                        </div>
                        <button className="btn-oneclassroom btndeleteclass-oneclassroom" onClick={this.clickedDeleteClass} >
                            <span>Delete Class</span>
                        </button>
                        <br/>
                        {/* <div className="classcodetitle-oneclassroom">Class Books</div> */}
                        <button className="btn-oneclassroom btnmanagebooks-oneclassroom" onClick={this.clickedManageBooks} >
                                <span>Manage Books</span>
                        </button>                        
                        <div className="tableWrap-books noborder-oneclassroom" >
                            <table className="table-oneclassroom noborder-oneclassroom">
                                <tbody>

                                    {bookCovers}

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="tableWrap-oneClassroom" >
                        <form onSubmit={this.handleSubmit} >
                            <table className="table-oneclassroom">
                                <thead>
                                    <tr>
                                        <th className="col-50-header">NAME</th>
                                        <th className="col-50-header">PASSWORD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentRows}
                                </tbody>
                            </table>
                        </form>
                    </div>

                    <div className="rightmenu-oneclassroom">
                        <div className="buttonblock-oneclassroom">
                            <button className="btn-oneclassroom" onClick={this.addNewRow} >
                                <span>Manually Add Student</span>
                            </button>

                            {/* <button className="btn-oneclassroom" onClick={this.toggleShowUploadCsvModal} >
                                <span>Upload Student List</span>
                            </button> */}

                            <label className="btn-oneclassroom">
                                <input className="hide-input" type="file" accept="text/csv" onChange={this.uploadCsv} />
                                <div className="center">
                                    <span>Upload Student List</span>
                                </div>
                            </label>


                            <button className="btn-oneclassroom" onClick={this.saveChanges} >
                                <span>Save</span>
                            </button>
                            <button className="btn-oneclassroom btnremovestudent-oneclassroom" onClick={this.removeStudent} >
                                <span>Remove Student</span>
                            </button>
                        </div>
                        <div className="signuplinkblock-oneclassroom">
                            <div className="classcodetitle-oneclassroom signuptext-oneclassroom">Student Sign Up Link</div>
                            <div className="classcodetitle-oneclassroom signuplink-oneclassroom"  ref={ref => (this.url = ref)}>https://storylabs.app/<br/>joinclass/{classCodeForLink}</div>
                            {/* <div className="classcodetitle-oneclassroom signuplink-oneclassroom"  ref={(textarea) => this.textArea = textarea}>storylabs.app/joinclass/{classCodeForLink}</div> */}
                            <div className="copylink-oneclassroom" onClick={this.copyToClipboard}>Click here to copy<br/>student invitation letter<br/>(to paste in email)</div>
                            
                            {/* Rounded switch from https://www.w3schools.com/howto/howto_css_switch.asp */}
                            {/* <div className="activatelink-oneclassroom">
                                 <span className="activatetext-onelcassroom">Activate sign up link</span>
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    deleteClassAlert = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div className="deletetext-oneclassroom">Delete this classroom and its students?</div>
                    <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.clickedKeepClass} >
                        <span>Keep</span>
                    </button>
                    <button className="btn-oneclassroom btndeleteclassconfirm-myclassrooms" onClick={this.clickedDeleteConfirm} >
                        <span>Delete</span>
                    </button>
                </div>
            </div>
        )
    }
    removeStudentAlert = () => {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classTitle = classroom.title

        const studentToRemove = this.state.blurredStudentId
        if (!studentToRemove) {
            this.setState({
                displayRemoveStudentAlert: false
            })
            return null
        }
        let studentToRemoveObj = this.state.students[studentToRemove]
        let studentName = studentToRemoveObj.nameFirst ?
            studentToRemoveObj.nameFirst :
            ""

        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div className="deletetext-oneclassroom">{"Remove " + studentName + " from the class?"} </div>
                    <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.clickedKeepStudent} >
                        <span>Keep</span>
                    </button>
                    <button className="btn-oneclassroom btndeleteclassconfirm-myclassrooms" onClick={this.clickedRemoveStudentConfirm} >
                        <span>Delete</span>
                    </button>
                </div>
            </div>
        )
    }


    getBookCovers = () => {
        let bookCovers = []
        const classroomId = this.props.classroomId
        const booksPurchased = this.props.state.books.booksPurchased
        const booksClassroomObj = this.props.state.books.paByClassroomIdBookId[classroomId] ?
            this.props.state.books.paByClassroomIdBookId[classroomId] :
            {}
        const bookIdsClassroom = Object.keys(booksClassroomObj)

        let booksForDisplay = []
        bookIdsClassroom.forEach( (bookId, i) => {
            const book = booksPurchased[bookId]
            if (book === undefined) { return }
            const bookCoverUrl = book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url ?
                    book.bookCoverImage.firebase_storage_url :
                    "" :
                ""
            const bookTitle = book.title ?
                book.title :
                ""
            const key = "pic-" + i
            const chapterId = book.chapterOrderArr ? book.chapterOrderArr[0] : "Chapter";
            booksForDisplay.push({
                bookCoverUrl,
                bookTitle,
                key,
                bookId,
                chapterId
            })
        })
        booksForDisplay.sort((a, b) => (a.bookTitle > b.bookTitle) ? 1 : -1)
        booksForDisplay.forEach((bookForDisplay) => {
            const key = bookForDisplay.key
            const bookCoverUrl = bookForDisplay.bookCoverUrl
            const bookTitle = bookForDisplay.bookTitle
            bookCovers.push(
                <tr key={key} >
                    <td className="col-100-body bookcol-oneclassroom noborder-oneclassroom">
                        <Link className="wrapping-link" to={'/Story/' + bookForDisplay.bookId + '/' + bookForDisplay.chapterId}></Link>
                        <div className="bookitem-oneclassroom">
                            <div className="bookitemcontainer-oneclassroom">
                                <div className="bookimagecontainer-oneclassroom">
                                    <img className="bookimage-oneclassroom" src={bookCoverUrl} alt="Book Cover" />
                                </div>
                                <div className="booktextcontainer-oneclassroom">
                                    {bookTitle}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        })
        
        return bookCovers
    }

    render(){
        const students = this.state.students
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )
        if (!students) { 
            return toDisplay
        }

        toDisplay = this.getStudentsToDisplay()        

        return toDisplay
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStudents: (classroomId) =>  dispatch(getStudents(classroomId)),
        updateStudentsAfterSave: (studentsToAdd) =>  dispatch(updateStudentsAfterSave(studentsToAdd)),
        saveClassCode: (classCode) =>  dispatch(saveClassCode(classCode)),
        deleteClassroom: (classroom) => dispatch(deleteClassroom(classroom)),
        parseStudentCsv: (file, classroomId) => dispatch(parseStudentCsv(file, classroomId))
        // replaceTicks: () => dispatch(replaceTicks()),
        // addClassId: () => dispatch(addClassId()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneClassroom)

