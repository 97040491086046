import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import M from 'materialize-css'
import { getQuillFile } from '../../../store/actions/retrieveClassBookActions'
import { saveNewMasterpiece } from '../../../store/actions/publisherActions'
import { updateQuillChapterFile, deleteChapterFile } from '../../../store/actions/publisherActions'
import firebase from '../../../config/fbConfig'


// https://www.npmjs.com/package/quill-image-drop-and-paste?activeTab=readme
// import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
// https://www.npmjs.com/package/blob-util/v/1.3.0?activeTab=readme
// import { base64StringToBlob } from 'blob-util'

// https://www.npmjs.com/package/quill-image-drop-module
import { ImageDrop } from 'quill-image-drop-module';

// https://github.com/zenoamaro/react-quill/issues/224
import * as QuillNamespace from 'quill';
// import ImageResize from 'quill-image-resize-module';  9/8/20
let Quill = QuillNamespace;
var BackgroundClass = Quill.import('attributors/class/background');
var ColorClass = Quill.import('attributors/class/color');
var SizeStyle = Quill.import('attributors/style/align');
// Quill.register('modules/imageResize', ImageResize);  9/8/20
// Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register(BackgroundClass, true);
Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);

//this replaces the quill p tag with a div and br
var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

class ShowQuillPub extends Component {

    state = { 
        text: "",
        quillHtml: ""
    }

    fileName = React.createRef();
    quillRef = null;      // Quill instance
    reactQuillRef = null; // ReactQuill component
    previousDelta = null;

    imageHandler1 = (image, callback) => {
        console.log("IN IMAGE HANDLER")
        // console.log(image)
        // console.log(this.quillRef)
        var range = this.quillRef.getSelection();
        console.log(range)
        var value = prompt('What is the image URL');
        if(value) {
            // this.quillRef.getEditor().insertEmbed(range.index, 'image', value, "user");
            console.log(this.quillRef)
        }
    }    

     // https://firebase.google.com/docs/storage/web/upload-files
     saveToFB = (file, callback) => {
        const chapter = this.props.chapter
        const bookId = chapter.bookId;
        const chapterId = chapter.chapterId;
        console.log("IDs")
        console.log(bookId)
        console.log(chapterId)

         // Create a root reference
         var storageRef = firebase.storage().ref();

        // Create the file metadata
        const extension = file.extension;
        var metadata = {
            contentType: 'image/'+extension
        };

        console.log('HEY')
        console.log(file.name)
        console.log(extension)
        // Upload file and metadata to the object 'images/mountains.jpg'
        // var uploadTask = storageRef.child('images/' + file.name).put(file, metadata);
        var uploadTask = storageRef.child('images/' + file.name).put(file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                console.log("ERROR Uploading")
                console.log(error)
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                // switch (error.code) {
                //     case 'storage/unauthorized':
                //         // User doesn't have permission to access the object
                //         break;

                //     case 'storage/canceled':
                //         // User canceled the upload
                //         break;
                    // ...
                    // case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                    // break;
            }, function () {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    callback(downloadURL);            
                });
            }
        );
    }


    // https://github.com/quilljs/quill/issues/863
    imageHandler = (e) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
  
        // Listen upload local image and save to server
        input.onchange = () => {
          const file = input.files[0];
  
          console.log("FILE")
          console.log(file)
          // file type is only image.
          if (/^image\//.test(file.type)) {
            // this.saveToFB(file);

            this.saveToFB( file, (url)  => {
                console.log("CALLED BACK")
                console.log(url)
                // https://github.com/quilljs/quill/issues/863
                // push image to quill editor
                const range = this.quillRef.getSelection();
                this.quillRef.insertEmbed(range.index, 'image', url);

            });
          } else {
            console.warn('You could only upload images.');
          }
        };        
    }





    modules = {
        formula: true,
        clipboard: {
            matchVisual: false, //this prevents an extra line break after headings
        },
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image'],
                ['clean'],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }]
            ],
            handlers: {
                // handlers object will be merged with default handlers object
                image: this.imageHandler
                // image: function(value) {
                //     console.log("In function")
                //     console.log(value)
                // }
            },

        },
        // imageResize: true,  9/8/20
        imageDrop: true,
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'script',
        'align',
        'color', 'background',
        'font'
    ];

    
    handleChange = (value, delta, source, editor) => {
        this.setState({ text: value })
        const text = editor.getText()
        console.log("VALUE")
        console.log(value)
        // console.log("TEXT")
        // console.log(text)
        console.log("DELTA")
        console.log(source)
        this.setState({
            quillHtml: value
        })
    }

    updateFile = (e) => {        
        const chapter = this.props.chapter
        const chapterFile = this.props.chapterFile
        const editor = this.quillRef
        const quillDelta = editor.editor.delta
        // https://stackoverflow.com/questions/42058551/get-html-from-delta-on-quill
        const htmlText = editor.root.innerHTML
        console.log(quillDelta)
        this.props.updateQuillChapterFile(chapter, chapterFile, quillDelta, htmlText)
    }

    saveMasterpiece = (e) => {
        const text = this.state.quillHtml
        const chapter = this.props.chapter
        const fileName = document.getElementById('file_name').value;
        this.props.saveNewMasterpiece(fileName, text, chapter)
    }

    saveImage = (e) => {
        console.log("IN saveImage")
        var range = this.quillRef;
        console.log(range)
        console.log(range.getSelection())
    }

    showQuill = (e) => {
        const htmlText = this.props.chapterFile["htmlText"]
        // console.log(htmlText)
        if (this.previousDelta == null) {
            console.log(1)
            this.previousDelta = this.quillRef.editor.getDelta()
        }
        console.log(2)
        // this.quillRef.editor.applyDelta(this.previousDelta)
        // this.quillRef.updateContents(this.previousDelta)

        // this.quillRef.pasteHTML(htmlText)

        this.quillRef.root.innerHTML = htmlText
    }


    // onChange(content, delta, source, editor) {
    //     const value = editor.getText(content);
    //     console.log(value)
    //     console.log(content)
    //     // this.setState({ text: value })
        
    // }

    deleteFile = () => {
        this.props.deleteChapterFile(this.props.chapterFile)
    }

    setInitialDelta = () => {
        const fileName = this.props.chapterFile.file_name
        // document.getElementById('file_name') = fileName
        // this.fileName.value = fileName
        // If this chapter file is using the old format (Gene's chapter_quillJs collection), 
        // retrieve the latest quill delta from chapter_quillJs
        // else, get the quillDelta from chapterFile.quillDelta
        if (this.props.chapterFile.contentType != "quillHtml") {
            // console.log("HERE NOW")
            const quillId = this.props.fileUrl;
            const quillFilesFromState = this.props.state.chapterFiles.quillFiles
            if (quillFilesFromState.hasOwnProperty(quillId)) {
                // console.log("quill file already in state")
                // console.log(this.props.state.chapterFiles)
                const quillDelta = quillFilesFromState[quillId]["quillEditorDelta"]
                const editor = this.quillRef;
                if (quillDelta != undefined) {
                    // console.log(quillDelta)
                    const parsedQuillDelta = JSON.parse(quillDelta)
                    editor.setContents(parsedQuillDelta)
                    // console.log("Quill Editor")
                    // console.log(editor)        
                } else {
                    editor.setContents([])                
                }
            } else {
                // console.log("quill file not in state. retrieving")
                // console.log(this.props.state.chapterFiles)
                this.props.getQuillFile(quillId)
            }    
        } else {
            const quillDelta = this.props.chapterFile.quillDelta
            const editor = this.quillRef;
            if (quillDelta != undefined) {
                console.log("CHAPTER FILE DELTA")
                console.log(quillDelta)
                const parsedQuillDelta = JSON.parse(quillDelta)
                editor.setContents(parsedQuillDelta)
                // console.log("Quill Editor")
                // console.log(editor)        
            } else {
                editor.setContents([])                
            }
        }
    }


    componentDidMount() {
        console.log("ShowQuillPub DidMount")
        // const editor = ReactQuill.editor
        // const x = editor.getText()
        // const chapter = this.props.chapter
        // const chapterFile = this.props.chapterFile
        // console.log("CHAPTERfiles")
        // console.log(chapterFiles)
        // console.log(chapterFile)

        this.attachQuillRefs()
        
        // const chapter = this.props.chapter
        // console.log("Mounted ShowQuill")
        // console.log(chapter)

        this.setInitialDelta()

    }

    componentDidUpdate(prevProps) {
        // console.log("ShowQuill DidUpdate")
        this.attachQuillRefs()
        // Typical usage (don't forget to compare props):
        // if (this.props.userID !== prevProps.userID) {
        //     this.fetchData(this.props.userID);
        // }
        // const links = auth.id ? <SignedInLinks /> : <SignedOutLinks />;
        // console.log("PREV props")
        // console.log(prevProps)



        // If this chapter file is using the old format (Gene's chapter_quillJs collection), display the latest quill delta
        if (this.props.chapterFile.contentType != "quillHtml") { 
            const quillId = this.props.fileUrl;
            const prevQuillFile = prevProps.state.chapterFiles.quillFiles[quillId]
            //Check if the quill file existed in the prevProps. If it did exist, then do nothing.
            if (prevQuillFile == undefined) {
                const quillFile = this.props.state.chapterFiles.quillFiles[quillId]
                //check the current props for a quill file. If the quill file exists, then display it.
                if (quillFile != undefined) {
                    const quillDelta = quillFile["quillEditorDelta"]
                    // console.log("QUILL in state")
                    // console.log(quillDelta)
                    const editor = this.quillRef
                    // console.log(editor)
                    const parsedQuillDelta = JSON.parse(quillDelta)
                    editor.setContents(parsedQuillDelta)
                    // console.log(editor.editor.delta)    
                }
            }
        } else {

            if (this.props.chapterFile != prevProps.chapterFile) {
                this.setInitialDelta()
            }
        }



        // console.log("PREVIOUS props")
        // console.log(prevProps)
        // const quillId = this.props.fileUrl;
        // const quillFile = this.props.state.chapterFiles.quillFiles[quillId]
        // if (quillFile == undefined) {
        //     console.log("QUILL NOT in state")
        // } else {
        //     const quillDelta = this.props.state.chapterFiles.quillFiles[quillId]["quillEditorDelta"]
        //     console.log("QUILL in state")
        //     console.log(quillDelta)
        //     const editor = this.quillRef
        //     console.log(editor)
        //     const parsedQuillDelta = JSON.parse(quillDelta)
        //     editor.setContents(parsedQuillDelta)
        //     console.log(editor.editor.delta)
        // }
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    render(){
        // const quillId = this.props.fileUrl;
        // let quillFile = this.props.state.chapterFiles.quillFiles[quillId]
        // if (quillFile == undefined) {
        //     quillFile = ""
        //     console.log("QUILL NOT in state")
        // } else {
        //     const theQuillFile = this.props.state.chapterFiles.quillFiles[quillId] 
        //     quillFile = this.props.state.chapterFiles.quillFiles[quillId]["quillEditorDelta"]
        //     const quillDelta = quillFile["'ops'"]
        //     console.log("QUILL in state")
        //     console.log(quillFile)
        // }

        const styles1 = {
            height: "50px",
            paddingTop: "0px",
            borderWidth: "0px",
            marginTop: "0px"
        };

        const quill = (
            <div>
                    
                    <div >
                        <form >
                            File Name:
                             <div >
                            <input id="file_name" ref={this.fileName} type="text" className="validate" />
                            {/* <label htmlFor="file_name">File Name</label> */}
                            </div>
                        </form>
                        <div >
                        <button className="btn" onClick={this.updateFile}>
                            Update File
                            </button>
                        <button className="btn" onClick={this.saveMasterpiece}>
                            Save File
                            </button>
                        <button className="btn" onClick={this.saveImage}>
                            Save Image
                            </button>
                        <button className="btn" onClick={this.showQuill}>
                            Show Quill
                            </button>
                            <button className="btn" onClick={this.deleteFile}>
                            Delete File
                            </button>


                        </div>
                    </div>




                <div>
                    <ReactQuill 
                    
                        modules={this.modules}
                        formats={this.formats}
                        placeholder= {'Write here...'}
                        ref={(el) => { this.reactQuillRef = el }}
                        name="editor"
                        onChange={this.handleChange}
                        value={this.state.text}
                    />
                </div>
                
                <div dangerouslySetInnerHTML={{__html: this.state.quillHtml}}></div>
                {/* <div dangerouslySetInnerHTML={{__html: this.props.chapterFile.htmlText}}></div> */}
                {/* <div>{this.state.quillHtml}</div> */}

                {/* <div dangerouslySetInnerHTML={{__html: this.props.fileUrl}}></div> */}

            </div>
        )

        return (
            quill
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getQuillFile: (quillId) => dispatch(getQuillFile(quillId)),
        saveNewMasterpiece: (fileName, text, chapter) => dispatch(saveNewMasterpiece(fileName, text, chapter)),
        updateQuillChapterFile: (chapter, chapterFile, quillDelta, htmlText) => dispatch(updateQuillChapterFile(chapter, chapterFile, quillDelta, htmlText)),
        deleteChapterFile: (chapterFile) => dispatch(deleteChapterFile(chapterFile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowQuillPub)


