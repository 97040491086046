import React from 'react'


const Privacy = () => {
    return (
<div className="privacy-policy">

<p dir="ltr">
    Privacy Policy
</p>
<p dir="ltr">
    Revised: May 25, 2020
</p>

<p dir="ltr">
    Introduction
</p>
<p dir="ltr">
    Storylabs is an educational technology company that offers a smart, online
    delivery of independent curricula. We let teachers and publishers
    synthesize, share, and/or sell their favorite curricula and their favorite
    EdTech tools to deliver a trackable, online learning experience.
</p>

<p dir="ltr">
    At Storylabs, we take security and privacy seriously. This Privacy Policy
    (“Privacy Policy”) describes the types of information that may be collected
    or processed by us or that website users provide to us when visiting our
    website www.storylabs.online, or when registering with, installing,
    downloading, accessing, or otherwise using the content or applications we
    provide.
</p>

<p dir="ltr">
    We use the term “Personal Data” in this policy to refer to information that
    personally identifies a user of Storylab’s platform as well as other
    information we receive or create in the course of a User’s usage of the
    Platform that is linked to information that personally identifies a User.
</p>

<p dir="ltr">
    Please read this policy carefully to understand our policies and practices
    regarding your information and how we will treat it. If you do not agree
    with our policies and practices, your choice is not to use our Website or
    application. By accessing or using this Website or application, you agree
    to this Privacy Policy. This policy may change from time to time (see
    Section 10 Privacy Policy Changes).
</p>

<p dir="ltr">
    Information We Collect:
</p>
<p dir="ltr">
    We collect Personal Data in different ways. We collect Personal Data
    directly when Users register for a Storylabs account or when a teacher
    invites a student to join their class on the Platform by entering the
    student’s email into the Platform and sending the student an email
    invitation.
</p>

<p dir="ltr">
    We collect several types of information from and about users of our
    Website. This includes information by which you may be personally
    identified, such as a name, e-mail address, username or any other
    information the Website collects that is defined as personal or personally
    identifiable information under an applicable law (“personal data”), and
    information about you in the aggregate that does not individually identify
    you, such as your internet connection, the equipment you use to access our
    Website, and usage details.
</p>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            Information You Provide:
        </p>
    </li>
</ol>


<ul>
    <li dir="ltr">
        <p dir="ltr">
            Requests and Forums. If you submit a request (through email, text,
            or other electronic communication) for information or otherwise
            contact us through our Website, we record your request and can copy
            your correspondence and your identifying contact information (for
            clarity, references to “Contact Information” throughout this
            Privacy Policy includes name, email address, phone number, and
            address).
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Account Information. Information that you provide by using
            software, applications, or filling in forms on the Website. This
            includes information provided at the time of registering to use the
            Website, such as subscribing to our service, payment information
            submitted when creating a profile or selecting an activity, posting
            material or requesting further services. Details of transactions
            you carry out through the Website and of the fulfillment of your
            orders. You may be required to provide financial information before
            placing an order through the Website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Surveys and Contest. Your responses to surveys that we might ask
            you to complete for research purposes. We may also ask you for
            information when you enter a contest or promotion sponsored by us,
            and when you report a problem with the Website.
        </p>
    </li>
</ul>
<p dir="ltr">
    You also may provide information to be published or displayed (“Posted”) on
    public areas of the Website, or transmitted to other users of the Website
    or third parties (collectively, “User Contributions”). Your User
    Contributions are posted on and transmitted to others at your own risk. We
    cannot control the actions of other users of the Website with whom you may
    choose to share your User Contributions.
</p>

<ol start="2">
    <li dir="ltr">
        <p dir="ltr">
            Information Collected Automatically:
        </p>
    </li>
</ol>

<p dir="ltr">
    This policy applies to information we collect automatically as you use the
    Website, content or data, or interact with third-party websites,
    applications or software. Information collected automatically may include:
</p>

<ul>
    <li dir="ltr">
        <p dir="ltr">
            Log Information: We collect log information about your use of the
            Services, including the type of browser you use, access times,
            pages viewed, your IP address and the page you visited before
            navigating to our Services.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Device Data: Like most web-based services, we (or our Service
            Providers) may automatically receive and log information from your
            browser or your device when you use our Platform (“Device Data”).
            Device data received could include information about your mobile
            device and internet connection, including the device’s unique
            device identifier, IP address, operating system, browser type,
            mobile network information, and the device’s telephone number.
            Additionally, we may automatically collect certain details of your
            access to and use of the Website, including traffic data, location
            data, logs, and other communication data and the resources that you
            access and use on or through the Website or related applications.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Usage Data: User interactions with our Platform generates data we
            refer to as “Usage Data”. Usage Data for Student Users may include
            how a student performed on select lessons, when a student starts
            and stops a lesson, and student responses in their notebook. Usage
            Data for Educator Users may include their class rosters, the
            lessons they have created and assigned, and their class
            preferences. Usage Data will be used for educational and product
            development purposes only.
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            If you use Storylab on different devices, we may link the
            information we collect from those different devices to help us
            provide a consistent Platform experience across your different
            devices.
        </p>
    </li>
</ul>

<p dir="ltr">
    These do not apply to information we collect offline or through any other
    means, including on any other website operated by Company or any third
    party; or any third party, including through any application or content
    (including advertising) that may link to or be accessible from or on the
    Website.
</p>

<p dir="ltr">
    If you provide us any Personal Data relating to other individuals, you
    represent that you have the authority to do so and permit us to use the
    Personal Data in accordance with this Privacy Statement. If you believe
    that your Personal Data has been provided to us improperly, or to otherwise
    exercise your rights relating to your Personal Data, please contact us by
    using the information in Section 11 below.
</p>
<p dir="ltr">
    How We Use Data:
</p>

<p dir="ltr">
    We use the Personal Data we collect and process to provide, maintain, and
    improve our services and may also use the Personal Data we collect to:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Deliver better and more personalized services.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Estimate our audience size and usage patterns.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Store information about your use and preferences.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Enhance the content and any other products or services we may
            offer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Enhance any applications or software we offer as part of the
            Website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Enhance the Website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Recognize you when you return to or use the Website.
        </p>
    </li>
</ul>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            To present the Website and its contents to you.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To provide you with information, products or services that you
            request from us.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To develop new products or services.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To fulfill any other purpose for which you provide it.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To provide you with notices about your account or subscription,
            including expiration and renewal notices.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To carry out our obligations and monitor and enforce our rights
            arising from your use of the Website, software, content or
            services, or any contracts entered into between you and us,
            including for licensing, billing and collection.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To notify you about changes to the Website or any products or
            services we offer or provide though it.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To allow you to participate in interactive features through the
            Website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To audit compliance with our Terms and licensing.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To facilitate third-party applications or services.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In any other way we may describe when you provide the information.
        </p>
    </li>
</ul>
<p dir="ltr">
    How We Collect Data Automatically
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Cookies (or browser or mobile cookies). Cookies are small data
            files stored on your hard drive or in device memory that help us
            improve our Services and your experience, see which areas and
            features of our Services are popular, and count visits. You may
            refuse to accept cookies by activating the appropriate setting.
            However, if you select this setting you may be unable to access
            certain parts of the Website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Flash Cookies. Certain features of the Website may use local stored
            objects (or Flash cookies) to collect and store information about
            your preferences and navigation to, from and on the Website. Flash
            cookies are not managed by the same browser settings as are used
            for browser cookies.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Web Beacons. Pages of the Website may contain small electronic
            files known as web beacons (also referred to as clear gifs, pixel
            tags and single-pixel gifs) that permit the Company, for example,
            to count users who have visited those pages and for other related
            website or application statistics (such as, recording the
            popularity of certain website or application content and verifying
            system and server integrity).
        </p>
    </li>
</ul>
<ol start="3">
    <li dir="ltr">
        <p dir="ltr">
            Third-Party Usage
        </p>
    </li>
</ol>
<p dir="ltr">
    Certain third-parties may use automatic information collection technologies
    to collect information about you when you use the Website. The information
    they collect may be associated with your personal data or they may collect
    information, including personal data, about your online activities over
    time and across different websites, applications, and other online
    services.
</p>
<p dir="ltr">
    We do not control these third parties' tracking technologies or how they
    may be used. If you have any questions about an advertisement or other
    targeted content, you should contact the responsible third-party directly.
</p>

<p dir="ltr">
    We do not disclose, share, rent, or sell any User Information to any third
    parties for commercial uses, such as targeted advertising. We only disclose
    or share User Information with bona fide Service Providers for purposes
    related to or arising out of the ordinary course of creation, development,
    operation, service, and maintenance of the Platform. Such bona fide Service
    Providers shall only use such User Information for such purposes and not to
    sell such User Information under any circumstances.
</p>

<p dir="ltr">
    Service Providers who do help us operate our Platform must adhere to
    privacy and security obligations in a manner consistent with the Company’s
    policies and practices.
</p>

<ol start="4">
    <li dir="ltr">
        <p dir="ltr">
            Educator Users and Student Data
        </p>
    </li>
</ol>

<p dir="ltr">
    If you are a Student User using the Platform in connection with a teacher,
    school, or district (a “School”), your School administrator(s) and
    teacher(s) (“Educator Users” and each an “Educator User”) may have the
    ability to access, monitor, use, edit, delete or disclose data related to
    Student Data. Additionally, Educator Users may create Student User accounts
    on behalf of students and in so doing, provide Storylab with the Personal
    Data of students. If you are an Educator User, you agree that you will
    obtain and maintain all required consents from Student Users or their
    parents or legal guardians (when such Student Users are under the age of 13
    or the age of consent in the state in which the Student resides) to allow:
    (i) your access, monitoring, use, editing, deleting, and disclosure of
    their Student Information and our providing you with the ability to do so,
    and (ii) your Student Users’ use of the Platform.
</p>

<p dir="ltr">
    If you are a Student User using the Platform in connection with a School
    and do not believe you or your parent or guardian has provided consent for
    the School or its Educator Users to access, monitor, use, edit, delete, or
    disclose data related to your Usage Data and Personal Data, please contact
    us immediately at <a>info@storylabslanguage.com</a>.
</p>

<ol start="5">
    <li dir="ltr">
        <p dir="ltr">
            Viewing and Correcting Information
        </p>
    </li>
</ol>

<p dir="ltr">
    A parent or guardian may review Student Information in the applicable
    student’s records by viewing the Student’s Storylabs account. The Platform
    enables any Educator User to permit parents, legal guardians, and eligible
    pupils to review personally identifiable information contained in Student
    Data, and to correct erroneous information, in accordance with procedures
    established by the School.
</p>

<p dir="ltr">
    To the extent that a User opts to share his or her profile with his or her
    parent or guardian, such User expressly agrees to such sharing and all
    related responsibilities and liabilities therewith. Minor Users or Child
    Users cannot opt-out of sharing his or her profile with his or her parent
    or guardian. Parents, legal guardians, or eligible students may review
    student Personal Data and correct erroneous information by contacting their
    educational institution. Our Platform provides educational institutions
    with the functionality required to assist educational institutions in
    responding to requests from parents, legal guardians, or students to
    inspect and review Personal Data and categories of data being held in
    education records. Additionally, users may access, correct, update, or
    delete personal information in their profile by signing into a console,
    accessing their user account, and making the appropriate changes.
</p>

<p dir="ltr">
    We fully comply with the Requirements for Accessible Electronic and
    Information Technology Design as laid out by the U.S. Department of
    Education here.
</p>

<ol start="6">
    <li dir="ltr">
        <p dir="ltr">
            How Long We Keep Data
        </p>
    </li>
</ol>

<p dir="ltr">
    Following the termination of a license, a School may request that we
    deactivate and de-identify Student Information and we will do so, unless
    the School or applicable regulations require the retention of such data, in
    which case the records shall be de-identified upon the expiration of the
    retention period.
</p>

<p dir="ltr">
    In the case of a request for deactivation and de-identification, the
    following happens:
</p>

<p dir="ltr">
    ● We will encrypt all of the Personal Data in the relevant Student User
    accounts. This means that their email, first name, last name, salutation,
    and username get replaced with a long, meaningless identifier that is
    randomly assigned. This is a one-way change, and we can never recover the
    identity associated with the account after this step. We will perform this
    obfuscation in our database, all backups that we maintain, and in any
    Service Providers that we use to deliver the Platform.
</p>
<p dir="ltr">
    ● We will retain all Usage Data associated with the accounts to improve the
    Platform. These reasons include, but are not limited to: internal data
    analytics and prevention of fraud and abuse.
</p>
<p dir="ltr">
    ● This action results in the deactivation of the impacted Student User
    accounts, preventing them from being used or restored in the future.
</p>
<p dir="ltr">
● In order to request an account reactivation, please contact us at i    <a>nfo@storylabslanguage.com</a>. To request that Student Information be
    de-identified, please contact us at i<a>nfo@storylabslanguage.com</a>
</p>

<p dir="ltr">
    Please note that the requested deletion will be as comprehensive as
    possible but is always subject to issues outside of our control, including
    applicable regulations and laws, your actions and the actions of third
    parties. We may also need to retain a copy of certain information for legal
    compliance purposes, including, without limitation, to avoid identity theft
    or fraud.
</p>

<p dir="ltr">
    We will delete or de-identify personal information when it is no longer
    needed, upon expiration or termination of our agreement with an educational
    institution with any deletion or de-identification to be completed
    according to the terms of our agreement with the educational institution,
    or at the direction or request of the educational institution.
</p>

<ol start="7">
    <li dir="ltr">
        <p dir="ltr">
            Disclosure
        </p>
    </li>
</ol>
<p dir="ltr">
    We may disclose aggregated information about our users, and information
    that does not identify any individual, without restriction.
</p>
<p dir="ltr">
    We may disclose personal information that we collect or you provide as
    described in this Privacy Policy:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            To contractors, service providers, and other third parties we use
            to support our business and are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them or which you consent to.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To a buyer or other successor in the event of a merger,
            divestiture, restructuring, reorganization, dissolution or other
            sale or transfer of some or all of Company’s assets, whether as a
            going concern or as part of bankruptcy, liquidation or similar
            proceeding, in which personal information held by Company about the
            Website users is among the assets transferred.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To fulfill the purpose for which you provide it.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For any other purpose disclosed by us when you provide the
            information.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            With your consent.
        </p>
    </li>
</ul>
<p dir="ltr">
    We may also disclose your personal information:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            To comply with any court order, law or legal process, including
            responding to any government or regulatory request.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To enforce and apply our Terms, terms of sale, or licensing of
            goods or services, and other agreements, including for billing and
            collection purposes.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Company, our customers or others.
            This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
        </p>
    </li>
</ul>
<ol start="8">
    <li dir="ltr">
        <p dir="ltr">
            Your Choices
        </p>
    </li>
</ol>
<p dir="ltr">
    There are mechanisms available to provide you with control over your
    information:
</p>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Tracking Technologies and Advertising. You can set your browser to
            refuse all or some browser cookies or to alert you when cookies are
            being sent. To learn how you can manage your Flash cookie settings,
            visit the Flash player settings page on Adobe's website. If you
            disable or refuse cookies, please note that some parts of this site
            may then be inaccessible or not function properly.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Promotional Offers from the Company. If you do not wish to have
            your contact information used by the Company to promote our own or
            third parties' products or services, you can opt-out by sending us
            an email stating your request to info@storylabslanguage.com. If we
            have sent you a promotional email, you may send us a return email
            asking to be omitted from future email distributions. This opt-out
            does not apply to information provided to the Company as a result
            of a product purchase, warranty registration, product service
            experience or other transactions.
        </p>
    </li>
</ul>
<ol start="9">
    <li dir="ltr">
        <p dir="ltr">
            Security
        </p>
    </li>
</ol>
<p dir="ltr">
    We have implemented reasonable measures designed to secure your personal
    information from accidental loss and from unauthorized access, use,
    alteration, and disclosure.
</p>
<p dir="ltr">
    The safety and security of your information also depend on you. Where we
    have given you (or where you have chosen) a password for access to certain
    parts of the Website, you are responsible for keeping this password
    confidential. We ask you not to share your password with anyone. We also
    urge you to be careful about giving out information in public areas of the
    Website like message boards. Other users of the Website may view any
    information you share in public areas.
</p>
<p dir="ltr">
    Unfortunately, the transmission of information via the internet is not
    completely secure. Although we do our best to protect your personal
    information, we cannot guarantee the security of your personal information
    transmitted in relation to the Website. Any transmission of personal
    information is at your own risk. We are not responsible for circumvention
    of any privacy settings or security measures contained on the Website.
</p>

<p dir="ltr">
    Storylabs is committed to preventing unauthorized access to our systems and
    data, and will investigate any possible occurrence. In the event of a
    breach of personal data, StoryLabs will comply with all relevant breach
    laws. We make concerted good faith efforts to maintain the security of
    personal information. Outages, attacks, human error, system failure,
    unauthorized use, or other factors may compromise the security of user
    information at any time. If we learn of a security breach or other
    unauthorized disclosure of your Personal Data, we will attempt to notify
    you so that you can take appropriate protective steps by posting a notice
on our homepage (    <a href="http://www.storylabs.online/">www.storylabs.online</a>) or
    elsewhere in our Service and we will send email to you at the email address
    you have provided to us. Additionally, we will notify the primary
    administrative contact at your school or district by email and telephone
    and assist with their efforts to ensure your notification. Any such notice
    will include:
</p>

<ul>
    <li dir="ltr">
        <p dir="ltr">
            The date of the breach.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The type of information that was subject to breach.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            General description of what occurred.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Steps we are taking to address the breach.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The contact person with Storylabs who you can contact regarding the
            breach.
        </p>
    </li>
</ul>
<ol start="10">
    <li dir="ltr">
        <p dir="ltr">
            Privacy Policy Changes
        </p>
    </li>
</ol>
<p dir="ltr">
    It is our policy to post any changes we make to our Policy on this page. If
    we make material changes to how we treat our users' personal data, we will
    notify you at least 30 days in advance by posting the new privacy policy on
    this page and with a notice that the privacy policy has been updated. The
    date the Policy was last revised is identified at the top of the page. You
    are responsible for ensuring we have an up-to-date active and deliverable
    email address for you, and for periodically visiting the Website and this
    Policy to check for any changes.
</p>
<ol start="11">
    <li dir="ltr">
        <p dir="ltr">
            Contacting Us
        </p>
    </li>
</ol>

<p dir="ltr">
    To exercise your rights regarding your Personal Data, or if you have any
    questions regarding this Privacy Statement or our privacy practices,
    please:
</p>

<p dir="ltr">
    E-mail us at <a>info@storylabslanguage.com</a>,
</p>
<p dir="ltr">
    Call 1-(530) 350-9272, or
</p>
<p dir="ltr">
    Write us at:
</p>

<p dir="ltr">
    Storylabs Language
</p>
<p dir="ltr">
    5000 Windplay Drive Suite 3-203
</p>
<p dir="ltr">
    El Dorado Hills, CA 95762
</p>


</div>
    )
}


export default Privacy;