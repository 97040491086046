import React from 'react'
import Terms from "./Terms"
import Privacy from "./Privacy"
import Copyright from "./Copyright"
import Footer from "../../ui/navbar/Footer"

const Policies = (props) => {
    const chapterId = props.match.params.policy;

    let ToShow

    switch (chapterId) {
        case "copyright":
            ToShow = Copyright
            break;
        case "privacy":
            ToShow = Privacy
            break
        default:
            ToShow = Terms
    }

    return (
        <div className="policy-container">
            <ToShow />
            <div className="footer">
                    <Footer />
                </div>

        </div>
    )
}


export default Policies;