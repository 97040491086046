import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signInStudent } from '../../store/actions/authActions'
import { signOut } from '../../store/actions/authActions'
import { getJoinClassroom } from '../../store/actions/authActions'
import { joinClassStudent } from '../../store/actions/authActions'
import { resetPwError } from '../../store/actions/authActions'
import { resetSuccess } from '../../store/actions/authActions'


class SignUpStudent extends Component {
    state = {
        name: "",
        password: "",
    }
    componentDidMount() {
        const classCode = this.props.match.params.classCode
        this.props.getJoinClassroom(classCode)
        // this.props.signOut()
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("sign up update")
        console.log(this.props.state)
        const prevPwError = prevProps.state.auth.pwError ?
            prevProps.state.auth.pwError :
            ""
        const pwError = this.props.state.auth.pwError ?
            this.props.state.auth.pwError :
            ""
        if (prevPwError === "" && pwError !== "") {
            this.passwordAlert()
            this.props.resetPwError()
            return
        }
    }
    componentWillUnmount() {
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const credentials = {
            ...this.state,
        }
        this.props.joinClassStudent(credentials)
    }
    clickedOK = () => {
        // this.props.resetSuccess()
        this.props.history.push("/")
    }
    clickedOKDisabled = () => {
        // this.props.resetSuccess()
        this.props.history.push("/signin")
    }
    
    passwordAlert = () => {
        alert("Please choose a different password")
    }

    passwordLabel = () => {
        let label = "Password"
        const classCode = this.props.match.params.classCode;
        if (classCode === "parents" || classCode === "1776unites") {
            label = "Email (this will be your password)"
        }
        return label
    }

    //render functions
    signUpForm = () => {
        const joinClass = this.props.state.auth.joinClass
        const classTitle = joinClass.classroom ?
            joinClass.classroom.title ?
                joinClass.classroom.title :
                "" :
            ""
        return (
            <form className="login-form" onSubmit={this.handleSubmit} >
                <h2>Create a new login for {classTitle}</h2>
                <div className="group">
                    <input className="login-input" type="text" id="name" onChange={this.handleChange} />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="login-label" htmlFor="name">Name</label>
                </div>
                <div className="group">
                    <input className="login-input" type="password" id="password" onChange={this.handleChange} />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label className="login-label" htmlFor="password">{this.passwordLabel()}</label>
                </div>
                <button className="button buttonBlue">Signup</button>
            </form>
        )

    }
    verification = () => {
        const pwError = this.props.state.auth.pwError
        return (
            <div className="verification-container">
                <div className="verification-item1">
                    <div className="verify-text1">
                        {pwError}
                    </div>
                </div>
            </div>
        )
    }
    getSuccessAlert = (classTitle, classCode) => {
        return (
            <div className="ajs-modal">
                <div className="successbox-signupstudent">
                    <div className="deletetext-oneclassroom">You successfully joined <br/>
                        <span className="bold-signsupstudent">{classTitle}</span>! 
                    </div><br/>
                    <div className="deletetext-oneclassroom">For future logins, go to {" "}<br/>
                        <span className="bold-signsupstudent">storylabs.app/signin</span><br/> 
                        and use these credentials:
                        </div>
                    <div className="deletetext-oneclassroom">
                        <div className="border-signupstudent">
                            Class Code: {" "}
                            <span className="bold-signsupstudent">{classCode}</span>
                            <br />&<br />
                            <span className="bold-signsupstudent">Your New Password</span>
                        </div>
                    </div><br/>
                    <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.clickedOK} >
                        <span>OK</span>
                    </button>
                    {/* <button className="btn-oneclassroom btndeleteclassconfirm-myclassrooms" onClick={this.clickedDeleteConfirm} >
                        <span>Delete</span>
                    </button> */}
                </div>
            </div>
        )
    }
    getDisabledAlert = (classTitle) => {
        return (
            <div className="ajs-modal">
                <div className="successbox-signupstudent">
                    <div className="deletetext-oneclassroom">Sorry, you can't join <br/>
                        <span className="bold-signsupstudent">{classTitle}</span> <br/>
                        from this page <br/><br/>
                        Please contact your teacher
                    </div><br/>
                    <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.clickedOKDisabled} >
                        <span>OK</span>
                    </button>
                </div>
            </div>
        )
    }



    render() {
        
        let toDisplay = (
            <div className="signinpage-container">
                <div className="loader"></div>
            </div>
        )
        // const thisAuth = this.props.state.auth
        // if (thisAuth.uidStudent) {
        //     return <Redirect to='/' />
        // }
        const joinClass = this.props.state.auth.joinClass
        const joinSuccess = this.props.state.auth.joinSuccess
        if (joinClass !== null && joinClass !== "error") {
            const classTitle = joinClass.classroom.title ?
                joinClass.classroom.title :
                ""
            const classCode = joinClass.classroom.classCode ?
                joinClass.classroom.classCode :
                ""    
            let successAlert = null
            let disabledAlert = null
            if (joinSuccess) {
                successAlert = this.getSuccessAlert(classTitle, classCode)
            }
            if (joinClass.classroom.joinclassDisabled) {
                disabledAlert = this.getDisabledAlert(classTitle)
            }
            toDisplay = (
                <div>
                    {successAlert}
                    {disabledAlert}
                    <div className="signinpage-container">
                        <div className="header-signupstudent">Welcome to {classTitle}</div>
                        <div>The class code for this classroom is{" "}
                            <span className="bold700-signsupstudent">{classCode}</span>    
                        </div>
                        {this.signUpForm()}
                        {/* {this.verification()} */}
                    </div>
                </div>
            )
        }
        return toDisplay
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInStudent: (creds) => dispatch(signInStudent(creds)),
        signOut: () => dispatch(signOut()),
        getJoinClassroom: (classCode) => dispatch(getJoinClassroom(classCode)),
        joinClassStudent: (credentials) => dispatch(joinClassStudent(credentials)),
        resetPwError: () => dispatch(resetPwError()),
        resetSuccess: () => dispatch(resetSuccess())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpStudent)

