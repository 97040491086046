const initState = {
    authorBooks: {
        isEmpty: true
        // author1: [],
        // author2: []
    },
    cart: {
        bookId1: 1,
        bookId2: 500,
        bookId3: 25
    }
}

const storyReducer = (state = initState, action) => {
    switch (action.type) {
        case "NOT_SET":
            return {
                ...state,
                authorBooks: action.store
            }
            case "NOT_SET2":
            return {
                ...state,
            }
        default:
            return state
    }
}

export default storyReducer