import React from "react";
import Drawer from "react-modern-drawer";

export const NavDrawer = ({ isOpen, handleNavToggleClick, children }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={handleNavToggleClick}
      direction="right"
      className="drawer"
    >
      <div className="drawer-close-box">
        <button
          type="button"
          className="drawer-close"
          onClick={handleNavToggleClick}
        >
          <img src="../../../../img/x-close.svg" alt="" />
        </button>
      </div>

      {children}
    </Drawer>
  );
};
