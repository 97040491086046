import React from 'react'
import BookCard from '../ui/BookCard'
import { Link } from "react-router-dom"



const ClassContainer = ({booksPurchased}) => {  
    let toDisplay = (<div className="loader"></div>)
    if (booksPurchased !== null) {
        const booksArr = Object.values(booksPurchased)
        toDisplay = (
            <div className="oneclass-container">
                {/* <h2 className="classroom-title"><em>Purchased Books</em></h2> */}
                <BookCard books={booksArr} />
            </div>
        )
    }

    return (
        <div >
            {toDisplay}
        </div>
    )
}

export default ClassContainer


