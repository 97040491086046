import React, { useState } from "react";
import { useMedia } from "use-media";
import { mediaQueries } from "../../../config/mediaQueries";
import "react-modern-drawer/dist/index.css";
import { BrandLogoLarge, BrandLogoSmall } from "./BrandLogo";
import { NavDrawer } from "./NavDrawer";
import { AppNavLink } from "../AppNavLink";

const SignedOutLinks = (props) => {
  const isSmall = useMedia(mediaQueries.mobile);
  const [isOpen, setIsOpen] = useState(false);

  const handleNavToggleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  if (isSmall) {
    return (
      <>
        <div className="nav-links">
          <AppNavLink className="brand-link" to="/">
            <BrandLogoSmall />
          </AppNavLink>
          <button
            type="button"
            className="nav-toggle"
            onClick={handleNavToggleClick}
          >
            <img src="../../../../img/iconmonstr-menu-1.svg" alt="" />
          </button>
        </div>
        <NavDrawer
          isOpen={isOpen}
          onClose={handleNavToggleClick}
          handleNavToggleClick={handleNavToggleClick}
        >
          <>
            <AppNavLink
              to="/SignIn"
              className="drawer-item"
              onClick={handleNavToggleClick}
            >
              Sign In
            </AppNavLink>
            <hr />
            <AppNavLink
              to="/SignUp"
              className="drawer-item"
              onClick={handleNavToggleClick}
            >
              Teacher Sign Up
            </AppNavLink>
          </>
        </NavDrawer>
      </>
    );
  }

  return (
    <div>
      <BrandLogoLarge />

      <ul className="right">
        <li>
          <AppNavLink to="/SignIn">
            <span className="header-item">Sign In</span>
          </AppNavLink>
        </li>
        <li>
          <AppNavLink to="/SignUp">
            <span className="header-item">Teacher Sign Up</span>
          </AppNavLink>
        </li>
      </ul>
    </div>
  );
};

export default SignedOutLinks;
