import React, { Component } from 'react'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux' //Lesson 22/40
import { toggleBookCodeForm, joinClassWithCode } from "../../../store/actions/createClassActions"
import { resetInvalidClassCode } from "../../../store/actions/createClassActions"
import { togglePublisherFeatures } from "../../../store/actions/publisherActions"
import { withRouter } from 'react-router-dom';


class Navbar extends Component {
    state = {
        showMenu: false,
        // showCodeForm: false,
        showHelpBox: false,
        classroomCode: "",
    }

    clickedShowCodeForm = (e) => {
        e.preventDefault()
        this.props.toggleBookCodeForm()
        // this.setState({
        //     showCodeForm: !this.state.showCodeForm
        // })
    }

    clickedShowHelp = (e) => {
        e.preventDefault()
        this.setState({
            showHelpBox: !this.state.showHelpBox
        })
    }

    clickedTogglePubFeat = (e) => {
        e.preventDefault()
        this.props.togglePublisherFeatures()
    }
    
    handleSubmit = (e) => {
        e.preventDefault()
        // console.log("submit add user to class")
        // console.log(this.props)
        let user = this.props.profile
        user["uid"] = this.props.auth.uid
        const classCode = this.state.classroomCode
        this.props.joinClassWithCode(user, classCode)
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    componentDidUpdate(prevProps) {
        // const { match, location, history } = this.props
        //   {/* <div>{location.pathname}</div> */}
        // console.log("CONNECT STUFF")
        // console.log(match)
        // console.log(location)
        // console.log(history)

        const newClassAdded = this.props.state.classrooms.newClassroomAdded ? this.props.state.classrooms.newClassroomAdded : false
        if (newClassAdded) {
            window.location.reload()
        }
        const invalidClass = this.props.state.classrooms.invalidClassCode ? this.props.state.classrooms.invalidClassCode : false
        // const prevInvalidClass = prevProps.state.classrooms.invalidClassCode ? prevProps.state.classrooms.invalidClassCode : false
        if (invalidClass) {
            alert("Invalid Classroom Clode")
            this.props.resetInvalidClassCode()
        }
    }



    render() {
        const { match, location, history } = this.props
          {/* <div>{location.pathname}</div> */}
        const locationPath = location.pathname

        // const { auth, profile } = this.props;  //Lesson 25
        const auth = this.props.state.firebase.auth
        const profile = this.props.state.auth

        const links = auth.uid ? (
          <SignedInLinks
            theState={this.props.state}
            locationPath={locationPath}
            profile={profile}
            clickedShowCodeForm={this.clickedShowCodeForm}
            clickedShowHelp={this.clickedShowHelp}
            clickedTogglePubFeat={this.clickedTogglePubFeat}
            uid={auth.uid}
          />
        ) : (
          <SignedOutLinks locationPath={locationPath} />
        );

        let menuVisible = {display: "none"}
        if (this.state.showMenu) {
            menuVisible = {display: "inline-block"}
        }
        let codeFormVisible = {display: "none"}
        if (this.props.state.books.showCodeForm) {
            codeFormVisible = {display: "inline-block"}
        }
        let helpBoxVisible = {display: "none"}
        if (this.state.showHelpBox) {
            helpBoxVisible = {display: "inline-block"}
        }


        const menu = (
            <div className="nav-menu" style={menuVisible}>
                <a href="#!" onClick={this.clickedShowCodeForm}>Enter a Class Code</a>
            </div>
        )

        const codeForm = (
            <div className="code-form-container" style={codeFormVisible}>
                <div className="ajsclose-codeform">
                    <span className="ajs-close3" onClick={this.clickedShowCodeForm}>&times;</span>
                </div>
                <form className="code-form" onSubmit={this.handleSubmit}>
                    <div>Book Code</div>
                    <input className="code-input" type="text" id="classroomCode" onChange={this.handleChange} />
                    <br/>
                    <button className="buttonBlue">Submit</button>
                </form>
            </div>
        )


        const helpBox = (
            <div className="ajs-modal" style={helpBoxVisible}>
                <div className="modalcontent-helpbox">
                    {/* <div className="deletetext-oneclassroom"> */}
                        <h1>Helpful Links</h1>
                        <span>Signing in and setting up classes:</span><br />
                        <a href="https://www.storylabs.online/howto" target="_blank">
                            www.storylabs.online/howto
                        </a><br /><br />

                        <span>Email inquiries:</span><br />
                        <a href="mailto:info@storylabslanguage.com?subject=Storylabs%20Help">
                            brooke@storylabs.app
                        </a><br /><br />
                        <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.clickedShowHelp} >
                            <span>Close</span>
                        </button>
                    {/* </div> */}
                </div>
            </div>
        )

        const helpBoxOLD = ( //Made old on 4/7/20. Can probably be deleted.
            <div className="help-box" style={helpBoxVisible}>
                <div className="ajsclose-codeform">
                    <span className="ajs-close3" onClick={this.clickedShowHelp}>&times;</span>
                </div>
                <span>For help signing in or setting up classes, please visit:</span><br/>
                <a href="https://www.storylabs.online/help-for-language-teachers">
                    www.storylabs.online/help-for-language-teachers
                </a><br/><br/>

                <span>For a list of book codes with free and sample stories please visit:</span><br/>
                <a href="https://www.storylabs.online/freebies">
                    www.storylabs.online/freebies
                </a><br/><br/>

                <span>For links to how teachers may use these stories in their classrooms please visit:</span><br/>
                <a href="https://www.storylabs.online/webinars">
                    www.storylabs.online/webinars
                </a><br/><br/>

                <span>To purchase codes for HEART: A New California Sex Ed Curriculum please visit:</span><br/>
                <a href="https://www.storylabs.online/shopheart">
                    www.storylabs.online/shopheart
                </a><br/><br/>

                <span>Email inquiries to</span><br/>
                <a href="mailto:info@storylabslanguage.com?subject=Storylabs%20Help">
                    info@storylabslanguage.com
                </a>
            </div>
        )

        return (
            <div>
                {/* {menu} */}
                {codeForm}
                {links}
                {helpBox}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile, //added from Lesson 29
        state
    }
};

// export default connect(mapStateToProps)(Navbar);


const mapDispatchToProps = (dispatch) => {
    return {
        toggleBookCodeForm: () => dispatch(toggleBookCodeForm()),
        joinClassWithCode: (user, classCode) => dispatch(joinClassWithCode(user, classCode)),
        resetInvalidClassCode: () => dispatch(resetInvalidClassCode()),
        togglePublisherFeatures: () => dispatch(togglePublisherFeatures()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
// export default connect(withRouter(mapStateToProps, mapDispatchToProps)(Navbar))
