import React from "react";
import { AppNavLink } from "../AppNavLink";

export function JoinClassLinks({ signOut, clickedShowHelp }) {
  return (
    <ul>
      <li>
        <a
          target="_blank"
          href="https://www.storylabs.online/help-for-language-teachers"
        >
          <span className="login-help">Signup Instructions</span>
        </a>
      </li>

      <li className="logout">
        <AppNavLink to="/" onClick={signOut}>
          <span className="header-item">Log out</span>
        </AppNavLink>
      </li>
      <li className="help">
        <AppNavLink to="/" onClick={clickedShowHelp}>
          <span className="header-item">Help</span>
        </AppNavLink>
      </li>
    </ul>
  );
}

export function JoinClassLinksMobile({
  signOut,
  clickedShowHelp,
  handleNavToggleClick,
}) {
  return (
    <>
      <a
        target="_blank"
        href="https://www.storylabs.online/help-for-language-teachers"
      >
        <span className="login-help">Signup Instructions</span>
      </a>

      <button className="drawer-item" to="/" onClick={signOut}>
        Log out
      </button>

      <button
        className="drawer-item"
        onClick={() => {
          handleNavToggleClick();
          clickedShowHelp();
        }}
      >
        Help
      </button>
    </>
  );
}
