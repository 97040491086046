import React from 'react'
import BookCard from '../ui/BookCard'
import { Link } from "react-router-dom"



const ClassList = ({classroomsArr}) => {  
    let toDisplay = null

    toDisplay = classroomsArr.map(classroom => {
        return (
            
            
            <div key={classroom.classroomId} className="classtitle-classpage">
                <Link className="wrapping-link2" to={'/MyClassrooms/' + classroom.classroomId}>
                    {classroom.title}
                </Link>
            </div>
        )
    })


    return (
        <div >
            {toDisplay}
        </div>
    )
}

export default ClassList
