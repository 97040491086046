import firebase from '../../config/fbConfig'
const db = firebase.firestore()


// This is a temporary function adding the classroomId to the ticksStudent and journalsStudent documents
// This was executed one time 4/4/20
export const addClassId = () => {
    return (dispatch, getState) => {
        //For adding classroomId to student tick documents
        const ticksCollection = db.collection("ticksStudent")
        ticksCollection.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                // if (doc.id === "KFCqTy560hcOCozJKIGB") {
                if (doc.data().classroomId === undefined) {
                    const userId = doc.data().uid
                    db.collection("usersChild").doc(userId).get().then(function (docUser) {
                        const classroomId = docUser.data().classroomId
                        const tickRef = ticksCollection.doc(doc.id)
                        tickRef.update({
                            classroomId: classroomId
                        })
                            .then(function () {
                                console.log("Document successfully updated!");
                            })
                    })
                }
                // }
            });
        });
        //For adding classroomId to student journal documents
        const journalsCollection = db.collection("journalsStudent")
        journalsCollection.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                // if (doc.id === "KFCqTy560hcOCozJKIGB") {
                if (doc.data().classroomId === undefined) {

                    const userId = doc.data().uid
                    db.collection("usersChild").doc(userId).get().then(function (docUser) {
                        const classroomId = docUser.data().classroomId
                        const journalRef = journalsCollection.doc(doc.id)
                        journalRef.update({
                            classroomId: classroomId
                        })
                            .then(function () {
                                console.log("Document successfully updated!");
                            })
                    })
                }
                // }
            });
        });
    }
};


// This is a temporary function adding the classroomId to the ticksStudent and journalsStudent documents
// This was executed one time 4/4/20
export const replaceTicksStudent = () => {
    return (dispatch, getState) => {
        //For adding classroomId to student tick documents
        const ticksCollection = db.collection("ticksStudent")
        ticksCollection.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {

                if (doc.data().classroomId !== undefined) {
                    const tickObjOld = doc.data()
                    const completedDates = tickObjOld.completedDates ?
                        tickObjOld.completedDates :
                        {}
                    const completedFiles = tickObjOld.completedFiles ?
                        tickObjOld.completedFiles :
                        {}
                    const completedFilesArr = Object.entries(completedFiles)
                    completedFilesArr.forEach((completedFile) => {
                        const dateTicked = completedDates[completedFile[0]] ?
                            completedDates[completedFile[0]] :
                            new Date()
                        const tickObjNew = {
                            bookId: tickObjOld.bookId,
                            chapterId: tickObjOld.chapterId,
                            classroomId: tickObjOld.classroomId,
                            fileId: completedFile[0],
                            dateTicked: dateTicked,
                            ticked: completedFile[1],
                            uid: tickObjOld.uid
                        }

                        ticksCollection.add(tickObjNew)
                            .then(function (docRef) {
                                console.log("Document written with ID: ");
                            })
                            .catch(function (error) {
                                console.error("Error adding document: ", error);
                            });
                    })
                }
            });
        });
    }
}

export const replaceTicksTeacher = () => {
    return (dispatch, getState) => {
        //For adding classroomId to student tick documents
        const ticksCollection = db.collection("ticksTeacher")
        ticksCollection.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {


                const tickObjOld = doc.data()
                const completedDates = tickObjOld.completedDates ?
                    tickObjOld.completedDates :
                    {}
                const completedFiles = tickObjOld.completedFiles ?
                    tickObjOld.completedFiles :
                    {}
                const completedFilesArr = Object.entries(completedFiles)
                completedFilesArr.forEach((completedFile) => {
                    const dateTicked = completedDates[completedFile[0]] ?
                        completedDates[completedFile[0]] :
                        new Date()
                    const tickObjNew = {
                        bookId: tickObjOld.bookId,
                        chapterId: tickObjOld.chapterId,
                        fileId: completedFile[0],
                        dateTicked: dateTicked,
                        ticked: completedFile[1],
                        uid: tickObjOld.uid
                    }

                    ticksCollection.add(tickObjNew)
                        .then(function (docRef) {
                            console.log("Document written with ID: ", docRef.id);
                        })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                        });
                })
            });
        });
    }
}


export const setSections = (bookId, sections) => {
    return (dispatch, getState) => {
        let bookRef = db.collection("books").doc(bookId)

        bookRef.update({
            chapterSections: sections
        })
            .then(() => {
                console.log("it worked")
                dispatch({ type: "UPDATE_BOOK_SECTIONS", bookId, sections })
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            })
    }
}

// export const removeDuplicates = () => {
export const addPurchaseAccess = () => {
    return (dispatch, getState) => {
        const paRef = db.collection("purchaseAccess")
        const newDate = new Date()
        // const payerUid = "bj9Q8SE8t6ZoTMBJu0JTsTiUCd82"  //debra.hall@rusd.org
        // const payerUid = "UMoGPEIJr6VCjF8HEaFyJ0oaAJ82"  //annebeckett@icimagine.org
        // const payerUid = "xrsENL073ted7CB5VrorXCBMipw2"  //bwentzel@gmail.com
        // const payerUid = "Tj7aJ9ZIrKWUgv1qzliUjlsHloe2"  //miclubdeespanol@gmail.com 7/28/20
        // const payerUid = "sthMYO4GSvfZJM8tGTdhUmuiA3D3"  //terry.silvey@morgan.k12.ga.us 7/29/20
        // const payerUid = "gw6e7MS4AiVBFNSCxifQAxWe6do2"  //sahlborn@mosesbrown.org 7/29/20
        // const payerUid = "6KrPECBYEgWsOfJU6uDxMHEfb1F2"  //melissadorfman@hunschool.org 7/31/20
        const payerUid = "324fEjmUocbqEHfOW4zi4A9u6aE3"  //caroline.hay@corvallis.k12.or.us 8/4/20
        const purchaseOrderId = "freebies"
        const books = [
            "DdYsNM5ZNHObMiSdbaXq", //00 - 5 Latin Books for Preview
            "zqC8SWw8zpsTCfshF1Rx", //Chinese - The Girl Wants Hot Chocolate
            "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
            "BfTpY6WfQkjxbIg2Yaev", //Das Mädchen Will Heiße Schokolade
            "DNcEcd4Sqs3TPxpFaJiO", //French Cultural Nonfiction
            "xvPux93CnDxl87uBm59D", //Korean - The Girl Wants Hot Chocolate
            "0m5AnMgvvDIdwopojS8M", //Spanish Beginner Student Story 1
            "Yj4rl3QcXmBJSspoaI8D", //Spanish Beginner Student Story 2
            "op224uqV2qzBzS6A66jh", //Spanish Fairytale - Level 1
            "hL06emH2908xjw8wISpL", //Spanish Novel - Level 1/2
            "sqDQZE0PwhSDOPYcWb7g", //Spanish Novel - Level 2
            "BLCkudpmO3xk6zEu4K88", //Spanish Printable Mini-Books - Level 2
            "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
            "JTHobnQdPMI202UTGwSc", //Stories for Beginners (Fr) A
            "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
            "BnJ8E0x2yhCbnaRv6QM1", //Storytime con Sra. Jensen
            "uBZI5BE8QaXKKm57gDgo", //Topic:  Music - A
            "NeJ7drWvdMzthjLWcC37", //Topic:  Music - B
        ]

        const docs = books.map(itemId => {
            return {
                createTime: newDate,
                daysPurchased: 365,
                itemId: itemId,
                payerUid: payerUid,
                purchaseOrderId: purchaseOrderId,
                quantity: 1,
                updatedOn: newDate
            }
        })

        docs.forEach(doc => {
            paRef.add(doc)
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        })
    }
}



//THis is for the order of one book
// export const removeDuplicates = () => {
export const addPaForOneBook = () => {
    return (dispatch, getState) => {
        const paRef = db.collection("purchaseAccess")
        const newDate = new Date()
        const payerUid = "UMoGPEIJr6VCjF8HEaFyJ0oaAJ82"
        const purchaseOrderId = "brookerequest"
        const bookId = "iVC7ouPYLefmXd9BvCRf"
        const doc = {
            createTime: newDate,
            daysPurchased: 365,
            itemId: bookId,
            payerUid: payerUid,
            purchaseOrderId: purchaseOrderId,
            quantity: 1,
            updatedOn: newDate
        }
        paRef.add(doc)
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                // callback()
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
                // callback()
            });
    }
}

// export const removeDuplicates = () => {
export const forBookCodeCheri = () => {
    return (dispatch, getState) => {
        const paRef = db.collection("purchaseAccess")
        const newDate = new Date()
        const payerUid = "0Df7L1SQRhZu8yrF5jecC6yFrml1"  //awalker@djusd.net 7/31/20
        const purchaseOrderId = "paid"
        //from book code cheri
        const books = [
            "Ue5kOAVpUHoPihTeXovd",
            "vqFPPIyh9kzMSZrirdf4",
            "7ZikixXAqGN2GAmnlCZe",
            "09OnwfkhmKoqnh8YOLKg",
            "tyzlPtYrQ0HWWspiIxyJ",
            "ND4ed0ygTmxnVSCbO2lj",
            "q2inapU0UBIDWEzvW11z",
            "sVaqJEvi6bMuuZMXPJo7",
            "dkZANsLOcYfW7l8uXePE",
            "7QRR5NNnBuj0ikFfHwUj",
            "qxpWd78SfNmrJww1dzDN",
            "2QYGBaaPTFJiAd2ZaOom",
            "BLZMnCxwApoijdgRZLtL",
            "xOUEu3C7Z7xLAfN1PJWs",
            "xqw7M822Z506s1PoaV6A",
            "TG2x5VGCTdvpIVgnAuzU",
            "7ZikixXAqGN2GAmnlCZe",
            "T1vdnN51mN6LCZDOs87n",
            "09OnwfkhmKoqnh8YOLKg",
            "ldhZynxEAi37wMetevAn",
            "tyzlPtYrQ0HWWspiIxyJ",
            "Uz8loJILog0vJHUAwxpP",
            "ND4ed0ygTmxnVSCbO2lj",
            "Lou5hQyg1KUPMF8tZsSS",
            "U7oilJm6T2tU2A2PihoW",
            "zb1SZjIzgqFKWdIlnWEg",
            "Azxd7A3mTR1flf8Xlryv",
            "9cwrhRMKl4qNpVr9AYtO",
            "V3lwAXdxF041PjbPKe0w",
            "O6OpwjHYDM1M97Q2zS9C",
            "KhgAAJ8eE7kU2wEIhVYG",
            "TbuaXQ4tHnm1E5plaDu4",
            "PnIdHphWWb6RXfGnhAEH",
            "a1YymDHO8yzi4XAKAr4p",
            "94O0QNCLnjDDt9zyxy6x",
            "LNkOWXZ2AR2yJg4VCYdw",
            "tViUdCb9fgbqWqh2XefT",
            "HoSUIMjyT28SktFrHbMq",
            "H1AxFLYKrbzcF1b8WNJg",
            "gJE8dnnHUdf0vrmGypt0",
            "ePhpmbBEyzXhBuAgtrHL",
            "1FR40jP7QE8Q7F4ShNeO",
            "mL44ImtXxuGZ0oxBdJs5",
            "Gi57TNIOSr8FFmUtjyzv",
            "OjwaX5WsdgmG1hIbkPSZ",
            "OfizAyWIFNMX0Juz8ebm",
            "ZrY27ZlVpQ0MQuLLZK6u",
            "MYbtnLIO79MQnHowY5ac",
            "WMd0u0FRPQ9aTXVKjBgW",
            "pxERdOdqsqufZSqwxubi",
            "ifktjBXVRellWbZXWFkn",
            "HctgxrXBULrNzpLxyGc7",
        ]
        const docs = books.map(itemId => {
            return {
                createTime: newDate,
                daysPurchased: 365,
                itemId: itemId,
                payerUid: payerUid,
                purchaseOrderId: purchaseOrderId,
                quantity: 1,
                updatedOn: newDate
            }
        })
        docs.forEach(doc => {
            paRef.add(doc)
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        })
    }
}



// the following was for one order on 3/13/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "IQ7JPgLVLjfdcqbUGnH9gMjdsSz2"  //classicsandrelstud@umb.edu
//         const purchaseOrderId = "wix"
//         const books = [            
//             ...pisoverse,
//             ...olimpi
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/16/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "ZHRYw03cl6Z9DDqTW31uWslMV1G2"  //ifr3908@gmail.com
//         const purchaseOrderId = "wix"
//         const books = [            
//             ...freebies,

//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }    
// }

// // the following was for one order on 3/16/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "c1irfiFV9GdZU6jvw5IRoYjg8lh2"  //lmagdy@ggusd.us
//         const purchaseOrderId = "wix"
//         const books = [            
//             "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
//             "Bi9FQAA3G5KB4R2tGQ2I", //Stories for Beginners (Eng) B
//             "TxdwxaKRkjBg4CyGuy77", //Stories for Beginners (Eng) C
//             "uEojVRmryTNNNDD6L2Rz", //Stories for Beginners (Eng) D
//             "d2uL9PC1jk0CdgGeLL6D", //Stories for Beginners (Eng) E
//             "yjsRs8nzDYiNnATd9XrU", //Stories for Beginners (Eng) F
//             "yfeN6BbzBE1vCtVS5DLA", //Stories for Beginners (Eng) H
//             "hzSdc7BtumXWhaXqYhH3", //Stories for Beginners (Eng) I
//             "XBFucE2bEfcqsYvbAhcF", //Stories for Beginners (Eng) J        
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// the following was for one order on 3/16/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "c1irfiFV9GdZU6jvw5IRoYjg8lh2"  //lmagdy@ggusd.us
//         const purchaseOrderId = "wix"
//         const books = [            
//             ...freebies,
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }


// the following was for one order on 3/16/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "7zyd3RDq5yTCQ6ynZbjPipZKCZw2"  //dchilders@my.rescueusd.org
//         const purchaseOrderId = "wix"
//         const books = [
//             "qtura7Znn4QUxLpvmyPl", //HEART: 7th Grade
//             "2YHgZ3VS6mg4xcEETKX1", //HEART: 8th Grade
//         ]
//         recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// the following was for one order on 3/16/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "s6DG6w7OgseMcuJXZOsrsjncosS2"  //swilliams@rescueusd.org
//         const purchaseOrderId = "wix"
//         const books = [            
//             "qtura7Znn4QUxLpvmyPl", //HEART: 7th Grade
//             "2YHgZ3VS6mg4xcEETKX1", //HEART: 8th Grade
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/18/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "BIn0ELovMVMchLUwSO3eJbJuMjE2"  //amy.axe@rsu35.org
//         const purchaseOrderId = "wix"
//         const books = [            
//             "BIvRRizE4V9yQlRHAn5Y",
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/18/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "Dz4QYhIKD9TpHAIYBNCqGQSDfDI3"  //tdougher@udsd.org
//         const purchaseOrderId = "wix"
//         const books = [            
//             "fpSC0gJskhLrYuvCVigV", //Lars Romam Odit
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/18/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "BIn0ELovMVMchLUwSO3eJbJuMjE2"  //amy.axe@rsu35.org
//         const purchaseOrderId = "brooke"
//         const books = [            
//             "gn4zXx4lhmSRw1dES00O", //Amh Axe
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/18/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "53sDnev1AURjFXAWIpt53UBpLCi1"  //sreiss@klschools.org
//         const purchaseOrderId = "po"
//         const books = [            
//             ...pisoverse,
//             ...olimpi
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/21/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "cGeMCIozDjeIQ4lOsmrRDZEi7003"  //noleftturnky@protonmail.com
//         const purchaseOrderId = "po"
//         const books = [            
//             "8DspMkHmayFYRaYisJ3l"
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/23/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "ePdrEwNE4Qaos4c4YycL5lBV2ey2"  //mromerocortes@isd622.org
//         const purchaseOrderId = "publisher"
//         const books = [            
//             "F4LfXcjcqDQXVIdXvhUa"
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/23/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "Mc8CIOqiqDU5dxBqx6muEiPhrwm1"  //sdepedro@kleinisd.net
//         const purchaseOrderId = "po"
//         const books = [            
//             "Pei8HrRk71JWaFkqUTxl", // 11 - Agrippīna aurīga
//             "6LEMGW0rVpH6JwRJdo3d", // 24 - Tiberius et Gallisena ultima
//             "j4d5xiQPfcaBfen73Ie9", // Familia Mala 2 - Duo Fratres
//             "8Ot1PuQRVbleWybtAQgh", // Familia Mala 3 - Pandora
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// // the following was for one order on 3/25/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "ZZMaN8OESXcOvkeIgjjevbGPCN73"  //emma.dempewolf@bishoplynch.org
//         const purchaseOrderId = "wix"
//         const books = [            
//             "JHmpzEnG9FygppX61s7K", // Labyrinthus
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }

// the following was for one order on 3/26/21
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {
//         const payerUid = "XTmmJWNob9evqAAwCPAMH7ViZTk1"  //hginni@comcast.net
//         const purchaseOrderId = "ginni"
//         const books = [            
//             "OCylRBCvcHYV2Zd22py2", // What does Juan see?
//         ]
// recordOrderInFS(payerUid, purchaseOrderId, books)
//     }
// }


// the following was for one order on 3/26/21
export const removeDuplicates = () => {
    return (dispatch, getState) => {
        const payerUid = "D229wjKwNkSO29o8v59Uc63Sg542"  
        const purchaseOrderId = "bonnie"
        const books = [            
            "6QqjR9EMzm2RGWedwquZ",
        ]
recordOrderInFS(payerUid, purchaseOrderId, books)
    }
}



// This is the Original removeDuplicates function
export const removeDuplicatesDisable = (bookId, chapterId) => {
    // export const removeDuplicates = (bookId, chapterId) => {
    return (dispatch, getState) => {
        const allChapters = getState().books.chaptersByBook[bookId]
        let chapter = allChapters[chapterId]
        const fileArray = chapter.file_array
        console.log(chapter)
        let newFileArray = []
        let newFileObj = {}

        fileArray.forEach((file) => {
            const fileId = file.fileId
            if (!(newFileObj[fileId])) {
                newFileObj[fileId] = true
                newFileArray.push(file)
            }
        })

        const refChapter = db.collection("chapters_v2").doc(chapterId);
        refChapter.update({
            "file_array": newFileArray
        })
            .then(function () {
                chapter["file_array"] = newFileArray
                dispatch({ type: "UPDATE_CHAPTER", chapter })
                dispatch({ type: "UPDATE_CHAPTER2", chapter })
            })
            .catch(function (error) {
                console.error("Error removing duplicate chapter files: ", error);
            });

    }
}

// This is a helper function for adding new orders
const recordOrderInFS = (payerUid, purchaseOrderId, books) => {
    const paRef = db.collection("purchaseAccess")
    const newDate = new Date()
    const docs = books.map(itemId => {
        return {
            createTime: newDate,
            daysPurchased: 365,
            itemId: itemId,
            payerUid: payerUid,
            purchaseOrderId: purchaseOrderId,
            quantity: 1,
            updatedOn: newDate
        }
    })
    let i = 0
    docs.forEach(doc => {
        paRef.add(doc)
            .then(function (docRef) {
                i = i + 1
                console.log("Book# / ID: " + "(" + i + ") " + doc.itemId);
                // console.log("Document written with ID: ", docRef.id);
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    })
}

// This is a helper function for adding new orders
const recordOrderInFSforPuids = (payerUid, purchaseOrderId, books) => {
    const paRef = db.collection("purchaseAccess")
    const newDate = new Date()
    let i = 0
    payerUid.forEach(pUid => {
        const docs = books.map(itemId => {
            return {
                createTime: newDate,
                daysPurchased: 365,
                itemId: itemId,
                payerUid: pUid,
                purchaseOrderId: purchaseOrderId,
                quantity: 1,
                updatedOn: newDate
            }
        })
        docs.forEach(doc => {
            paRef.add(doc)
                .then(function (docRef) {
                    i = i + 1
                    // console.log("pUid: " + pUid)
                    console.log("Saved: " + i);
                    // console.log("Document written with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        })
    })

}

// Get all books
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {        
//         db.collection("books").get().then(function(querySnapshot) {
//             const bookIds = []
//             const bookTitles = []
//             querySnapshot.forEach(function(doc) {
//                 bookIds.push(doc.id)
//                 bookTitles.push(doc.data().title)
//             });
//             console.log(bookIds)
//             console.log(bookTitles)
//         });
//     }
// }

// Get all users
// export const removeDuplicates = () => {
//     return (dispatch, getState) => {        
//         db.collection("users").get().then(function(querySnapshot) {
//             const userIds = []            
//             querySnapshot.forEach(function(doc) {
//                 const userIdArr = [doc.id, doc.data().email]                
//                 userIds.push(userIdArr)
//             });
//             console.log(userIds)
//             let csvContent = "data:text/csv;charset=utf-8,"
//             userIds.forEach(function(rowArray) {
//                 let row = rowArray.join(",");
//                 csvContent += row + "\r\n";
//             });
//             var encodedUri = encodeURI(csvContent);
//             window.open(encodedUri);
//         });
//     }
// }


// book code created 11/13/20
// export const removeDuplicates = () => {
export const createNewBookCode = () => {
    return (dispatch, getState) => {
        const ref = db.collection("bookCodes")
        const bookCode = "Christmas"
        const dateCreated = new Date()
        const books = [
            "ClcnqkcM7VnZfJfKN7mZ", //La Anunciación
        ]
        const doc = {
            books: books,
            dateCreated: dateCreated,
        }
        ref.doc(bookCode).set(doc)
            .then(function () {
                console.log("New Book Code Created!");
            })
            .catch(function (error) {
                console.error("Error creating New Book Code: ", error);
            });
    }
}

// // book code created 11/2/20
// // export const removeDuplicates = () => {
// export const createNewBookCode = () => {
//     return (dispatch, getState) => {
//         const ref = db.collection("bookCodes")
//         const bookCode = "GEORGIA"
//         const dateCreated = new Date()
//         const books = [
//             "uZDfOjuNtr8hXvIbwQY0", //Cultural Nonfiction A
//             "Uz8loJILog0vJHUAwxpP", //Cultural Nonfiction C
//             "sVaqJEvi6bMuuZMXPJo7", //Cultural Nonfiction D
//             "7QRR5NNnBuj0ikFfHwUj", //Cultural Nonfiction E
//             "ldhZynxEAi37wMetevAn", //Cultural Nonfiction F
//             "2QYGBaaPTFJiAd2ZaOom", //Cultural Nonfiction G
//             "xOUEu3C7Z7xLAfN1PJWs", //Cultural Nonfiction H
//             "O6OpwjHYDM1M97Q2zS9C", //Cultural Nonfiction I
//             "Azxd7A3mTR1flf8Xlryv", //Cultural Nonfiction J
//             "xqw7M822Z506s1PoaV6A", //Cultural Nonfiction K
//             "qxpWd78SfNmrJww1dzDN", //Cultural Nonfiction L
//             "dkZANsLOcYfW7l8uXePE", //Cultural Nonfiction M
//             "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
//             "ifktjBXVRellWbZXWFkn", //Embedded Reading
//             "zb1SZjIzgqFKWdIlnWEg", //Embedded Reading B
//             "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
//             "Ue5kOAVpUHoPihTeXovd", //Stories for Beginners - B
//             "gJE8dnnHUdf0vrmGypt0", //Stories for Beginners - B2
//             "9cwrhRMKl4qNpVr9AYtO", //Stories for Beginners - C
//             "U7oilJm6T2tU2A2PihoW", //Stories for Beginners - D
//             "1FR40jP7QE8Q7F4ShNeO", //Stories for Beginners - E
//             "q2inapU0UBIDWEzvW11z", //Stories for Beginners - F
//             "Lou5hQyg1KUPMF8tZsSS", //Stories for Beginners - G
//             "V3lwAXdxF041PjbPKe0w", //Stories for Beginners - H
//             "94O0QNCLnjDDt9zyxy6x", //Stories for Beginners - I
//             "TG2x5VGCTdvpIVgnAuzU", //Stories for Beginners - K
//             "KhgAAJ8eE7kU2wEIhVYG", //Stories for Beginners - L
//             "mL44ImtXxuGZ0oxBdJs5", //Stories for Beginners - M
//             "HoSUIMjyT28SktFrHbMq", //Stories for Level 2 - A
//             "Gi57TNIOSr8FFmUtjyzv", //Stories for Level 2 - B
//             "ZrY27ZlVpQ0MQuLLZK6u", //Stories for Level 2 - C
//             "MYbtnLIO79MQnHowY5ac", //Stories for Level 2 - D
//             "WMd0u0FRPQ9aTXVKjBgW", //Stories for Level 2 - E
//             "H1AxFLYKrbzcF1b8WNJg", //Stories for Level 2 - F
//             "pxERdOdqsqufZSqwxubi", //Stories for Level 2 - G
//             "ePhpmbBEyzXhBuAgtrHL", //Stories for Level 4 - A
//             "PnIdHphWWb6RXfGnhAEH", //Cultural Fiction 1A
//             "a1YymDHO8yzi4XAKAr4p", //Cultural Fiction 1B
//             "BLZMnCxwApoijdgRZLtL", //Cultural Fiction 1C
//             "tViUdCb9fgbqWqh2XefT", //Cultural Fiction 1D
//             "OjwaX5WsdgmG1hIbkPSZ", //Cultural Fiction 1E
//             "09OnwfkhmKoqnh8YOLKg", //Cultural Fiction 2A
//             "tyzlPtYrQ0HWWspiIxyJ", //Cultural Fiction 2B
//             "ND4ed0ygTmxnVSCbO2lj", //Cultural Fiction 3
//             "HctgxrXBULrNzpLxyGc7", //Embedded Reading (English)
//             "7ZikixXAqGN2GAmnlCZe", //Embedded Reading A
//             "DNcEcd4Sqs3TPxpFaJiO", //French Cultural Nonfiction
//             "yylk4Vr3wPL5vDzR0ik7", //Little Red Riding Hood
//             "sCgMg6RT3jx6qPazmCv3", //Montana Joe
//             "0m5AnMgvvDIdwopojS8M", //Spanish Beginner Student Story 1
//             "Yj4rl3QcXmBJSspoaI8D", //Spanish Beginner Student Story 2
//             "op224uqV2qzBzS6A66jh", //Spanish Fairytale - Level 1
//             "Qxthxoxr0sZbQrKr5pzr", //Spanish Movietalk: El Gato Bilingüe
//             "wfonHSjw9ZoUgMQEVvBC", //Spanish Movietalk: Pantera Rosa
//             "sqDQZE0PwhSDOPYcWb7g", //Spanish Novel - Level 2
//             "BLCkudpmO3xk6zEu4K88", //Spanish Printable Mini-Books - Level 2
//             "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
//             "Bi9FQAA3G5KB4R2tGQ2I", //Stories for Beginners (Eng) B
//             "TxdwxaKRkjBg4CyGuy77", //Stories for Beginners (Eng) C
//             "uEojVRmryTNNNDD6L2Rz", //Stories for Beginners (Eng) D
//             "d2uL9PC1jk0CdgGeLL6D", //Stories for Beginners (Eng) E
//             "yjsRs8nzDYiNnATd9XrU", //Stories for Beginners (Eng) F
//             "yfeN6BbzBE1vCtVS5DLA", //Stories for Beginners (Eng) H
//             "hzSdc7BtumXWhaXqYhH3", //Stories for Beginners (Eng) I
//             "XBFucE2bEfcqsYvbAhcF", //Stories for Beginners (Eng) J
//             "JTHobnQdPMI202UTGwSc", //Stories for Beginners (Fr) A
//             "pbWGTsd62zNmmCDdcmPp", //Stories for Beginners (Fr) B
//             "OEUmTmr1Uk61iLmDobiR", //Stories for Beginners (Fr) C
//             "cQlFB9tAvAN57B6GHn9P", //Stories for Beginners (Fr) D
//             "zqXDp2hGTwg11dB6uKw0", //Stories for Beginners (Fr) E
//             "xn5AO5fbhxqfwa3Zgp5V", //Stories for Beginners (Fr) F
//             "QobF3Nxtujc8PoEsS9W1", //Stories for Beginners (Fr) G
//             "g7aj2emuBTBBsjOmx40c", //Stories for Beginners (Fr) H
//             "onDsJUdzMvFRp5kXTwlk", //Stories for Beginners (Fr) I
//             "hYlvinhoo1A2ysxHhdS3", //Stories for Beginners (Fr) J
//             "BnJ8E0x2yhCbnaRv6QM1", //Storytime con Sra. Jensen
//             "fTpy1y5EroS6KZOsa0ht", //Storytime con Sra. Jensen - 1
//             "JADLgQ3raeoP5A9L39dT", //Storytime con Sra. Jensen - 10
//             "syFoaCVm3m064GJTWLWP", //Storytime con Sra. Jensen - 11
//             "YmY3uWivzlt5R0A7MQ1J", //Storytime con Sra. Jensen - 12
//             "s6VLB3h1Ayy5s3Am4M1o", //Storytime con Sra. Jensen - 13
//             "XnKHk0h4YOh90t17Kip2", //Storytime con Sra. Jensen - 14
//             "dg3ZIHk7JU5oPz17Kg0p", //Storytime con Sra. Jensen - 2
//             "TPGN0LivIkh4UIZZMJkx", //Storytime con Sra. Jensen - 3
//             "aYFoOPlcGQAEndSQYguV", //Storytime con Sra. Jensen - 4
//             "Do4Gkf2EZH1XndC9qMbn", //Storytime con Sra. Jensen - 5
//             "VCvU4OQVJqysxYWKYy9k", //Storytime con Sra. Jensen - 6
//             "6BAr3UooYiqeAXadotAy", //Storytime con Sra. Jensen - 7
//             "ZURN6hDTwpISLZicNrSP", //Storytime con Sra. Jensen - 8
//             "vihMb11Nko4hXckTbEf0", //Storytime con Sra. Jensen - 9
//             "uBZI5BE8QaXKKm57gDgo", //Topic: Elementary Music - A
//             "NeJ7drWvdMzthjLWcC37", //Topic: Elementary Music - B
//             "TbuaXQ4tHnm1E5plaDu4", //Topic: Music
//             "OfizAyWIFNMX0Juz8ebm", //Topic: Numbers
//         ]
//         const doc = {
//             books: books,
//             dateCreated: dateCreated,
//         }
//         ref.doc(bookCode).set(doc)
//             .then(function () {
//                 console.log("New Book Code Created!");
//             })
//             .catch(function (error) {
//                 console.error("Error creating New Book Code: ", error);
//             });
//     }
// }

// // book code created 10/27/20 and 10/28/20
// // export const removeDuplicates = () => {
//     export const createNewBookCode = () => {
//     return (dispatch, getState) => {
//         const ref = db.collection("bookCodes")
//         // const bookCode = "CCSD15"
//         const bookCode = "NAPERVILLE"
//         const dateCreated = new Date()
//         const books = [
//             "uZDfOjuNtr8hXvIbwQY0", //Cultural Nonfiction A
//             "Uz8loJILog0vJHUAwxpP", //Cultural Nonfiction C
//             "sVaqJEvi6bMuuZMXPJo7", //Cultural Nonfiction D
//             "7QRR5NNnBuj0ikFfHwUj", //Cultural Nonfiction E
//             "ldhZynxEAi37wMetevAn", //Cultural Nonfiction F
//             "2QYGBaaPTFJiAd2ZaOom", //Cultural Nonfiction G
//             "xOUEu3C7Z7xLAfN1PJWs", //Cultural Nonfiction H
//             "O6OpwjHYDM1M97Q2zS9C", //Cultural Nonfiction I
//             "Azxd7A3mTR1flf8Xlryv", //Cultural Nonfiction J
//             "xqw7M822Z506s1PoaV6A", //Cultural Nonfiction K
//             "qxpWd78SfNmrJww1dzDN", //Cultural Nonfiction L
//             "dkZANsLOcYfW7l8uXePE", //Cultural Nonfiction M
//             "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
//             "ifktjBXVRellWbZXWFkn", //Embedded Reading
//             "zb1SZjIzgqFKWdIlnWEg", //Embedded Reading B
//             "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
//             "Ue5kOAVpUHoPihTeXovd", //Stories for Beginners - B
//             "gJE8dnnHUdf0vrmGypt0", //Stories for Beginners - B2
//             "9cwrhRMKl4qNpVr9AYtO", //Stories for Beginners - C
//             "U7oilJm6T2tU2A2PihoW", //Stories for Beginners - D
//             "1FR40jP7QE8Q7F4ShNeO", //Stories for Beginners - E
//             "q2inapU0UBIDWEzvW11z", //Stories for Beginners - F
//             "Lou5hQyg1KUPMF8tZsSS", //Stories for Beginners - G
//             "V3lwAXdxF041PjbPKe0w", //Stories for Beginners - H
//             "94O0QNCLnjDDt9zyxy6x", //Stories for Beginners - I
//             "TG2x5VGCTdvpIVgnAuzU", //Stories for Beginners - K
//             "KhgAAJ8eE7kU2wEIhVYG", //Stories for Beginners - L
//             "mL44ImtXxuGZ0oxBdJs5", //Stories for Beginners - M
//             "HoSUIMjyT28SktFrHbMq", //Stories for Level 2 - A
//             "Gi57TNIOSr8FFmUtjyzv", //Stories for Level 2 - B
//             "ZrY27ZlVpQ0MQuLLZK6u", //Stories for Level 2 - C
//             "MYbtnLIO79MQnHowY5ac", //Stories for Level 2 - D
//             "WMd0u0FRPQ9aTXVKjBgW", //Stories for Level 2 - E
//             "H1AxFLYKrbzcF1b8WNJg", //Stories for Level 2 - F
//             "pxERdOdqsqufZSqwxubi", //Stories for Level 2 - G
//             "ePhpmbBEyzXhBuAgtrHL", //Stories for Level 4 - A
//             "PnIdHphWWb6RXfGnhAEH", //Cultural Fiction 1A
//             "a1YymDHO8yzi4XAKAr4p", //Cultural Fiction 1B
//             "BLZMnCxwApoijdgRZLtL", //Cultural Fiction 1C
//             "tViUdCb9fgbqWqh2XefT", //Cultural Fiction 1D
//             "OjwaX5WsdgmG1hIbkPSZ", //Cultural Fiction 1E
//             "09OnwfkhmKoqnh8YOLKg", //Cultural Fiction 2A
//             "tyzlPtYrQ0HWWspiIxyJ", //Cultural Fiction 2B
//             "ND4ed0ygTmxnVSCbO2lj", //Cultural Fiction 3
//             "HctgxrXBULrNzpLxyGc7", //Embedded Reading (English)
//             "7ZikixXAqGN2GAmnlCZe", //Embedded Reading A
//             "DNcEcd4Sqs3TPxpFaJiO", //French Cultural Nonfiction
//             "yylk4Vr3wPL5vDzR0ik7", //Little Red Riding Hood
//             "sCgMg6RT3jx6qPazmCv3", //Montana Joe
//             "0m5AnMgvvDIdwopojS8M", //Spanish Beginner Student Story 1
//             "Yj4rl3QcXmBJSspoaI8D", //Spanish Beginner Student Story 2
//             "op224uqV2qzBzS6A66jh", //Spanish Fairytale - Level 1
//             "Qxthxoxr0sZbQrKr5pzr", //Spanish Movietalk: El Gato Bilingüe
//             "wfonHSjw9ZoUgMQEVvBC", //Spanish Movietalk: Pantera Rosa
//             "sqDQZE0PwhSDOPYcWb7g", //Spanish Novel - Level 2
//             "BLCkudpmO3xk6zEu4K88", //Spanish Printable Mini-Books - Level 2
//             "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
//             "Bi9FQAA3G5KB4R2tGQ2I", //Stories for Beginners (Eng) B
//             "TxdwxaKRkjBg4CyGuy77", //Stories for Beginners (Eng) C
//             "uEojVRmryTNNNDD6L2Rz", //Stories for Beginners (Eng) D
//             "d2uL9PC1jk0CdgGeLL6D", //Stories for Beginners (Eng) E
//             "yjsRs8nzDYiNnATd9XrU", //Stories for Beginners (Eng) F
//             "yfeN6BbzBE1vCtVS5DLA", //Stories for Beginners (Eng) H
//             "hzSdc7BtumXWhaXqYhH3", //Stories for Beginners (Eng) I
//             "XBFucE2bEfcqsYvbAhcF", //Stories for Beginners (Eng) J
//             "JTHobnQdPMI202UTGwSc", //Stories for Beginners (Fr) A
//             "pbWGTsd62zNmmCDdcmPp", //Stories for Beginners (Fr) B
//             "OEUmTmr1Uk61iLmDobiR", //Stories for Beginners (Fr) C
//             "cQlFB9tAvAN57B6GHn9P", //Stories for Beginners (Fr) D
//             "zqXDp2hGTwg11dB6uKw0", //Stories for Beginners (Fr) E
//             "xn5AO5fbhxqfwa3Zgp5V", //Stories for Beginners (Fr) F
//             "QobF3Nxtujc8PoEsS9W1", //Stories for Beginners (Fr) G
//             "g7aj2emuBTBBsjOmx40c", //Stories for Beginners (Fr) H
//             "onDsJUdzMvFRp5kXTwlk", //Stories for Beginners (Fr) I
//             "hYlvinhoo1A2ysxHhdS3", //Stories for Beginners (Fr) J
//             "BnJ8E0x2yhCbnaRv6QM1", //Storytime con Sra. Jensen
//             "fTpy1y5EroS6KZOsa0ht", //Storytime con Sra. Jensen - 1
//             "JADLgQ3raeoP5A9L39dT", //Storytime con Sra. Jensen - 10
//             "syFoaCVm3m064GJTWLWP", //Storytime con Sra. Jensen - 11
//             "YmY3uWivzlt5R0A7MQ1J", //Storytime con Sra. Jensen - 12
//             "s6VLB3h1Ayy5s3Am4M1o", //Storytime con Sra. Jensen - 13
//             "XnKHk0h4YOh90t17Kip2", //Storytime con Sra. Jensen - 14
//             "dg3ZIHk7JU5oPz17Kg0p", //Storytime con Sra. Jensen - 2
//             "TPGN0LivIkh4UIZZMJkx", //Storytime con Sra. Jensen - 3
//             "aYFoOPlcGQAEndSQYguV", //Storytime con Sra. Jensen - 4
//             "Do4Gkf2EZH1XndC9qMbn", //Storytime con Sra. Jensen - 5
//             "VCvU4OQVJqysxYWKYy9k", //Storytime con Sra. Jensen - 6
//             "6BAr3UooYiqeAXadotAy", //Storytime con Sra. Jensen - 7
//             "ZURN6hDTwpISLZicNrSP", //Storytime con Sra. Jensen - 8
//             "vihMb11Nko4hXckTbEf0", //Storytime con Sra. Jensen - 9
//             "uBZI5BE8QaXKKm57gDgo", //Topic: Elementary Music - A
//             "NeJ7drWvdMzthjLWcC37", //Topic: Elementary Music - B
//             "TbuaXQ4tHnm1E5plaDu4", //Topic: Music
//             "OfizAyWIFNMX0Juz8ebm", //Topic: Numbers
//         ]
//         const doc = {
//             books: books,
//             dateCreated: dateCreated,
//         }
//         ref.doc(bookCode).set(doc)
//             .then(function () {
//                 console.log("New Book Code Created!");
//             })
//             .catch(function (error) {
//                 console.error("Error creating New Book Code: ", error);
//             });
//     }
// }


// // export const removeDuplicates = () => {
// export const createNewBookCode = () => {
//     return (dispatch, getState) => {
//         const ref = db.collection("bookCodes")
//         const bookCode = "DDLM"
//         const dateCreated = new Date()
//         const books = [
//             "uZDfOjuNtr8hXvIbwQY0", //Cultural Nonfiction A
//             "Uz8loJILog0vJHUAwxpP", //Cultural Nonfiction C
//             "sVaqJEvi6bMuuZMXPJo7", //Cultural Nonfiction D
//             "7QRR5NNnBuj0ikFfHwUj", //Cultural Nonfiction E
//             "ldhZynxEAi37wMetevAn", //Cultural Nonfiction F
//             "2QYGBaaPTFJiAd2ZaOom", //Cultural Nonfiction G
//             "xOUEu3C7Z7xLAfN1PJWs", //Cultural Nonfiction H
//             "O6OpwjHYDM1M97Q2zS9C", //Cultural Nonfiction I
//             "Azxd7A3mTR1flf8Xlryv", //Cultural Nonfiction J
//             "xqw7M822Z506s1PoaV6A", //Cultural Nonfiction K
//             "qxpWd78SfNmrJww1dzDN", //Cultural Nonfiction L
//             "dkZANsLOcYfW7l8uXePE", //Cultural Nonfiction M
//             "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
//             "ifktjBXVRellWbZXWFkn", //Embedded Reading
//             "zb1SZjIzgqFKWdIlnWEg", //Embedded Reading B
//             "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
// "Ue5kOAVpUHoPihTeXovd", //Stories for Beginners - B
// "gJE8dnnHUdf0vrmGypt0", //Stories for Beginners - B2
// "9cwrhRMKl4qNpVr9AYtO", //Stories for Beginners - C
// "U7oilJm6T2tU2A2PihoW", //Stories for Beginners - D
// "1FR40jP7QE8Q7F4ShNeO", //Stories for Beginners - E
// "q2inapU0UBIDWEzvW11z", //Stories for Beginners - F
// "Lou5hQyg1KUPMF8tZsSS", //Stories for Beginners - G
// "V3lwAXdxF041PjbPKe0w", //Stories for Beginners - H
// "94O0QNCLnjDDt9zyxy6x", //Stories for Beginners - I
// "rnizNuaIhjiXmgydpfSz", //Stories for Beginners - J
// "TG2x5VGCTdvpIVgnAuzU", //Stories for Beginners - K
// "KhgAAJ8eE7kU2wEIhVYG", //Stories for Beginners - L
// "mL44ImtXxuGZ0oxBdJs5", //Stories for Beginners - M
// "HoSUIMjyT28SktFrHbMq", //Stories for Level 2 - A
// "Gi57TNIOSr8FFmUtjyzv", //Stories for Level 2 - B
// "ZrY27ZlVpQ0MQuLLZK6u", //Stories for Level 2 - C
// "MYbtnLIO79MQnHowY5ac", //Stories for Level 2 - D
// "WMd0u0FRPQ9aTXVKjBgW", //Stories for Level 2 - E
// "H1AxFLYKrbzcF1b8WNJg", //Stories for Level 2 - F
// "pxERdOdqsqufZSqwxubi", //Stories for Level 2 - G
// "ePhpmbBEyzXhBuAgtrHL", //Stories for Level 4 - A

//         ]        
//         const doc = {
//             books: books,
//             dateCreated: dateCreated,
//         }
//         ref.doc(bookCode).set(doc)
//             .then(function () {
//                 console.log("New Book Code Created!");
//             })
//             .catch(function (error) {
//                 console.error("Error creating New Book Code: ", error);
//             });
//     }
// }

// export const removeDuplicates = () => {
export const addBooksToBookCode2 = () => {
    return (dispatch, getState) => {
        const ref = db.collection("bookCodes").doc("12312021")
        getBookArray(ref, (existingBookArray) => {
            const booksToAdd = [
                "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
                "Ue5kOAVpUHoPihTeXovd", //Stories for Beginners - B
                "gJE8dnnHUdf0vrmGypt0", //Stories for Beginners - C
                "9cwrhRMKl4qNpVr9AYtO", //Stories for Beginners - D
                "U7oilJm6T2tU2A2PihoW", //Stories for Beginners - E
                "1FR40jP7QE8Q7F4ShNeO", //Stories for Beginners - F
                "q2inapU0UBIDWEzvW11z", //Stories for Beginners - G
                "Lou5hQyg1KUPMF8tZsSS", //Stories for Beginners - H
                "V3lwAXdxF041PjbPKe0w", //Stories for Beginners - I
                "94O0QNCLnjDDt9zyxy6x", //Stories for Beginners - J
                "TG2x5VGCTdvpIVgnAuzU", //Stories for Beginners - K
                "KhgAAJ8eE7kU2wEIhVYG", //Stories for Beginners - L
                "mL44ImtXxuGZ0oxBdJs5", //Stories for Beginners - M
                "HoSUIMjyT28SktFrHbMq", //Stories for Level 2 - A
                "Gi57TNIOSr8FFmUtjyzv", //Stories for Level 2 - B
                "ZrY27ZlVpQ0MQuLLZK6u", //Stories for Level 2 - C
                "MYbtnLIO79MQnHowY5ac", //Stories for Level 2 - D
                "WMd0u0FRPQ9aTXVKjBgW", //Stories for Level 2 - E
                "H1AxFLYKrbzcF1b8WNJg", //Stories for Level 2 - F
                "pxERdOdqsqufZSqwxubi", //Stories for Level 2 - G
                "ePhpmbBEyzXhBuAgtrHL", //Stories for Level 4 - A            
            ]
            const newBookArray = existingBookArray.concat(booksToAdd)
            ref.update({
                books: newBookArray
            })
                .then(function () {
                    console.log("New books successfully added!");
                })
                .catch(function (error) {
                    console.error("Error adding books Book Code: ", error);
                });
        })
    }
}

// export const removeDuplicates = () => {
export const addBooksToBookCode = () => {
    return (dispatch, getState) => {
        const ref = db.collection("bookCodes").doc("hedstrom")
        getBookArray(ref, (existingBookArray) => {
            const booksToAdd = [
                "DdYsNM5ZNHObMiSdbaXq", //00 - 5 Latin Books for Preview
                "zqC8SWw8zpsTCfshF1Rx", //Chinese - The Girl Wants Hot Chocolate
                "BfTpY6WfQkjxbIg2Yaev", //Das Mädchen Will Heiße Schokolade
                "ifktjBXVRellWbZXWFkn", //Embedded Reading
                "HctgxrXBULrNzpLxyGc7", //Embedded Reading (English)
                "Qxthxoxr0sZbQrKr5pzr", //Spanish Movietalk: El Gato Bilingüe
                "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
                "Bi9FQAA3G5KB4R2tGQ2I", //Stories for Beginners (Eng) B
                "TxdwxaKRkjBg4CyGuy77", //Stories for Beginners (Eng) C
                "uEojVRmryTNNNDD6L2Rz", //Stories for Beginners (Eng) D
                "d2uL9PC1jk0CdgGeLL6D", //Stories for Beginners (Eng) E
                "yjsRs8nzDYiNnATd9XrU", //Stories for Beginners (Eng) F
                "yfeN6BbzBE1vCtVS5DLA", //Stories for Beginners (Eng) H
                "hzSdc7BtumXWhaXqYhH3", //Stories for Beginners (Eng) I
                "XBFucE2bEfcqsYvbAhcF", //Stories for Beginners (Eng) J
                "JTHobnQdPMI202UTGwSc", //Stories for Beginners (Fr) A
                "pbWGTsd62zNmmCDdcmPp", //Stories for Beginners (Fr) B
                "OEUmTmr1Uk61iLmDobiR", //Stories for Beginners (Fr) C
                "cQlFB9tAvAN57B6GHn9P", //Stories for Beginners (Fr) D
                "zqXDp2hGTwg11dB6uKw0", //Stories for Beginners (Fr) E
                "xn5AO5fbhxqfwa3Zgp5V", //Stories for Beginners (Fr) F
                "QobF3Nxtujc8PoEsS9W1", //Stories for Beginners (Fr) G
                "onDsJUdzMvFRp5kXTwlk", //Stories for Beginners (Fr) I
                "hYlvinhoo1A2ysxHhdS3", //Stories for Beginners (Fr) J
                "fTpy1y5EroS6KZOsa0ht", //Storytime con Sra. Jensen - 1
                "JADLgQ3raeoP5A9L39dT", //Storytime con Sra. Jensen - 10
                "syFoaCVm3m064GJTWLWP", //Storytime con Sra. Jensen - 11
                "YmY3uWivzlt5R0A7MQ1J", //Storytime con Sra. Jensen - 12
                "s6VLB3h1Ayy5s3Am4M1o", //Storytime con Sra. Jensen - 13
                "XnKHk0h4YOh90t17Kip2", //Storytime con Sra. Jensen - 14
                "dg3ZIHk7JU5oPz17Kg0p", //Storytime con Sra. Jensen - 2
                "TPGN0LivIkh4UIZZMJkx", //Storytime con Sra. Jensen - 3
                "aYFoOPlcGQAEndSQYguV", //Storytime con Sra. Jensen - 4
                "Do4Gkf2EZH1XndC9qMbn", //Storytime con Sra. Jensen - 5
                "VCvU4OQVJqysxYWKYy9k", //Storytime con Sra. Jensen - 6
                "6BAr3UooYiqeAXadotAy", //Storytime con Sra. Jensen - 7
                "ZURN6hDTwpISLZicNrSP", //Storytime con Sra. Jensen - 8
                "vihMb11Nko4hXckTbEf0", //Storytime con Sra. Jensen - 9
                "F0UsZaXNhphhVXghSoCJ",
                "LHJmf0amuxboJLjPCqsO"]
            const newBookArray = existingBookArray.concat(booksToAdd)
            ref.update({
                books: newBookArray
            })
                .then(function () {
                    console.log("New books successfully added!");
                })
                .catch(function (error) {
                    console.error("Error adding books Book Code: ", error);
                });
        })
    }
}
const getBookArray = (ref, callback) => {
    ref.get().then(function (doc) {
        if (doc.exists) {
            const existingBookArray = doc.data().books
            callback(existingBookArray)
        } else {
            console.log("Book code not found");
        }
    }).catch(function (error) {
        console.log("Error getting book code document", error);
    });
}

const completeSpanishCurriculum = [
    //Complete Spanish Curriculum
    "PnIdHphWWb6RXfGnhAEH", //Cultural Fiction 1A
    "a1YymDHO8yzi4XAKAr4p", //Cultural Fiction 1B
    "BLZMnCxwApoijdgRZLtL", //Cultural Fiction 1C
    "tViUdCb9fgbqWqh2XefT", //Cultural Fiction 1D
    "OjwaX5WsdgmG1hIbkPSZ", //Cultural Fiction 1E
    "09OnwfkhmKoqnh8YOLKg", //Cultural Fiction 2A
    "tyzlPtYrQ0HWWspiIxyJ", //Cultural Fiction 2B
    "ND4ed0ygTmxnVSCbO2lj", //Cultural Fiction 3
    "uZDfOjuNtr8hXvIbwQY0", //Cultural Nonfiction A
    "Uz8loJILog0vJHUAwxpP", //Cultural Nonfiction C
    "sVaqJEvi6bMuuZMXPJo7", //Cultural Nonfiction D
    "7QRR5NNnBuj0ikFfHwUj", //Cultural Nonfiction E
    "ldhZynxEAi37wMetevAn", //Cultural Nonfiction F
    "2QYGBaaPTFJiAd2ZaOom", //Cultural Nonfiction G
    "xOUEu3C7Z7xLAfN1PJWs", //Cultural Nonfiction H
    "O6OpwjHYDM1M97Q2zS9C", //Cultural Nonfiction I
    "Azxd7A3mTR1flf8Xlryv", //Cultural Nonfiction J
    "xqw7M822Z506s1PoaV6A", //Cultural Nonfiction K
    "qxpWd78SfNmrJww1dzDN", //Cultural Nonfiction L
    "dkZANsLOcYfW7l8uXePE", //Cultural Nonfiction M
    "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
    "ifktjBXVRellWbZXWFkn", //Embedded Reading
    "7ZikixXAqGN2GAmnlCZe", //Embedded Reading A
    "zb1SZjIzgqFKWdIlnWEg", //Embedded Reading B
    "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
    "Ue5kOAVpUHoPihTeXovd", //Stories for Beginners - B
    "gJE8dnnHUdf0vrmGypt0", //Stories for Beginners - B2
    "9cwrhRMKl4qNpVr9AYtO", //Stories for Beginners - C
    "U7oilJm6T2tU2A2PihoW", //Stories for Beginners - D
    "1FR40jP7QE8Q7F4ShNeO", //Stories for Beginners - E
    "q2inapU0UBIDWEzvW11z", //Stories for Beginners - F
    "Lou5hQyg1KUPMF8tZsSS", //Stories for Beginners - G
    "V3lwAXdxF041PjbPKe0w", //Stories for Beginners - H
    "94O0QNCLnjDDt9zyxy6x", //Stories for Beginners - I
    "TG2x5VGCTdvpIVgnAuzU", //Stories for Beginners - K
    "KhgAAJ8eE7kU2wEIhVYG", //Stories for Beginners - L
    "mL44ImtXxuGZ0oxBdJs5", //Stories for Beginners - M
    "HoSUIMjyT28SktFrHbMq", //Stories for Level 2 - A
    "Gi57TNIOSr8FFmUtjyzv", //Stories for Level 2 - B
    "ZrY27ZlVpQ0MQuLLZK6u", //Stories for Level 2 - C
    "MYbtnLIO79MQnHowY5ac", //Stories for Level 2 - D
    "WMd0u0FRPQ9aTXVKjBgW", //Stories for Level 2 - E
    "H1AxFLYKrbzcF1b8WNJg", //Stories for Level 2 - F
    "pxERdOdqsqufZSqwxubi", //Stories for Level 2 - G
    "ePhpmbBEyzXhBuAgtrHL", //Stories for Level 4 - A
    "TbuaXQ4tHnm1E5plaDu4", //Topic: Music
    "OfizAyWIFNMX0Juz8ebm", //Topic: Numbers    
]

const freebies = [
    //Freebies
    "DdYsNM5ZNHObMiSdbaXq", //00 - 5 Latin Books for Preview
    "zqC8SWw8zpsTCfshF1Rx", //Chinese - The Girl Wants Hot Chocolate
    "T1vdnN51mN6LCZDOs87n", //Cultural Nonfiction P
    "BfTpY6WfQkjxbIg2Yaev", //Das Mädchen Will Heiße Schokolade
    "DNcEcd4Sqs3TPxpFaJiO", //French Cultural Nonfiction
    "xvPux93CnDxl87uBm59D", //Korean - The Girl Wants Hot Chocolate
    "yylk4Vr3wPL5vDzR0ik7", //Little Red Riding Hood
    "0m5AnMgvvDIdwopojS8M", //Spanish Beginner Student Story 1
    "Yj4rl3QcXmBJSspoaI8D", //Spanish Beginner Student Story 2
    "op224uqV2qzBzS6A66jh", //Spanish Fairytale - Level 1
    "Qxthxoxr0sZbQrKr5pzr", //Spanish Movietalk: El Gato Bilingüe
    "sqDQZE0PwhSDOPYcWb7g", //Spanish Novel - Level 2
    "BLCkudpmO3xk6zEu4K88", //Spanish Printable Mini-Books - Level 2
    "ei8snQSw8LwdNTZ9IjK8", //Stories for Beginners (Eng) A
    "JTHobnQdPMI202UTGwSc", //Stories for Beginners (Fr) A
    "vqFPPIyh9kzMSZrirdf4", //Stories for Beginners - A
    "fTpy1y5EroS6KZOsa0ht", //Storytime con Sra. Jensen - 1
    "JADLgQ3raeoP5A9L39dT", //Storytime con Sra. Jensen - 10
    "syFoaCVm3m064GJTWLWP", //Storytime con Sra. Jensen - 11
    "YmY3uWivzlt5R0A7MQ1J", //Storytime con Sra. Jensen - 12
    "s6VLB3h1Ayy5s3Am4M1o", //Storytime con Sra. Jensen - 13
    "XnKHk0h4YOh90t17Kip2", //Storytime con Sra. Jensen - 14
    "dg3ZIHk7JU5oPz17Kg0p", //Storytime con Sra. Jensen - 2
    "TPGN0LivIkh4UIZZMJkx", //Storytime con Sra. Jensen - 3
    "aYFoOPlcGQAEndSQYguV", //Storytime con Sra. Jensen - 4
    "Do4Gkf2EZH1XndC9qMbn", //Storytime con Sra. Jensen - 5
    "VCvU4OQVJqysxYWKYy9k", //Storytime con Sra. Jensen - 6
    "6BAr3UooYiqeAXadotAy", //Storytime con Sra. Jensen - 7
    "ZURN6hDTwpISLZicNrSP", //Storytime con Sra. Jensen - 8
    "vihMb11Nko4hXckTbEf0", //Storytime con Sra. Jensen - 9
    "gbcPTbJaKchL7DUZvvCZ", //Storytime con Sra. Jensen - 15
    "F0UsZaXNhphhVXghSoCJ", //Storytime con Sra. Jensen - 16
    "LHJmf0amuxboJLjPCqsO", //Storytime con Sra. Jensen - 17
    "CLLVY7aH10mcSlqAx55O", //Storytime con Sra. Jensen - 18
    "TbuaXQ4tHnm1E5plaDu4", //Topic: Music
    "OfizAyWIFNMX0Juz8ebm", //Topic: Numbers
]

const pisoverse = [
    // All of Lance's books
    "quHrr23Iu4cG4tqhqM7P", // 01 - Rufus lutulentus                         3/13
    "j4Dg84umP6VaQMkCj9yN", // 02 - Rufus et Lucia: liberi lutulenti         3/13
    "Ov4Zm0VeYGiSj3hs84Ri", // 03 - Quintus et nox horrifica                 3/13
    "2Y1NAuFcafo3BnOjJLtd", // 04 - Syra sola                                3/13
    "GiB64vRQTgAFtd32vO8o", // 05 - Syra et animalia                         3/13
    "7v2BBhBy9jQjU5UHpdRo", // 06 - Poenica purpurāria                       3/13
    "xVoi7NmNQxLXi87HUKoq", // 07 - Piso perturbatus                         3/13
    "MAeYQibGyrqiXJnizBRU", // 08 - Drusilla in Subura                       3/13
    "lHkswcugQZffPNUVqzqz", // 09 - Rufus et arma atra                       3/13
    "scMnD3eVLj5R9C9rJjmg", // 10 - Rufus et gladiatores                     3/13
    "Pei8HrRk71JWaFkqUTxl", // 11 - Agrippīna aurīga                         3/13 NEW
    "AgWU7OuKRWScAXyFd87Z", // 12 - tres amici et monstrum saevum            3/13
    "HlnrlxVDicRQBq6pGoJK", // 13 - sitne amor?                              3/13
    "kRwiLV0aeTWMoXvZpAAq", // 14 - Agrippina: mater fortis                  3/13
    "MQ2b0qtsGEwvZVGkXzBJ", // 15 - Livia: mater eloquens                    3/13
    "8o9Xh68w0W3rhBJK4jeV", // 16 - Piso et Syra et potiones mysticae        3/13
    "iOUuXNJusN0fSuzIT1az", // 17 - Drusilla et convivium magarum            3/13
    "sihLORwkEN4rUugrPDMg", // 18 - sīgna zōdiaca Vol. 1                     3/13
    "lk2MCCbfrhfS3icYozEx", // 19 - sīgna zōdiaca Vol. 2                     3/13
    "eg1NszpJaFrfNawaqHXX", // 20 - sīgna zōdiaca Vol. 3                     3/13 NEW
    "LsZu9yBNjV3i09ANWu8u", // 21 - fragmenta Pisonis                        3/13
    "2rXPNNDLihdMGKgu2isi", // 22 - Piso Ille Poetulus                       3/13
    "uPF6sKVTdHPlfBvxlOYE", // 23 - Piso: Tiered Versions                    3/13
    "6LEMGW0rVpH6JwRJdo3d", // 24 - Tiberius et Gallisena ultima             3/13
]

const olimpi = [
    // All of Andrew's books
    "rJBwrqoAjoHxBt04qtKB", // Familia Mala - Saturnus et Iuppiter
    "j4d5xiQPfcaBfen73Ie9", // Familia Mala 2 - Duo Fratres
    "8Ot1PuQRVbleWybtAQgh", // Familia Mala 3 - Pandora
    "fpSC0gJskhLrYuvCVigV", // Lars Romam Odit
    "Wng3GXxgFqN0Reb0h7SL", // Via Periculosa
    "JHmpzEnG9FygppX61s7K", // Labyrinthus
    "iO2lNaEE1PlRpYHrqiDp", // Ego Polyphemus
]
