const initState = {
    loadedFromFB: false,
    myClassroomsAdult: false, //no longer used as of 3/7/20
    myClassroomsChild: false, //no longer used as of 3/7/20
    myClassrooms: {
    //     classroomId: {...classroomObj}
    },
    // Original object for OneClassroom
    students: {
        // classroomId1: objectOfStudents,
        // classroomId2: objectOfStudents
    }
}

const myClassroomsReducer = (state = initState, action) => {
    switch (action.type) {
        // case "SET_MY_CLASSROOMS": //removed 3/7/20
        //     const { myClassroomsAdult, myClassroomsChild } = action.myClassrooms
        //     return {
        //         ...state,
        //         loadedFromFB: true,
        //         myClassroomsAdult,
        //         myClassroomsChild
        //     }
        case "SET_MY_CLASSROOMS":
            return {
                ...state,
                loadedFromFB: true,
                myClassrooms: { ...action.classrooms }
            }    
        case "SET_NEW_CLASSROOM":
            let newClassId = action.newClassroom.classroomId
            const newClassroom = action.newClassroom
            return {
                ...state,
                myClassrooms: {
                    ...state.myClassrooms,
                    [newClassId]: newClassroom
                }
            }
        case "SET_STUDENTS_IN_CLASS":
            return {
                ...state,
                students: {
                    ...state.students,
                    ...action.studentsInClass
                }
            }
        case "UPDATE_STUDENTS_IN_CLASS":
            const { classroomId, students } = action.studentsToAdd
            return {
                ...state,
                students: {
                    ...state.students,
                    [classroomId]: {
                        ...students
                    }
                }
            }
        // case "UPDATE_CLASS_CODE":    //Updated 3/7/20
        //     const classroomIdForUpdateClassCode = action.classCodeDetails.classroomId
        //     const classCode = action.classCodeDetails.classCode
        //     return {
        //         ...state,
        //         myClassroomsAdult: {
        //             ...state.myClassroomsAdult,
        //             [classroomIdForUpdateClassCode] : {
        //                 ...state.myClassroomsAdult[classroomIdForUpdateClassCode],
        //                 classCode: classCode
        //             }
        //         }
        //     }
        case "UPDATE_CLASS_CODE":
            const classroomIdForUpdateClassCode = action.classCodeDetails.classroomId
            const classCode = action.classCodeDetails.classCode
            return {
                ...state,
                myClassrooms: {
                    ...state.myClassrooms,
                    [classroomIdForUpdateClassCode]: {
                        ...state.myClassrooms[classroomIdForUpdateClassCode],
                        classCode: classCode
                    }
                }
            }
        // case "SET_NEW_MY_CLASSROOM":
        //     let newClassId = action.newClassroom.classroomId
        //     const newClassroom = action.newClassroom
        //     return {
        //         ...state,
        //         loadedFromFB: true,
        //         myClassroomsAdult: {
        //             ...state.myClassroomsAdult,
        //             [newClassId]: newClassroom
        //         }
        //     }
        case "DELETE_CLASSROOM_FROM_MYCLASSROOMSREDUCER":
            const classDeleteId = action.classroom.classroomId
            return {
                ...state,
                loadedFromFB: true,
                myClassrooms: {
                    ...state.myClassrooms,
                    [classDeleteId]: "deleted"
                }             
            }

        default:
            return state
    }
}

export default myClassroomsReducer