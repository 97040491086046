const initState = {
    isEmpty: true
}

const journalReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_JOURNAL":
            return action.journalstuff

        case "UPDATE_JOURNAL":
            return {
                ...state,
                newStuff: action.newStuff
            }
        default:
            return state
    }
}

export default journalReducer