import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateChapterFile } from '../../../store/actions/publisherActions'

class ModalUpdateComment extends Component {

// Props passed to this class
// chapterFile
// chapter
// toggleShowModalUpdateComment

    state = {
        comment: ""
    }
    
    componentDidMount() {
        let fileCommentText = this.getFileCommentText()

        this.setState({
            comment: fileCommentText
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    //HELPER FUNCTIONS
    getFileCommentText = () => {
        let fileCommentText = this.props.chapterFile.fileComment ? this.props.chapterFile.fileComment : ""
        if (fileCommentText.substring(0, 1) === "\"" ) {
            fileCommentText = fileCommentText.substring(1); //removes initial "
            fileCommentText = fileCommentText.slice(0, -1);  //removes ending "
        }
        const newLine = String.fromCharCode(13, 10); //gets the newLine character
        fileCommentText = fileCommentText.replace(/\\n/g, newLine) //replaces\n with newLine character
        fileCommentText = fileCommentText.replace(/\\/g, '') //removes \
        return fileCommentText
    }


    //EVENT FUNCTIONS
    handleChange = (e) => {
        this.setState({
            comment: e.target.value
        })
    }
    saveComment = () => {
        let fileCommentText = this.getFileCommentText()
        if (this.state.comment === fileCommentText) {
            this.props.toggleShowUpdateComment()
            return
        }
        const chapterFile = this.props.chapterFile
        chapterFile.fileComment = this.state.comment
        this.props.updateChapterFile(this.props.chapter, chapterFile)
        this.props.toggleShowUpdateComment()
    }


    //COMPONENT FUNCTIONS
    getCommentInput = () => {
        let fileCommentText = this.getFileCommentText()
        let styleName = "pubfeat-textarea"
        if (this.state.comment !== fileCommentText) {
            styleName = "pubfeat-textarea pubfeat-textareaedit"
        }
        return (
                <form onSubmit={this.handleSubmit} >
                    <textarea
                        className={styleName}
                        type="text"
                        id="comment"
                        onChange={this.handleChange}
                        value={this.state.comment}
                    />
                </form>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                    <div className="pubfeat-close" onClick={this.props.toggleShowUpdateComment}></div>
                    <div className="pubfeat-renametitle">Update File Note</div>
                    {this.getCommentInput()}

                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.saveComment} >
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        updateChapterFile: (chapter, chapterFile) =>  dispatch(updateChapterFile(chapter, chapterFile)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateComment)

