import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateChapterFile } from '../../../store/actions/publisherActions'

class ModalUpdateLink extends Component {

// Props passed to this class
// chapterFile
// chapter
// toggleShowUpdateLink

    state = {
        fileName: "",
        webLink: ""
    }
    
    componentDidMount() {
        const fileName = this.props.chapterFile.file_name ?
            this.props.chapterFile.file_name :
            ""
        const webLink = this.props.chapterFile.firebase_storage_url ?
            this.props.chapterFile.firebase_storage_url :
            ""
        this.setState({ fileName: fileName })
        this.setState({ webLink: webLink })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }


    handleChange = (e) => {
        const targetId = e.target.id
        const targetValue = e.target.value
        this.setState({
            [targetId]: targetValue
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        // const embeddedWebLink = this.addEmbedToWebLink(this.state.webLink)
        const embeddedWebLink = this.state.webLink
        const newChapterFile = Object.assign({}, this.props.chapterFile) //clones an object
        newChapterFile.file_name = this.state.fileName
        newChapterFile.firebase_storage_url = embeddedWebLink
        this.props.updateChapterFile(this.props.chapter, newChapterFile)
        this.props.toggleShowUpdateLink()
    }

    addEmbedToWebLink = (oldWebLink) => {
        let webLink = oldWebLink
        if (webLink.includes("youtube") && !webLink.includes("embed")) {
            webLink = webLink.replace("watch?v=", "embed/")
        } else if (webLink.includes("quizlet") && !webLink.includes("embed")) {
            webLink = webLink + "/embed"
        } else if (webLink.includes("edpuzzle") && !webLink.includes("embed")) {
            webLink = webLink.slice(0, 21) + "embed/" + webLink.slice(21);
        }
        return webLink
    }

    getFileNameInput = () => {
        let styleNameFile = "input-myclassrooms input-uploadepub"
        if (this.state.fileName !== this.props.chapterFile.file_name) {
            styleNameFile = "input-myclassrooms input-uploadepub input-mcnew"
        }
        let styleNameLink = "input-myclassrooms input-uploadepub input-weblink"
        if (this.state.webLink !== this.props.chapterFile.firebase_storage_url) {
            styleNameLink = "input-myclassrooms input-uploadepub input-weblink input-mcnew"
        }
        return (
            <form onSubmit={this.handleSubmit} >
                <div className="epubupload-file-label">Update File Label</div>
                <div className="input-filename">
                    <input
                        className={styleNameFile}
                        type="text"
                        id="fileName"
                        onChange={this.handleChange}
                        tabIndex={6}
                        value={this.state.fileName}
                    />
                </div>
                <div className="input-space"></div>
                <div className="epubupload-file-label">Update Web Link</div>
                <div className="input-filename">
                    <input
                        className={styleNameLink}
                        type="text"
                        id="webLink"
                        onChange={this.handleChange}
                        tabIndex={6}
                        value={this.state.webLink}
                    />
                </div>
            </form>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowUpdateLink}></div>
                    {this.getFileNameInput()}
                    <div className="input-space"></div>
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom epubupload-btn" onClick={this.handleSubmit} >
                            <span>UPDATE</span>
                        </button>
                    </div>
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        updateChapterFile: (chapter, newChapterFile) =>  dispatch(updateChapterFile(chapter, newChapterFile)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateLink)
