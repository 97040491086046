import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addBookToClassroom, removeBookFromClassroom } from '../../../store/actions/myClassroomsActions'
import firebase from "../../../config/fbConfig"


class OneClassroomBooks extends Component {
    state = {
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
    }

    //Button Events
    clickedRemoveBook = (bookId) => {
        const classroomId = this.props.classroomId
        this.props.removeBookFromClassroom(classroomId, bookId)
    }
    clickedAddBook = (bookId) => {
        const classroomId = this.props.classroomId
        this.props.addBookToClassroom(classroomId, bookId)
    }


    // onClick={() => this.clickedClassroomRow(classroomId)}
    // onClick={(e) => this.props.clickedChapterFile(e, chapterFile, false)
    //Render Functions
    getBookDisplay = () => {
        // const classBooks = this.getClassBooks("classroom")
        // const purchasedBooks = this.getClassBooks("purchased")
        const { bookIdsPurchased, bookIdsClassroom } = this.getBookIds()
        const classBooks = this.getBookCovers(bookIdsClassroom, "classBooks")
        const purchasedBooks = this.getBookCovers(bookIdsPurchased, "purchasedBooks")
        return (
            <div className="ajs-modal">
                <div className="modalcontent-oneclassbooks">
                    <div className="header-oneclassbooks">Classroom Books</div>
                    <div className="classbooksbox-oneclassbooks">
                        <div className="grid-row-oneclassroom">
                            {classBooks}
                        </div>
                    </div>
                    <br/>
                    <div className="header-oneclassbooks">Available Books</div>
                    <div className="classbooksbox-oneclassbooks">
                        <div className="grid-row-oneclassroom">
                            {purchasedBooks}
                        </div>
                    </div>
                    <button className="btn-oneclassroom btnkeepclass-myclassrooms btnclose-oneclassbooks" onClick={this.props.clickedManageBooks} >
                        <span>Close</span>
                    </button>
                </div>
            </div>
        )
    }
    getBookIds = () => {
        const booksPurchasedAll = this.props.state.books.booksPurchased ?
            this.props.state.books.booksPurchased :
            {}
        const booksPurchasedAllArr = Object.entries(booksPurchasedAll)
        booksPurchasedAllArr.sort((a, b) => (a[1].title > b[1].title) ? 1 : -1)        
        const classroomId = this.props.classroomId
        const booksClassroomObj = this.props.state.books.paByClassroomIdBookId[classroomId] ?
            this.props.state.books.paByClassroomIdBookId[classroomId] :
            {}
        let bookIdsClassroom = []
        let bookIdsPurchased = []
        booksPurchasedAllArr.forEach( (book) => {
            const bookId = book[0]
            if (booksClassroomObj[bookId] !== undefined) {
                bookIdsClassroom.push(bookId)
            } else {
                bookIdsPurchased.push(bookId)
            }
        })
        return { bookIdsPurchased, bookIdsClassroom }

    }
    getBookCovers = (bookIds, group) => {
        const removeIcon =  "../../../../../img/removeIcon.svg"
        // const removeIcon =  "../../../../../img/plus.svg"
        const addIcon = "../../../../../img/addIcon.svg"
        // const addIcon = "../../../../../img/minus.svg"
        let bookCovers = []
        const booksPurchased = this.props.state.books.booksPurchased ?
            this.props.state.books.booksPurchased :
            {}
        bookIds.forEach( (bookId) => {
            const book = booksPurchased[bookId]
            if (book === undefined ) { return }
            const bookCoverUrl = book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url ?
                    book.bookCoverImage.firebase_storage_url :
                    "" :
                ""
            const bookTitle = book.title ?
                book.title :
                ""
            let actionIcon = (
                <img className="bookbtn-oneclassbooks" src={removeIcon} alt="" onClick={() => this.clickedRemoveBook(bookId)} />
            )
            if (group === "purchasedBooks") {
                actionIcon = (
                    <img className="bookbtn-oneclassbooks" src={addIcon} alt="" onClick={() => this.clickedAddBook(bookId)} />
                )
            }
            bookCovers.push(
                        <div className="grid-item-oneclassroom" key={bookId}>
                            <div className="bookitemcontainer-oneclassroom">
                                <div className="bookimagecontainer-oneclassbooks">
                                    {actionIcon}
                                    <img className="bookimage-oneclassroom" src={bookCoverUrl} alt="Book Cover" />
                                </div>
                                <div className="booktitle-oneclassbooks">
                                    {bookTitle}
                                </div>
                            </div>
                        </div>
            )
        })
        return bookCovers
    }





    getClassBooks = (type) => {
        const removeIcon =  "../../../../../img/removeIcon.svg"
        const addIcon = "../../../../../img/addIcon.svg"
        let bookCovers = []
        let books = []
        const classroomId = this.props.classroomId
        const classroomBooks = this.props.state.classrooms.classrooms ?
            this.props.state.classrooms.classrooms[classroomId] ?
                this.props.state.classrooms.classrooms[classroomId].books ?
                    this.props.state.classrooms.classrooms[classroomId].books :
                    [] :
                [] :
            []

        if (type==="classroom") {
            books = classroomBooks
        } else {
            let classroomBooksObj = {}
            classroomBooks.forEach( (book) => {
                classroomBooksObj[book.bookId] = true
            })
            const purchasedBooksObj = this.props.state.books.booksPurchased ?
                this.props.state.books.booksPurchased :
                {}
            const purchasedBooksArr = Object.values(purchasedBooksObj)
            purchasedBooksArr.forEach( (book) => {
                if (classroomBooksObj[book.bookId] === undefined) {
                    books.push(book)
                }
            })
        }

        books.forEach( (book, i) => {
            const bookId = book.bookId
            const bookCoverUrl = book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url ?
                    book.bookCoverImage.firebase_storage_url :
                    "" :
                ""
            const bookTitle = book.title ?
                book.title :
                ""
            let actionIcon = (
                <img className="bookbtn-oneclassbooks" src={removeIcon} alt="" onClick={() => this.clickedRemoveBook(bookId)} />
            )
            if (type==="purchased") {
                actionIcon = (
                    <img className="bookbtn-oneclassbooks" src={addIcon} alt="" onClick={() => this.clickedAddBook(bookId)} />
                )
            }
            bookCovers.push(
                        <div className="grid-item-oneclassroom" key={bookId}>
                            <div className="bookitemcontainer-oneclassroom">
                                <div className="bookimagecontainer-oneclassbooks">
                                    {actionIcon}
                                    <img className="bookimage-oneclassroom" src={bookCoverUrl} alt="Book Cover" />
                                </div>
                                <div className="booktitle-oneclassbooks">
                                    {bookTitle}
                                </div>
                            </div>
                        </div>
            )
        })
        return bookCovers
    }


    render(){
        let toDisplay = (
            <div className="ajs-modal">
                <div className="modalcontent-oneclassbooks">
                    <div className="loader"></div>
                </div>
            </div>
        )
        if (this.props.state.books.booksPurchased) {
            toDisplay = this.getBookDisplay()
        }

        return toDisplay
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBookToClassroom: (classroomId, bookId) => dispatch(addBookToClassroom(classroomId, bookId)),
        removeBookFromClassroom: (classroomId, bookId) => dispatch(removeBookFromClassroom(classroomId, bookId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneClassroomBooks)

