import AdmZip from 'adm-zip' //https://stackoverflow.com/questions/10359485/how-to-download-and-unzip-a-zip-file-in-memory-in-nodejs
import firebase from 'firebase/app'

// Create a root reference
const storageRef = firebase.storage().ref();
const db = firebase.firestore()
// let epubHtmlUrl = null
// let chapter = null
// let chapterFile = null
// let bookId = null;
// let chapterId = null;
// let fileId = null;


export const epubToHtml = (thisChapter, thisChapterFile, epubUrl) => {
    return (dispatch, getState) => {
        let imageNames = []
        let imageData = []
        // console.log("Get Zip File")
        // console.log(epubUrl)
        getEpubFile( epubUrl, (zip) => {
            console.log("ZIP TYPE")
            console.log(zip)
            let epubHtml = processZip(zip, imageNames, imageData)
            console.log("Save Images to Firebase")
            saveToFB( thisChapter.bookId, thisChapter.chapterId, thisChapterFile.fileId, imageData, imageNames, (snapshots) => {
                // console.log('File available at', downloadURL);
                console.log("Get Image URLs");
                getImageUrls( snapshots, (imageUrls) => {
                    console.log("GOT Images")
                    console.log(imageUrls)
                    console.log(epubHtml)              
                    epubHtml = replaceImageRefs(imageNames, imageUrls, epubHtml)
                    console.log(typeof epubHtml)
                    console.log("Save HTML File to Firebase")
                    uploadHtml( thisChapter.bookId, thisChapter.chapterId, thisChapterFile.fileId, epubHtml, (epubHtmlUrl) => {
                        console.log("Save HTML File URL in Firestore")
                        updateChapterFile( thisChapterFile, thisChapter, epubHtmlUrl, () => {
                            console.log("COMPLETE!!!");
                            dispatch({ type: "Testing" })
                        })
                    })
                })
            })
        })
    } 
}


export const uploadEpub = (epubFile, label, section, bookId, chapterId) => { //part of the new publisher system
    return (dispatch, getState) => {
        // console.log("ACTION ACTION")
        // console.log(epubFile)
        // console.log(label)
        // console.log(section)
        // console.log(bookId)
        // console.log(chapterId)
        dispatch({ type: "SHOW_SPINNER" })
        let imageNames = []
        let imageData = []
        const fileId = uuidv4()
        saveEpubFile( bookId, chapterId, fileId, epubFile, (epubUrl) => {
            console.log("1 Epub Saved")
            // console.log(epubUrl)
            getEpubFile( epubUrl, (zip) => {
                console.log("2 Epub Retrieved")
                // console.log(zip)
                let epubHtml = processZip(zip, imageNames, imageData)
                console.log("3 Zip Processed")
                // console.log(epubHtml)
                saveToFB( bookId, chapterId, fileId, imageData, imageNames, (snapshots) => {
                    console.log("4 Images Saved")
                    getImageUrls( snapshots, (imageUrls) => {
                        console.log("5 Image Urls Retrieved")
                        // console.log(imageUrls)
                        // console.log(epubHtml)              
                        epubHtml = replaceImageRefs(imageNames, imageUrls, epubHtml)
                        
                        // console.log(typeof epubHtml)
                        // console.log("Save HTML File to Firebase")
                        uploadHtml( bookId, chapterId, fileId, epubHtml, (epubHtmlUrl) => {
                            console.log("6 Epub Html Saved")
                            saveNewChapterFile( chapterId, fileId, section, label, epubUrl, epubHtmlUrl, (newFile) => {
                                console.log("7 New Chapter File Saved. DONE!");
                        
                                const chapter = getState().books.chaptersByBook[bookId][chapterId]
                                chapter.file_array.push(newFile)
                                // console.log(chapterObj)
                                dispatch({ type: "UPDATE_CHAPTER", chapter })
                                dispatch({ type: "UPDATE_CHAPTER2", chapter })
                                dispatch({ type: "HIDE_SPINNER" })
                            })
                        })
                    })
                })
            })    
        })
    }
}

const saveEpubFile = (bookId, chapterId, fileId, epubFile, callback) => {
    const rootStoragePath = "books/" + bookId + "/" + chapterId + "/" + fileId + "/"
    const storagePath = rootStoragePath + "epubFile.epub"
    storageRef.child(storagePath).put(epubFile).then(function(snapshot) {
        snapshot.ref.getDownloadURL().then( (epubUrl) => {
            callback(epubUrl)
        })
    }, function (err) {
        console.log("Could not save audio tts")
    })
}



const saveNewChapterFile = (chapterId, fileId, section, label, epubUrl, epubHtmlUrl, callback) => {
    const newFile = {
        contentType: "application/epub+zip",
        epubHtmlUrl: epubHtmlUrl,
        fileId: fileId,
        fileSection: section,
        file_name: label,
        firebase_storage_url: epubUrl,
        key: 0
    };
    const refChapter = db.collection("chapters_v2").doc(chapterId);
    refChapter.update({
        file_array: firebase.firestore.FieldValue.arrayUnion(newFile)
    })
        .then(function () {
            callback(newFile)
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}





const getEpubFile = (epubUrl, callback) => {
    //https://stackoverflow.com/questions/10359485/how-to-download-and-unzip-a-zip-file-in-memory-in-nodejs
    const http = require('http');
    http.get(epubUrl, function (res) {
        // res.setHeader('Access-Control-Allow-Origin', '*')
        var data = [], dataLen = 0;
        res.on('data', function (chunk) {
            data.push(chunk);
            dataLen += chunk.length;
        }).on('end', function () {
            var buf = Buffer.alloc(dataLen);
            for (var i = 0, len = data.length, pos = 0; i < len; i++) {
                data[i].copy(buf, pos);
                pos += data[i].length;
            }
            let zip = new AdmZip(buf);
            callback(zip)
        });
    });
}

const addIdsToSpans = (epubHtml) => {
    var regex = /<span/gi, result, indices = [];
    while ((result = regex.exec(epubHtml))) {
        indices.push(result.index);
    }
    //increase the index values so that when text is inserted, the subsequent indix values will be correct
    for (var i = 0; i < indices.length; i++) {
        indices[i] = indices[i] + 5 + ((i)*14)
    }
    //insert ids for all span tags
    for (var i = 0; i < indices.length; i++) {
        let id = "0"
        if (i<10) {id = "0000"+i}
        else if (i<100) {id = "000"+i}
        else if (i<1000) {id = "00"+i}
        else if (i<10000) {id= "0"+i}
        else {id = i.toString()}
        const position = indices[i]
        const idToInsert = " id=\"tts" + id + "\""
        epubHtml = [epubHtml.slice(0, position), idToInsert, epubHtml.slice(position)].join('');
    }
}

const processZip = (zip, imageNames, imageData) => {
    // console.log("HTML")
    const zipEntries = zip.getEntries();
    let epubHtml = zip.readAsText(zipEntries[3])

    //update meta tag
    const newMeta = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\" "
    epubHtml = epubHtml.replace("<meta", newMeta);

    //add this to the span tags that enclose the img tags so images will fit width of screen
    const textToReplace = /style=\"/gi;
    let newImgStyle = "style=\"max-width: 100%; height: auto !important; "
    epubHtml = epubHtml.replace(textToReplace, newImgStyle)

    //remove extra white spaces
    // epubHtml = epubHtml.replace(/\s+/g,' ').trim();

    addIdsToSpans(epubHtml)
    // console.log("EPUB HTML")
    // console.log(epubHtml)
    for (var i = 0; i < zipEntries.length; i++) {
        // console.log("Entry")
        // console.log(zipEntries[i])
        if (zipEntries[i].entryName.includes("image")) {
            const parts = zipEntries[i].entryName.split("/");
            const imageName = parts.pop()
            imageNames.push(imageName)
            imageData.push(zipEntries[i].getData())
            // imageUrls.push(null);
        }
    }

    return epubHtml

    // console.log(imageUrls)
    
    // In case I want to work with Blobs
    // https://stackoverflow.com/questions/13950865/javascript-render-png-stored-as-uint8array-onto-canvas-element-without-data-uri
    // https://stackoverflow.com/questions/34389649/javascript-blob-document-with-html-tags-to-be-saved-with-formatting
    // https://stackoverflow.com/questions/50620821/uint8array-to-image-in-javascript/50621529
    // const blob = new Blob([entry6Data], { 'type': 'image/png' });
    // const thisurl = URL.createObjectURL(blob)
}


const saveToFB = (bookId, chapterId, fileId, imageData, imageNames, callback) => {    
    // storagePath = books/bookId/chapterId/fileId
    const rootStoragePath = "books/" + bookId + "/" + chapterId + "/" + fileId + "/"
    let promises = [];
    for (var i = 0; i < imageData.length; i++) {
        // Create the file metadata
        // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
        // or use this:  const fileExt = filename.split('.').pop();
        let re = /(?:\.([^.]+))?$/;
        // console.log("IMAGES IMAGES IMAGES")
        // console.log(imageData[i])
        const extension = re.exec(imageNames[i])[1];
        var metadata = {
            contentType: "image/" + extension
        };
        const storagePath = rootStoragePath + imageNames[i]
        promises.push(storageRef.child(storagePath).put(imageData[i], metadata));
    }

    Promise.all(promises).then( (theseSnapshots) => {
        // returned data is in arguments[0], arguments[1], ... arguments[n]
        // snapshots = theseSnapshots
        callback(theseSnapshots);
    }, function(err) {
        console.log('COULD NOT save all images');
    });    

}

const getImageUrls = (snapshots, callback) => {
    let promises = [];
    for (var i = 0; i < snapshots.length; i++) {
        // console.log(snapshots[i].ref.getDownloadURL())
        promises.push(snapshots[i].ref.getDownloadURL());
    }

    Promise.all(promises).then( (theseImageUrls) => {
        // returned data is in arguments[0], arguments[1], ... arguments[n]
        // imageUrls = theseImageUrls
        callback(theseImageUrls);
    }, function(err) {
        console.log('COULD NOT get all image urls');
    });    
}


const replaceImageRefs = (imageNames, imageUrls, epubHtml) => {
    // console.log(imageNames)
    // console.log(imageUrls) 
    // console.log(epubHtml)

    if (imageNames.length !== imageUrls.length) {
        return
    }

    let workingHtml = epubHtml

    for (var i = 0; i < imageNames.length; i++) {
        const textToBeReplaced = new RegExp("images/"+imageNames[i], "g");
        const textToReplace = imageUrls[i]
        workingHtml = workingHtml.replace(textToBeReplaced, textToReplace);
    }

    // epubHtml = workingHtml
    return workingHtml
}


const uploadHtml = (bookId, chapterId, fileId, epubHtml, callback) => {
    // storagePath = books/bookId/chapterId/fileId
    const storagePathString = "books/" + bookId + "/" + chapterId + "/" + fileId + "/epubText.html"
    const metadata = {
        contentType: "text/html"
    };
    const storagePath = storageRef.child(storagePathString)
    storagePath.putString(epubHtml, "raw", metadata).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log('File available at', downloadURL);
            // epubHtmlUrl = downloadURL
            callback(downloadURL);            
        });
    }, function(err) {
        console.log('COULD NOT save html file');
    });
}


const updateChapterFile = (chapterFile, chapter, epubHtmlUrl, callback) => {
    //UPDATE THIS !!!!!!
    // console.log("In saveMasterpiece")
    // console.log(chapterFile)
    // console.log(newText)
    chapterFile["epubHtmlUrl"] = epubHtmlUrl
    const fileIndex = chapterFile["key"]
    const file_array = chapter["file_array"]
    file_array[fileIndex] = chapterFile
    // console.log(file_array)

    const refChapter = db.collection("chapters_v2").doc(chapter.chapterId);

    refChapter.update({
        "file_array": file_array
    })
    .then(function() {        
        callback()
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
}

export const saveAudioFile = (chapter, chapterFile, audioFile) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        saveAudioFileToFB2(chapter, chapterFile, audioFile, (audioUrl) => {
            saveAudioUrlToFS(chapter, chapterFile, audioUrl, () => {
                console.log("CHAPTER FILE UPDATED IN FIRESTORE")
                dispatch({ type: "UPDATE_CHAPTER", chapter })
                dispatch({ type: "UPDATE_CHAPTER2", chapter })
                dispatch({ type: "HIDE_SPINNER" })
            })
        })
    } 
}

export const getTextToSpeech = (chapter, chapterFile, voiceObject) => {
    return (dispatch, getState) => {
        // console.log("SUCCESSFULLY CALLED getTextToSpeech")
        dispatch({ type: "SHOW_SPINNER" })
        downloadText(chapterFile, (htmlText) => {
            const textOnly = stripHtml(htmlText)
            var textOnly2 = textOnly.replace(/"/g, '');
            console.log("GETTING TTS")
            console.log(textOnly.length)
            getAudio(textOnly, voiceObject, (responseText) => {
                const jsonResponse = JSON.parse(responseText)
                const base64Audio = jsonResponse.audioContent
                // console.log("HAHAHAHAHA")
                // console.log(base64Audio)
                const base64AudioWithHeader = "data:audio/mp3;base64," + base64Audio
                console.log("GOT TTS base64 File")
                // const mp3Audio = window.atob(base64Audio)  //this is for decoding the string to mp3 format
                // saveAudioFileToFB(mp3Audio)
                saveAudioFileToFB( chapter, chapterFile, base64AudioWithHeader, (audioUrl) => {
                    console.log("SAVED Audio File to Storage")
                    console.log(audioUrl)
                    console.log("CHAPTER")
                    console.log(chapter)
                    console.log("CHAPTERFILE")
                    console.log(chapterFile)
                    saveAudioUrlToFS( chapter, chapterFile, audioUrl, () => {
                        console.log("CHAPTER FILE UPDATED IN FIRESTORE")
                        dispatch({ type: "UPDATE_CHAPTER", chapter })
                        dispatch({ type: "UPDATE_CHAPTER2", chapter })
                        dispatch({ type: "HIDE_SPINNER" })
                    })
                })
            })        
        })
    } 
}

const downloadText = (chapterFile, callback) => {
    console.log("DOWNLOADING HTML TEXT")
    const http = require('http');
    const epubUrl = chapterFile.epubHtmlUrl
    http.get(epubUrl, function (res) {
        var data = [], dataLen = 0;
        res.on('data', function (chunk) {
            data.push(chunk);
            dataLen += chunk.length;
        }).on('end', function () {
            var buf = Buffer.alloc(dataLen);
            for (var i = 0, len = data.length, pos = 0; i < len; i++) {
                data[i].copy(buf, pos);
                pos += data[i].length;
            }
            const htmlText = new TextDecoder("utf-8").decode(buf)
            callback(htmlText)
        });
    });    
}


const saveAudioUrlToFS = (chapter, chapterFile, audioUrl, callback) => {
    const thisChapterId = chapter.chapterId
    chapterFile["audioUrl"] = audioUrl
    // const fileIndex = chapterFile["key"]
    const file_array = chapter["file_array"]
    const fileIndex = getFileIndex(file_array, chapterFile)
    file_array[fileIndex] = chapterFile
    // console.log("THIS PARTICULAR CHAPTER FILE")
    // console.log(thisChapterId)
    const refChapter = db.collection("chapters_v2").doc(thisChapterId);
    refChapter.update({
        "file_array": file_array
    })
    .then(function() {        
        callback()
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
}

const getFileIndex = (file_array, chapterFile) => {
    let fileId = chapterFile.fileId
    for (let i=0; i < file_array.length; i++) {
        if (file_array[i].fileId === fileId) {
            return i;
        }
    }
}

const saveAudioFileToFB = (chapter, chapterFile, mp3Audio, callback) => {
    // storagePath = books/bookId/chapterId/fileId
    const thisBookId = chapter.bookId
    const thisChapterId = chapter.chapterId
    const thisFileId = chapterFile.fileId
    const rootStoragePath = "books/" + thisBookId + "/" + thisChapterId + "/" + thisFileId + "/"
    // const rootStoragePath = "ttsAudio/"
    console.log("CHAPTER")
    console.log(chapter)
    console.log("CHAPTER FILE")
    console.log(chapterFile)
    var metadata = {
        contentType: "audio/mp3"
    }
    const storagePath = rootStoragePath + "fileAudio.mp3"

    storageRef.child(storagePath).putString(mp3Audio, "data_url").then((snapshot) => {
        snapshot.ref.getDownloadURL().then( (audioUrl) => {
            callback(audioUrl)    
        })
    }, function (err) {
        console.log("Could not save audio tts")
    })

    // storageRef.child(storagePath).put(mp3Audio, metadata).then((snapshot) => {
    //     const audioUrl = snapshot.ref.getDownloadURL()
    //     console.log("THIS IS THE AUDIO URL")
    //     console.log(audioUrl)
    // }, function (err) {
    //     console.log("Could not save audio tts")
    // })



}
const saveAudioFileToFB2 = (chapter, chapterFile, audioFile, callback) => {
    const thisBookId = chapter.bookId
    const thisChapterId = chapter.chapterId
    const thisFileId = chapterFile.fileId
    const rootStoragePath = "books/" + thisBookId + "/" + thisChapterId + "/" + thisFileId + "/"
    const storagePath = rootStoragePath + "fileAudio.mp3"
    storageRef.child(storagePath).put(audioFile).then(function(snapshot) {
        snapshot.ref.getDownloadURL().then( (audioUrl) => {
            callback(audioUrl)    
        })
    }, function (err) {
        console.log("Could not save audio tts")
    })
}


const getAudio = (textOnly, voiceObject, callback) => {
    //Example content
    //https://cloud.google.com/text-to-speech/docs/quickstart-protocol
    //https://cloud.google.com/text-to-speech/docs/reference/rest/v1/text/synthesize#SynthesisInput

    const requestContent = {
        "input": {
            "text": textOnly
        },
        'voice': voiceObject,
        'audioConfig': {
            'audioEncoding': 'MP3',
            "speakingRate": 0.75
        }
    }

    //SPEAKING RATE
    /*
    Input only. Optional. Speaking rate/speed, in the range [0.25, 4.0]. 
    1.0 is the normal native speed supported by the specific voice. 2.0 is twice as fast, and 0.5 is half as fast. 
    If unset(0.0), defaults to the native 1.0 speed. Any other values < 0.25 or > 4.0 will return an error.
    */
    // const requestContent = {
    //     "input": {
    //         "text": textOnly
    //     },
    //     'voice': {
    //         'languageCode': 'es-ES',
    //         'name': 'es-ES-Standard-A',
    //         'ssmlGender': 'FEMALE'
    //     },
    //     'audioConfig': {
    //         'audioEncoding': 'MP3'
    //     }
    // }

    //This block works for getting synthesized speech
    //https://stackoverflow.com/questions/9713058/send-post-data-using-xmlhttprequest
    var http = new XMLHttpRequest();
    var url = "https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyAJrbtpM53WhdMGU_mKgAWvcmkUuO7LbhQ"
    var params = 'orem=ipsum&name=binny';
    http.open('POST', url, true);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-Type", "application/json");

    http.onreadystatechange = function () {//Call a function when the state changes.
        if (http.readyState == 4 && http.status == 200) {
            // alert(http.responseText);
            callback(http.responseText)
        }
    }
    http.send(JSON.stringify(requestContent));
}

//This seems to work. I got it here:
//https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
const stripHtml = (html) => {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}
//This doesn't work
// extractContent = (s) => {
//     var span = document.createElement('span');
//     span.innerHTML = s;
//     return span.textContent || span.innerText;
//   }; 
//This doesn't work
// stripHtml = (html) => {
//     var tmp = document.createElement("DIV");
//     tmp.innerHTML = html;
//     return tmp.textContent || tmp.innerText || "";
// }


function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

