import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { getStudents } from '../../../store/actions/statsActions'
// import { Redirect } from 'react-router-dom'
// import { withRouter } from 'react-router-dom'  //for redirecting from a function https://tylermcginnis.com/react-router-programmatically-navigate/

class ViewStatsClassroom extends Component {
    state = {
        classroom: {},
        studentFileProgress: {
            // uid: {
            //     completedDates: {
            //         fileId: date
            //     },
            //     completedFiles: {
            //         fileId: true
            //     }
            // }
        }, 

        toUpdate: true
    }

    componentDidMount() {
        // console.log("ViewStatsClassroom did mount")
        this.getClassroom()
        console.log("VIEW STATS CLASSROOM did mount")
        console.log(this.props.state)
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("Did update VSC")
        if (prevState.classroom !== this.state.classroom) {
            // console.log("Did update VSC Classroom:")
            // console.log(this.state.classroom)
            this.props.getStudents(this.state.classroom)
            console.log("ViewStatsClassroom DID Update")
            console.log(this.state)
        }
    }

    getClassroom = () => {
        const classrooms = this.props.state.classrooms.classrooms ?
            this.props.state.classrooms.classrooms :
            {}
        const classroomId = this.props.classroomId;
        const thisClassroom = classrooms[classroomId]
        console.log("CLASSROOM SET")
        console.log(thisClassroom)
        // console.log(this.props.state)
        const studentFileProgress =  this.getStudentFileProgress(thisClassroom)
        // console.log(studentFileProgress)
        this.setState({
            classroom: thisClassroom,
            studentFileProgress //REMOVE - I think this isn't used
        })
    }


    getStudentDetailsNEW = () => {
        const classroomId = this.props.classroomId
        const studentProfilesObjs = this.state.classroom.studentProfiles ?
            this.state.classroom.studentProfiles :
            null
        const studentProfiles = studentProfilesObjs ?
            Object.values(studentProfilesObjs) :
            null
        let studentDetails = [];      
        if (studentProfiles === null) {
            studentDetails = [<tr key={"loading"}><td>Loading...</td></tr>]
        } else {
            this.getStudentStats(studentProfiles)
            for (let i = 0; i < studentProfiles.length; i++) {
                const userId = studentProfiles[i]["userId"]
                studentDetails.push(
                    <tr key={userId}>
                        <td className="summary-col-1">{studentProfiles[i]["user_name"]}</td>
                        <td className="summary-col-2">{studentProfiles[i]["lastDate"]}</td>
                        <td className="summary-col-3">{studentProfiles[i]["timeSpent"]}</td>
                        <td className="summary-col-4">
                            <Link to={"/ViewProgress/" + classroomId + "/" + userId + "/all"}>
                                {studentProfiles[i]["numActivities"]}
                            </Link>
                        </td>
                        <td className="summary-col-4">{studentProfiles[i]["percentDone"]}</td>
                        <td className="summary-col-5">
                            <Link to={"/ViewJournals/" + classroomId + "/" + userId}>
                                {studentProfiles[i]["numJournals"]}
                            </Link>
                        </td>
                        {/* <td>{studentProfiles[i]["streak"]}</td> */}
                    </tr>
                )
            }
        }
        return studentDetails
    }


    getStudentStats = (studentProfiles) => {
        const studentChapFileIds = this.getStudentChapFileIds()
        for (let i = 0; i < studentProfiles.length; i++) {
            const uid = studentProfiles[i]["userId"]
            studentProfiles[i]["numActivities"] = this.getNumActivitiesOfStudentNEW(uid, studentChapFileIds)
            studentProfiles[i]["numJournals"] = this.getNumJournalsNEW(uid)
            studentProfiles[i]["percentDone"] = this.getPercentDoneNEW(studentProfiles[i]["numActivities"], studentChapFileIds.length)
            studentProfiles[i]["timeSpent"] = this.getTimeSpentNEW(uid)
            studentProfiles[i]["lastDate"] = this.getLastDate(uid)
        }
    }

    getNumActivitiesOfStudentNEW = (userId, studentChapFileIds) => {
        const studentProgress = this.state.classroom.studentProgress ?
            this.state.classroom.studentProgress :
            {}
        const thisStudentProgress = studentProgress[userId] ?
            studentProgress[userId] :
            {}
        let completedFilesArr = []
        const studentProgressArr = Object.values(thisStudentProgress)
        for (let i = 0; i < studentProgressArr.length; i++) {
            const completedFiles = studentProgressArr[i].completedFiles ?
                studentProgressArr[i].completedFiles :
                {}
            const thisCompletedFilesArr = Object.entries(completedFiles)
            completedFilesArr.push(...thisCompletedFilesArr)
        }
        let numCompletedActivities = 0
        for (let j = 0; j < completedFilesArr.length; j++) {
            if (completedFilesArr[j][1] && studentChapFileIds.includes(completedFilesArr[j][0])) { 
                numCompletedActivities++ 
            }
        }
        return numCompletedActivities
    }

    getLastDate = (studentId) => {
        if (!this.state.classroom.studentActivity) {return 0}
        const studentActivities = this.state.classroom.studentActivity
        const allStudentActivity = studentActivities[studentId] ?
            studentActivities[studentId] :
            []
        if (allStudentActivity.length === 0) { return "" }
        //Filter the student activity array
        let studentActivity = []
        if (this.props.bookId === "all") {
            studentActivity = allStudentActivity
        } else if (this.props.chapterId === "all") {
            studentActivity = allStudentActivity.filter(thisActivity => thisActivity.bookId === this.props.bookId)
        } else {
            studentActivity = allStudentActivity.filter(thisActivity => thisActivity.chapterId === this.props.chapterId)
        }
        if (studentActivity.length === 0) { return "" }
        //sort the array in descending order
        studentActivity.sort(this.sortByTimeStamp)
        const lastTimeStamp = studentActivity[0]["timeLeave"]["seconds"]
        //format the date
        const date = this.timeConverter(lastTimeStamp)
        return date
    }
    sortByTimeStamp = (a,b) => {
        if (a.timeLeave.seconds > b.timeLeave.seconds) {
            return -1;
        }
        if (a.timeLeave.seconds < b.timeLeave.seconds) {
            return 1;
        }
        return 0;
    }
    timeConverter = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const fullYear = a.getFullYear();
        const year = fullYear.toString().substr(-2)
        let month = a.getMonth()
        var monthStr = months[a.getMonth()];
        month++ //add 1 to month because it is begins at 0
        let date = a.getDate();
        const dayStr = days[a.getDay()]
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        const time = dayStr + " " + month + "/" + date + "/" + year
        return time;
      }

    getTimeSpentNEW = (studentId) => { //started using 10/24/2019
        let totalTimeSpent = 0
        if (!this.state.classroom.studentActivity) {return 0}
        const studentActivities = this.state.classroom.studentActivity
        let thisStudentActivity = []
        const allStudentActivity = studentActivities[studentId] ?
            studentActivities[studentId] :
            []
        //Filter the student activity array
        if (this.props.bookId === "all") {
            thisStudentActivity = allStudentActivity
        } else if (this.props.chapterId === "all") {
            thisStudentActivity = allStudentActivity.filter(thisActivity => thisActivity.bookId === this.props.bookId)
        } else {
            thisStudentActivity = allStudentActivity.filter(thisActivity => thisActivity.chapterId === this.props.chapterId)
        }
        
        //Add up all time of the filtered array
        for (let i = 0; i < thisStudentActivity.length; i++) {
            const timeAccess = thisStudentActivity[i].timeAccess.seconds
            const timeLeave = thisStudentActivity[i].timeLeave.seconds
            const timeSpent = timeLeave - timeAccess
            if (timeSpent < 6) {continue}
            if (timeSpent > 600) {timeSpent = 600}
            totalTimeSpent = totalTimeSpent + timeSpent
        }

        //format the output
        if (totalTimeSpent === 0) {
            return "-"
        }
        if (totalTimeSpent < 60) {
            const timeFormatted = totalTimeSpent + " secs"
            return timeFormatted
        }
        if (totalTimeSpent < 3600) {
            const minutes = Math.floor(totalTimeSpent / 60)
            // const seconds = totalTimeSpent - (minutes * 60)
            const timeFormatted = minutes + " mins "
            return timeFormatted    
        }
        let hourLabel = " hrs "
        const hours = Math.floor(totalTimeSpent / 3600)
        const minutes = Math.floor((totalTimeSpent - (hours * 3600)) / 60)
        // const seconds = totalTimeSpent - (hours * 3600) - (minutes * 60)
        if (hours === 1) {hourLabel = " hr "}
        const timeFormatted = hours + hourLabel + minutes + " mins "
        return timeFormatted
    }


    getTimeSpentOLD = (studentId) => { //stopped using 10/24,2019
        let totalTimeSpent = 0
        if (!this.state.classroom.studentActivity) {return 0}
        const studentActivities = this.state.classroom.studentActivity
        const thisStudentActivity = studentActivities[studentId] ?
            studentActivities[studentId] :
            []
        for (let i = 0; i < thisStudentActivity.length; i++) {
            const timeAccess = thisStudentActivity[i].timeAccess.seconds
            const timeLeave = thisStudentActivity[i].timeLeave.seconds
            const timeSpent = timeLeave - timeAccess
            if (timeSpent < 6) {continue}
            if (timeSpent > 600) {timeSpent = 600}
            totalTimeSpent = totalTimeSpent + timeSpent
        }
        if (totalTimeSpent === 0) {
            return "-"
        }
        if (totalTimeSpent < 60) {
            const timeFormatted = totalTimeSpent + " secs"
            return timeFormatted
        }
        const minutes = Math.floor(totalTimeSpent / 60)
        const seconds = totalTimeSpent - (minutes * 60)
        const timeFormatted = minutes + " mins " + seconds + " secs"
        return timeFormatted
    }

    getPercentDoneNEW = (numActivitiesDone, totalActivities) => { //started using 10/24/2019
        // let percentDone = "No Classroom Activities"
        let percentDone = 0
        if (totalActivities > 0) {
            const percentDoneNum = (numActivitiesDone / totalActivities) * 100
            const percentDoneTrunc = Math.floor(percentDoneNum)
            percentDone = percentDoneTrunc + "%"
        }
        return percentDone
    }

    getPercentDoneOLD = (numActivitiesForClassroom, numActivitiesDone) => { //stopped using 10/24/2019
        let percentDone = "No Classroom Activities"
        if (numActivitiesForClassroom > 0) {
            const percentDoneNum = (numActivitiesDone / numActivitiesForClassroom) * 100
            const percentDoneTrunc = Math.floor(percentDoneNum)
            percentDone = percentDoneTrunc + "%"
        }
        return percentDone
    }

    getNumActivitiesForClassroom = (classroomId) => {
        const classrooms = this.props.state.classrooms.classrooms ?
            this.props.state.classrooms.classrooms :
            {}
        const numActivitiesForClassroom = classrooms[classroomId] ?
            classrooms[classroomId]["numActivities"] ?
                classrooms[classroomId]["numActivities"] :
                0 :
            0
        return numActivitiesForClassroom
    }

    getStudentChapFileIds = () => {
        if (this.props.bookId === "all") { 
            return this.getStudentChapFileIdsForClassroom()
        }
        if (this.props.chapterId === "all") { 
            return this.getStudentChapFileIdsForBook()
        }
        return this.getStudentChapFileIdsForChapter()
    }

    getStudentChapFileIdsForClassroom = () => {
        const classroom = this.props.state.classrooms.classrooms[this.props.classroomId] ?
            this.props.state.classrooms.classrooms[this.props.classroomId] :
            {}
        const studentChapFileIds = classroom["studentChapFileIds"] ?
            classroom["studentChapFileIds"] :
            []
        return studentChapFileIds
    }

    getStudentChapFileIdsForBook = () => {
        const book = this.props.state.books.books[this.props.bookId] ?
            this.props.state.books.books[this.props.bookId] :
            {}
        const studentChapFileIds = book["studentChapFileIds"] ?
            book["studentChapFileIds"] :
            []
        return studentChapFileIds
    }

    getStudentChapFileIdsForChapter = () => {
        const chapter = this.props.state.chapters.chapters[this.props.chapterId] ?
            this.props.state.chapters.chapters[this.props.chapterId] :
            {}
        const studentChapFileIds = chapter["studentChapFileIds"] ?
            chapter["studentChapFileIds"] :
            []
        return studentChapFileIds
    }

    getNumActivities = (studentProfiles) => {
        for (let i = 0; i < studentProfiles.length; i++) {
            const uid = studentProfiles[i]["userId"]
            studentProfiles[i]["numActivities"] = this.getNumActivitiesOfStudent(uid)
        }
    }


    getNumActivitiesOfStudentOLD2 = (userId) => {  //Stopped using this function 10/21/2019
        const classroomId = this.props.classroomId
        const bookId = this.props.bookId
        const chapterId = this.props.chapterId
        const studentProgress = this.state.classroom.studentProgress ?
            this.state.classroom.studentProgress :
            {}
        const thisStudentProgress = studentProgress[userId] ?
            studentProgress[userId] :
            {}
        const studentProgressArr = Object.values(thisStudentProgress)
        let numCompletedActivities = 0
        if (bookId === "all") { //in this case, chapterId will always ==="all" as well
            const studentChapFileIds = this.getStudentChapFileIdsForClassroom(classroomId)
            for (let i = 0; i < studentProgressArr.length; i++) {
                const completedFiles = studentProgressArr[i].completedFiles ?
                    studentProgressArr[i].completedFiles :
                    {}
                const completedFilesArr = Object.entries(completedFiles)
                for (let j = 0; j < completedFilesArr.length; j++) {
                    if (completedFilesArr[j][1] && studentChapFileIds.includes(completedFilesArr[j][0])) { 
                        numCompletedActivities++ 
                    }
                }
            }    
        } else {  //in this case, a book is selected
            for (let i = 0; i < studentProgressArr.length; i++) {
                if (studentProgressArr[i].bookId !== bookId) { continue }
                const completedFiles = studentProgressArr[i].completedFiles ?
                    studentProgressArr[i].completedFiles :
                    {}
                const completedFilesArr = Object.entries(completedFiles)
                if (chapterId === "all") { //in this case, one book and all its chapters are selected
                    const studentChapFileIds = this.getStudentChapFileIdsForBook(bookId)
                    for (let j = 0; j < completedFilesArr.length; j++) {
                        if (completedFilesArr[j][1] && studentChapFileIds.includes(completedFilesArr[j][0])) { 
                            numCompletedActivities++ 
                        }
                    }    
                } else { //in this case, a book and chapter are both selected
                    const studentChapFileIds = this.getStudentChapFileIdsForChapter(chapterId)
                    for (let j = 0; j < completedFilesArr.length; j++) {
                        if (completedFilesArr[j][1] && studentChapFileIds.includes(completedFilesArr[j][0])) { 
                            numCompletedActivities++                                 
                        }
                    }                        
                }
            }    
        }


        return numCompletedActivities
    }


    getNumActivitiesOfStudentOLD1 = (userId) => {  //Stopped using this function 10/21/2019
        const studentProgress = this.state.classroom.studentProgress ?
            this.state.classroom.studentProgress :
            {}
        const thisStudentProgress = studentProgress[userId] ?
            studentProgress[userId] :
            {}
        const studentProgressArr = Object.values(thisStudentProgress)
        let numCompletedActivities = 0
        for (let i = 0; i < studentProgressArr.length; i++) {
            const completedFiles = studentProgressArr[i].completedFiles ?
                studentProgressArr[i].completedFiles :
                {}
            const completedFilesArr = Object.values(completedFiles)
            for (let j = 0; j < completedFilesArr.length; j++) {
                if (completedFilesArr[j]) { numCompletedActivities++ }
            }
        }
        return numCompletedActivities
    }


    getNumJournalsNEW = (uid) => { //started using 10/24/2019
        const allStudentJournals = this.state.classroom.studentJournals ?
            this.state.classroom.studentJournals :
            0
        if (allStudentJournals === 0) { return 0 }
        const thisStudentJournals = allStudentJournals[uid] ?
            allStudentJournals[uid] :
            0
        if (thisStudentJournals === 0) { return 0 }
        const journalsArr = Object.values(thisStudentJournals)
        if (this.props.bookId === "all") { return journalsArr.length }
        if (this.props.chapterId === "all") {
            const filteredJournalsArr = journalsArr.filter(thisJournal => thisJournal.bookId === this.props.bookId)
            return filteredJournalsArr.length
        }
        const filteredJournalsArr = journalsArr.filter(thisJournal => thisJournal.chapterId === this.props.chapterId)
        return filteredJournalsArr.length
    }


    getNumJournalsOLD = (studentProfiles) => { //stopped using 10/24/2019
        // console.log("studentProfiles")
        // console.log(studentProfiles)
        // // convert array to an object
        // const studentProfilesObj = studentProfiles.reduce((obj, item) => (obj[item.userId] = item, obj) ,{});
        // console.log("studentProfilesObj")
        // console.log(studentProfilesObj)

        const studentJournals = this.state.classroom.studentJournals ?
            this.state.classroom.studentJournals :
            {}
        const journalsPerStudent = Object.entries(studentJournals)
        let journalsPerUid = {}
        for (let i = 0; i < journalsPerStudent.length; i++) {
            const studentId = journalsPerStudent[i][0]
            const journalsOfThisStudent = Object.values(journalsPerStudent[i][1])
            let totalNumJournals = 0
            for (let j = 0; j < journalsOfThisStudent.length; j++) {
                const thisJournal = journalsOfThisStudent[j]
                // const isBlank = (thisJournal)
                // console.log("THIS JOURNAL")
                // console.log(thisJournal)
                if (thisJournal.text && thisJournal.text !== "" && thisJournal.text !== null) {
                    totalNumJournals++
                }
            }
            // console.log("NUMBER OF JOURNALS")
            // console.log(totalNumJournals)
            journalsPerUid[studentId] = totalNumJournals
        }
        for (let i = 0; i < studentProfiles.length; i++) {
            const uid = studentProfiles[i]["userId"]
            const numJournals = journalsPerUid[uid] ?
                journalsPerUid[uid] :
                0
            studentProfiles[i]["numJournals"] = numJournals
        }
    }
    
    bookSelected = () => {
        // console.log("BOOK SELECTED")
        // console.log(this.bookSelect.value)
        const bookId = this.bookSelect.value
        this.props.history.push("/ViewStats/" + this.props.classroomId + "/" + bookId + "/all")
    }

    chapterSelected = () => {
        // console.log("CHAPTER SELECTED")
        // console.log(this.chapterSelect.value) 
        const chapterId = this.chapterSelect.value
        this.props.history.push("/ViewStats/" + this.props.classroomId + "/" + this.props.bookId + "/" + chapterId)
    }


    getBooks = () => {
        if (!this.state.classroom.books) {
            return [
                <option key={"all"} value={"all"}>
                    {"All"}
                </option>
            ]
        }
        const books = this.state.classroom.books
        
        let booksToReturn = [
            <option key={"all"} value={"all"}>
                {"All"}
            </option>
        ]
        books.forEach( (book) => {
            if (!book.title) { return }
            if (!book.bookId) { return }
            booksToReturn.push(
                <option key={book.bookId} value={book.bookId}>
                    {book.title}
                </option>
            )
        })
        return booksToReturn
    }

    getChapters = () => {
        const bookId = this.props.bookId
        if (bookId === "all") {
            return [
                <option key={"all"} value={"all"}>
                    {"All"}
                </option>
            ]    
        }

        let chapterList = [
            <option key={"all"} value={"all"}>
                {"All"}
            </option>
        ]
        const book = this.props.state.books.books[bookId]
        const chapterIdArr = book.chapterOrderArr ?
            book.chapterOrderArr :
            []
        const chapters = this.props.state.chapters.chapters ?
            this.props.state.chapters.chapters :
            {}
        for (let i = 0; i < chapterIdArr.length; i++) {
            const chapterId = chapterIdArr[i]
            if (chapters[chapterId] === undefined) { continue }
            const thisChapter = chapters[chapterId]
            if (thisChapter.isBlocked) { continue }
            const chapterTitle = thisChapter.title ?
                thisChapter.title :
                "Unknown Chapter Title"
            chapterList.push(
                <option key={thisChapter.chapterId} value={thisChapter.chapterId}>
                    {chapterTitle}
                </option>
            )
        }
        return chapterList
    }

    //REMOVE - I think this isn't used
    getStudentFileProgress = (classroom) => {
        const classroomId = classroom.classroomId
        const progress = this.props.state.progress
        console.log("VIEW STATS PROGRESS")
        console.log(progress)
        const progressArr = Object.values(progress)
        const studentFileProgressArr = progressArr.filter(thisProgress => thisProgress.classroomId === classroomId)
        // console.log(studentFileProgressArr)
        return studentFileProgressArr
    }
    

    render(){        
        // console.log("RENDER student profiles")
        // console.log(this.state.classroom.studentProfiles)
        const books = this.getBooks()
        const chapters = this.getChapters()        
        const studentNames = this.getStudentDetailsNEW()

        return (
            <div>
                <div className="statsClass-header">
                    <div>
                        <div className="progreport-title">
                            Progress Report Quick Summary:
                        </div>
                        <h2 className="progreport-title2">
                            {this.state.classroom.title}
                        </h2>
                    </div>
                    <div className="tablewrap-summary">
                        <div className="quick-summary">
                            <div className="progress-dropdownl">
                                <div className="progselect-label">Select Book</div>

                                <select
                                    className="select-css"
                                    id="mySelect"
                                    onChange={this.bookSelected}
                                    ref={ref => (this.bookSelect = ref)}
                                >
                                    {books}
                                </select>
                            </div>
                            <div className="progress-dropdownr">
                                <div className="progselect-label">Select Chapter</div>
                                <select
                                    className="select-css"
                                    id="mySelect"
                                    onChange={this.chapterSelected}
                                    ref={ref => (this.chapterSelect = ref)}
                                >
                                    {chapters}
                                </select>
                            </div>
                        </div>
                        <table className="details">
                            <thead>
                                <tr>
                                    <th className="summary-col-1">Student Name</th>
                                    <th className="summary-col-2">Last date used</th>
                                    <th className="summary-col-3">Total time spent</th>
                                    <th className="summary-col-4"># of activities marked</th>
                                    <th className="summary-col-4">% Done</th>
                                    <th className="summary-col-5"># of journal entries</th>
                                    {/* <th className="summary-col-6">Longest streak (consistent days in a row of 5 minutes)</th> */}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className="tablewrap-summary2">
                    <table className="details">
                        <tbody>
                            {studentNames}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}



    /*
    getStudentDetailsOLD = () => {  //stopped using 10/24/2019
        const classroomId = this.props.classroomId
        const studentProfilesObjs = this.state.classroom.studentProfiles ?
            this.state.classroom.studentProfiles :
            null
        const studentProfiles = studentProfilesObjs ?
            Object.values(studentProfilesObjs) :
            null
        let studentDetails = [];      
        if (studentProfiles === null) {
            studentDetails = [<tr key={"loading"}><td>Loading...</td></tr>]
        } else {
            const studentStats = this.getStudentStats(studentProfiles)
            const numActivitiesForClassroom = this.getNumActivitiesForClassroom(classroomId)
            this.getNumActivities(studentProfiles)
            this.getNumJournals(studentProfiles)
            for (let i = 0; i < studentProfiles.length; i++) {
                const userId = studentProfiles[i]["userId"]
                const percentDone = this.getPercentDone(numActivitiesForClassroom, studentProfiles[i]["numActivities"])
                const timeSpent = this.getTimeSpent(userId)
                studentDetails.push(
                    <tr key={userId}>
                        <td className="summary-col-1">{studentProfiles[i]["user_name"]}</td>
                        <td className="summary-col-2">{studentProfiles[i]["lastDate"]}</td>
                        <td className="summary-col-3">{timeSpent}</td>
                        <td className="summary-col-4">
                            <Link to={"/ViewProgress/" + classroomId + "/" + userId + "/all"}>
                                {studentProfiles[i]["numActivities"]}
                            </Link>
                        </td>
                        <td className="summary-col-4">{percentDone}</td>
                        <td className="summary-col-5">
                            <Link to={"/ViewJournals/" + classroomId + "/" + userId}>
                                {studentProfiles[i]["numJournals"]}
                            </Link>
                        </td>
                        // <td>{studentProfiles[i]["streak"]}</td>
                    </tr>
                )
            }
        }
        return studentDetails
    }
    */

    



const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStudents: (classroom) =>  dispatch(getStudents(classroom)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewStatsClassroom)



