import React from 'react'


const Copyright = () => {
    return (
        <div>


        <p dir="ltr"><span>Copyright Policy</span></p>
<p dir="ltr"><span>Last Modified: January 10, 2019</span></p>
<p dir="ltr"><span>Reporting Claims of Copyright Infringement</span></p>
<p dir="ltr"><span>S&amp;S Apps, LLC takes claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from this site (the "</span><span>Website</span><span>") infringe your copyright, you may request removal of those materials (or access to them) from the Website by submitting written notification to our copyright agent designated below. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. &sect; 512) ("</span><span>DMCA</span><span>"), the written notice (the "</span><span>DMCA Notice</span><span>") must include substantially the following:</span></p>
<ul>
<li dir="ltr">
<p dir="ltr"><span>Your physical or electronic signature.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>A statement that the information in the written notice is accurate.</span></p>
</li>
<li dir="ltr">
<p dir="ltr"><span>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</span></p>
</li>
</ul>
<p dir="ltr"><span>Our designated copyright agent to receive DMCA Notices is:</span></p>
<p dir="ltr"><span>Aaron Stephens</span><span><br /></span><span>S&amp;S Apps, LLC</span><span><br /></span><span>5000 Windplay Drive, Suite 3-106</span><span><br /></span><span>El Dorado Hills, CA 95762</span><span><br /></span><span>916-704-3717</span><span><br /></span><span>info@storylabslanguage.com</span></p>
<p dir="ltr"><span>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.</span></p>
<p dir="ltr"><span>Please be aware that if you knowingly materially misrepresent that material or activity on the Website is infringing your copyright, you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</span></p>
<p dir="ltr"><span>Repeat Infringers</span></p>
<p dir="ltr"><span>It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.</span></p>


        </div>
    )
}


export default Copyright;