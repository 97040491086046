import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClassContainer from '../containers/ClassContainer'
import ClassList from '../containers/ClassList'
import { getStudentBooks } from '../../store/actions/retrieveClassBookActions'
import { getTeacherBooks, saveNewClassroom, saveNewPaForHeart } from '../../store/actions/myClassroomsActions'
import { loginActivity, recordMount, recordWillUnmount } from '../../store/actions/userActivityActions'
import { closeNoBookCodeAlert, resetBooksAddedForCode } from '../../store/actions/createClassActions'
import { Redirect } from 'react-router-dom' //For route guarding. Lesson 27
import Footer from "../../components/ui/navbar/Footer"

class ClassPage extends Component {
    
    state = {
        displayAddClassForm: false,
        newClassTitle: "",
    }
    componentDidMount() {
        const authFB = this.props.state.firebase.auth
        if (authFB.uid) { //If this is true, then the user is logged in
            this.props.recordMount("Classroom");
            if (authFB.isAnonymous) {
                // this.props.getStudentBooks()
            } else {
                this.props.getTeacherBooks()
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const prevBooksAdded = prevProps.state.books.booksAddedForCode
        const currBooksAdded = this.props.state.books.booksAddedForCode
        if ( !prevBooksAdded && currBooksAdded ) {  //If books were added with a code, then reload the teacher books
            this.props.getTeacherBooks()
        }

        if (this.state.displayAddClassForm) {
            this["newClassInput"].focus()
        }
        const prevClassrooms = prevProps.state.myClassrooms.myClassrooms
        const nowClassrooms = this.props.state.myClassrooms.myClassrooms
        if (!prevClassrooms || !nowClassrooms) { return }
        const prevClassroomsLength = Object.keys(prevClassrooms).length
        const nowClassroomsLength = Object.keys(nowClassrooms).length
        if (nowClassroomsLength > prevClassroomsLength) {
            this.setState({ 
                displayAddClassForm: false,
                newClassTitle: ""
            })
        }
    }
    componentWillUnmount() {
        if (this.props.auth.uid) {
            this.props.recordWillUnmount("Classroom");
        }
    }


    // >> new class title form events    
    clickedAddClassroom = () => {
        this.setState ({displayAddClassForm: true})
    }
    clickedCloseClassForm = () => {
        this.setState ({displayAddClassForm: false})
    }
    handleChangeClassTitle = (e) => {
        const targetValue = e.target.value
        this.setState ({
            newClassTitle: targetValue
        })
    }
    handleChangeEmailForHeart = (e) => {
        const targetValue = e.target.value
        this.setState ({
            emailForHeart: targetValue
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.clickedSaveNewClass()
    }
    handleSubmit2 = (e) => {
        e.preventDefault();
        // this.clickedSaveNewClass()
    }

    clickedSaveNewClass = () => {
        console.log(this.state.newClassTitle)
        if (this.state.newClassTitle === "") {return}
        //check for duplicate title
        const classroomsObj = this.props.state.myClassrooms.myClassroomsAdult
        if (classroomsObj) { 
            const classrooms = Object.values(classroomsObj)
            for (let i=0; i < classrooms.length; i++) {
                if (classrooms[i].title === this.state.newClassTitle) { return } //Duplicate title exists. Exit function.
            }             
        }
        this.props.saveNewClassroom(this.state.newClassTitle)
    }

    clickedSavePaForm = () => {
        console.log(this.state.emailForHeart)
        this.props.saveNewPaForHeart(this.state.emailForHeart)
    }
    clickedCloseAddedBooksDisplay = () => {
        this.props.resetBooksAddedForCode()
    }
    clickedCloseNoBookCodeAlert = () => {
        this.props.closeNoBookCodeAlert()
    }

    //Render functions
    getAddClassForm = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>New Classroom Title</div>
                    <div className="formnewclass-myclassrooms">
                        <form onSubmit={this.handleSubmit} >
                            <input
                                className="inputnewclass-myclassrooms"
                                type="text"
                                onChange={this.handleChangeClassTitle}
                                value={this.state.newClassTitle}
                                ref={ref => (this["newClassInput"] = ref)}
                            />
                        </form>
                    </div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseClassForm} >
                        <span>Close</span>
                    </button>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedSaveNewClass} >
                        <span>Save</span>
                    </button>
                </div>
            </div>
        )
    }

    getPaForm = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>Email Address for Heart Curriculum</div>
                    <div className="formnewclass-myclassrooms">
                        <form onSubmit={this.handleSubmit2} >
                            <input
                                className="inputnewclass-myclassrooms"
                                type="text"
                                onChange={this.handleChangeEmailForHeart}
                                value={this.state.emailForHeart}
                                ref={ref => (this["newClassInput"] = ref)}
                            />
                        </form>
                    </div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseClassForm} >
                        <span>Close</span>
                    </button>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedSavePaForm} >
                        <span>Save</span>
                    </button>
                </div>
            </div>
        )
    }

    getAddedBooksWithCode = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>You successfully added books to your library.</div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseAddedBooksDisplay} >
                        <span>Close</span>
                    </button>
                </div>
            </div>
        )
    }

    getNoBookCodeAlert = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>This book code doesn't exist</div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseNoBookCodeAlert} >
                        <span>Close</span>
                    </button>
                </div>
            </div>
        )
    }

    render(){
        let addedBooksDisplay = null
        if (this.props.state.books.booksAddedForCode) {
            addedBooksDisplay = this.getAddedBooksWithCode()
        } 
        let noBookCodeAlert = null
        if (this.props.state.books.displayNoBookCode) {
            noBookCodeAlert = this.getNoBookCodeAlert()
        } 


        const { classroomsObj, auth, user } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />

        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )


        if (this.props.state.books.booksLoaded) {
            let addClassForm = null
            let createPaForm = null
            if (this.state.displayAddClassForm) { 
                addClassForm = this.getAddClassForm() 
            }
            if (this.props.state.auth.uid === "9dVqqVAwzGb0svaCUOYzNjAH3s02") {
                createPaForm = this.getPaForm()
            }            

            const booksPurchased = this.props.state.books.booksPurchased
            const myClassrooms = this.props.state.myClassrooms.myClassrooms
            const myClassroomsArr = Object.values(myClassrooms)
            myClassroomsArr.sort((a, b) => (a.title > b.title) ? 1 : -1)
            
            toDisplay = (
                <div>
                    {addedBooksDisplay}
                    {addClassForm}
                    {noBookCodeAlert}
                    
                    {/* {createPaForm} */}
                    <div className="classroom-container">
                        <div className="classlistcontainer-classpage">
                            <h2 className="listtitle-classpage">My Classrooms</h2>
                            <div className="btn-oneclassroom btnaddclassroom-oneclassroom" onClick={this.clickedAddClassroom} >
                                    <span>Add Classroom</span>
                            </div>
                            <div className="classlist-classpage">
                                <ClassList classroomsArr={myClassroomsArr} />
                            </div>    
                        </div>
                        <div className="booklistcontainer-classpage">
                            <h2 className="listtitle-classpage">My Books</h2>
                            <div className="booklist-classpage">
                                <ClassContainer booksPurchased={booksPurchased} />
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            )
        }
        return toDisplay
    }
}

const mapStateToProps = (state) => {
    return {
        classroomsObj: state.classrooms,
        auth: state.firebase.auth,
        user: state.auth,
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeacherBooks: () => dispatch(getTeacherBooks()), 
        getStudentBooks: () => dispatch(getStudentBooks()),
        saveNewClassroom: (newClassTitle) =>  dispatch(saveNewClassroom(newClassTitle)),
        loginActivity: () => dispatch(loginActivity()),
        recordMount: (page) => dispatch(recordMount(page)),
        recordWillUnmount: (page) => dispatch(recordWillUnmount(page)),
        saveNewPaForHeart: (email) => dispatch(saveNewPaForHeart(email)),
        resetBooksAddedForCode: () => dispatch(resetBooksAddedForCode()),
        closeNoBookCodeAlert: () => dispatch(closeNoBookCodeAlert())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassPage)  //mapDispatchToProps has to be the second parameter.  If there is no mapStateToProps, use null as the first parameter. 
