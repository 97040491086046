import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from "react-router-dom"
import { addBookToCart } from '../../../store/actions/storeAction'

class StoreBookCard extends Component {


    // style='height: 100%; width: 100%; object-fit: contain'/>
    clickedCart = () => {
        console.log("You clicked me!")
        addBookToCart("Book1", 5)
    }

    render(){

    const imgStyle = {
        maxHeight: '150px',
        maxWidth: 'auto',
        // maxHeight: 'auto',
        // maxWidth: '100%',
        // objectFit: 'contain',
    };

    let authorBooks = this.props.authorBooks;
    // console.log("AUTHOR BOOKS")
    // console.log(authorBooks)


    let bookList = ["No Books"]

    if (authorBooks.author !== "isEmpty") {
        

        // authorBooks.books.push('nobook');
        const allBooks = this.props.state.books.books;
        const authorBooksDetails = authorBooks.books.map(bookId => {
            // if (allBooks[bookId] !== undefined) {
                return (
                    allBooks[bookId]
                )    
            // }
        })
        // console.log("ALL BOOKS")
        // console.log(authorBooksDetails)

        // if (1 === 2) {
            bookList = authorBooksDetails.map(book => {
                const coverUrl = book && book.bookCoverImage ? book.bookCoverImage.firebase_storage_url : null;
                const price = book.bookPackagePricing ? book.bookPackagePricing.book : "";
                // console.log("BOOK PRICE")
                // console.log(price)
                return (
                    <div className="col s4 l3" key={book.bookId}>
                        <div className="card medium">
                            {/* <Link to={'/Story/'+classroomId+'/'+book.bookId+'/chapter'}> */}
                            <div className="card-image">
                                <img src={coverUrl} alt={book.title} />
                                {/* <span className="card-title">{book.title}</span> */}
                                {/* <a className="btn-floating halfway-fab waves-effect waves-light red"><i className="material-icons">add</i></a> */}
                            </div>
                            {/* </Link> */}
                            <div className="card-content center-align">
                                {/* <span className="card-title">{book.title}</span> */}
                                <p>{book.title}</p>
                                <p>{"$"+price}</p>
                            </div>
                            <div className="card-action center-align">
                                <a href="#" onClick={ () => {this.clickedCart()}}>
                                    <i className="material-icons">add_shopping_cart</i>
                                    {/* <i className="material-icons">remove_shopping_cart</i> */}
                                </a>
                            </div>
                        </div>
                    </div>
                )
            })
        }

    

    return (
        <div>{bookList}</div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBookToCart: (bookId, qty) => dispatch(addBookToCart(bookId, qty))
    }
}


// export default connect(mapStateToProps, mapDispatchToProps)(StoreBookCard)
export default connect(mapStateToProps, mapDispatchToProps)(StoreBookCard)