import firebase from '../../config/fbConfig'
const db = firebase.firestore()


export const saveJournal = (newContent) => {
    return (dispatch, getState) => {
        const authSL = getState().auth
        let uid = ""
        let journalCollection = ""
        if (authSL.uid) {
            uid = authSL.uid
            journalCollection = db.collection("chapterJournals")
        } else {
            uid = authSL.uidStudent
            journalCollection = db.collection("journalsStudent")
        }
    
        let chapterJournalUpdate = {
            bookId: newContent.bookId,
            chapterId: newContent.chapterId,
            uid: uid,
            htmlText: newContent.htmlText,
            quillDelta: JSON.stringify(newContent.delta),
            text: newContent.text,
            updatedOn: new Date(),  //this field syncs with the iOS app
            updateDevice: "web"
        }

        if (authSL.uidStudent) {
            chapterJournalUpdate["classroomId"] = authSL.classroomId
        }

        if (newContent.chapterJournalId !== null) {
            const refJournal = journalCollection.doc(newContent.chapterJournalId);
            refJournal.update(chapterJournalUpdate)
            .then(function() {
                console.log("Journal successfully overwritten!");
                dispatch({ type: "UPDATE_JOURNAL_FOR_BOOK", chapterJournalUpdate })
            })
            .catch(function(error) {
                console.error("Error updating journal: ", error);
            });    
        } else {
            journalCollection.add(chapterJournalUpdate)
            .then(function(docRef) {        
                console.log("Journal successfully newly written!");
                console.log("NEW JOURNAL ID")
                console.log(docRef.id)
                chapterJournalUpdate["chapterJournalId"] = docRef.id
                dispatch({ type: "UPDATE_JOURNAL_FOR_BOOK", chapterJournalUpdate })
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        }
    }
}


//This function made old on 3/26/20. Can probably be deleted.
export const saveJournalOLD = (classroomId, chapter, newContent) => {
    return (dispatch, getState) => {
        
        let uidLabel = "uid"
        let uid = getState().auth.uid
        if (!uid) {
            uidLabel = "uidStudent"
            uid = getState().auth.uidStudent
        }
        
        let chapterJournal = chapter.journal ?
            chapter.journal :
            {
                bookId: chapter.bookId,
                chapterId: chapter.chapterId,
                // classroomId: classroomId,
                [uidLabel]: uid
            }

        chapterJournal = {
            ...chapterJournal,
            // classroomId: classroomId,
            htmlText: newContent.htmlText,
            quillDelta: JSON.stringify(newContent.delta),
            text: newContent.text,
            updatedOn: new Date(),
            updateDevice: "web"
        }
        console.log("THIS HERE IS BEING SAVED")
        console.log(chapterJournal)
        if (chapterJournal.chapterJournalId) {
            console.log("JOURNAL ID")
            console.log(chapterJournal.chapterJournalId)
            const refJournal = db.collection("chapterJournals").doc(chapterJournal.chapterJournalId);
            refJournal.update({
                ...chapterJournal
            })
            .then(function() {        
                console.log("Journal successfully overwritten!");
                console.log(chapterJournal)
                chapter.journal = chapterJournal
                dispatch({ type: "Testing" })
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });    
        } else {
            console.log("HERE HERE HERE")
            const refJournal = db.collection("chapterJournals")
            refJournal.add({
                ...chapterJournal
            })
            .then(function(docRef) {        
                console.log("Journal successfully newly written!");
                chapterJournal["chapterJournalId"] = docRef.id
                chapter["journal"] = chapterJournal
                dispatch({ type: "Testing" })
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
            });
        }
    }
}


export const saveJournalPic = (chapter, newContent) => {
    return (dispatch, getState) => {

    }
}
