import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveBookDetails } from '../../../store/actions/publisherActions'
import { uploadCover } from '../../../store/actions/publisherActions'
import { updateBookChapterOrderArr } from '../../../store/actions/publisherActions'

class ModalUploadCsv extends Component {

    // Props passed to this class
    // book
    // chapters
    // toggleShowModalSetBookCover

    state = {}

    componentDidMount() {}

    componentDidUpdate(prevProps) {}

    componentWillUnmount() {}

    //HELPER FUNCTIONS


    // RENDER FUNCTIONS


    render() {
        return (
            <div className="ajs-modal">
                <div className="bd-modalcontent">
                    <div>
                        Upload a List of Students
                    </div>
                    <div>
                        Upload a .csv file with two columns. The first column will consist of student names. The second column will be the passwords. The first row of the table will be a header row labeled Names and Passwords.
                    </div>
                    <div>
                        <link rel="stylesheet" href=""/>
                        Download a Template File
                    </div>

                    <label className="btn-oneclassroom">
                        <input className="hide-input" type="file" accept="text/csv" onChange={this.uploadCsv} />
                        <div className="center">
                            <span>Choose a File to Upload</span>
                        </div>
                    </label>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUploadCsv)

