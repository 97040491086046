import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { ReactReader } from 'react-reader';
// import { EpubView } from "react-reader";
import M from 'materialize-css'
import AdmZip from 'adm-zip' //https://stackoverflow.com/questions/10359485/how-to-download-and-unzip-a-zip-file-in-memory-in-nodejs
import { saveMasterpiece } from '../../../store/actions/publisherActions'
import { epubToHtml } from '../../../store/actions/epubActions'


class ShowEpubPub extends Component {
    
    state = {
        htmlToShow: "Yo yo yo",
    }
    
    saveMasterpiece = (e) => {
        const chapter = this.props.chapter
        const chapterFile = this.props.chapterFile
        const newText = this.state.htmlToShow
        this.props.saveMasterpiece(chapter, chapterFile, newText)
    }


    //https://stackoverflow.com/questions/10359485/how-to-download-and-unzip-a-zip-file-in-memory-in-nodejs
    http = require('http'); 
    getUnzipEpub = (file_url, callback) => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/"
        this.http.get(proxyurl + file_url, function (res) {
            // res.setHeader('Access-Control-Allow-Origin', '*')
            var data = [], dataLen = 0;

            res.on('data', function (chunk) {
                data.push(chunk);
                dataLen += chunk.length;

            }).on('end', function () {
                var buf = Buffer.alloc(dataLen);

                for (var i = 0, len = data.length, pos = 0; i < len; i++) {
                    data[i].copy(buf, pos);
                    pos += data[i].length;
                }

                var zip = new AdmZip(buf);
                var zipEntries = zip.getEntries();
                
                console.log("ZIP")
                console.log(zip)
                console.log("ZIP ENTRIES")
                console.log(zipEntries)
                
                const entry6Data = zipEntries[6].getData()

                // https://stackoverflow.com/questions/13950865/javascript-render-png-stored-as-uint8array-onto-canvas-element-without-data-uri
                // https://stackoverflow.com/questions/34389649/javascript-blob-document-with-html-tags-to-be-saved-with-formatting
                // https://stackoverflow.com/questions/50620821/uint8array-to-image-in-javascript/50621529
                
                const blob = new Blob([entry6Data], {'type': 'image/png'});
                console.log("Entry 6")
                const thisurl = URL.createObjectURL(blob)
                console.log(blob)
                // console.log(zip)
                // zip.extractAllTo(/*target path*/"/Macintosh_HD⁩/Users⁩/aaron⁩/Desktop⁩/unzippedStuff/here", /*overwrite*/true);

                const xhtmltext = zip.readAsText(zipEntries[3])
                // console.log(xhtmltext)


                callback(xhtmltext)



                for (var i = 0; i < zipEntries.length; i++) {
                    // console.log("Entry")
                    // console.log(zipEntries[i])
                    if (zipEntries[i].entryName.match(/readme/))
                        console.log("ENTRIES")
                        console.log(zipEntries[i].entryName)
                        // console.log(zip.readAsText(zipEntries[i]));
                }
            });
        });
    }

    showEpubFromUrl = () => {
        // console.log("1")

        const chapter = this.props.chapter
        const chapterFile = this.props.chapterFile
        const url = this.props.fileUrl

        this.props.epubToHtml(chapter, chapterFile, url)
        // console.log("100")

        // this.getUnzipEpub(url, (htmlText) => {
        //     this.setState({
        //         htmlToShow: htmlText
        //     })    
        // });
    }

    downloadEpub = () => {
        const url = this.props.fileUrl
        this.getUnzipEpub(url, (htmlText) => {
            this.setState({
                htmlToShow: htmlText
            })    
        });
    }

    showAlert = () => {
        alert("Hello! I am an alert box!!")
    }

    componentDidMount() {
        console.log("ShowEpub DidMount")
        // var images = document.querySelectorAll('.materialboxed')
        // console.log("IMAGES")
        // console.log(images)
        // const options = {};
        // M.Materialbox.init(images, options);
        // const url = this.props.fileUrl
        // console.log("URL")
        // console.log(url)
        // this.getUnzipEpub(url, (htmlText) => {
        //     this.setState({
        //         htmlToShow: htmlText
        //     })    
        // });
    }

    componentDidUpdate(prevProps) {
        console.log("ShowEpub DidUpdate!!!!!")
        // if (this.props.state.chapters.showEpubAlert) {
        //     this.showAlert()
        // }

        // Typical usage (don't forget to compare props):
        // if (this.props.userID !== prevProps.userID) {
        //     this.fetchData(this.props.userID);
        // }
    }

    render(){

        // const StreamZip = require('node-stream-zip');
        // console.log("STREAMZIP")
        // console.log(StreamZip)
        // const fileUrl = this.props.fileUrl;
        // console.log("FILE URL")
        // console.log(fileUrl);
        // const localFilePath = "../../../../public/img/VersionD2.cce5 (7).epub"
        // const zip = new StreamZip({
        //     file: fileUrl,
        //     storeEntries: true
        // });        
        // // Handle errors
        // zip.on('error', err => { 
        //     console.log("ERROR")
        //     console.log(err)
        //  });

        const epubHtmlUrl = this.props.chapterFile.epubHtmlUrl
        console.log("EPUB html url")
        console.log(epubHtmlUrl)
        
        const epub = (
            <div style={{ position: "relative", height: "100%" }}>
                
                    {/* <button className="btn orange lighten-1 z-depth-2" onClick={this.saveMasterpiece}> */}
                        {/* Save File */}
                            {/* </button> */}
                    <button className="btn purple lighten-1 z-depth-2" onClick={this.showEpubFromUrl}>
                        Show epub from URL
                            </button>
                    {/* <button className="btn blue lighten-1 z-depth-2" onClick={this.downloadEpub}> */}
                        {/* Download epub File */}
                            {/* </button> */}

                
                {/* <div>{this.state.htmlToShow}</div> */}
                {/* <div dangerouslySetInnerHTML={{__html: this.state.htmlToShow}}></div> */}
                <div className="container">
                <div className="row">
                    <div className="video-container">
                        {/* <iframe height='100%' width='100%' scrolling='no' allowFullScreen webkitallowfullscreen="true" src={fileUrl}></iframe> */}
                        <iframe src={epubHtmlUrl} frameBorder="0" allowFullScreen></iframe>
                        {/* <iframe width="853" height="700" src={fileUrl} frameBorder="0" allowFullScreen></iframe> */}
                    </div>
                </div>
                </div>
            </div>
        )

        return (
            epub
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveMasterpiece: (chapterFile, newText, chapter) => dispatch(saveMasterpiece(chapterFile, newText, chapter)),
        epubToHtml: (chapter, chapterFile, epubUrl) => dispatch(epubToHtml(chapter, chapterFile, epubUrl))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowEpubPub)





