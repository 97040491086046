import authReducer from './authReducer'
import classReducer from './classReducer'
import bookReducer from './bookReducer'
import chapterReducer from './chapterReducer'
import chapterFileReducer from './chapterFileReducer'
import storeReducer from './storeReducer'
import activityReducer from './activityReducer'
import storyReducer from './storyReducer'
import progressReducer from './progressReducer'
import journalReducer from './journalReducer'
import myClassroomsReducer from './myClassroomsReducer'
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase' //Lesson 22
import adminReducer from '../../components/pages/adminPages/reducerAdmin'
import reportReducer from './reportReducer'
import reportJournalReducer from './reportJournalReducer'

// const rootReducer = combineReducers({
//     auth: authReducer,
//     classrooms: classReducer,
//     books: bookReducer,
//     chapters: chapterReducer,
//     chapterFiles: chapterFileReducer,
//     store: storeReducer,
//     story: storyReducer,
//     activity: activityReducer,
//     firebase: firebaseReducer,
//     progress: progressReducer,
//     studentJournals: journalReducer,
//     myClassrooms: myClassroomsReducer,
// });

const appReducer = combineReducers({
    auth: authReducer,
    classrooms: classReducer,
    books: bookReducer,
    chapters: chapterReducer,
    chapterFiles: chapterFileReducer,
    store: storeReducer,
    story: storyReducer,
    activity: activityReducer,
    firebase: firebaseReducer,
    progress: progressReducer,
    studentJournals: journalReducer,
    myClassrooms: myClassroomsReducer,
    adminData: adminReducer,
    reports: reportReducer,
    reportsJournal: reportJournalReducer,
});

const rootReducer = (state, action) => { //This is used to clear the state on logou. Found here: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    if (action.type === 'USER_LOGOUT') {
      state = undefined
    }
    return appReducer(state, action)
}


export default rootReducer
