import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import M from 'materialize-css'
import { getQuillFile } from '../../../store/actions/retrieveClassBookActions'
import { saveNewMasterpiece } from '../../../store/actions/publisherActions'
import { updateQuillChapterFile, deleteChapterFile } from '../../../store/actions/publisherActions'


class ShowQuill extends Component {

    state = { 
        text: "",
        quillHtml: ""
    }

    componentDidMount() {
        // console.log("ShowQuill DidMount")
    }

    componentDidUpdate(prevProps) {
        // console.log("ShowQuill DidUpdate")
        // console.log(this.props.chapterFile.htmlText)
    }

    render(){
        const htmlText = this.props.chapterFile ? this.props.chapterFile.htmlText : ""
        const quill = (
            <div className="epub-center">
                <div className="display-center">
                    <div className="display-box shadow">
                        <div className="disable-select">
                            <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
        return (
            quill
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getQuillFile: (quillId) => dispatch(getQuillFile(quillId)),
        saveNewMasterpiece: (fileName, text, chapter) => dispatch(saveNewMasterpiece(fileName, text, chapter)),
        updateQuillChapterFile: (chapter, chapterFile, quillDelta, htmlText) => dispatch(updateQuillChapterFile(chapter, chapterFile, quillDelta, htmlText)),
        deleteChapterFile: (chapterFile, chapter) => dispatch(deleteChapterFile(chapterFile, chapter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowQuill)


