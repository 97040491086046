import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createOrders } from '../adminPages/actionsAdmin'


class CreateOrdersModal extends Component {
    state = {
        bookFilter: "",
        bookIdsToAdd: {
            // bookId: true
        },
        createTime: new Date().toLocaleDateString("en-CA"),
        daysPurchased: "365",
        expirationDate: "",
        purchaseOrderId: "",
    }
    componentDidMount() {
        this.updateExpirationDate()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.daysPurchased !== this.state.daysPurchased || prevState.createTime !== this.state.createTime) {
            this.updateExpirationDate()
        }
        // console.log("UPDATED")
        // console.log(this.state.createTime)
        // console.log( typeof this.state.createTime)
        // console.log( new Date() )
        // console.log( typeof new Date() )
        // console.log( Date(this.state.createTime))
        // console.log( typeof Date(this.state.createTime) )
    }


    // Action Functions
    clickedAddBook = (bookId) => {
        this.setState({
            bookIdsToAdd: {
                ...this.state.bookIdsToAdd,
                [bookId]: true
            }
        })
    }

    clickedRemoveBook = (bookId) => {
        this.setState({
            bookIdsToAdd: {
                ...this.state.bookIdsToAdd,
                [bookId]: false
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    clickedCreateOrders = () => {
        const bookIdsAll = Object.entries(this.state.bookIdsToAdd)
        const books = []
        bookIdsAll.forEach( (bookIdArr) => {
            if ( bookIdArr[1] ) { books.push(bookIdArr[0]) }
        })
        this.props.createOrders(this.props.selectedUser.userId, this.state.purchaseOrderId, new Date(this.state.createTime), parseInt(this.state.daysPurchased, 10), books)
        this.props.clickedAddOrder()
    }


    // Helper Functions

    updateExpirationDate = () => {
        const dateTodayMS = Date.parse(new Date(this.state.createTime))
        const purchasedMS = this.state.daysPurchased * 86400 * 1000
        const dateExpirationMS = dateTodayMS + purchasedMS
        const expirationDate = new Date(dateExpirationMS).toLocaleDateString("en-CA")
        this.setState({ expirationDate })
    }

    filterAuthorTitle = (book) => {
        const bookFilter = this.state.bookFilter.toLowerCase()
        const title = book.title.toLowerCase()
        const author = book.book_author.toLowerCase()
        let isInTitle = true
        let isInAuthor = true
        if (bookFilter !== "") {
            isInTitle = title.includes(bookFilter)
            isInAuthor = author.includes(bookFilter)
        }

        const isSelected = this.state.bookIdsToAdd[book.bookId]

        const isAlreadyOrdered = this.props.state.adminData.orders.find( ({ itemId }) => itemId === book.bookId ) ? true : false
        
        return (isInTitle || isInAuthor) && !isSelected && !isAlreadyOrdered
    }

    compareAuthorTitle = (a,b) => {
        const aAuthor = a.book_author
        const bAuthor = b.book_author
        const aTitle = a.title
        const bTitle = b.title

        if (aAuthor === bAuthor) {
            return (aTitle < bTitle) ? -1 : (aTitle > bTitle) ? 1 : 0;
        }
        else
        {
            return (aAuthor < bAuthor) ? -1 : 1;
        }        
    }


    // Render Functions

    getBookCovers = () => {
        const allBooks = this.props.state.adminData.allBooks || {}
        const allBooksArrUnfiltered = Object.values(allBooks)
        let allBooksArr = allBooksArrUnfiltered
        allBooksArr = allBooksArrUnfiltered.filter(this.filterAuthorTitle)

        allBooksArr.sort(this.compareAuthorTitle)
        

        const bookCovers = allBooksArr.map((book, i) => {
            const bookCoverUrl = book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url ?
                    book.bookCoverImage.firebase_storage_url :
                    "" :
                ""
            const bookTitle = book.title ?
                book.title :
                ""

            return (
                <div className="grid-item-oneclassroom ao-bookcover" key={book.bookId} onClick={() => this.clickedAddBook(book.bookId)}  >
                    <div className="bookitemcontainer-oneclassroom">
                        <div className="bookimagecontainer-oneclassbooks">
                            <img className="bookimage-oneclassroom" src={bookCoverUrl} alt="Book Cover" />
                        </div>
                        <div className="booktitle-oneclassbooks">
                            {bookTitle}
                        </div>
                    </div>
                </div>
            )
        })

        return bookCovers
    }

    getSelectedBooks = () => {
        const allBooks = this.props.state.adminData.allBooks || {}

        const bookIdsToAdd = Object.keys(this.state.bookIdsToAdd)
        const allBooksArr = []
        bookIdsToAdd.forEach( (bookId) => {
            if (this.state.bookIdsToAdd[bookId]) {
                allBooksArr.push(allBooks[bookId])
            }
        })

        allBooksArr.sort(this.compareAuthorTitle)
        
        const bookCovers = allBooksArr.map((book, i) => {
            const bookCoverUrl = book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url ?
                    book.bookCoverImage.firebase_storage_url :
                    "" :
                ""
            const bookTitle = book.title ?
                book.title :
                ""

            return (
                <div className="grid-item-oneclassroom ao-bookcover" key={book.bookId} onClick={() => this.clickedRemoveBook(book.bookId)}  >
                    <div className="bookitemcontainer-oneclassroom">
                        <div className="bookimagecontainer-oneclassbooks">
                            <img className="bookimage-oneclassroom" src={bookCoverUrl} alt="Book Cover" />
                        </div>
                        <div className="booktitle-oneclassbooks">
                            {bookTitle}
                        </div>
                    </div>
                </div>
            )
        })

        return bookCovers
    }

    getOrderDetails = () => {
        return (
            <div className="ao-orderdetails">
                <div className="ao-orderlabels">Order Date</div>
                <div className="ao-search-box">
                    <input
                        autoComplete="off"
                        className="input-myclassrooms"
                        type="date"
                        id="createTime"
                        onChange={this.handleChange}
                        value={this.state.createTime}
                    />
                </div>
                <div className="ao-orderlabels">Number of Days</div>
                <div className="ao-search-box">
                    <input
                        autoComplete="off"
                        className="input-myclassrooms"
                        type="number"
                        id="daysPurchased"
                        onChange={this.handleChange}
                        value={this.state.daysPurchased}
                    />
                </div>
                <div className="ao-orderlabels">Expiration Date</div>
                <div className="ao-search-box">
                    <input
                        autoComplete="off"
                        disabled 
                        className="input-myclassrooms ao-disabledinput"
                        type="date"
                        id="expirationDate"
                        onChange={this.handleChange}
                        value={this.state.expirationDate}
                    />
                </div>
                <div className="ao-orderlabels">Purchase Order Id</div>
                <div className="ao-search-box">
                    <input
                        autoComplete="off"
                        className="input-myclassrooms"
                        type="text"
                        id="purchaseOrderId"
                        onChange={this.handleChange}
                        value={this.state.purchaseOrderId}
                    />
                </div>
            </div>
        )
    }


    render(){

        return (
            <div className="ajs-modal">
                <div className="ao-ordersmodal">
                <div className="ao-closeicon" onClick={this.props.clickedAddOrder}></div>

                    <div className="header-oneclassbooks">
                        <div className="ao-username"> { this.props.selectedUser.user_name } </div>
                        <span>Create Orders</span>
                    </div>
                    <div className="ao-topbox">
                        <div className="ao-orderedbookswrapper">
                            <div className="ao-orderedbooks">
                                <div className="grid-row-oneclassroom">
                                    {this.getSelectedBooks()}
                                </div>
                            </div>
                        </div>
                        <div className="ao-orderdetailswrapper">
                            {this.getOrderDetails()}
                        </div>
                    </div>
                    <br />
                    <div className="header-oneclassbooks">
                        <div className="ao-filterbooks">
                            <input
                                autoComplete="off"
                                className="ao-input-myclassrooms"
                                type="text"
                                id="bookFilter"
                                onChange={this.handleChange}
                                value={this.state.bookFilter}
                            />
                        </div>
                        <span>Available Books</span>
                    </div>
                    <div className="classbooksbox-oneclassbooks ao-allbooksbox">
                        <div className="grid-row-oneclassroom">
                            {this.getBookCovers()}
                        </div>
                    </div>
                    <button className="btn-oneclassroom ao-savebtn" onClick={this.clickedCreateOrders} >
                        <span>CREATE ORDERS</span>
                    </button>

                </div>
            </div>
        )


        let toDisplay = (
            <div className="ajs-modal">
                <div className="modalcontent-oneclassbooks">
                    <div className="loader"></div>
                </div>
            </div>
        )
        if (this.props.state.books.booksPurchased) {
            toDisplay = this.getBookDisplay()
        }

        return toDisplay
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOrders: (payerUid, purchaseOrderId, newDate, daysPurchased, books) =>  dispatch(createOrders(payerUid, purchaseOrderId, newDate, daysPurchased, books)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrdersModal)

