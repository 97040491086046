import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { toggleDone } from '../../../store/actions/statsActions'


class SectionsModal extends Component {

// Props passed to this class
// toggleShowSections - function which will hide the modal
// saveSections - function which will save the section titles
// sectionsSaved - array of sections headings which are saved in firestore

    state = {
        sections: ["","","","",""]
    }
    
    componentDidMount() {
        const sectionsSavedCopy = this.props.sectionsSaved.map ( (title) => {
            return title
        })
        this.setState({
            sections: sectionsSavedCopy
        })
    }

    componentDidUpdate(prevProps) {

    }

    componentWillUnmount() {
    }

    handleChange = (e) => {
        const targetId = e.target.id
        const targetValue = e.target.value
        const tabIndex = e.target.tabIndex
        let sections = this.state.sections
        sections[targetId] = targetValue
        this.setState({
            sections
        })
    }



    getSections = () => {
        let sectionRows = []
        for (let i=0; i < 5; i++) {
            const title = this.state.sections[i]
            const savedTitle = this.props.sectionsSaved[i]
            let styleName = "input-myclassrooms"
            if (title !== savedTitle) {
                styleName = "input-myclassrooms input-mcnew"
            }
            const row = (
                <tr
                    key={i}
                >
                    <td className="col-50-body row-myclassrooms">
                        <input
                            className={styleName}
                            type="text"
                            id={i}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            tabIndex={i+1}
                            ref={ref => (this.section0 = ref)}
                            value={title}
                        />
                    </td>
                </tr>
            )
            sectionRows.push(row)
        }

        return (
            <div className="tableWrap-oneClassroom" >
                <form onSubmit={this.handleSubmit} >
                    <table className="table-oneclassroom">
                        <tbody>
                            {sectionRows}
                        </tbody>
                    </table>
                </form>
            </div>
        )

    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowSections}></div>
                    <div className="story-sections-title">Section Titles</div>
                    {this.getSections()}
                    {/* <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.props.toggleShowSections} > */}
                        {/* <span>Close</span> */}
                    {/* </button> */}
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={(even) => this.props.saveSections(this.state.sections)} >
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}


// const mapStateToProps = (state, ownProps) => {    
//     return {
//         state
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         toggleDone: (fileId, progressKey) =>  dispatch(toggleDone(fileId, progressKey)),
//     }
// }

export default connect(null, null)(SectionsModal)





