import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { setClassroomId, getReportData, setDisplayInfo, sortActivityReport } from '../../../store/actions/reportActions'

// const chapterId = this.props.match.params.chapter_id;

class ClassReportAll extends Component {
    state = {
        sortOrderIsAscending : [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ]
     }

    componentDidMount() {
        console.log("Class Report did mount state")
        // console.log(this.props.state)
        const classroomId = this.props.classroomId
        this.props.setClassroomId(classroomId)
        this.props.getReportData(classroomId)
        // const classReportData = this.props.state.reports.usage[classroomId]
        // console.log(classReportData)
        // if (!classReportData) {
        //     this.props.getReportData(classroomId)
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("Class Report update state")
        console.log(this.props.state)
    }


    //UI Functions
    handleSelect = (e) => {
        const elementToUpdate = e.target.id
        const elementValue = e.target.value
        this.props.setDisplayInfo(elementToUpdate, elementValue)
    }
    clickedName = (studentId) => {
        const classroomId = this.props.classroomId
        this.props.history.push("/classreport/" + classroomId + "/" + studentId)
    } 
    sort = (sortBy) => {
        const sortOrder = !this.state.sortOrderIsAscending[sortBy]
        this.props.sortActivityReport(sortBy, sortOrder)
        const sortOrderIsAscending = this.state.sortOrderIsAscending
        sortOrderIsAscending[sortBy] = sortOrder
        this.setState({ sortOrderIsAscending })
    }

    //Helper Functions

    //Render Functions
    getDropDownList = (listLabel) => {
        const list = this.props.state.reports.toDisplay.dropDownLists[listLabel]
        const listElements = list.map( (listItem) => {
            return (
                <option key={listItem[0]} value={listItem[0]}>{listItem[1]}</option>
            )
        })
        return listElements            
    }


    getHeader = () => {
        const classroomId = this.props.classroomId
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        const classTitle = classroom.title
        return (
            <div>
                <div className="header-oneclassroom">
                    {classTitle + " Usage Report"}
                </div>
                <div className="header-linetwo-box">
                    <div className="header-oneclassroom-linetwo">
                        <span> <b>Time:</b> {this.timeDesc()} </span>
                    </div>
                    <div className="header-oneclassroom-linetwo">
                        <span> <b>Mastery:</b> {this.masteryDesc()} </span>
                    </div>
                </div>
            </div>
        )
    }

    masteryDesc = () => {
        return `
              This is the sum of all of the green checks the student has marked by each file. 
             Checking off these boxes allows students to visibly track their own progress. 
             Teachers can use these marks to start a conversation with the student about what mastery looks like and their process of achieving it.
             `
    }
    timeDesc = () => {
        return `
              Usage reported per file is set to automatically register ten minutes if the screen is left idle or if the student logs out. 
             The usage report gives teachers a general overview of student actiity and interest.
             `
    }

    getDropDowns = () => {
        const selectedBookId = this.props.state.reports.toDisplay.dropDownValues.selectedBookId
        const selectedChapterId = this.props.state.reports.toDisplay.dropDownValues.selectedChapterId
        const selectedTimePeriod = this.props.state.reports.toDisplay.dropDownValues.selectedTimePeriod
        return (
            <div className="dropdownblock-classreport">
                <div className="pub-selectcontainer3">
                    <div className="chapselect-label">Book</div>
                    <select 
                        className="select-css" 
                        id="selectedBookId" 
                        value = {selectedBookId}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownBook")}
                    </select>
                </div>
                <div className="pub-selectcontainer3 selectmiddle-classreport">
                    <div className="chapselect-label">Chapter</div>
                    <select 
                        className="select-css" 
                        id="selectedChapterId" 
                        value = {selectedChapterId}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownChapter")}
                    </select>
                </div>
                <div className="pub-selectcontainer3">
                    <div className="chapselect-label">Time Period</div>
                    <select 
                        className="select-css" 
                        id="selectedTimePeriod" 
                        value = {selectedTimePeriod}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownTimePeriod")}
                    </select>
                </div>
            </div>
        )
    }

    getTable = () => {
        return (
            <div className="tableWrap-oneclassroom" >
                <table className="table-oneclassroom">
                    <thead>
                        <tr>
                            {/* <th className="col-30-header" onClick={() => this.clickedSortField("name")}> */}
                            <th className="col-30-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort(1)}> Name </span>
                            </th>
                            <th className="col-25-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort(6)}> Last Activity </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort(7)}> Time </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort(8)}> Mastery </span>
                            </th>
                            <th className="col-15-header" >
                                <span className="colhead-adminusers" onClick={() => this.sort(9)}> Notes </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getUserRows()}
                    </tbody>
                </table>
            </div >
        )
    }

    getUserRows = () => {
        const userRows = this.props.state.reports.toDisplay.userRows
        const userRowElements = userRows.map( (userRow) => {
            const studentId = userRow[0]
            return (
                <tr key={studentId}>
                    <td className="col-30-body tdname-oneclassroom" onClick={() => this.clickedName(studentId)}>
                        {userRow[1]}
                    </td>
                    <td className="col-25-body tdlastactivity-oneclassroom" >
                        {userRow[2]}
                    </td>
                    <td className="col-15-body" >
                        {userRow[3]}
                    </td>
                    <td className="col-15-body" >
                        {userRow[4]}
                    </td>
                    <td className="col-15-body" >
                        {userRow[5]}
                    </td>
                </tr>

            )
        })
        return userRowElements            
    }

    render(){

    
        let toDisplay = (            
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        const userRows = this.props.state.reports.toDisplay.userRows
        if (userRows !== "loading") {
            toDisplay = (
                <div className="oneclassrooms-container">
                    {this.getHeader()}
                    {this.getDropDowns()}
                    {this.getTable()}
                </div>
            )
        }

        return toDisplay
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setClassroomId: (classroomId) => dispatch(setClassroomId(classroomId)),
        getReportData: (classroomId) => dispatch(getReportData(classroomId)),
        setDisplayInfo: (elementToUpdate, elementValue) => dispatch(setDisplayInfo(elementToUpdate, elementValue)),
        sortActivityReport: (sortBy, sortOrder) => dispatch(sortActivityReport(sortBy, sortOrder)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassReportAll)

