import firebase from '../../config/fbConfig'
const db = firebase.firestore()



export const sampleStoreAction = (bookId, qty) => {
    console.log("in add book");
    const bookInfo = {
        bookId,
        qty
    }
    console.log("BOOKINFO")
    console.log(bookInfo)
    return (dispatch, getState) => {  //dispatch dispatches an action to the reducer
        console.log("in return");
    };
};

export const getDetailsForBook = (bookId) => {
    return (dispatch, getState) => {  
        getChaptersForBook( bookId, (bookChapters) => {
            // const authSL = getState().auth
            // if (authSL.studentId) {
            //     getProgressForBook( authSL, bookId, (bookProgress) => {

            //     })
            // }
            const authSL = getState().auth
            const bookId = bookChapters.bookId
            getJournalsTicksForChapters ( authSL, bookId, (bookJournals, ticks) => {
                console.log("BOOK JOURNALS")
                console.log(bookJournals)
                dispatch({ type: "SET_CHAPTERS_FOR_BOOK", bookChapters });
                dispatch({ type: "SET_JOURNALS_FOR_BOOK", bookJournals });
                dispatch({ type: "SET_TICKS_FOR_BOOK", ticks });
                // console.log("JOURNALS AND TICKS")
                // console.log(bookJournals)
                // console.log(bookTicks)
            })
        })
    }
}

//TODO - Finish this function
const getProgressForBook = (authSL, bookId, callback) => {
    // const uid = authSL.studentId
    // const classroomId = authSL.classroomId
    // const collection = db.collection('classroomProgress');
    // const query = collection.where('uid', '==', studentId)
    //     .where('classroomId', '==', classroomId)
    //     .where('bookId', '==', bookId)
    // query.get().then( (querySnapshot) => {
    //     let progress = {}
    //     querySnapshot.forEach( (doc) => {
    //         chapters[doc.id] = {
    //             chapterId: doc.id,
    //             ...doc.data()
    //         };
    //     })
    //     const bookChapters = {}
    //     bookChapters["bookId"] = bookId
    //     bookChapters["chapters"] = chapters
    //     callback(bookChapters)
    // }, function (err) {
    //     console.log('COULD NOT RETRIEVE Classrooms for Book:' + err);
    // })
}

const getChaptersForBook = (bookId, callback) => {
    const collection = db.collection('chapters_v2');
    const query = collection.where('bookId', '==', bookId);
    query.get().then( (querySnapshot) => {
        let chapters = {}
        querySnapshot.forEach( (doc) => {
            if (!doc.data().chapterDeleted) {
                chapters[doc.id] = {
                    chapterId: doc.id,
                    ...doc.data()
                };    
            }
        })
        const bookChapters = {}
        bookChapters["bookId"] = bookId
        bookChapters["chapters"] = chapters
        callback(bookChapters)
    }, function (err) {
        console.log('COULD NOT RETRIEVE Chapters for Book:' + err);
    })
}


const getJournalsTicksForChapters = (authSL, bookId, callback) => {
    let uid = ""
    let journalCollection = ""
    let ticksCollection = ""
    if (authSL.uid) {
        uid = authSL.uid
        journalCollection = db.collection("chapterJournals")
        ticksCollection = db.collection("ticksTeacher")
    } else {
        uid = authSL.uidStudent
        journalCollection = db.collection("journalsStudent")
        ticksCollection = db.collection("ticksStudent")
    }
    const journalQuery = journalCollection.where("uid", "==", uid).where("bookId", "==", bookId)
    const ticksQuery = ticksCollection.where("uid", "==", uid).where("bookId", "==", bookId)

    let promises = [];
    promises.push(journalQuery.get());
    promises.push(ticksQuery.get());

    Promise.all(promises).then((results) => {
        let chapterJournals = {}
        let ticks = {}
        results.forEach((querySnapshot, index) => {
            if (index === 0) {
                querySnapshot.forEach((doc) => {
                    const chapterId = doc.data().chapterId
                    //If there are more than one journal for the same user and chapter, then delete this journal.
                    //There may be duplicates because the iOS app stores journal according to user and chapter and classroom.
                    //The iOS app needs to be changed to be consistent with the website.
                    //As of 4/4/20, the web app also stores journal by classroomId if the user is a student
                    if (chapterJournals[chapterId]) {
                        deleteJournalFromFB(doc.id)
                    } else {
                        chapterJournals[chapterId] = {
                            chapterJournalId: doc.id,
                            ...doc.data()
                        }                
                    }
                })    
            } else {
                querySnapshot.forEach((doc) => {
                    const fileId = doc.data().fileId
                    ticks[fileId] = {
                        docId: doc.id,
                        ...doc.data()
                    }
                })
            }
        })
        const bookJournals = {
            [bookId]: chapterJournals
        }
        callback(bookJournals, ticks)
    }, function (err) {
        console.log('COULD NOT RETRIEVE Journals and/or Ticks');
        callback({}, {})
    });
}


// Replaced 4/4/20. Can be deleted
// const getJournalsTicksForChapters = (authSL, bookId, callback) => {
//     let uid = ""
//     let journalCollection = ""
//     let ticksCollection = ""
//     if (authSL.uid) {
//         uid = authSL.uid
//         journalCollection = db.collection("chapterJournals")
//         ticksCollection = db.collection("ticksTeacher")
//     } else {
//         uid = authSL.uidStudent
//         journalCollection = db.collection("journalsStudent")
//         ticksCollection = db.collection("ticksStudent")
//     }
//     const journalQuery = journalCollection.where("uid", "==", uid).where("bookId", "==", bookId)
//     const ticksQuery = ticksCollection.where("uid", "==", uid).where("bookId", "==", bookId)

//     let promises = [];
//     promises.push(journalQuery.get());
//     promises.push(ticksQuery.get());

//     Promise.all(promises).then((results) => {
//         let chapterJournals = {}
//         let ticks = {}
//         results.forEach((querySnapshot, index) => {
//             if (index === 0) {
//                 querySnapshot.forEach((doc) => {
//                     const chapterId = doc.data().chapterId
//                     //If there are more than one journal for the same user and chapter, then delete this journal.
//                     //There may be duplicates because the iOS app stores journal according to user and chapter and classroom.
//                     //The iOS app needs to be changed to be consistent with the website.
//                     if (chapterJournals[chapterId]) {
//                         deleteJournalFromFB(doc.id)
//                     } else {
//                         chapterJournals[chapterId] = {
//                             chapterJournalId: doc.id,
//                             ...doc.data()
//                         }                
//                     }
//                 })    
//             } else {
//                 querySnapshot.forEach((doc) => {
//                     const chapterId = doc.data().chapterId
//                     ticks[chapterId] = {
//                         docId: doc.id,
//                         ...doc.data()
//                     }
//                 })
//             }
//         })
//         const bookJournals = {
//             [bookId]: chapterJournals
//         }
//         const bookTicks = {
//             [bookId]: ticks
//         }
//         callback(bookJournals, bookTicks)
//     }, function (err) {
//         console.log('COULD NOT RETRIEVE Journals and/or Ticks');
//         callback({}, {})
//     });


//     //Commented out on 3/28/20
//     // journalQuery.get().then( (querySnapshot) => {
//     //     let chapterJournals = {}
//     //     querySnapshot.forEach( (doc) => {
//     //         const chapterId = doc.data().chapterId
//     //         //If there are more than one journal for the same user and chapter, then delete this journal.
//     //         //There may be duplicates because the iOS app stores journal according to user and chapter and classroom.
//     //         //The iOS app needs to be changed to be consistent with the website.
//     //         if (chapterJournals[chapterId]) {
//     //             deleteJournalFromFB(doc.id)
//     //         } else {
//     //             chapterJournals[chapterId] = {
//     //                 chapterJournalId: doc.id,
//     //                 ...doc.data()
//     //             }                
//     //         }
//     //     })
//     //     const bookJournals = {
//     //         [bookId]: chapterJournals
//     //     }
//     //     callback(bookJournals)
//     // }, function (err) {
//     //     console.log('COULD NOT RETRIEVE Journals for Book:' + err);
//     // })
// }

const deleteJournalFromFB = (chapterJournalId) => {
    db.collection("chapterJournals").doc(chapterJournalId).delete().then(function() {
        // console.log("Document successfully deleted!");
    }).catch(function(error) {
        // console.error("Error removing document: ", error);
    });
}


