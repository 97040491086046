import React from 'react'

const ShowPdf = ({ fileUrl, chapterFile, hasAudioControls }) => {

    // console.log("Chapter FILE")
    // console.log(chapterFile)

    const cssClass = hasAudioControls ? "iframe-containpdf-audio-controls" : "iframe-containpdf"

    const pdf = (
        <div className={cssClass}>
            {/* <iframe height='100%' width='100%' scrolling='no' allowFullScreen webkitallowfullscreen="true" src={fileUrl}></iframe> */}
            <iframe src={fileUrl} frameBorder="0" allowFullScreen></iframe>
            {/* <iframe width="853" height="700" src={fileUrl} frameBorder="0" allowFullScreen></iframe> */}
        </div>
    );


    return (
        pdf
    )
}

export default ShowPdf
