import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom"
// import { withRouter } from 'react-router-dom'  //for redirecting from a function https://tylermcginnis.com/react-router-programmatically-navigate/
import ViewStatsClassroom from "./ViewStatsClassroom"



class ViewStats extends Component {
    state = {}

    getClassList = () => {
        const classroomsArr = this.props.state.classrooms.classrooms ?
            Object.values(this.props.state.classrooms.classrooms) :
            []
        const teacherClassrooms = classroomsArr.map(classroom => {
            let classTitle = classroom.title
            if (classTitle === "Free Books") {classTitle = null}
            return (
                <div key={classroom.classroomId}>
                    <Link to={"/ViewStats/" + classroom.classroomId + "/all/all"}>
                        <h3>{classTitle}</h3>
                    </Link>
                </div>
            )
        })
        return (
            <div>
                <h2>Click on a class to view student stats</h2>
                {teacherClassrooms}
            </div>
        )
    }

    componentDidMount() {
        console.log("ViewStats did mount")
        console.log(this.props.state)
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render(){
        //redirect if the user doesnt have the proper credentials
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/SignIn' />
        const role = this.props.profile.role
        const notStudent = (role == "Admin" || role == "Publisher" || role == "Teacher")
        if (!notStudent) {
            return <Redirect to='/' />
        }
        
        let toDisplay = null
        const classroomId = this.props.match.params.classroomId;
        const bookId = this.props.match.params.bookId;
        const chapterId = this.props.match.params.chapterId;
        if (classroomId === "all") {
            toDisplay = this.getClassList()
        } else {
            toDisplay = <ViewStatsClassroom
                classroomId={classroomId}
                bookId={bookId}
                chapterId={chapterId}
                history={this.props.history}
                />
        }

        return (
            <div className="viewstats-container">
                {toDisplay}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}
/*
const mapDispatchToProps = (dispatch) => {
    return {
        changeBookName: (bookId) =>  dispatch(changeTitle(bookId)),
        getBooks: () => dispatch(getBooks()),
        getBooks2: () => dispatch(getBooks2()) 
    }
}
*/
export default connect(mapStateToProps, null)(ViewStats)

