import React, { useState } from "react";
import { BrandLogoSmall } from "./BrandLogo";
import { NavDrawer } from "./NavDrawer";
import { AppNavLink } from "../AppNavLink";
import { JoinClassLinksMobile } from "./JoinClassLinks";

export const SignedInLinksMobile = ({
  clickedShowHelp,
  clickedShowCodeForm,
  clickedTogglePubFeat,
  isPublisher,
  isStudent,
  isAdmin,
  showBooksLink,
  showEnterClassCodeLink,
  showTeachersHelpLink,
  classroom,
  locationPath,
  profile,
  signOut,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavToggleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="nav-links">
        <AppNavLink className="brand-link" to="/">
          <BrandLogoSmall />
        </AppNavLink>
        <button
          type="button"
          className="nav-toggle"
          onClick={handleNavToggleClick}
        >
          <img src="../../../../img/iconmonstr-menu-1.svg" alt="" />
        </button>
      </div>
      <NavDrawer
        isOpen={isOpen}
        onClose={handleNavToggleClick}
        handleNavToggleClick={handleNavToggleClick}
      >
        {locationPath.includes("joinclass") ? (
          <JoinClassLinksMobile
            signOut={signOut}
            clickedShowHelp={clickedShowHelp}
            handleNavToggleClick={handleNavToggleClick}
          />
        ) : (
          <>
            {isStudent && classroom?.title && (
              <div className="header-item-classroom">{classroom.title}</div>
            )}
            {isStudent && (
              <div className="header-item-name">{profile.nameFirst}</div>
            )}
            {showBooksLink && (
              <AppNavLink
                className="drawer-item"
                to="/"
                onClick={handleNavToggleClick}
              >
                Home
              </AppNavLink>
            )}
            {showEnterClassCodeLink && (
              <AppNavLink
                className="drawer-item"
                to="/"
                onClick={(e) => {
                  handleNavToggleClick();
                  clickedShowCodeForm(e);
                }}
              >
                Enter a Book Code
              </AppNavLink>
            )}
            {isPublisher && (
              <AppNavLink
                className="drawer-item"
                to="/"
                onClick={(e) => {
                  handleNavToggleClick();
                  clickedTogglePubFeat(e);
                }}
              >
                Publisher Features
              </AppNavLink>
            )}
            {isAdmin && (
              <AppNavLink
                className="drawer-item"
                to="/adminorders"
                onClick={handleNavToggleClick}
              >
                Orders
              </AppNavLink>
            )}
            <AppNavLink
              className="drawer-item"
              to="/"
              onClick={() => {
                handleNavToggleClick();
                signOut();
              }}
            >
              Log out
            </AppNavLink>
            {showTeachersHelpLink ? (
              <a
                className="drawer-item"
                target="_blank"
                href="https://www.storylabs.online/help-for-language-teachers"
                onClick={handleNavToggleClick}
              >
                Help
              </a>
            ) : (
              <AppNavLink
                className="drawer-item"
                to="/"
                onClick={(e) => {
                  handleNavToggleClick();
                  clickedShowHelp(e);
                }}
              >
                Help
              </AppNavLink>
            )}
          </>
        )}
      </NavDrawer>
    </>
  );
};
