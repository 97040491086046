const initState = {
    usersAll: [],
    usersFound: [],
    userId: "",
    allBooks: null,
    // {
    //     bookId: { bookData }
    // }
    orders: null,
    // [
    //     {order data}
    // ]
    used: {
        objs: [
            // {
            //     bookTitle,
            //     count,
            //     bookId,
            //     teachers: {
            //         teacherId: {
            //             count,
            //             teacherEmail, 
            //             teacherName, 
            //             teacherId
            //         }
            //     }
            // }
        ],
        // toDisplay: [
        //     {
        //         bookTitle, bookCount, teacherName, teacherEmail, teacherCount, bookId, teacherId
        //     }
        // ]
    },
    bookCodes: [
        //     { ...bookCodeObject }
    ],
}

const adminReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_ALL_USERS':
            return {
                ...state,
                usersAll: action.usersAll
            }

        case 'SET_USERS_FOUND':
            return {
                ...state,
                usersFound: action.usersFound
            }
    
        case 'CLEAR_SEARCH':
            return {
                ...state,
                usersFound: []
            }
    
        case 'CLEAR_ORDERS':
                return {
                    ...state,
                    usersFound: [],
                    userId: "",
                    orders: null
                }

        case "SET_USED":
            return {
                ...state,
                used: {
                    objs: action.objs,
                    toDisplay: action.toDisplay
                }
            }
        
        case "SET_ALL_BOOKS":
            return {
                ...state,
                allBooks: {
                    ...action.allBooks
                }
            }

        case "SET_ORDERS":
            return {
                ...state,
                userId: action.userId,
                orders: action.orders
            }
        
        case "SET_BOOKCODES":
            return {
                ...state,
                bookCodes: action.bookCodes
            }

        default:
            return state
    }
}

export default adminReducer