import React, { Component } from 'react'
import { connect } from 'react-redux'
import { helpers } from 'react-redux-firebase'
import { updateJournalPrompt } from '../../../store/actions/publisherActions'

class ModalUpdateJournalPrompt extends Component {

// Props passed to this class
// chapter
// toggleShowModalUpdateJournalPrompt

    state = {
        journalPrompt: ""
    }
    
    componentDidMount() {
        const journalPromptText = this.getJournalPromptText()

        this.setState({
            journalPrompt: journalPromptText
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    //HELPER FUNCTIONS
    getJournalPromptText = () => {
        let journalPromptText = this.props.chapter.journalPrompt ?? ""
      
        if (journalPromptText.substring(0, 1) === "\"" ) {
            journalPromptText = journalPromptText.substring(1); //removes initial "
            journalPromptText = journalPromptText.slice(0, -1);  //removes ending "
        }
        const newLine = String.fromCharCode(13, 10); //gets the newLine character
        journalPromptText = journalPromptText.replace(/\\n/g, newLine) //replaces\n with newLine character
        journalPromptText = journalPromptText.replace(/\\/g, '') //removes \
        return journalPromptText
    }


    //EVENT FUNCTIONS
    handleChange = (e) => {
        this.setState({
            journalPrompt: e.target.value
        })
    }
    saveJournalPrompt = () => {
        let journalPromptText = this.getJournalPromptText()
        if (this.state.journalPrompt === journalPromptText) {
            this.props.toggleShowModalUpdateJournalPrompt()
            return
        }
        const chapter = this.props.chapter
        chapter.journalPrompt = this.state.journalPrompt
        this.props.updateJournalPrompt(chapter)
        this.props.toggleShowModalUpdateJournalPrompt()
    }


    //COMPONENT FUNCTIONS
    getCommentInput = () => {
        let journalPromptText = this.getJournalPromptText()
        let styleName = "pubfeat-textarea"
        if (this.state.journalPrompt !== journalPromptText) {
            styleName = "pubfeat-textarea pubfeat-textareaedit"
        }
        return (
                <form onSubmit={this.handleSubmit} >
                    <textarea
                        className={styleName}
                        type="text"
                        id="journalPrompt"
                        onChange={this.handleChange}
                        value={this.state.journalPrompt}
                    />
                </form>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                    <div className="pubfeat-close" onClick={this.props.toggleShowModalUpdateJournalPrompt}></div>
                    <div className="pubfeat-renametitle">Update Journal Prompt</div>
                    {this.getCommentInput()}

                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.saveJournalPrompt} >
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        updateJournalPrompt: (chapter) =>  dispatch(updateJournalPrompt(chapter)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateJournalPrompt)

