import React from 'react'
import BookCard from '../ui/BookCard'
import { Link } from "react-router-dom"


const ClassContainerStudent = ({classroomId, books}) => {  


    const thisClassroom = (
        <div className="oneclass-container">
            <BookCard classroomId={classroomId} books={books} />
        </div>
    )

return (
        <div >
            {thisClassroom}
        </div>
    )
}

export default ClassContainerStudent



// const ClassContainerStudent = ({classrooms}) => {  
//     let toDisplay = (
//         <div className="myclassrooms-container">
//             <div className="loader"></div>
//         </div>
//     )
//     if (!classrooms.isEmpty) {
//         let classList = []
//         for (const classroom of classrooms){
//             if (classroom.classroomId === "new") { continue }
//             let books = null
//             if (classroom.hasOwnProperty('books')) {
//                 books = <BookCard books={classroom.books} classroomId={classroom.classroomId} />
//             }
//             classList.push(
//                 <div key={classroom.classroomId} className="oneclass-container">
//                 {/* <h2 className="classroom-title"><em>{classroom.title}</em></h2> */}
//                     {books}
//                 </div>
//             )            
//         }
//         toDisplay = classList
//     }
//     return (
//         <div >
//             {toDisplay}
//         </div>
//     )
// }
