import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom"
// import { withRouter } from 'react-router-dom'  //for redirecting from a function https://tylermcginnis.com/react-router-programmatically-navigate/


class ViewProgress extends Component {
    state = {
        selectedChapter: null,
    }

    clickedBookRow = (bookId) => {
        console.log("CLICKED BookRow")
        console.log(this.props)
        const classroomId = this.props.match.params.classroomId
        const studentId = this.props.match.params.studentId
        this.props.history.push("/ViewProgress/" + classroomId + "/" + studentId + "/" + bookId)
    }

    chapterSelected = (e) => {
        e.persist()
        // console.log("CHAPTER SELECTED")
        // console.log(e.target.value)
        const chapterId = e.target.value
        const chapter = this.props.state.chapters.chapters ?
            this.props.state.chapters.chapters[chapterId] ?
                this.props.state.chapters.chapters[chapterId] :
                null :
            null
        this.setState({
            selectedChapter: chapter
        })
    }

    setInitialChapter = () => {
        const bookId = this.props.match.params.bookId
        if (bookId === "all") {return}
        const book = this.props.state.books.books ?
            this.props.state.books.books[bookId] ?
                this.props.state.books.books[bookId] :
                {} :
            {}
        const chaptersArr = book.chapterOrderArr ?
            book.chapterOrderArr :
            null
        if (chaptersArr === null) {
            this.setState({ selectedChapter: null })
            return
        }
        const selectedChapterId = chaptersArr[0]
        const chaptersAll = this.props.state.chapters.chapters ?
            this.props.state.chapters.chapters :
            {}
        const selectedChapter = chaptersAll[selectedChapterId] ?
            chaptersAll[selectedChapterId] :
            null
        this.setState({ selectedChapter })
    }

    getClassroom = () => {
        const classroomId = this.props.match.params.classroomId
        // if (!this.props.classrooms) {
        //     return "Loading"
        // }
        const classrooms = this.props.state.classrooms.classrooms
        const classroom = classrooms[classroomId] ?
            classrooms[classroomId] :
            {}
        return classroom
    }

    getStudentName = () => {
        const classroomId = this.props.match.params.classroomId
        const studentId = this.props.match.params.studentId
        // if (!this.props.classrooms) {
        //     return ""
        // }
        const classrooms = this.props.state.classrooms.classrooms
        const studentName = classrooms[classroomId] ?
            classrooms[classroomId]["studentProfiles"] ?
                classrooms[classroomId]["studentProfiles"][studentId] ?
                    classrooms[classroomId]["studentProfiles"][studentId]["user_name"] :
                    "Unknown Studen Name" :
                "Unknown Studen Name" :
            "Unknown Studen Name"

        return studentName
    }

    tableBookList = (classroom) => {
        const bookList = this.getBookList(classroom)
        return (
            <table className="details">
                <thead>
                    <tr>
                        <th className="summary-col-1">Book Title</th>
                        <th className="summary-col-2"># of activities marked</th>
                        <th className="summary-col-3">% Done</th>
                        <th className="summary-col-3">Total Time Spent</th>
                    </tr>
                </thead>
                <tbody>
                    {bookList}
                </tbody>
            </table>
        )
    }

    getBookList = (classroom) => {
        const studentId = this.props.match.params.studentId
        const booksArr = classroom.books ?
            classroom.books :
            []
        const timeSpentByBook = this.getTimeSpentByBook(classroom, studentId)
        const booksToDisplay = booksArr.map(book => {
            const bookId = book.bookId
            const bookTitle = book.title ?
                book.title :
                "Unknown Book Title"
            const numActivitiesMarked = this.getNumActivitiesMarked(classroom, studentId, bookId)
            const percentDone = this.getPercentDone(bookId, numActivitiesMarked)
            const timeSpent = timeSpentByBook[bookId] ? timeSpentByBook[bookId] : 0
            const formattedTime = this.getFormattedTime(timeSpent)
            return (
                <tr className="cursor-pointer" key={book.bookId} onClick={() => this.clickedBookRow(book.bookId)}>
                    <td>{bookTitle}</td>
                    <td>{numActivitiesMarked}</td>
                    <td>{percentDone}</td>
                    <td>{formattedTime}</td>
                </tr>                
            )
        })
        return booksToDisplay
    }

    getFormattedTime = (timeSpent) => {
        if (timeSpent === 0) {
            return "-"
        }
        if (timeSpent < 60) {
            const timeFormatted = timeSpent + " secs"
            return timeFormatted
        }
        const minutes = Math.floor(timeSpent / 60)
        const seconds = timeSpent - (minutes * 60)
        const timeFormatted = minutes + " mins " + seconds + " secs"
        return timeFormatted
    }

    getTimeSpentByBook = (classroom, studentId) => {
        let timeSpentByBook = {}
        const studentActivities = classroom.studentActivity ?
            classroom.studentActivity :
            {}
        const thisStudentActivity = studentActivities[studentId] ?
            studentActivities[studentId] :
            []
        for (let i = 0; i < thisStudentActivity.length; i++) {
            const bookId = thisStudentActivity[i].bookId
            const timeAccess = thisStudentActivity[i].timeAccess.seconds
            const timeLeave = thisStudentActivity[i].timeLeave.seconds
            const timeSpent = timeLeave - timeAccess
            if (timeSpent < 6) {continue}
            if (timeSpent > 600) (timeSpent = 600)
            if (timeSpentByBook.hasOwnProperty(bookId)) {
                timeSpentByBook[bookId] = timeSpentByBook[bookId] + timeSpent
            } else {
                timeSpentByBook[bookId] = timeSpent
            }
        }
        return timeSpentByBook
    }

    getPercentDone = (bookId, numActivitiesMarked) => {
        const books = this.props.state.books.books ?
            this.props.state.books.books :
            {}
        console.log('BOOKs AND BOOKID')
        console.log(books)
        console.log(bookId)
        const numActivitiesForBook = books[bookId] ?
            books[bookId]["numActivities"] ?
                books[bookId]["numActivities"] :
                0 :
            0
        console.log('NUM ACTIVITIES FOR BOOK')
        console.log(numActivitiesForBook)
        let percentDone = "No Book Activities"
        if (numActivitiesForBook>0) {
            const percentDoneNum = (numActivitiesMarked / numActivitiesForBook) * 100
            const percentDoneTrunc = Math.floor(percentDoneNum)
            percentDone = percentDoneTrunc + "%"
        }
        return percentDone
    }

    getNumActivitiesMarked = (classroom, studentId, bookId) => {
        let completedFiles = classroom["studentProgress"] ?
            classroom["studentProgress"][studentId] ?
                classroom["studentProgress"][studentId][bookId] ?
                    classroom["studentProgress"][studentId][bookId]["completedFiles"] :
                    {} :
                {} :
            {}
        if (!completedFiles) { completedFiles = {} }
        const isCompleteArr = Object.values(completedFiles)
        let numCompletedActivities = 0
        for (let i=0; i<isCompleteArr.length; i++) {
            if (isCompleteArr[i]) {numCompletedActivities++}
        }
        return numCompletedActivities
    }

    tableActivityList = () => {
        const bookId = this.props.match.params.bookId
        const book = this.props.state.books.books ?
            this.props.state.books.books[bookId] ?
                this.props.state.books.books[bookId] :
                {} :
            {}
        const bookTitle = book.title ?
            book.title :
            "Unknown Book Title"
        return (
            <table className="details">
                <thead>
                    <tr>
                        <th className="summary-col-1">
                            {bookTitle}
                            {this.getChapterList(book)}
                        </th>
                        <th className="summary-col-2">Date</th>
                        <th className="summary-col-3">Time Spent</th>
                    </tr>
                </thead>
                <tbody>
                    {this.getActivityList()}
                </tbody>
            </table>
        )
    }

    getChapterList = (book) => {
        let chapterList = []
        const chapterIdArr = book.chapterOrderArr ?
            book.chapterOrderArr :
            []
        const chapters = this.props.state.chapters.chapters ?
            this.props.state.chapters.chapters :
            {}
        
        for (let i = 0; i < chapterIdArr.length; i++) {
            const chapterId = chapterIdArr[i]
            if (chapters[chapterId] === undefined) { continue }
            const thisChapter = chapters[chapterId]
            if (thisChapter.isBlocked) { continue }
            const chapterTitle = thisChapter.title ?
                thisChapter.title :
                "Unknown Chapter Title"
            chapterList.push(
                <option key={thisChapter.chapterId} value={thisChapter.chapterId}>
                    {chapterTitle}
                </option>
            )
        }

        return (
            <div className="chapter-dropdown">
                <select className="select-css" id="mySelect" onChange={this.chapterSelected}>
                    {chapterList}
                </select>
            </div>
        )
    }


    getActivityList = () => {
        if (this.state.selectedChapter === null) { return null }
        const classroom = this.getClassroom()
        const bookId = this.props.match.params.bookId        
        const studentId = this.props.match.params.studentId
        let studentProgress = classroom["studentProgress"] ?
            classroom["studentProgress"][studentId] ?
                classroom["studentProgress"][studentId][bookId] :
                {} :
            {} 
        if (studentProgress === undefined) {studentProgress = {}}
        const completedFiles = studentProgress["completedFiles"] ?
            studentProgress["completedFiles"] :
            {}
        const completedDates = studentProgress["completedDates"] ?
            studentProgress["completedDates"] :
            {}
        const fileArray = this.state.selectedChapter.file_array ?
            this.state.selectedChapter.file_array :
            []

        let activitiesToDisplay = []        

        const timeSpentByActivity = this.getTimeSpentByActivity(classroom, studentId)

        for (let i = 0; i < fileArray.length; i++) {
            const activity = fileArray[i]
            const isStudentFile = !(activity.fileAccessLevel && activity.fileAccessLevel !== "Student")
            if (isStudentFile) {
                const activityName = activity.file_name ?
                    activity.file_name :
                    "Unknown File Name"
                const dateDone = this.getDateDone(activity, completedFiles, completedDates)
                //conintue from HERE!!!!!!!!
                const timeSpent = timeSpentByActivity[activity.fileId] ? timeSpentByActivity[activity.fileId] : 0
                const formattedTime = this.getFormattedTime(timeSpent)
                activitiesToDisplay.push(
                    <tr key={activity.fileId} >
                        <td>{activityName}</td>
                        <td>{dateDone}</td>
                        <td>{formattedTime}</td>
                    </tr>
                )
            }
        }
        return activitiesToDisplay
    }

    getTimeSpentByActivity = (classroom, studentId) => {
        let timeSpentByActivity = {}
        const studentActivities = classroom.studentActivity ?
            classroom.studentActivity :
            {}
        const thisStudentActivity = studentActivities[studentId] ?
            studentActivities[studentId] :
            []
        for (let i = 0; i < thisStudentActivity.length; i++) {
            const activityId = thisStudentActivity[i].activityId
            const timeAccess = thisStudentActivity[i].timeAccess.seconds
            const timeLeave = thisStudentActivity[i].timeLeave.seconds
            const timeSpent = timeLeave - timeAccess
            if (timeSpent < 6) {continue}
            if (timeSpent > 600) (timeSpent = 600)
            if (timeSpentByActivity.hasOwnProperty(activityId)) {
                timeSpentByActivity[activityId] = timeSpentByActivity[activityId] + timeSpent
            } else {
                timeSpentByActivity[activityId] = timeSpent
            }
        }    
        return timeSpentByActivity
    }



    getDateDone = (activity, completedFiles, completedDates) => {
        const activityId = activity.fileId
        const isDone = completedFiles[activityId] ?
            completedFiles[activityId] :
            false
        let doneTimestamp
        if (isDone) {
            doneTimestamp = completedDates[activityId] ?
                completedDates[activityId] :
                "done"
        } else {
            return "-"
        }
        if (doneTimestamp === "done" ) { return "done" }

        const doneSeconds = doneTimestamp.seconds * 1000
        const doneDate = new Date(doneSeconds);
        // const optionsDateDay = { weekday: 'short' }
        const optionsDateFull = { weekday: 'short', month: 'numeric', day: 'numeric', year: '2-digit', hour: 'numeric', minute: "numeric" }
        // const journalDateDay = journalDate.toLocaleDateString("en-US", optionsDateDay)
        const doneDateFull = doneDate.toLocaleDateString("en-US", optionsDateFull)
        return doneDateFull



        // console.log("DATE DONE")
        // console.log(dateDone)
        

        // if (journal.webUpdate) {
        //     const journalTimestamp = journal.webUpdate.seconds * 1000
        //     const journalDate = new Date(journalTimestamp);
        //     const optionsDateDay = { weekday: 'short' }
        //     const optionsDateFull = { month: 'short', day: 'numeric', year: 'numeric' }
        //     const journalDateDay = journalDate.toLocaleDateString("en-US", optionsDateDay)
        //     const journalDateFull = journalDate.toLocaleDateString("en-US", optionsDateFull)
        //     return {journalDateDay, journalDateFull}    
        // }

    }

    

    componentDidMount() {
        console.log("VIEW PROGRESS - global state")
        console.log(this.props.state)
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("VIEW PROGRESS - did update")
        const bookIdPrev = prevProps.match.params.bookId
        const bookIdNew = this.props.match.params.bookId
        if (bookIdPrev !== bookIdNew) {
            this.setInitialChapter()
        }

    }

    render(){
        const classroom = this.getClassroom()
        const bookId = this.props.match.params.bookId        
        const classroomName = classroom["title"] ?
            classroom["title"] :
            "Unknown Class Name"
        const studentName = this.getStudentName()
        let table = null
        if (bookId === "all" ) {
            table = this.tableBookList(classroom)
        } else {
            table = this.tableActivityList()
        }

        let toDisplay = (
            <div className="viewstats-container">
                <div className="statsClass-container">
                    <h2>{classroomName}</h2>
                    <div className="tablewrap-summary">
                        <div className="vj-studentname">
                            {studentName}'s Progress Report
                        </div>
                        {table}
                    </div>
                </div>
            </div>
        )
        return toDisplay
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}
/*
const mapDispatchToProps = (dispatch) => {
    return {
        changeBookName: (bookId) =>  dispatch(changeTitle(bookId)),
        getBooks: () => dispatch(getBooks()),
    }
}
*/
export default connect(mapStateToProps, null)(ViewProgress)

