import React, { Component } from 'react'
import M from 'materialize-css'

class ShowImage extends Component {

    componentDidMount() {

        var images = document.querySelectorAll('.materialboxed')
        // console.log("IMAGES")
        // console.log(images)
        const options = {};
        M.Materialbox.init(images, options);

        // document.addEventListener('DOMContentLoaded', function () {
        //     var elems = document.querySelectorAll('.materialboxed');
        //     const options = {};
        //     var instances = M.Materialbox.init(elems, options);
        // });
    }

    render(){
        const fileUrl = this.props.fileUrl;
        const image = (
            <div className="center-image">
                <img src={fileUrl} className="responsive-img materialboxed" alt="image" />
            </div>
        )

        return(
            image
        )
    }
}

export default ShowImage


// const ShowImage = ({fileUrl}) => {

//     // console.log("Chapter FILE")
//     // console.log(chapterFile)

    // componentDidMount() {
    //     document.addEventListener('DOMContentLoaded', function() {
    //         var elems = document.querySelectorAll('.materialboxed');
    //         var instances = M.Materialbox.init(elems, options);
    //       });    
    // }

    

//     const image = (
//         <div>
//             <img src={fileUrl} className="responsive-img" className="materialboxed" alt="Book Cover" />
//         </div>
//     )
    

//     return (
//         image
//     )
// }

// export default ShowImage
