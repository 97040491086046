// import firebase from '../../config/fbConfig'  //work around instead of using getFirebase from thunk
import firebase from '../../config/fbConfig'
// import { getBooks } from './retrieveClassBookActions'

const db = firebase.firestore()

//This action created in Lesson 23
export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => { //These properties come from the store. See index.js createStore. getFirebase is available because of applyMiddleware(thunk.withExtraArgument
        
        //Access State Like This:
        // const profile = getState().firebase.profile;
        
        const fb = getFirebase();

        fb.auth().setPersistence(fb.auth.Auth.Persistence.SESSION)
            .then(function () {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                // return fb.auth().signInWithEmailAndPassword(
                fb.auth().signInWithEmailAndPassword(
                    credentials.email,
                    credentials.password
                ).then(function (user) {
                    getUserProfile(user, (dbUser) => {
                        // console.log("LOGIN SUCCESS - dbUser")
                        // console.log(dbUser)
                        const user = dbUser
                        if (user.role === "Student") {
                            dispatch({ type: 'BLOCK_ACCESS' })
                            firebase.auth().signOut().then( () => {} );                    
                        } else {
                            dispatch({ type: 'LOGIN_SUCCESS', user })
                        }
                    })
                    
                    // dispatch({ type: 'LOGIN_SUCCESS', user })
                }).catch((err) => {
                    console.log("IN THIS CATCH LOGIN ERROR 1")
                    dispatch({ type: 'LOGIN_ERROR', err });
                })
            })
            .catch(function (err) {
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                console.log("IN THIS CATCH LOGIN ERROR 2")
                dispatch({ type: 'LOGIN_ERROR', err });
            });
    }
}

export const closeNoAccess = () => {
    return (dispatch, getState) => { //These properties come from the store. See index.js createStore. getFirebase is available because of applyMiddleware(thunk.withExtraArgument
        dispatch({ type: 'CLOSE_NO_ACCESS' });              
    }
}

const getUserProfile = (user, callback) => {
    const uid = user.user.uid
    const userDoc = db.collection("users").doc(uid)
    userDoc.get().then(function(doc) {
        if (doc.exists) {
            const dbUser = {
                uid: doc.id,
                ...doc.data()    
            }
            callback(dbUser)
        } else {
            console.log("ERROR - The user document does not exist");
            //There is no user profile saved in firestore. Create one.
            const user_name = user.user.displayName ?
                user.user.displayName :
                ""
            const newUser = {
                uid: uid,
                email: user.user.email,
                firstName: user_name,
                lastName: "",
                user_name: user_name,
                role: "student"
            }
            recordInFS(newUser, () => {
                console.log("Successfully recorded")
                callback(newUser)
            })
        }
    }).catch(function(error) {
        console.log("Error getting user document:", error);
    });
}



export const signInStudent = (credentials) => { 
    return (dispatch, getState, {getFirebase}) => { //These properties come from the store. See index.js createStore. getFirebase is available because of applyMiddleware(thunk.withExtraArgument
        const theState = getState()
        const fb = getFirebase();
        signInAnonymous( theState, fb, (didSignIn, anonId) => {
            if (!didSignIn) { 
                dispatch({ type: 'LOGIN_ERROR', anonId });    
                return
            }
            const classCode = credentials.classCode.toLowerCase()
            getClassroomForStudent( classCode, (classroom) => {
                if (classroom === "error") {
                    dispatch({ type: 'LOGIN_ERROR', classroom });    
                    return
                }
                if (classroom === 0) {
                    dispatch({ type: 'LOGIN_ERROR', classroom });    
                    return
                }
                if (classroom.isDeleted) {
                    dispatch({ type: 'LOGIN_ERROR', classroom });    
                    return
                }
                getStudentUser( credentials, classroom, (studentUser) => {
                    if (studentUser === "error") {
                        // console.log("MORE THAN ONE STUDENT FOUND WITH the same classCode and password")
                        dispatch({ type: 'LOGIN_ERROR', studentUser });
                    } else if (studentUser === 0) {
                        // console.log("STUDENT LOGIN FAILED")
                        dispatch({ type: 'LOGIN_ERROR', studentUser });
                    } else {
                        saveAnonIdToFS(anonId, studentUser.uidStudent)
                        dispatch({ type: "LOGIN_STUDENT", studentUser });
                    }
                })
            })
        })
    }
}

const signInAnonymous = (theState, fb, callback) => {
    if (theState.firebase.auth.uid) {
        callback(true, theState.firebase.auth.uid)
    }
    fb.auth().setPersistence(fb.auth.Auth.Persistence.SESSION)
    .then(function () {
        fb.auth().signInAnonymously().then(function(user) {
            callback(true, user.user.uid)
        }).catch(function(error) {
            // Handle Errors here.
            // var errorCode = error.code;
            var errorMessage = error.message;
            console.log("Error signing in anonymously:  " + errorMessage)
            callback(false, error)
        });            
    })
    .catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        console.log("Error setting persistence:  " + error.message)
        callback(false, error)
    });
}

const getClassroomForStudent = (classCode, callback) => {
    const collection = db.collection("classrooms_v3");
    const query = collection.where("classCode", "==", classCode);
    const classrooms = []
    query.get().then(function (querySnapshot) {        
        querySnapshot.forEach(function (doc) {
                classrooms.push({
                    classroomId: doc.id,
                    ...doc.data()
                })
        })
        if (classrooms.length === 1) {
            callback(classrooms[0])
        } else if (classrooms.length > 1) {
            callback("error")
        } else {
            callback(0)
        }
    }).catch(function (error) {
        console.log("ERROR - could not get classroomId for student user login", error)
    })
}

const getStudentUser = (credentials, classroom, callback) => {
    const password = credentials.classPassword
    const classroomId = classroom.classroomId
    const collection = db.collection("usersChild");
    const query = collection.where("classroomId", "==", classroomId)
                            .where("password", "==", password)
    const studentUsers = []
    query.get().then(function (querySnapshot) {        
        querySnapshot.forEach(function (doc) {
                studentUsers.push({
                    classroom: classroom,
                    uidStudent: doc.id, 
                    ...doc.data()
                })
        })
        if (studentUsers.length === 1) {
            callback(studentUsers[0])
        } else if (studentUsers.length > 1) {
            callback("error")
        } else {
            callback(0)
        }
    }).catch(function (error) {
        console.log("ERROR - could not get student profile for student user login", error)
    })
}

const saveAnonIdToFS = ( anonId, studentUid ) => {
    const userDoc = db.collection("usersChild").doc(studentUid)
    userDoc.update({
        anonId: anonId
    })
    .then(function() {
        // console.log("SUCCESSFULLY added anonId to student user")
        // console.log(studentUid)
        // console.log(anonId)
    })
    .catch(function(error) {
        console.log("ERROR updating anonId on student user fs doc: ", error)
    })
}




export const signInStudentOLD = (credentials) => { //this method removed 12/28/19
    return (dispatch, getState) => { //These properties come from the store. See index.js createStore. getFirebase is available because of applyMiddleware(thunk.withExtraArgument

        signInAnonymous( (anonId) => {
            getClassroomForStudent( credentials.email, (classroomId) => {
                getStudentUser( classroomId, credentials.classPassword, (studentUser) => {
                    if (studentUser === "error") {
                        console.log("MORE THAN ONE STUDENT FOUND WITH the same classCode and password")
                    } else if (studentUser === 0) {
                        console.log("STUDENT LOGIN FAILED")
                        dispatch({ type: "LOGIN_ERROR" });    
                    } else {
                        saveAnonIdToFS( anonId, studentUser[0] )
                        const user = {
                            anonId,
                            uid: studentUser[0],
                            ...studentUser[1]
                        }
                        dispatch({ type: "LOGIN_STUDENT", user });
                    }
                })
            })
        })

    }
}

export const signInStudentOLDNotUsed = (credentials) => { //this was the first singInStudent method
    return (dispatch, getState) => { //These properties come from the store. See index.js createStore. getFirebase is available because of applyMiddleware(thunk.withExtraArgument
        console.log("TRYING TO sign in student")

        signInAnonymous( (anonId) => {
            getClassroomForStudent( credentials.email, (classroomId) => {
                getStudentUser( classroomId, credentials.password, (studentUser) => {
                    if (studentUser === "error") {
                        console.log("MORE THAN ONE STUDENT FOUND WITH the same classCode and password")
                    } else if (studentUser === 0) {
                        console.log("STUDENT LOGIN FAILED")
                        dispatch({ type: "LOGIN_ERROR" });    
                    } else {
                        saveAnonIdToFS( anonId, studentUser[0] )
                        const user = {
                            anonId,
                            uid: studentUser[0],
                            ...studentUser[1]
                        }
                        dispatch({ type: "LOGIN_STUDENT", user });
                    }
                })
            })
        })

    }
}

const signInAnonymousOLD = (callback) => { //this method removed 12/28/19
    firebase.auth().signInAnonymously().then(function(user) {
        callback(user.user.uid)
    }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
    });        
}

const getClassroomForStudentOLD = (classCode, callback) => { //this methond removed 12/28/19
    const collection = db.collection("classrooms_v3");
    const query = collection.where("classCode", "==", classCode);
    const classroomIds = []
    query.get().then(function (querySnapshot) {        
        querySnapshot.forEach(function (doc) {
                classroomIds.push(doc.id)
        })
        if (classroomIds.length > 0) {
            callback(classroomIds[0])
        } else {
            callback(0)
        }
    }).catch(function (error) {
        console.log("ERROR - could not get classroomId for student user login", error)
    })
}

const getStudentUserOLD = (classroomId, password, callback) => { //this method removed 12/28/19
    const collection = db.collection("users");
    const query = collection.where("classroomId", "==", classroomId)
                            .where("password", "==", password)
    const studentUsers = []
    query.get().then(function (querySnapshot) {        
        querySnapshot.forEach(function (doc) {
                studentUsers.push([doc.id, doc.data()])
        })
        if (studentUsers.length === 1) {
            callback(studentUsers[0])
        } else if (studentUsers.length > 1) {
            callback("error")
        } else {
            callback(0)
        }
    }).catch(function (error) {
        console.log("ERROR - could not get student profile for student user login", error)
    })
}


export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        // dispatch({ type: 'SIGNOUT_SUCCESS' });
        // dispatch({ type: 'RESET_BOOKS' })
        // dispatch({ type: 'RESET_CLASSROOMS' })
        // dispatch({ type: 'RESET_CHAPTERS' })


        const fb = getFirebase();
        fb.auth().signOut().then( () => {
            // dispatch({ type: 'SIGNOUT_SUCCESS' });
            dispatch({ type: 'USER_LOGOUT' })
        });    


        // const activity = this.props.state.activity.activityFile.docId
        // console.log("ACTIVITY")
        // console.log(activity)
        // if (activity) {
        //     recordActivityLeaveTime( getState(), () => {
        //         const fb = getFirebase();
        //         fb.auth().signOut().then( () => {
        //             // dispatch({ type: 'SIGNOUT_SUCCESS' });
        //             dispatch({ type: 'USER_LOGOUT' })
        //         });        
        //     })
        // } else {
        //     const fb = getFirebase();
        //     fb.auth().signOut().then( () => {
        //         // dispatch({ type: 'SIGNOUT_SUCCESS' });
        //         dispatch({ type: 'USER_LOGOUT' })
        //     });    
        // }


    }
}

const signOut2 = (dispatch, getState, callback) => {
    if (getState().firebase.auth.uid) {
        firebase.auth().signOut().then(() => {
            dispatch({ type: 'USER_LOGOUT' })
            callback()
        });    
    } else {
        callback()
    }
}

const recordActivityLeaveTime = (theState) => {
    const authSL = theState.auth
    let activityCollection = ""
    if (authSL.uid) {
        activityCollection = db.collection("activityTeacher")
    } else {
        activityCollection = db.collection("activityStudent")
    }
    const activityId = theState.activity.activityFile.docId

    console.log("ABOUT TO SAVE LEAVE Time on log out")
    activityCollection.doc(activityId).update({
        timeLeave: new Date()
    })
    .then(function() {
        console.log("SAVED Activity leave time on LOG OUT");
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("ERROR Saving Activity leave time on LOG OUT ", error);
    });           

}

export const signUp = (newUser) => {
    return (dispatch, getState) => {
        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((response) => {
            newUser["uid"] = response.user.uid
            recordInFS(newUser, (userRecorded) => {
                addPaForAddBooks( response.user.uid, () => {
                    dispatch({ type: 'SIGNUP_SUCCESS', userRecorded })
                })
            })
        }).then(() => {
        }).catch(err => {
            dispatch({ type: 'SIGNUP_ERROR', err })
        })
    }
}

const recordInFS = (newUser, callback) => {
    const userId = newUser.uid
    let refUser = db.collection('users').doc(userId);
    const userRecorded = {
        "createTime": new Date(),
        "email": newUser.email,
        "role": "Teacher",
        "user_name": newUser.firstName + " " + newUser.lastName,
        "lastName": newUser.lastName,
        "firstName": newUser.firstName
    }
    refUser.set(userRecorded, { merge: true })
        .then(function () {
            // console.log("NEW USER recorded in FS");
            userRecorded["uid"] = userId
            callback(userRecorded)
        })
        .catch(function (error) {
            console.error("Error writing new user in FS: ", error);
        });
}

// This is to create a purchase access for the book "Add Books" for new teachers
const addPaForAddBooks = (uid, callback) => {
    const paRef = db.collection("purchaseAccess")
    const newDate = new Date()
    const payerUid = uid
    const purchaseOrderId = "addbooks"
    const bookId = "kCR4lYNL2uRcRSPKRH0w" //Add Books
    const doc = {
        createTime: newDate,
        daysPurchased: 365,
        itemId: bookId,
        payerUid: payerUid,
        purchaseOrderId: purchaseOrderId,
        quantity: 1,
        updatedOn: newDate
    }
    paRef.add(doc)
        .then(function (docRef) {
            // console.log("Document written with ID: ", docRef.id);
            callback()
        })
        .catch(function (error) {
            // console.error("Error adding document: ", error);
            callback()
        });
}



// Functions for joinclass
export const getJoinClassroom = (classCode) => {
    return (dispatch, getState, {getFirebase}) => {
        const fb = getFirebase();
        signOut2( dispatch, getState, () => {
            signInAnon(fb, (anonId) => {
                getClassroomForStudent( classCode, (classroom) => {
                    const noClassroom = (classroom === "error" || classroom === 0 || classroom.isDeleted)
                    if (noClassroom) {
                        dispatch({ type: 'RETRIEVED_CLASS_ERROR' });    
                    } else {
                        const classData = {
                            classroom,
                            anonId
                        }
                        dispatch({ type: 'RETRIEVED_CLASS', classData })
                    }
                })
            })
        })
    }
}

const signInAnon = (fb, callback) => {
    fb.auth().setPersistence(fb.auth.Auth.Persistence.SESSION)
        .then(function () {
            firebase.auth().signInAnonymously().then(function (user) {
                callback(user.user.uid)
            }).catch(function (error) {
                console.log("Error signing in anonymously:  " + error.message)
            });
        })
        .catch(function (error) {
            console.log("Error setting persistence:  " + error.message)
        });
}

export const joinClassStudent = (credentials) => {
    return (dispatch, getState) => {
        const classroomId = getState().auth.joinClass.classroom.classroomId
        const anonId = getState().auth.joinClass.anonId
        const name = credentials.name
        const password = credentials.password
        passwordIsUnique( classroomId, password, (isUnique) => { 
            if (!isUnique) {
                dispatch({ type: 'PW_NOT_UNIQUE' }) 
                return
            }
            saveNewStudent(name, password, anonId, classroomId, (studentUser) => {
                if (studentUser) {
                    const classroom = getState().auth.joinClass.classroom
                    // console.log("CLASSROOM")
                    // console.log(classroom)
                    studentUser["classroom"] = classroom
                    dispatch({ type: "STUDENT_JOINED", studentUser });
                }
            })
        })
    }
}

const passwordIsUnique = (classroomId, password, callback) => {
    const collection = db.collection("usersChild");
    const query = collection.where("classroomId", "==", classroomId).where("password", "==", password);
    query.get().then(function (querySnapshot) {
        if (querySnapshot.size === 0) {
            callback(true)
        } else {
            callback(false)
        }
    }).catch(function (error) {
        console.log("ERROR - could not check for unique password", error)
    })   
}

const saveNewStudent = (name, password, anonId, classroomId, callback) => {
    const userRef = db.collection("usersChild").doc()
    const studentUser = {
        anonId,
        classroomId,
        dateCreated: new Date(),
        nameFirst: name,
        password
    }
    userRef.set( studentUser )
        .then(function (docRef) {
            // console.log("Document written with ID: ", userRef.id);
            studentUser["uidStudent"] = userRef.id
            callback(studentUser)
        })
        .catch(function (error) {
            console.error("Error adding document: ", error);
            callback(false)
        });
}

export const resetPwError = () => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_PW_ERROR" });
    }
}

export const resetSuccess = () => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_SUCCESS" });
    }
}



//From retrieveClassBookActions 3/12/2020. For getting user profile from the Landing page
export const getUser = () => {
    return (dispatch, getState) => {
        const authFB = getState().firebase.auth
        const authSL = getState().auth
        if (!authSL.isEmpty) {
            return
        }
        if (authFB.isAnonymous) {
            getStudentProfileFromAnonId(authFB, (user) => {
                console.log("USER")
                console.log(user)
                const studentUser = user
                dispatch({ type: "LOGIN_STUDENT", studentUser })
            })
        } else {
            getTeacherProfile(authFB, (user) => {
                dispatch({ type: 'LOGIN_SUCCESS', user })
            })
        }    
    }
}
const getStudentProfileFromAnonId = (authFB, callback) => {
    const userCollection = db.collection("usersChild")
    const userQuery = userCollection.where("anonId", "==", authFB.uid)
    let studentUsers = []
    userQuery.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
            console.log("DDDDDDDATA")
            console.log(doc.data())
            studentUsers.push({
                anonId: authFB.uid,
                uidStudent: doc.id,
                ...doc.data()
            })
        })
        if (studentUsers.length > 1) {
            console.log("ERROR - more than one student user found for an anonymous ID")
            callback(false)
        } else if (studentUsers.length === 0){
            console.log("ERROR - no student user found for anonymous id")
            callback(false)
        } else {
            if (!studentUsers[0].isDeleted) {
                getClassroomForStudent2(studentUsers[0], (user) => {
                    callback(user)
                })
            } else {
                //Student is deleted
                callback(false)
            }
        }    
    })
    .catch(function (error) {
        console.log("ERROR could not get user object: ", error)
    })
}
const getClassroomForStudent2 = (studentUser, callback) => {
    let classroom = null
    const classroomRef = db.collection("classrooms_v3").doc(studentUser.classroomId)

    classroomRef.get().then(function (doc) {
        if (doc.exists) {
            classroom = {
                classroomId: doc.id,
                ...doc.data()
            }
            studentUser["classroom"] = classroom
            callback(studentUser)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
}
const getTeacherProfile = (authFB, callback) => {
    const userDoc = db.collection("users").doc(authFB.uid)
    userDoc.get().then(function(doc) {
        if (doc.exists) {
            const user = {
                uid: doc.id,
                ...doc.data()    
            }
            callback(user)
        } else {
            recordUserProfileInFS(authFB, (user) => {
                callback(user)
            })
        }
    }).catch(function(error) {
        console.log("Error getting user document:", error);
    });
}
const recordUserProfileInFS = (authFB, callback) => {
    const user_name = authFB.displayName ?
        authFB.displayName :
        ""
    const userId = authFB.uid
    const newUser = {
        uid:  userId,
        email: authFB.email,
        firstName: user_name,
        lastName: "",
        user_name: user_name,
        role: "student"
    }
    let refUser = db.collection('users').doc(userId);
    refUser.set({
        "createTime": new Date(),
        "email": newUser.email,
        // "role": "Teacher",
        "role": "Student",
        "user_name": newUser.user_name,
        "lastName": newUser.lastName,
        "firstName": newUser.firstName
    }, { merge: true })
        .then(function () {
            console.log("NEW USER recorded in FS");
            callback(newUser)
        })
        .catch(function (error) {
            console.error("Error writing new user in FS: ", error);
        });
}


