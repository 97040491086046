import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveReorderedFiles } from '../../../store/actions/publisherActions'


class ModalReorderChapterFiles extends Component {

// Props passed to this class
// book
// chapter
// toggleShowModalReorderFiles

    state = {
        sectionsOriginal: [],
        sectionsUser: [],
        sectionsFilesObj : {},
    }
    
    componentDidMount() {
        const sectionsOriginal = this.props.state.books.sectionsOriginal
        const sectionsUser = this.props.book.chapterSections ? this.props.book.chapterSections : this.props.state.books.sectionsDefault
        const sectionsFilesObj = this.getSectionsFilesObj()
        this.setState({ 
            sectionsOriginal,
            sectionsUser,
            sectionsFilesObj,
        })
    }

    componentDidUpdate(prevProps) {
        console.log("did update")
        console.log(this.state.sectionsFilesObj)
    }

    componentWillUnmount() {
    }   

    //HELPER FUNCTIONS
    getSectionsFilesObj = () => {
        const fileArray = this.props.chapter.file_array
        const sectionsFilesObj = {}
        fileArray.forEach( (file) => {
            const section = file.fileSection
            if ( sectionsFilesObj[section] ) {
                sectionsFilesObj[section].push( file )
            } else {
                sectionsFilesObj[section] = [file]
            }
        })
        return sectionsFilesObj
    }
    parseId = (id) => {
        const draggedSection = id.substring(0, id.indexOf('-'));
        let draggedIndex = id.substring(id.indexOf('-')+1);
        draggedIndex = parseInt(draggedIndex)
        return [draggedSection, draggedIndex]
    }
    getTargets = (target, sectionDragged, indexDragged, sectionDropped, indexDropped) => {
        let indexTarget = null
        if ( target.style['border-bottom'] !== '' ) {
            target.style['border-bottom'] = '';
            indexTarget = indexDropped + 1
        } else {
            target.style['border-top'] = '';
            indexTarget = indexDropped
        }
        if ( sectionDragged === sectionDropped && indexDragged < indexTarget ) {
            indexTarget--
        }
        return [sectionDropped, indexTarget]
    }
    getUpdatedSectionsFilesObj = (sectionDragged, indexDragged, sectionTarget, indexTarget) => {
        const sectionsFilesObj = this.state.sectionsFilesObj
        const fileToMove = sectionsFilesObj[sectionDragged][indexDragged]
        fileToMove.fileSection = sectionTarget
        sectionsFilesObj[sectionDragged].splice(indexDragged, 1)
        if ( sectionsFilesObj[sectionDragged].length === 0 ) {
            delete sectionsFilesObj[sectionDragged]
        }
        if ( sectionsFilesObj[sectionTarget] ) {
            sectionsFilesObj[sectionTarget].splice(indexTarget, 0, fileToMove)
        } else {
            sectionsFilesObj[sectionTarget] = [fileToMove]
        }
        return sectionsFilesObj
    }



    dragStart = (e, idKey) => {
        // console.log(e.target.id)
        e.dataTransfer.setData("text/plain", idKey);
        // e.dataTransfer.setDragImage(e.target,0,0)
    }
    dragOver = (e) => {
        // let event = e as Event;
        e.stopPropagation();
        e.preventDefault();
        // e.preventDefault();
        var target = e.target
        var bounding = target.getBoundingClientRect()
        var offset = bounding.y + (bounding.height/2);
        if ( e.clientY - offset > 0 ) {
            target.style['border-bottom'] = 'solid 4px blue';
            target.style['border-top'] = '';
        } else {
            target.style['border-top'] = 'solid 4px blue';
            target.style['border-bottom'] = '';
        }
    }
    dragLeave = (e) => {
        const target = e.target
        target.style['border-bottom'] = ''
        target.style['border-top'] = ''
    }
    //This function based on this source: https://stackoverflow.com/questions/44415228/list-sorting-with-html5-dragndrop-drop-above-or-below-depending-on-mouse
    drop = (e) => {
        e.preventDefault();
        const droppedId = e.dataTransfer.getData("text/plain");
        const [sectionDragged, indexDragged] = this.parseId(droppedId)
        const [sectionDropped, indexDropped] = this.parseId(e.target.id)
        const [sectionTarget, indexTarget] = this.getTargets(e.target, sectionDragged, indexDragged, sectionDropped, indexDropped)

        // console.log("DRAGGED")
        // console.log(sectionDragged + " and " + indexDragged)
        // console.log("TARGET")
        // console.log(sectionTarget + " and " + indexTarget)

        if ( sectionDragged === sectionTarget && indexDragged === indexTarget ) { return }
        const updatedSectionsFilesObj = this.getUpdatedSectionsFilesObj(sectionDragged, indexDragged, sectionTarget, indexTarget)

        this.props.saveReorderedFiles(this.props.chapter, updatedSectionsFilesObj)

        this.setState({
            sectionsFilesObj: updatedSectionsFilesObj
        })
        return
    }
      
    // getFilesForSectionNewExperiment = (section, indexSection) => {
    //     const sectionsFilesObj = this.state.sectionsFilesObj
    //     let fileList = []
    //     if ( sectionsFilesObj[section] ) {
    //         fileList = sectionsFilesObj[section].map( (file, indexFile) => {
    //             const title = file.file_name
    //             const indexFileStr = indexFile.toString()
    //             const idKey = section + "-" + indexFileStr
    //             return (
    //                 <div
    //                 >
    //                     <div
    //                         id={idKey}
    //                         key={idKey}
    //                         className="pubfeat-filename"
    //                         draggable="true"
    //                         onDragStart={(e) => this.dragStart(e)}
    //                     >
    //                         {title}
    //                     </div>
    //                 </div>
    //             )
    //         })
    //     } else {
    //         const idKey = section + "-0"
    //         fileList.push(                    
    //             <div
    //                 id={idKey}
    //                 key={idKey}
    //                 className="pubfeat-filename pubfeat-nofilename"
    //                 draggable="false"
    //                 onDragStart={(e) => this.dragStart(e)}
    //             >
    //             </div>
    //         ) 
    //     }
    //     return fileList
    // }

    getFilesForSection = (section, indexSection) => {
        const sectionsFilesObj = this.state.sectionsFilesObj
        let fileList = []
        if ( sectionsFilesObj[section] ) {
            fileList = sectionsFilesObj[section].map( (file, indexFile) => {
                const title = file.file_name
                const idKey = section + "-" + indexFile
                return (
                    <div key={idKey}>
                        <div
                            id={idKey}
                            key={idKey}
                            className="pubfeat-filename"
                            draggable="true"
                            onDragStart={(e) => this.dragStart(e, idKey)}
                            onDragOver={(e) => this.dragOver(e)}
                            onDragLeave={(e) => this.dragLeave(e)}
                            onDrop={(e) => this.drop(e)}
                        >
                            {title}
                        </div>
                    </div>
                )
            })
        } else {
            const idKey = section + "-0"
            fileList.push(                    
                <div
                    id={idKey}
                    key={idKey}
                    className="pubfeat-filename pubfeat-nofilename"
                    draggable="false"
                    onDragStart={(e) => this.dragStart(e)}
                    onDragOver={(e) => this.dragOver(e)}
                    onDragLeave={(e) => this.dragLeave(e)}
                    onDrop={(e) => this.drop(e)}
                >
                </div>
            ) 
        }
        return fileList
    }

    getFileList = () => {
        const sectionsOriginal = this.state.sectionsOriginal
        const sectionsUser = this.state.sectionsUser
        const theList = sectionsOriginal.map( (section, indexSection) => {
            const sectionUser = sectionsUser[indexSection]
            return (
                <div 
                    key={section}
                >
                    <div className="pubfeat-sectiontitle" >
                        {sectionUser}
                    </div>
                    {this.getFilesForSection(section, indexSection)}
                </div>
            )
        })

        return (
            <div className="pubfeat-reordercontainer">
                {theList}
            </div>
        )
    }




    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                    <div className="pubfeat-close" onClick={this.props.toggleShowModalReorderFiles}></div>
                    <div className="pubfeat-renametitle">Reorder Files</div>
                    {this.getFileList()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        saveReorderedFiles: (chapter, sectionsFilesObj) =>  dispatch(saveReorderedFiles(chapter, sectionsFilesObj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalReorderChapterFiles)

