import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
// import { Link } from "react-router-dom"
// import { NavLink } from "react-router-dom"


class ChapterEdit extends Component {
    state = {}

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }



    render(){
        return (
                        <div className="story-page-container">
            <div >CHAPTER CHAPTER CHAPTER EDIT!!!!!!</div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        user: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // toggleDone: (fileId, progressKey) =>  dispatch(toggleDone(fileId, progressKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterEdit)

