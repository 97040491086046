import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
// import { Link } from "react-router-dom"
// import { withRouter } from 'react-router-dom'  //for redirecting from a function https://tylermcginnis.com/react-router-programmatically-navigate/
import { getTeacherBooks, saveNewClassroom } from '../../../store/actions/myClassroomsActions'
import OneClassroom from "./OneClassroom"



class MyClassrooms extends Component {
    state = {
        height: 0,
        displayAddClassForm: false,
        newClassTitle: "",
        showButtonsFor: ""
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        // this.props.getTeacherBooks()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.displayAddClassForm) {
            this["newClassInput"].focus()
        }
        const prevClassrooms = prevProps.state.myClassrooms.myClassroomsAdult
        const nowClassrooms = this.props.state.myClassrooms.myClassroomsAdult
        if (!prevClassrooms || !nowClassrooms) { return }
        const prevClassroomsLength = Object.values(prevClassrooms).length
        const nowClassroomsLength = Object.values(nowClassrooms).length
        if (nowClassroomsLength > prevClassroomsLength) {
            this.setState({ 
                displayAddClassForm: false,
                newClassTitle: ""
            })
        }

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    clickedClassroomRow = (classroomId) => {
        this.setState({ showButtonsFor: "" })
        this.props.history.push("/MyClassrooms/" + classroomId)
    }
    hoverEnterClassRow = (classroomId) => {
        this.setState({ showButtonsFor: classroomId })
    }
    hoverLeaveClassRow = (classroomId) => {
        this.setState({ showButtonsFor: "" })
    }


    // >> new class title form events
    handleSubmit = (e) => {
        e.preventDefault();
        this.clickedSaveNewClass()
    }

    clickedAddClassroom = () => {
        this.setState ({displayAddClassForm: true})
    }

    clickedCloseClassForm = () => {
        this.setState ({displayAddClassForm: false})
    }

    clickedSaveNewClass = () => {
        if (this.state.newClassTitle === "") {return}
        //check for duplicate title
        const classroomsObj = this.props.state.myClassrooms.myClassroomsAdult
        if (classroomsObj) { 
            const classrooms = Object.values(classroomsObj)
            for (let i=0; i < classrooms.length; i++) {
                if (classrooms[i].title === this.state.newClassTitle) { return } //Duplicate title exists. Exit function.
            }             
        }
        this.props.saveNewClassroom(this.state.newClassTitle)
    }

    handleChangeClassTitle = (e) => {
        const targetValue = e.target.value
        this.setState ({
            newClassTitle: targetValue
        })
    }

    // << new class title form events

    updateWindowDimensions = () => {
        this.setState({ height: window.innerHeight });
    }
    

    getTableHeight = (classType) => {
        const classroomsAdult = this.props.state.myClassrooms.myClassroomsAdult
        const classroomsChild = this.props.state.myClassrooms.myClassroomsChild
        const lengthAdult = classroomsAdult ?
            classroomsAdult.length :
            0
        const lengthChild = classroomsChild ?
            classroomsChild.length :
            0
        const lengthTotal = lengthAdult + lengthChild
        const heightScreen = this.state.height - 100
        const heightAdultMax = (heightScreen) * (lengthAdult / lengthTotal)
        const heightChildMax = (heightScreen) * (lengthChild / lengthTotal)
        let heightAdult = 42 + (38 * lengthAdult) //42 is height of header, 38 is height of each body row
        let heightChild = 42 + (38 * lengthChild)
        if ((heightAdult + heightChild) > (heightScreen)) {
            if (heightAdultMax > 0 && heightChildMax > 0) {
                if (heightAdultMax > heightChildMax) {
                    if (heightChildMax < (42 + 38)) {
                        heightChild = (42 + 38)
                        heightAdult = heightScreen - heightChild
                    } else {
                        heightAdult = heightAdultMax
                        heightChild = heightChildMax
                    }
                } else {
                    if (heightAdultMax < (42 + 38)) {
                        heightAdult = (42 + 38)
                        heightChild = heightScreen - heightAdult
                    } else {
                        heightAdult = heightAdultMax
                        heightChild = heightChildMax
                    }
                }
            } else if (heightAdultMax > 0) {
                heightAdult = heightAdultMax
            } else {
                heightChild = heightChildMax
            }
        }
        if (classType === "adult") {
            return {
                // maxHeight: "calc((100vh - 200px)*"+ {proportionAdult} + ")",
                // maxHeight: heightAdult + "px",
                height: heightAdult + "px",
            }
        } else {
            return {
                // maxHeight: "calc((100vh - 200px)*"+ {proportionChild} + ")",
                // maxHeight: heightChild + "px",
                height: heightChild + "px",
            }
        }
    }

    

    getAdultClassrooms = () => {
        const classroomsAdultObj = this.props.state.myClassrooms.myClassroomsAdult
        let classroomsAdult = []
        if (classroomsAdultObj) { 
            classroomsAdult = Object.values(classroomsAdultObj)
         }

        this.sortClassrooms(classroomsAdult)
        let tableTitle = "My Classrooms"
        const heightStyle = this.getTableHeight("adult")
        let classroomsTitles = []
        for (let i=0; i < classroomsAdult.length; i++) {
            if (classroomsAdult[i] === "deleted") { continue }
            if (!classroomsAdult[i].title) {continue}
            const classroomId = classroomsAdult[i].classroomId
            let rowNoButtons = "col-100-body row-myclassrooms"
            let rowWithButtons = "rowhidden-myclassrooms"
            if (this.state.showButtonsFor === classroomId) {
                rowNoButtons = "rowhidden-myclassrooms"
                rowWithButtons = "col-100-body row-myclassrooms"
            }
            classroomsTitles.push(
                <tr
                    key={classroomId}
                    onClick={() => this.clickedClassroomRow(classroomId)}
                    onMouseEnter={() => this.hoverEnterClassRow(classroomId)}
                    onMouseLeave={() => this.hoverLeaveClassRow(classroomId)}
                >
                    <td className={rowNoButtons}>
                        {classroomsAdult[i].title}
                    </td>
                    <td className={rowWithButtons}>
                        {classroomsAdult[i].title}
                        <div className="managebtns-myclassrooms">
                            <button className="btn-oneclassroom btnmanage-myclassrooms" onClick={this.clickedKeepClass} >
                                <span>Manage Students</span>
                            </button>
                            <button className="btn-oneclassroom btnmanage-myclassrooms" onClick={this.clickedKeepClass} >
                                <span>Manage Books</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )
        }
        return (
            <div className="tableWrap-myClassrooms" style={heightStyle}>
                <table className="details-myClassrooms">
                    <thead>
                        <tr>
                            <th className="col-100-header">
                                <span className="tabletitle-myclassrooms">{tableTitle}</span> 
                                <span className="add-myclassrooms" onClick={this.clickedAddClassroom}>+</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {classroomsTitles}
                    </tbody>
                </table>
            </div>
        )
    }

    //This function is not used
    getChildClassrooms = () => {
        const classroomsChildObj = this.props.state.myClassrooms.myClassroomsChild
        if (!classroomsChildObj) { return null }
        const classroomsChild = Object.values(classroomsChildObj)
        this.sortClassrooms(classroomsChild)
        if (classroomsChild.length === 0) { return null }
        const classroomsAdult = this.props.state.myClassrooms.myClassroomsAdult
        let tableTitle = "My Classrooms"
        if (classroomsAdult) {
            tableTitle = "Child Classrooms"
        }
        const heightStyle = this.getTableHeight("child")
        let classroomsTitles = []
        for (let i=0; i < classroomsChild.length; i++) {
            if (!classroomsChild[i].title) {continue}
            classroomsTitles.push(
                <tr
                    className="row-myclassrooms"
                    key={classroomsChild[i].classroomId}
                    onClick={() => this.clickedClassroomRow("c", classroomsChild[i].classroomId)}
                >
                    <td className="col-100-body">{classroomsChild[i].title}</td>
                </tr>
            )
        }
        return (
            <div className="tableWrap-myClassrooms tableWrap-myClassrooms2" style={heightStyle} >
                <table className="details-myClassrooms" >
                    <thead>
                        <tr>
                            <th className="col-100-header">{tableTitle}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classroomsTitles}
                    </tbody>
                </table>
            </div>
        )
    }

    sortClassrooms = (classrooms) => {
        function compare(a, b) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        }
        classrooms.sort(compare);
    }
    
    getAddClassForm = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>New Classroom Title</div>
                    <div className="formnewclass-myclassrooms">
                        <form onSubmit={this.handleSubmit} >
                            <input
                                className="inputnewclass-myclassrooms"
                                type="text"
                                onChange={this.handleChangeClassTitle}
                                value={this.state.newClassTitle}
                                ref={ref => (this["newClassInput"] = ref)}
                            />
                        </form>
                    </div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseClassForm} >
                        <span>Close</span>
                    </button>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedSaveNewClass} >
                        <span>Save</span>
                    </button>
                </div>
            </div>
        )
    }

    render(){
        //redirect if the user doesnt have the proper credentials
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/SignIn' />
        const role = this.props.profile.role
        const notStudent = (role == "Admin" || role == "Publisher" || role == "Teacher")
        if (!notStudent) {
            return <Redirect to='/' />
        }


        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        const loadedFromFB = this.props.state.myClassrooms.loadedFromFB
        if (!loadedFromFB) { 
            const userId = this.props.auth.uid
            // this.props.getTeacherBooks()
            return toDisplay
        }

        const classroomsAdultToDisplay = this.getAdultClassrooms()
        //disabled 12/19/19. We're only using one type of classroom
        // const classroomsChildToDisplay = this.getChildClassrooms()

        const classroomId = this.props.match.params.classroomId;
        if (classroomId === "all") {
            let addClassForm = null
            if (this.state.displayAddClassForm) { 
                addClassForm = this.getAddClassForm() 
            }
            toDisplay = (
                <div>
                    {addClassForm}
                    <div className="myclassrooms-container">
                        {classroomsAdultToDisplay}
                        {/* //disabled 12/19/19. We're only using one type of classroom */}
                        {/* {classroomsChildToDisplay} */}
                    </div>
                </div>
            )
        
        } else {
            // let classroom = this.props.state.myClassrooms.myClassroomsAdult[classroomId] ?
            //     this.props.state.myClassrooms.myClassroomsAdult[classroomId] :
            //     this.props.state.myClassrooms.myClassroomsChild[classroomId] ?
            //         this.props.state.myClassrooms.myClassroomsChild[classroomId] :
            //         false
            const classroom = this.props.state.myClassrooms.myClassrooms[classroomId] ?
                this.props.state.myClassrooms.myClassrooms[classroomId] :
                false
    
            if (classroom) {
                toDisplay = <OneClassroom
                    classroomId={classroomId}
                    history={this.props.history}
                />
            }
        }

        return toDisplay
        
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeacherBooks: () =>  dispatch(getTeacherBooks()),
        saveNewClassroom: (newClassTitle) =>  dispatch(saveNewClassroom(newClassTitle)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyClassrooms)

