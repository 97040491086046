import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import fbConfig from './config/fbConfig'

//Reference: Lesson 14
//https://www.youtube.com/watch?v=apg98RIJfJo&index=14&list=PL4cUxeGkcC9iWstfXntcj8f-dFZ4UtlN3
import thunk from 'redux-thunk'

/*
Reference: Lesson 17/40
https://www.youtube.com/watch?v=gf5bVfVlNUM&list=PL4cUxeGkcC9iWstfXntcj8f-dFZ4UtlN3&index=17
Additional libraries for interracting and syncing to firebase/firestore.
I probably don't want to use these. Also, there is an updated version that doesn't require these?
http://docs.react-redux-firebase.com/history/v3.0.0/docs/v3-migration-guide.html
import { getFirestore } from 'redux-firestore'
*/

// These seem to be working fine eventhough I thought I was using the updated versions
import { reduxFirestore, getFirestore } from 'redux-firestore'; 
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'; //I'm using this so I can sync the store to firebase for authentication purposes. At 3:30 in the video.

//Reference: Lesson 39/44
//https://www.youtube.com/watch?v=f87wPQMgF4c&index=39&list=PL4cUxeGkcC9ij8CfkAY2RAGb-tmkNwQHG
import { createStore, applyMiddleware, compose } from 'redux'; //compose from Lesson 17
import { Provider } from 'react-redux';
import rootReducer from './store/reducers/rootReducer'
// const store = createStore(rootReducer, applyMiddleware(thunk)); //This is how the store was initiated prior to lesson 17.
const store = createStore(rootReducer, 
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reduxFirestore(fbConfig),
        reactReduxFirebase(fbConfig, {
            useFirestoreForProfile: true, //This and the following line were added from Lesson 29
            userProfile: 'users',
            attachAuthIsReady: true  //attachAuthIsReady added from lesson 26.
        })
    )
); //Added withExtraArgument so store can stay synced with authentication. See Lesson 17 and above.

//firebaseAuthIsReady (reference Lesson 26). App waits to display to the browser until the auth state is checked with Firebase.
store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
})



