import React, { Component } from 'react'
import { connect } from 'react-redux'
import { togglePublisherFeatures } from '../../../store/actions/publisherActions'
import { saveAudioFile, getTextToSpeech } from '../../../store/actions/epubActions'

class PublisherFeatures extends Component {
    // Props passed down from parent
    // toggleShowCreateBook
    // toggleShowSections
    // toggleShowUploadEpub
    // toggleShowConfirmDeleteFile
    // toggleShowConfirmDeleteChapter
    // toggleShowModalRenameChapterFile
    // toggleShowModalReorderFiles
    // toggleShowModalMarkTeacherFiles
    // toggleShowAddLink
    // toggleShowUpdateLink
    // toggleShowAddPdf
    // toggleShowCreateChapter
    // toggleSetBookCover
    // isEpub
    // bookId
    // chapter
    // chapterFile



    state = { }
    componentDidMount() { }
    componentDidUpdate(prevProps) { }
    componentWillUnmount() { }


    //actions
    uploadAudio = (e) => {
        const chapter = this.props.chapter
        const chapterFile = this.props.chapterFile
        const audioFile = e.target.files[0]
        this.props.saveAudioFile(chapter, chapterFile, audioFile)
    }
    getTTS = (e, voiceObject) => {
        const chapter = this.props.chapter
        const chapterFile = this.props.chapterFile
        const htmlText = this.state.htmlText
        this.props.getTextToSpeech(chapter, chapterFile, voiceObject)
    }



    //Buttons
    createBookButton = () => {
        const userEmail = this.props.state.firebase.auth.email
        let button = null
        if (userEmail === "bwentzel@gmail.com" || userEmail === "ajstephens@gmail.com" || userEmail == "tmjensen@mpsomaha.org" || "jeffs@gurutechnologies.net" || "vdiaz-barreiro@spsprinceton.org") {
            button = (
                <button className="pub-btncreate" onClick={this.props.toggleShowCreateBook}>
                    Create Book
                </button>
            )
        }
        return button        
    }
    copyBookButton = () => {
        const userEmail = this.props.state.firebase.auth.email
        let button = null
        if (userEmail === "bwentzel@gmail.com" || userEmail === "ajstephens@gmail.com" || "jeffs@gurutechnologies.net") {
            button = (
                <button className="pub-btncreate pub-btncopy" onClick={this.props.toggleShowCopyBook}>
                    Copy This Book
                </button>
            )
        }
        return button        
    }    
    changeChapterSectionsButton = () => {
        return (
                <button className="pub-btncreate" onClick={this.props.toggleShowSections}>
                    Set Headings
                </button>
        )
    }
    uploadEpubButton = () => {
            return (
                <button className="pub-btncreate" onClick={this.props.toggleShowUploadEpub}>
                    Upload Epub
                </button>
            )
    }
    renameFileButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowModalRenameChapterFile}>
                    Rename File
                </button>
            </div>
        )
    }
    addLinkButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowAddLink}>
                    Add Web Link
                </button>
            </div>
        )
    }
    updateLinkButton = () => {
        const contentType = this.props.chapterFile ?
            this.props.chapterFile.contentType ?
                this.props.chapterFile.contentType :
                "" :
            ""
        if ( contentType === "webLink" ) {
            return (
                <div>
                    <button className="pub-btncreate" onClick={this.props.toggleShowUpdateLink}>
                        Update Web Link
                    </button>
                </div>
            )    
        } else {
            return null
        }
    }
    addPdfButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowAddPdf}>
                    Add PDF
                </button>
            </div>
        )
    }
    reorderFilesButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowModalReorderFiles}>
                    Reorder Files
                </button>
            </div>
        )
    }
    markTeacherFilesButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowModalMarkTeacherFiles}>
                    Mark Teacher Files
                </button>
            </div>
        )
    }
    setBookCoverButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowModalSetBookCover}>
                    Book Details
                </button>
            </div>
        )
    }
    udpateJournalPrompt = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowModalUpdateJournalPrompt}>
                    Update Journal Prompt
                </button>
            </div>
        )
    }
    updateCommentButton = () => {
        return (
            <div>
                <button className="pub-btncreate" onClick={this.props.toggleShowUpdateComment}>
                    Add/Update Note
                </button>
            </div>
        )
    }
    deleteFileButton = () => {
        return (
                <button className="pub-btncreate pubfeat-deletebutton" onClick={this.props.toggleShowConfirmDeleteFile}>
                    Delete This File
                </button>
        )
    }
    deleteChapterButton = () => {
        return (
                <button className="pub-btncreate pubfeat-deletebutton" onClick={this.props.toggleShowConfirmDeleteChapter}>
                    Delete This Chapter
                </button>
        )
    }
    createChapterButton = () => {
        return (
                <button className="pub-btncreate" onClick={this.props.toggleShowCreateChapter}>
                    Create Chapter
                </button>
        )
    }
    uploadAudioButton = () => {
        return (
            <div className="pubfeat-epubbuttoncontainer">
                <label className="pub-btncreate">
                    {/* <label className="btn-oneclassroom epubupload-btn"> */}
                    <input
                        className="hide-input"
                        type="file"
                        name="file"
                        accept="audio/mpeg3"
                        onChange={this.uploadAudio}
                    />
                        Choose Audio File
                    </label>
            </div>
        )
    }
    ttsButtons = () => {
        if ( !this.props.isEpub ) { return null }

        const bookId = this.props.bookId
        const language = this.props.state.books.booksPurchased[bookId] ?
            this.props.state.books.booksPurchased[bookId]["language"] ?
                this.props.state.books.booksPurchased[bookId]["language"] :
                "English" :
            "English"
        let voiceList = this.props.state.books.ttsLanguages[language] ?
            this.props.state.books.ttsLanguages[language] :
            {
                "en-US Male 1": {
                    'languageCode': 'en-US',
                    'name': 'en-US-Wavenet-A',
                    'ssmlGender': 'MALE'
                },
            }
        if (language === "Latin") {
            voiceList = this.props.state.books.ttsLanguages["Italian"]
        }
        const voiceListArr = Object.entries(voiceList)
        const voiceButtons = voiceListArr.map( (voice) => {
            const voiceLabel = voice[0]
            const voiceObject= voice[1]
            return (    
                <div key={voiceLabel}>
                    <button className="pub-btncreate" key={voiceLabel} onClick={(e) => this.getTTS(e, voiceObject)}>
                        {voiceLabel}
                    </button>
                </div>
            )
        })
        return (
            <div>
                <div className="pubfeat-audiobuttonstitle"> OR </div>
                <div className="pubfeat-audiobuttonstitle"> Generate a Text-To-Speech File </div>
                <div className="ttsbutton-container">
                    {voiceButtons}
                </div>
            </div>
        )
    }
    audioButtons = () => {
        // if (this.props.isEpub || this.contentIsPdf() ) {
            return (
                <div className="pubfeat-audiobuttons">
                    <div className="pubfeat-audiobuttonstitle"> Upload Custom Audio File </div>
                    {this.uploadAudioButton()}
                    {this.ttsButtons()}
                </div>                
            )
        // }
    }

    // Helpers
    contentIsPdf = () => {
        const contentType = this.props.chapterFile ?
        this.props.chapterFile.contentType ?
            this.props.chapterFile.contentType :
            "" :
        ""
        return contentType.includes("pdf")
    }


    render() {
        return (
            <div className="pubfeat-container">
                <div className="pubfeat-title"> Publisher Features </div>
                <div className="pubfeat-close" onClick={this.props.togglePublisherFeatures}></div>
                <div className="pubfeat-fixedbuttons">
                    {this.createBookButton()}
                    {this.copyBookButton()}
                    {this.setBookCoverButton()}
                    {this.udpateJournalPrompt()}
                    {this.createChapterButton()}
                    {this.changeChapterSectionsButton()}
                    {this.uploadEpubButton()}
                    {this.addLinkButton()}
                    {this.updateLinkButton()}
                    {this.addPdfButton()}
                    {this.renameFileButton()}
                    {this.updateCommentButton()}
                    {this.reorderFilesButton()}
                    {this.markTeacherFilesButton()}
                    {this.deleteFileButton()}
                    {this.deleteChapterButton()}
                </div>
                {this.audioButtons()}
            </div>
        )    
    }
}



const mapStateToProps = (state, ownProps) => {    
    return { state }
}
const mapDispatchToProps = (dispatch) => {
    return {
        togglePublisherFeatures: () => dispatch(togglePublisherFeatures()),
        saveAudioFile: (chapter, chapterFile, audioFile) => dispatch(saveAudioFile(chapter, chapterFile, audioFile)),
        getTextToSpeech: (chapter, chapterFile, htmlText, voiceObject) => dispatch(getTextToSpeech(chapter, chapterFile, htmlText, voiceObject)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PublisherFeatures)


