import React from "react";

export function CertBlock() {
  return (
    <div className="cert-block">
      <img
        className="cert-image"
        src="../../../../../img/1_iKS_FERPA.png"
        alt=""
      />
      {/* <img className="cert-image" src="../../../../../img/2_iKS_COPPA.png" alt="oh no" /> */}
      <img
        className="cert-image"
        src="../../../../../img/3_iKS_CSPC.png"
        alt=""
      />
      <img
        className="cert-image"
        src="../../../../../img/4_iKS_SPC.png"
        alt=""
      />
    </div>
  );
}
