import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setStoreBooks } from '../../../store/actions/storeAction'
import ContainerAuthor from './containerAuthor'


class StorePage extends Component {
    
    componentDidMount() {

        const { authorBooks } = this.props.state.store;
        // const { books } = this.props.state.books;

        if (authorBooks.isEmpty) {
            this.props.setStoreBooks();
        }
        // if (classrooms.isEmpty) {
        //     this.props.getBooksForThisPage();
        // }
        // this.props.getBooksForThisPage();

    }

    getBooksPressed = (e) => {
        this.props.getBooksForThisPage();
    }

    getChaptersPressed = (e) => {
        this.props.getChapters()
    }


    render(){
        // const { classrooms } = this.props;

        const { authorBooks } = this.props.state.store

        // console.log("NEW STORE STATE");
        // console.log(store);


        return(
            <div className="container">
                {/* <button className="btn" onClick={this.getBooksPressed}>Get Books</button> */}
                {/* <button onClick={this.getChaptersPressed}>Get Chapters</button> */}
                <ContainerAuthor books={authorBooks} />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStoreBooks: () => dispatch(setStoreBooks())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(StorePage)  //mapDispatchToProps has to be the second parameter.  If there is no mapStateToProps, use null as the first parameter. 
