import React, { Component } from 'react'
import { connect } from 'react-redux'
import ClassContainerStudent from '../containers/ClassContainerStudent'
import { getStudentBooks } from '../../store/actions/retrieveClassBookActions'
import {  } from '../../store/actions/retrieveClassBookActions'
import { getChapters } from '../../store/actions/retrieveChapterActions'
import { loginActivity, recordMount, recordWillUnmount } from '../../store/actions/userActivityActions'
import { Redirect } from 'react-router-dom' //For route guarding. Lesson 27
import Footer from "../../components/ui/navbar/Footer"

class ClassPageStudent extends Component {
    
    componentDidMount() {
        const authFB = this.props.state.firebase.auth
        if (!authFB.isEmpty) { //If this is true, then the user is logged in
            // this.props.recordMount("Classroom");
            this.props.getStudentBooks()
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
        if (this.props.auth.uid) {
            // this.props.recordWillUnmount("Classroom");
        }
    }

    getBooksPressed = (e) => {
        this.props.loginActivity();
    }

    getChaptersPressed = (e) => {
        this.props.getChapters()
    }

    render(){
        const auth = this.props.state.firebase.auth
        if (!auth.uid) return <Redirect to='/signin' />
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        if (this.props.state.books.booksLoaded) {
            const classroomId = this.props.state.auth.classroom.classroomId
            const booksObj = this.props.state.books.booksPurchased
            const books = Object.values(booksObj)
            toDisplay = (
                <div>
                    <div className="classroom-container">
                        <ClassContainerStudent classroomId={classroomId} books={books}  />
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            )
        }

        return toDisplay
    }
}



const mapStateToProps = (state) => {
    return {
        classroomsObj: state.classrooms,
        auth: state.firebase.auth,
        user: state.auth,
        state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getStudentBooks: () => dispatch(getStudentBooks()),
        getChapters: () => dispatch(getChapters()),
        loginActivity: () => dispatch(loginActivity()),
        recordMount: (page) => dispatch(recordMount(page)),
        recordWillUnmount: (page) => dispatch(recordWillUnmount(page))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ClassPageStudent)
