import React, { Component } from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import CreateBook from "./CreateBook"
import { all } from "q"
// import { Redirect } from 'react-router-dom'
// import { Link } from "react-router-dom"


class Publisher extends Component {
    state = {
        sortBy: "title",
        showCreateBook: false,
        selectedLanguage: "all",
        selectedLevel: "all",
    }

    componentDidMount() {
        console.log("PUBLISHER did mount")
        console.log(this.props.state)
    }
    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    //click events
    clickedBook = () => {
        console.log("CLICKED BOOK")
    }
    clickedSort = (sortBy) => {
        this.setState({sortBy})
    }
    toggleCreateBook = () => {
        this.setState({ showCreateBook: !this.state.showCreateBook })
    }
    selectedLanguage = (e) => {
        console.log("SELECTED LANGUAGE")
        console.log(e.target.value)
        this.setState({selectedLanguage: e.target.value})
    }
    selectedLevel = (e) => {
        this.setState({selectedLevel: e.target.value})
    }
    
    //get data for render
    getListOfBooks = () => {
        const books = this.props.state.books.books
        if (!books || books.isEmpty) {return null}
        delete books.isEmpty
        let booksArr = Object.values(books)
        booksArr.sort(this.sortByTitle)
        // console.log("list of books")
        // console.log(booksArr)
        let listOfBooks = []
        for (let i=0; i<booksArr.length; i++) {
            //TODO: ERASE THESE NEXT TWO LINES
            // const thisBook = this.getFormattedBook(booksArr[i])
            // listOfBooks.push(thisBook)  

            // TODO: AND FINISH THIS BLOCK FOR FILTERING
            // TODO: STYLE THE PUBLISHER PAGE SO THE TITLE OF THE FIRST BOOK CAN BE SEEN
            if (this.state.selectedLanguage==="all") {
                if (this.state.selectedLevel==="all") {
                    const thisBook = this.getFormattedBook(booksArr[i])
                    listOfBooks.push(thisBook)    
                } else if (booksArr[i].languageLevel===this.state.selectedLevel) {
                    const thisBook = this.getFormattedBook(booksArr[i])
                    listOfBooks.push(thisBook)    
                }
                // console.log("AJHSHAJJFDJFSD")
                // console.log(booksArr[i].lan)
            } else if (booksArr[i].language===this.state.selectedLanguage) {
                if (this.state.selectedLevel==="all") {
                    const thisBook = this.getFormattedBook(booksArr[i])
                    listOfBooks.push(thisBook)    
                } else if (booksArr[i].languageLevel===this.state.selectedLevel) {
                    const thisBook = this.getFormattedBook(booksArr[i])
                    listOfBooks.push(thisBook)    
                }
            }
        }
        return listOfBooks
    }
    sortByTitle = (a,b) => {
        const sortBy = this.state.sortBy
        const titleA = a["title"].toLowerCase()
        const titleB = b["title"].toLowerCase()
        if (sortBy==="languageLevel") {
            //primary sort
            if (a["languageLevel"] < b["languageLevel"]) { return -1 }
            if (a["languageLevel"] > b["languageLevel"]) { return 1 }
            //secondary sort
            if (a["language"] < b["language"]) { return -1 }
            if (a["language"] > b["language"]) { return 1 }
            //third sort
            if (titleA < titleB) { return -1 }
            if (titleA > titleB) { return 1 }
            return 0;    
        }
        if (sortBy==="language") {
            //primary sort
            if (a["language"] < b["language"]) { return -1 }
            if (a["language"] > b["language"]) { return 1 }
            //secondary sort
            if (a["languageLevel"] < b["languageLevel"]) { return -1 }
            if (a["languageLevel"] > b["languageLevel"]) { return 1 }
            //third sort
            if (titleA < titleB) { return -1 }
            if (titleA > titleB) { return 1 }
            return 0;    
        }
        //This is performed if sortBy==="title"
        //primary sort
        if (titleA < titleB) { return -1 }
        if (titleA > titleB) { return 1 }
        //secondary sort
        if (a["languageLevel"] < b["languageLevel"]) { return -1 }
        if (a["languageLevel"] > b["languageLevel"]) { return 1 }
        return 0;    
    }
    sortByTitleOLD = (a,b) => { //stopped using 10/28/2019
        const sortBy = this.state.sortBy
        if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        return 0;
    }
    getFormattedBook = (book) => {
        return (
            <div key={book.bookId}>
                <div className="pub-bookblock" key={book.bookId} onClick={this.clickedBook}>
                    <div className="pub-booktitle">
                        {book.title}
                    </div>
                    <div className="pub-bookdetails" >
                        <div>
                            <span className="pub-labels">{"By: "}</span> <span>{book.book_author}</span>
                        </div>
                        <div>
                            <span className="pub-labels">{"Language: "}</span> <span>{book.language}</span>
                        </div>
                        <div>
                            <span className="pub-labels">{"Level: "}</span> <span>{book.languageLevel}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    getButtons = () => {
        let classTitle = "pub-btn pub-btnunselected"
        let classLanguage = "pub-btn pub-btnunselected"
        let classLevel = "pub-btn pub-btnunselected"
        switch (this.state.sortBy) {
            case "title":
                classTitle = "pub-btn pub-btnselected"
                break;
            case "language":
                classLanguage = "pub-btn pub-btnselected"
                break;
            case "languageLevel":
                classLevel = "pub-btn pub-btnselected"
        }
        let buttons = [
            (<button key="title" className={classTitle} onClick={() => this.clickedSort("title")}>Title</button>),
            (<button key="language" className={classLanguage} onClick={() => this.clickedSort("language")}>Language</button>),
            (<button key="languageLevel" className={classLevel} onClick={() => this.clickedSort("languageLevel")}>Level</button>)
        ]
        return buttons
    }
    getLanguages = () => {
        return ([
            (<option key={"all"} value={"all"}>All</option>),
            (<option key={"english"} value={"English"}>English</option>),
            (<option key={"Spanish"} value={"Spanish"}>Spanish</option>),
            (<option key={"French"} value={"French"}>French</option>),
            (<option key={"Italian"} value={"Italian"}>Italian</option>),
            (<option key={"German"} value={"German"}>German</option>),
            (<option key={"Chinese"} value={"Chinese"}>Chinese</option>),
            (<option key={"Korean"} value={"Korean"}>Korean</option>),
            (<option key={"Hebrew"} value={"Hebrew"}>Hebrew</option>),
        ])
    }
    getLevels = () => {
        return ([
            (<option key={"all"} value={"all"}>All</option>),
            (<option key={"1"} value={"1"}>1</option>),
            (<option key={"2"} value={"2"}>2</option>),
            (<option key={"3"} value={"3"}>3</option>),
            (<option key={"4"} value={"4"}>4</option>),    
        ])
    }

    render(){
        const listOfBooks = this.getListOfBooks()
        const languages = this.getLanguages()
        const levels = this.getLevels()
        const buttons = this.getButtons()
        const createBook = this.state.showCreateBook ?
            (<CreateBook toggleCreateBook={this.toggleCreateBook} />) :
            null

        return (
            <div className="publisher-page-container2">
                {/* <div >Hi There from Publisher View</div>
                <div>
                    <NavLink style={{ textDecoration: 'none' }} to='/BookEdit/hahaha'><span className="header-item">Book Edit</span></NavLink>
                </div>

                <div>
                    <NavLink style={{ textDecoration: 'none' }} to='/ChapterEdit/hohoho'><span className="header-item">Chapter Edit</span></NavLink>
                </div> */}
                {createBook}
                <div className="pub-buttonblock">
                    <div>
                        <h2>Books</h2>
                        <button className="pub-btncreate" type="button" onClick={this.toggleCreateBook}>
                            <span>Create Book</span>
                        </button>
                    </div>
                    <div>
                        <div className="pub-buttonslabel">
                            Filters
                        </div>
                        <div className="pub-selectcontainer1">
                            <div className="chapselect-label">Language</div>
                            <select className="select-css" onChange={this.selectedLanguage}>{languages}</select>
                        </div>
                        <div className="pub-selectcontainer2">
                            <div className="chapselect-label">Level</div>
                            <select className="select-css" onChange={this.selectedLevel}>{levels}</select>
                        </div>
                    </div>

                    {/* <div className="pub-buttonslabel">  removed 10/30/2019
                        Sort By
                    </div>
                    {buttons} */}
                </div>
                <div className="pub-lisofbooksblock">
                    {listOfBooks}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        user: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // toggleDone: (fileId, progressKey) =>  dispatch(toggleDone(fileId, progressKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Publisher)

