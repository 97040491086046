import React from "react";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { useMedia } from "use-media";
import { mediaQueries } from "../../../config/mediaQueries";
import "react-modern-drawer/dist/index.css";
import { AppNavLink } from "../AppNavLink";
import { JoinClassLinks } from "./JoinClassLinks";
import { SignedInLinksMobile } from "./SignedInLinksMobile";
import { BrandLogoLarge } from "./BrandLogo";

const SignedInLinks = ({
  clickedShowHelp,
  clickedShowCodeForm,
  clickedTogglePubFeat,
  locationPath,
  profile,
  signOut,
  theState,
}) => {
  const { role, classroom, uid } = profile;
  const isSmall = useMedia(mediaQueries.mobile);

  const isStudent = role != "Admin" && role != "Publisher" && role != "Teacher";
  const isPublisher = userIsPublisher(theState, locationPath);
  const showBooksLink = !!uid;
  const showEnterClassCodeLink = !!uid && locationPath === "/teacher";
  const isAdmin =
    uid === "9dVqqVAwzGb0svaCUOYzNjAH3s02" ||
    uid === "xrsENL073ted7CB5VrorXCBMipw2";
  const showTeachersHelpLink = locationPath.includes("MyClassrooms");

  if (isSmall) {
    return (
      <SignedInLinksMobile
        clickedShowHelp={clickedShowHelp}
        clickedShowCodeForm={clickedShowCodeForm}
        clickedTogglePubFeat={clickedTogglePubFeat}
        isPublisher={isPublisher}
        isStudent={isStudent}
        isAdmin={isAdmin}
        showBooksLink={showBooksLink}
        showEnterClassCodeLink={showEnterClassCodeLink}
        showTeachersHelpLink={showTeachersHelpLink}
        classroom={classroom}
        locationPath={locationPath}
        profile={profile}
        signOut={signOut}
      />
    );
  }

  return (
    <div>
      <AppNavLink to="/">
        <BrandLogoLarge />
      </AppNavLink>
      {locationPath.includes("joinclass") ? (
        <JoinClassLinks signOut={signOut} clickedShowHelp={clickedShowHelp} />
      ) : (
        <ul>
          {showBooksLink && (
            <li>
              <AppNavLink to="/">
                <span className="header-item">Home</span>
              </AppNavLink>
            </li>
          )}
          {showEnterClassCodeLink && (
            <li>
              <AppNavLink to="/" onClick={clickedShowCodeForm}>
                <span className="header-item">Enter a Book Code</span>
              </AppNavLink>
            </li>
          )}
          {isPublisher && (
            <li>
              <AppNavLink to="/" onClick={clickedTogglePubFeat}>
                <span className="header-item">Publisher Features</span>
              </AppNavLink>
            </li>
          )}
          {isStudent && classroom?.title && (
            <li>
              <span className="header-item-classroom">{classroom.title}</span>
            </li>
          )}
          {isStudent && (
            <li className="studentname">
              <span className="header-item-name">{profile.nameFirst}</span>
            </li>
          )}
          {isAdmin && (
            <li className="admin">
              <AppNavLink to="/adminorders">
                <span className="header-item">Orders</span>
              </AppNavLink>
            </li>
          )}
          <li className="logout">
            <AppNavLink to="/" onClick={signOut}>
              <span className="header-item">Log out</span>
            </AppNavLink>
          </li>
          {showTeachersHelpLink ? (
            <li className="help">
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                href="https://www.storylabs.online/help-for-language-teachers"
              >
                <span className="header-item">Help</span>
              </a>
            </li>
          ) : (
            <li className="help">
              <AppNavLink to="/" onClick={clickedShowHelp}>
                <span className="header-item">Help</span>
              </AppNavLink>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

const userIsPublisher = (theState, locationPath) => {
  //Check if the user is looking at the story page
  const page = locationPath.substring(0, 7);
  if (page !== "/Story/") {
    return false;
  }
  //Check if the user is an admin
  const role = theState.firebase.profile.role;
  if (role === "Admin") {
    return true;
  }
  //Check if the user is a publisher
  if (role !== "Publisher") {
    return false;
  }
  //Check if the user is the publisher of this book
  // console.log("YES USER IS A PUBLISHER")
  const ids = locationPath.substr(7);
  // console.log(ids)
  const bookId = ids.substr(0, ids.indexOf("/"));
  // console.log(bookId)
  const book = theState.books.booksPurchased[bookId];
  // console.log(book)
  // console.log(theState.auth.uid)
  const publisherId = book.publisherUid ? book.publisherUid : "";
  if (theState.auth.uid === publisherId) {
    return true;
  }
  return false;
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SignedInLinks);
