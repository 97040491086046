const initState = {
    isLoaded: false,
    progressByBook: { //updated 3/16/20
        // bookId: {
        //     progressId: progressId,
        //     completedFiles: {
        //         chapterFileId: {
        //             dateComplete: Date,
        //             isComplete: Boolean,
        //         }
        //     }
        // }
    }
}

const progressReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_PROGRESS":
            return action.classroomProgress

        case "UPDATE_PROGESS":
            const thisProgress = action.thisProgress
            // const progressKey = thisProgress.classroomId + thisProgress.bookId
            const progressKey = thisProgress.bookId
            return {
                ...state,
                [progressKey]: thisProgress
            }
        case "CREATE_PROGESS":
            const thisClassroomProgress = action.classroomProgress
            // const newProgressKey =  thisClassroomProgress.classroomId + thisClassroomProgress.bookId
            const newProgressKey = thisClassroomProgress.bookId
            return {
                ...state,
                [newProgressKey]: thisClassroomProgress
            }
        default:
            return state
    }
}

export default progressReducer