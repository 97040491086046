import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom"
// import { withRouter } from 'react-router-dom'  //for redirecting from a function https://tylermcginnis.com/react-router-programmatically-navigate/


class ViewJournals extends Component {
    state = {
        viewJournal: null
    }

    clickedJournal = (journalId, classroom) => {
        console.log("CLICKED JOURNAL ID")
        console.log(journalId)
        const studentId = this.props.match.params.studentId
        const journals = classroom ?
             classroom["studentJournals"] ?
                classroom["studentJournals"][studentId] ?
                    classroom["studentJournals"][studentId] :
                    {} :
                {} :
            {}
        const clickedJournal = journals[journalId]
        console.log("CLICKED JOURNAL")
        console.log(clickedJournal)
        this.setState({
            viewJournal: clickedJournal
        })
    }

    getClassroom = () => {
        const classroomId = this.props.match.params.classroomId
        // if (!this.props.classrooms) {
        //     return "Loading"
        // }
        const classrooms = this.props.state.classrooms.classrooms
        const classroom = classrooms[classroomId] ?
            classrooms[classroomId] :
            {}
        return classroom
    }

    getStudentName = () => {
        const classroomId = this.props.match.params.classroomId
        const studentId = this.props.match.params.studentId
        // if (!this.props.classrooms) {
        //     return ""
        // }
        const classrooms = this.props.state.classrooms.classrooms
        const studentName = classrooms[classroomId] ?
            classrooms[classroomId]["studentProfiles"] ?
                classrooms[classroomId]["studentProfiles"][studentId] ?
                    classrooms[classroomId]["studentProfiles"][studentId]["user_name"] :
                    "Unknown Studen Name" :
                "Unknown Studen Name" :
            "Unknown Studen Name"

        return studentName
    }


    getListOfJournals = (classroom) => {
        let listOfJournals = []
        const studentId = this.props.match.params.studentId
        const journals = classroom ?
             classroom["studentJournals"] ?
                classroom["studentJournals"][studentId] ?
                    classroom["studentJournals"][studentId] :
                    {} :
                {} :
            {}
        const journalsArr = Object.values(journals)
        
        https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
        console.log("JournalsARR")
        console.log(journalsArr)
        journalsArr.sort( this.journalDateCompare )

        journalsArr.forEach( (journal) => {
            const journalId = journal.chapterJournalId
            const book = this.getBookTitle(journal)
            const chapter = this.getChapterTitle(journal)
            console.log("CHAPTER")
            console.log(chapter)
            const { journalDateDay, journalDateFull } = this.getJournalDates(journal)

            const thisJournal = (
                <div className="vj-journalitem" key={journalId} onClick={() => this.clickedJournal(journalId, classroom)}>
                    <div className="vj-titles"> 
                        <div className="vj-titlebook">{book.title}</div>
                        <div className="vj-titlechapter">{chapter.title}</div>
                    </div>
                    <div className="vj-dates"> 
                        <div className="vj-dateday">{journalDateDay}</div>
                        <div className="vj-datefull">{journalDateFull}</div>
                    </div>
                </div>
            )
            listOfJournals.push(thisJournal)
        })
        return listOfJournals
    }

        
    getBookTitle = (journal) => {
        const book = this.props.state.books.books ?
            this.props.state.books.books[journal.bookId] :
            {}
        return book
    }

    getChapterTitle = (journal) => {
        const chapter = this.props.state.chapters.chapters ?
            this.props.state.chapters.chapters[journal.chapterId] ?
                this.props.state.chapters.chapters[journal.chapterId] :
                { title: "" } :
            { title: "" }
        return chapter
    }

    getJournalDates = (journal) => {
        if (journal.updatedOn) {
            const journalTimestamp = journal.updatedOn.seconds * 1000
            const journalDate = new Date(journalTimestamp);
            const optionsDateDay = { weekday: 'short' }
            const optionsDateFull = { month: 'short', day: 'numeric', year: 'numeric' }
            const journalDateDay = journalDate.toLocaleDateString("en-US", optionsDateDay)
            const journalDateFull = journalDate.toLocaleDateString("en-US", optionsDateFull)
            return {journalDateDay, journalDateFull}    
        }
        return {journalDateDay: "unknown", journalDateFull: "unknown"}
    }

    journalDateCompare = (a, b) => {
        console.log("IN COMPARE")
        console.log(a)
        console.log("B")
        console.log(b)
        if (a.updatedOn.seconds < b.updatedOn.seconds) {
            return 1;
        }
        if (a.updatedOn.seconds > b.updatedOn.seconds) {
            return -1;
        }
        return 0;
    }  


    //These two functions were replaced 9/26/19
    /*
    getJournalDates = (journal) => {
        if (journal.webUpdate) {
            const journalTimestamp = journal.webUpdate.seconds * 1000
            const journalDate = new Date(journalTimestamp);
            const optionsDateDay = { weekday: 'short' }
            const optionsDateFull = { month: 'short', day: 'numeric', year: 'numeric' }
            const journalDateDay = journalDate.toLocaleDateString("en-US", optionsDateDay)
            const journalDateFull = journalDate.toLocaleDateString("en-US", optionsDateFull)
            return {journalDateDay, journalDateFull}    
        }
        return {journalDateDay: "unknown", journalDateFull: "unknown"}
    }

    journalDateCompare = (a, b) => {
        if (a.webUpdate.seconds < b.webUpdate.seconds) {
            return 1;
        }
        if (a.webUpdate.seconds > b.webUpdate.seconds) {
            return -1;
        }
        return 0;
    }  
    */

    getJournalContent = () => {
        console.log("TRYING TO VIEW JOURNAL")
        console.log(this.state.viewJournal)
        const journalHtml = this.state.viewJournal ?
            this.state.viewJournal.htmlText ?
                this.state.viewJournal.htmlText :
                this.state.viewJournal.text ?
                    this.state.viewJournal.text :
                    "No Journal" :
            "No Journal"
        const journalContent = (
            <div className="epub-center">
                <div className="display-center">
                    <div className="display-box shadow">
                        <div className="disable-select">
                            <div dangerouslySetInnerHTML={{ __html: journalHtml }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return journalContent
    }


    componentDidMount() {
        console.log("VIEW JOURNALS - global state")
        console.log(this.props.state)
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render(){
        // let toDisplay = null
        // const classroomId = this.props.match.params.classroomId;
        // if (classroomId === "all") {
        //     toDisplay = this.getClassList()
        // } else {
        //     toDisplay = <ViewStatsClassroom classroomId={classroomId} />
        // }

        const classroom = this.getClassroom()
        const studentName = this.getStudentName()
        const listOfJournals = this.getListOfJournals(classroom)
        const journalContent = this.getJournalContent()

        const classroomName = classroom["title"] ?
            classroom["title"] :
            "Unknown Class Name"

        let toDisplay = (
            <div className="viewstats-container">
                <div className="statsClass-container">
                    <h2>{classroomName}</h2>
                    <div className="vj-studentname">
                            {studentName}'s Journals
                    </div>
                    <div className="vj-container">
                        <div className="vj-sidenav">
                            {listOfJournals}
                        </div>
                        <div className="vj-viewer">
                            {journalContent}
                        </div>
                    </div>
                </div>
            </div>
        )




        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}
/*
const mapDispatchToProps = (dispatch) => {
    return {
        changeBookName: (bookId) =>  dispatch(changeTitle(bookId)),
        getBooks: () => dispatch(getBooks()),
    }
}
*/
export default connect(mapStateToProps, null)(ViewJournals)

