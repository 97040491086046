import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getJournals, clickedJournalTitle, setJournalDisplayInfo, toggleExpandAllJournals, toggleStudentJournals } from '../../../store/actions/reportActions'

// const chapterId = this.props.match.params.chapter_id;

class JournalReport extends Component {
    state = { 
        isExpanded: false,
    }

    componentDidMount() {
        const classroomId = this.props.match.params.classroomId;
        this.props.getJournals(classroomId)
    }

    componentDidUpdate(prevProps, prevState) {
    }


    //UI Functions
    clickedTitle = (studentId, bookId, chapterId) => {
        this.props.clickedJournalTitle(studentId, bookId, chapterId)
    }
    handleSelect = (e) => {
        const elementToUpdate = e.target.id
        const elementValue = e.target.value
        this.props.setJournalDisplayInfo(elementToUpdate, elementValue)
    }
    toggleExpandAll = () => {
        const isExpanded = !this.state.isExpanded
        const journalRows = this.props.state.reports.toDisplayJournals.rows
        this.props.toggleExpandAllJournals(isExpanded)
        const studentsExpanded = {}
        journalRows.forEach( (student) => {
            studentsExpanded[student.studentId] = isExpanded
        })
        this.setState({ 
            isExpanded,
            ...studentsExpanded
        })
    }
    toggleStudentJournals = (studentId) => {
        const isExpanded = this.state[studentId] ? !this.state[studentId] : true
        this.props.toggleStudentJournals(studentId, isExpanded)
        this.setState({
            [studentId]: isExpanded
        })   
    }

    //Helper Functions

    //Render Functions
    getHeader = () => {
        const classroomId = this.props.match.params.classroomId;
        const classroom = this.props.state.myClassrooms.myClassrooms[classroomId]
        console.log("CLASSROOM")
        console.log(classroom)
        const classTitle = classroom.title
        return (
            <div className="header-oneclassroom">
                {classTitle + " Usage Report"}
            </div>
        )
    }
    getDropDowns = () => {
        const selectedBookId = this.props.state.reports.toDisplay.dropDownValues.selectedBookId
        const selectedChapterId = this.props.state.reports.toDisplay.dropDownValues.selectedChapterId
        const selectedTimePeriod = this.props.state.reports.toDisplay.dropDownValues.selectedTimePeriod
        return (
            <div className="dropdownblock-classreport">
                <div className="pub-selectcontainer3">
                    <div className="chapselect-label">Book</div>
                    <select 
                        className="select-css" 
                        id="selectedBookId" 
                        value = {selectedBookId}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownBook")}
                    </select>
                </div>
                <div className="pub-selectcontainer3 selectmiddle-classreport">
                    <div className="chapselect-label">Chapter</div>
                    <select 
                        className="select-css" 
                        id="selectedChapterId" 
                        value = {selectedChapterId}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownChapter")}
                    </select>
                </div>
                <div className="pub-selectcontainer3">
                    <div className="chapselect-label">Time Period</div>
                    <select 
                        className="select-css" 
                        id="selectedTimePeriod" 
                        value = {selectedTimePeriod}
                        onChange={this.handleSelect}
                    >
                        {this.getDropDownList("dropDownTimePeriod")}
                    </select>
                </div>
            </div>
        )
    }
    getDropDownList = (listLabel) => {
        const list = this.props.state.reports.toDisplay.dropDownLists[listLabel]
        const listElements = list.map( (listItem) => {
            return (
                <option key={listItem[0]} value={listItem[0]}>{listItem[1]}</option>
            )
        })
        return listElements            
    }

    expandAllButton = () => {
        return (
            <button className="journal-btnexpand" onClick={this.toggleExpandAll}>
                Expand/Collapse All
            </button>
        )
    }

    getTable = () => {
        return (
            <div className="tableWrap-oneclassroom" >
                <table className="table-oneclassroom">
                    <thead>
                        <tr>
                            <th className="col-100-header-journalreport" >
                                {this.expandAllButton()}
                                <span > Name / Book / Chapter / Journal</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getUserRows()}
                    </tbody>
                </table>
            </div >
        )
    }

    getUserRows = () => {
        {/* rows: [ */}
            // {
            //     studentId,
            //     nameFirst,
            //     books: [
            //         {
            //             bookId,
            //             bookTitle,
            //             chapters: [
            //                 {
            //                     chapterId,
            //                     chapterTitle,
            //                     date,
            //                     htmlText
            //                 }
            //             ]
            //         }
            //     ]
            // }
        {/* ], */}

        const journalRows = this.props.state.reports.toDisplayJournals.rows
        console.log("JOURNAL ROWS")
        console.log(journalRows)
        const journalRowElements = journalRows.map( (journalRow) => {
            let studentRow = null
            let bookRows = []
            const studentId = journalRow.studentId
            const studentName = journalRow.nameFirst
            studentRow = (
                <div>
                    {/* <i class="material-icons">add_circle_outline</i> */}
                    {/* <i class="material-icons">remove_circle_outline</i> */}
                    {/* <i class="material-icons">keyboard_arrow_right</i> */}
                    {/* <i class="material-icons">keyboard_arrow_down</i> */}
                   <span className="titles-journalreport" onClick={() => this.clickedTitle(studentId, null, null)}>
                        {/* <i class="material-icons">add_circle_outline</i> */}
                        <span className="duh"> {studentName} </span>
                    </span>
                    <button className="journal-btnexpand journal-btnstudent" onClick={() => this.toggleStudentJournals(studentId)}>
                            +/-
                    </button>
                </div>
            )
            if (journalRow.books.length > 0) {
                bookRows = this.getBookRows(studentId, journalRow.books)
            }
            const journalRowElement = (
                <tr key={studentId}>
                    <td className="col-100-body tdlastactivity-oneclassroom">
                        {studentRow}
                        {bookRows}
                    </td>
                </tr>
            )
            return journalRowElement
        })
        return journalRowElements
    }

    getUserRowsSAMPLE = () => {
        {/* const expandIcon =  "../../../../../img/removeIcon.svg" */}
        const expandIcon =  "../../../../../img/plus.svg"
        {/* const collapseIcon = "../../../../../img/addIcon.svg" */}
        const collapseIcon = "../../../../../img/minus.svg"
        
        const journalHTML = `
            <div>Write Your Story: </div><div><br></div><div>La anaranjado es la amarillo. La anaranjade le llama Dorado.  Dorado esta contenta y fuerts. Dorado se rie de, Morado quiere beber Rosado.  Rosado triste.  Dorado llora,  Por Que? Por Que?  Dorado se lervanta y camina a la vasa.   Finish.  </div><div><br></div><div>SMART story goals</div><div>Following these daily exercises will build proficiency around story.&nbsp;The goals for each day is as follows:&nbsp;Monday = I can comprehend most of the story.&nbsp;Tuesday = I can answer questions about the story in English.&nbsp;Wednesday = I can ask questions about the story in Spanish.&nbsp;Thursday = I can retell the story in my own words.&nbsp;Friday = I can write my own version of the story.</div><div>The story I am reading this week is:</div><div>Baby Orange</div><div>Other:</div><div>Monday - 1: I read and listened to the story this many times. (2 times required)</div><div><br></div><div>twice</div><div><br></div><div>Monday - 2: I understood this much of the story.</div><div>None of it</div><div>1</div><div>2</div><div>3</div><div>4</div><div>5</div><div><br></div><div>Monday - 3: I don't understand this....</div><div>I understnad this enough, but not really well.</div><div>Monday - 4: I worked on “Quizlet - Story” Learn . This was my score:</div><div>100%</div><div>Monday - 5: I worked on “Quizlet - Vocabulary” Match. This was my best time:</div><div>I couldn't find where to access it. </div><div><br></div>
        `
        return (
            [<tr >
                <td className="col-100-body tdlastactivity-oneclassroom" >
                    {/* <img className="btns-journalreport" src={expandIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                    {/* <img className="btns-journalreport" src={collapseIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                    <span> hooga booga nooga nooga</span>
                </td>
            </tr>,
            <tr >
                <td className="col-100-body tdlastactivity-oneclassroom" >
                    <div>
                        {/* <img className="btns-journalreport" src={expandIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        {/* <img className="btns-journalreport" src={collapseIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        <span className="titles-journalreport" onClick={() => this.clickedTitle()}>Name</span>
                    </div>
                    <div className="secondline-journareport">
                        {/* <img className="btns-journalreport" src={expandIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        {/* <img className="btns-journalreport" src={collapseIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        <span className="titles-journalreport" onClick={() => this.clickedTitle()}>Book</span>
                    </div>
                    <div className="thirdline-journareport">
                        {/* <img className="btns-journalreport" src={expandIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        {/* <img className="btns-journalreport" src={collapseIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                        <span className="titles-journalreport" onClick={() => this.clickedTitle()}>Chapter</span>
                    </div>
                    <div className="display-journareport">
                        <div  dangerouslySetInnerHTML={{ __html: journalHTML }}></div>
                    </div>
                </td>
            </tr>,
            <tr >
                <td className="col-100-body tdlastactivity-oneclassroom" >
                    {/* <img className="btns-journalreport" src={expandIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                    {/* <img className="btns-journalreport" src={collapseIcon} alt="" onClick={() => this.clickedRemoveBook()} /> */}
                    hooga booga nooga nooga
                </td>
            </tr>
            ]
        )
    }


    getBookRows = (studentId, books) => {
        let bookRows = []
        books.forEach( (book) => {
            let bookRow = null
            let chapterRows = []
            const bookId = book.bookId
            const bookTitle = book.bookTitle
            bookRow = (
                <div className="secondline-journareport">
                    <span className="titles-journalreport" onClick={() => this.clickedTitle(studentId, bookId, null)}>{bookTitle}</span>
                </div>
            )
            {/* console.log("CHAPTERS!!!!!!") */}
            {/* console.log(book.chapters) */}
            if (book.chapters.length > 0) {
                chapterRows = this.getChapterRows(studentId, bookId, book.chapters)
            }
            {/* console.log(chapterRows) */}
            bookRows.push(bookRow)
            {/* console.log(chapterRows) */}
            {/* bookRows.concat(chapterRows) */}
            chapterRows.forEach( (chapterRow) => {
                if (chapterRow !== null) {
                    bookRows.push(chapterRow)
                }
            })
            {/* console.log(chapterRows) */}
        })
        return bookRows
    }

    getChapterRows = (studentId, bookId, chapters) => {
        let chapterRows = []
        chapters.forEach( (chapter) => {
            let chapterRow = null
            let journalRow = null
            const chapterId = chapter.chapterId
            const chapterTitle = chapter.chapterTitle
            const date = chapter.date
            const htmlText = chapter.htmlText
            chapterRow = (
                <div className="thirdline-journareport">
                    <span className="titles-journalreport" onClick={() => this.clickedTitle(studentId, bookId, chapterId)}>{chapterTitle}</span>
                </div>
            )
            if (htmlText) {
                journalRow = (
                    <div className="display-journareport">
                        <div  dangerouslySetInnerHTML={{ __html: htmlText }}></div>
                    </div>
                )
            }
            chapterRows.push(chapterRow)
            chapterRows.push(journalRow)
        })
        return chapterRows
    }


    render(){

        // const usersAll = this.props.state.adminData.usersAll
        const userSL = this.props.state.firebase.auth
        if (!userSL.uid) return <Redirect to='/' />
    
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        if (this.props.state.reports.journals !== "loading") {
            toDisplay = (
                <div className="oneclassrooms-container">
                    {this.getHeader()}
                    {this.getDropDowns()}
                    {this.getTable()}
                </div>
            )
        }



        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJournals: (classroomId) =>  dispatch(getJournals(classroomId)),
        clickedJournalTitle: (studentId, bookId, chapterId) =>  dispatch(clickedJournalTitle(studentId, bookId, chapterId)),
        setJournalDisplayInfo: (elementToUpdate, elementValue) => dispatch(setJournalDisplayInfo(elementToUpdate, elementValue)),
        toggleExpandAllJournals: (isExpanded) => dispatch(toggleExpandAllJournals(isExpanded)),
        toggleStudentJournals: (studentId, isExpanded) => dispatch(toggleStudentJournals(studentId, isExpanded))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JournalReport)

