import React, { Component } from 'react'
import { connect } from 'react-redux'


class CreateBook extends Component {
    
    state = {
        journalHtml: "",
        text: "",
        show: false,
        chapter: null
    }
    componentDidMount() {
        console.log("PUBLISHER did mount")
        console.log(this.props.state)
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
    }

    //click events
    handleChange = (value, delta, source, editor) => {
    }
    saveJournal = () => {
    }


    render(){
        return (            
            <div className="pub-cbookbackground">
                <div className="pub-cbookcontent">
                    <span className="pub-cbookclose" onClick={this.props.toggleCreateBook}>&times;</span>
                    <div>Hi from Create Book</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        state,
        user: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBook)
