import React, { useState } from 'react';
import SideNavChapExpand from './SideNavChapExpand';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useMedia } from "use-media";
import { mediaQueries } from "../../../config/mediaQueries";

export const ResponsiveSideNavExpand = (props) => {
    let [isOpen, setIsOpen] = useState(false);

    const handleDrawerToggleClick = () => {
        setIsOpen(prev => !prev);
    };

    const isSmall = useMedia(mediaQueries.mobile);

    const sideNavExpand = (
      <SideNavChapExpand
        user={props.user}
        chapters={props.chapters}
        classId={props.classId}
        book={props.book}
        bookId={props.bookId}
        bookCoverUrl={props.bookCoverUrl}
        chapterId={props.chapterId}
        progress={props.progress}
        showChapterFiles={props.showChapterFiles}
        clickedChapterFile={(e, chapterFile, isToggle) => {
          handleDrawerToggleClick();
          props.clickedChapterFile(e, chapterFile, isToggle);
        }}
        clickedChapter={(chapterId) => {
          handleDrawerToggleClick();
          props.clickedChapter(chapterId);
        }}
        showJournal={() => {
          handleDrawerToggleClick();
          props.showJournal(props);
        }}
        chapterFile={props.chapterFile}
        toggleComments={props.toggleComments}
      />
    );

    if (isSmall) {
        return (
            <div className='ajs-sidenav-drawer-container'>
                <div className='ajs-sidenav-menu-hint'>
                    <button
                        type="button"
                        className="drawer-button"
                        onClick={handleDrawerToggleClick}
                    >
                        <img src="../../../../img/iconmonstr-menu-1.svg" alt="" />
                    </button>
                </div>
                <Drawer open={isOpen} direction="left" className='ajs-sidenav-drawer'>
                    <div className="drawer-close-box">
                        <button
                            type="button"
                            className="drawer-button"
                            onClick={handleDrawerToggleClick}
                            >
                            <img src="../../../../img/x-close.svg" alt="" />
                        </button>
                    </div>
                    {sideNavExpand}
                </Drawer>
            </div>
        );
    }

    return sideNavExpand;
};
