import firebase from 'firebase/app'
const db = firebase.firestore()
const storageRef = firebase.storage().ref();

export const saveNewMasterpiece = (fileName, text, chapter) => {
    return (dispatch, getState) => {

        // payload = {
        //     chapterId,
        //     file_aray,
        //     newFile: {
        //         contentType,
        //         fileId,
        //         fileSection,
        //         file_name,
        //         story,
        //         key    
        //     }
        // }
        // console.log("IN saveMasterpiece")
        // console.log(text)
        // console.log(chapter)



        let file_array = chapter.file_array
        let chapterId = chapter.chapterId

        const newFile = {
            contentType: "quillHtml",
            fileId: uuidv4(),
            fileSection: "Introduce",
            file_name: fileName,
            htmlText: text,
            key: file_array.length
        };

        console.log(chapterId)
        console.log(newFile)

        const refChapter = db.collection("chapters_v2").doc(chapterId);


        refChapter.update({
            file_array: firebase.firestore.FieldValue.arrayUnion(newFile)
        })
            .then(function () {

                console.log("SAVE NEW MASTERPIECE Document successfully written!");
                file_array.push(newFile)
                // chapter.file_array = file_array
                const allChapters = getState().chapters.chapters
                const chapter2 = allChapters[chapterId] ?
                    allChapters[chapterId] :
                    null
                dispatch({ type: "Testing" })

                // console.log("Chapter")
                // console.log(chapter)
                // console.log("Chapter2")
                // console.log(chapter2)
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    }
};


//This can be used from ShowEpub to save save the epub html text directly in the chapter file. This probably won't ever be used.
export const saveMasterpiece = (chapter, chapterFile, newText) => {
    return (dispatch, getState) => {

        console.log("In saveMasterpiece")
        // console.log(chapterFile)
        // console.log(newText)

        chapterFile["htmlText"] = newText
        const fileIndex = chapterFile["key"]
        const file_array = chapter["file_array"]
        file_array[fileIndex] = chapterFile
        const chapterId = chapter.chapterId
        // console.log(file_array)

        const refChapter = db.collection("chapters_v2").doc(chapterId);

        refChapter.update({
            "file_array": file_array
        })
            .then(function () {
                console.log("Document successfully written!");
                dispatch({ type: "Testing" })
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    }
};

//This can be used from ShowQuill to update the quillDelta and quill htmlText properties of the chapter file.
export const updateQuillChapterFile = (chapter, chapterFile, quillDelta, htmlText) => {
    return (dispatch, getState) => {

        chapterFile["htmlText"] = htmlText
        // https://www.w3schools.com/js/js_json_stringify.asp
        chapterFile["quillDelta"] = JSON.stringify(quillDelta)
        chapterFile["contentType"] = "quillHtml"
        const fileIndex = chapterFile["key"]
        const file_array = chapter["file_array"]
        file_array[fileIndex] = chapterFile
        const chapterId = chapter.chapterId
        // console.log(file_array)

        const refChapter = db.collection("chapters_v2").doc(chapterId);

        refChapter.update({
            "file_array": file_array
        })
            .then(function () {
                console.log("Document successfully written!");
                dispatch({ type: "Testing" })
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    }
};

export const deleteChapterFile = (chapterFile, chapter) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        getChapterFileArray(chapter.chapterId, (fileArray) => {
            if (fileArray === "fail") {
                dispatch({ type: "HIDE_SPINNER" })
                return
            }
            deleteStorageFiles(chapterFile, chapter, (deleteSuccess) => {
                const filteredFileArray = filterOutChapterFile(fileArray, chapterFile)
                updateFileArrayFS(filteredFileArray, chapter.chapterId, (updateSuccess) => {
                    chapter.file_array = filteredFileArray
                    if (updateSuccess) {
                        dispatch({ type: "UPDATE_CHAPTER", chapter })
                    }
                    dispatch({ type: "HIDE_SPINNER" })
                })
            })
        })
    }
}

export const deleteChapter = (book, chapter) => {
    return (dispatch, getState) => {
        const bookId = book.bookId
        const chapterId = chapter.chapterId
        // Remove the deleted chapterId from the book chapterOrderArr
        const chapterOrderArr = book.chapterOrderArr
        const indexToRemove = chapterOrderArr.indexOf(chapterId);
        if (indexToRemove > -1) {
            chapterOrderArr.splice(indexToRemove, 1);
        }

        const batch = db.batch();
        // Save the new chapterOrderArr to the book
        const bookRef = db.collection("books").doc(bookId);
        batch.update(bookRef, { chapterOrderArr: chapterOrderArr });
        // Mark the chapter as deleted
        const chapterRef = db.collection("chapters_v2").doc(chapterId);
        batch.update(
            chapterRef,
            {
                chapterDeleted: true,
                dateDeleted: new Date()
            }
        )
        // Commit the batch
        batch.commit()
            .then(() => {
                book.chapterOrderArr = chapterOrderArr
                const bookChapters = getState().books.chaptersByBook[bookId]
                delete bookChapters[chapterId]
                dispatch({ type: "DELETE_CHAPTER", book, bookChapters })
            })
            .catch((error) => {
                // The document probably doesn't exist.
                chapterOrderArr.splice(indexToRemove, 0, chapter.chapterId)
                console.error("Error deleting chapter: ", error);
            });
    }
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

export const togglePublisherFeatures = () => {
    return (dispatch, getState) => {
        dispatch({ type: "TOGGLE_PUBLISHER_FEATURES" });
    }
}

const getChapterFileArray = (chapterId, callback) => {
    var docRef = db.collection("chapters_v2").doc(chapterId);
    docRef.get().then(function (doc) {
        if (doc.exists) {
            const fileArray = doc.data().file_array
            callback(fileArray)
        } else {
            console.log("No such document!");
            callback("fail")
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
        callback("fail")
    });
}
const filterOutChapterFile = (fileArray, chapterFile) => {
    const filteredFileArray = fileArray.filter(file => {
        return file.fileId !== chapterFile.fileId
    })
    return filteredFileArray
}
const deleteStorageFiles = (chapterFile, chapter, callback) => {
    const thisBookId = chapter.bookId
    const thisChapterId = chapter.chapterId
    const thisFileId = chapterFile.fileId
    const rootStoragePath = "books/" + thisBookId + "/" + thisChapterId + "/" + thisFileId + "/"
    const listRef = storageRef.child(rootStoragePath)
    listRef.listAll().then(function (snapshot) {
        let promises = []
        snapshot.items.forEach(fileRef => {
            const fullRef = rootStoragePath + fileRef.name
            promises.push(storageRef.child(fullRef).delete())
        })
        Promise.all(promises).then((deleteResults) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            callback(true);
        }, function (err) {
            console.log("Could not delete all files: ", err)
            callback(false)
        });
    }, function (err) {
        console.log("Could not list all files: ", err)
        callback(false)
    })
}
const updateFileArrayFS = (newFileArray, chapterId, callback) => {
    const ref = db.collection("chapters_v2").doc(chapterId);
    ref.update({
        file_array: newFileArray
    })
        .then(function () {
            callback(true)
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            callback(false)
        });
}

export const renameChapterFile = (chapter, chapterFile, newChapterFileName) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        chapterFile.file_name = newChapterFileName
        const newFileArray = replaceChapterFileInArray(chapter, chapterFile)
        updateFileArrayFS(newFileArray, chapter.chapterId, (updateSuccess) => {
            chapter.file_array = newFileArray
            if (updateSuccess) {
                dispatch({ type: "UPDATE_CHAPTER", chapter })
            }
            dispatch({ type: "HIDE_SPINNER" })
        })
    }
}
export const updateChapterFile = (chapter, newChapterFile) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })
        const newFileArray = replaceChapterFileInArray(chapter, newChapterFile)
        updateFileArrayFS(newFileArray, chapter.chapterId, (updateSuccess) => {
            if (updateSuccess) {
                // console.log("It Worked!")
                chapter.file_array = newFileArray
                dispatch({ type: "UPDATE_CHAPTER", chapter })
            }
            // dispatch({ type: "HIDE_SPINNER" })
        })
    }
}
const replaceChapterFileInArray = (chapter, newChapterFile) => {
    const newFileArray = chapter.file_array
    const fileIndex = getFileIndex(newFileArray, newChapterFile)
    newFileArray[fileIndex] = newChapterFile
    return newFileArray
}
const getFileIndex = (fileArray, chapterFile) => {
    let fileId = chapterFile.fileId
    for (let i = 0; i < fileArray.length; i++) {
        if (fileArray[i].fileId === fileId) {
            return i;
        }
    }
}



export const updateJournalPrompt = (chapter) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })
        const ref = db.collection("chapters_v2").doc(chapter.chapterId);
        ref.update({
            journalPrompt: chapter.journalPrompt
        })
            .then(function () {
                dispatch({ type: "UPDATE_CHAPTER", chapter })
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // dispatch({ type: "HIDE_SPINNER" })
    }
}


export const saveWebLink = (chapter, fileName, webLink, section) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })
        const newFile = {
            contentType: "webLink",
            fileId: uuidv4(),
            fileSection: section,
            file_name: fileName,
            firebase_storage_url: webLink,
        };
        const refChapter = db.collection("chapters_v2").doc(chapter.chapterId);
        refChapter.update({
            file_array: firebase.firestore.FieldValue.arrayUnion(newFile)
        })
            .then(function () {
                chapter.file_array.push(newFile)
                dispatch({ type: "UPDATE_CHAPTER", chapter })
                // dispatch({ type: "HIDE_SPINNER" })
            })
            .catch(function (error) {
                console.error("Error saving new file: ", error);
            });
    }
}

export const uploadPdf = (chapter, section, fileName, pdfFile) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        const fileId = uuidv4()
        savePdfToStorage(chapter.bookId, chapter.chapterId, fileId, pdfFile, (pdfUrl) => {
            saveNewChapterFile(chapter.chapterId, section, fileId, fileName, pdfUrl, (newFile) => {
                chapter.file_array.push(newFile)
                dispatch({ type: "UPDATE_CHAPTER", chapter })
                dispatch({ type: "HIDE_SPINNER" })
            })
        })
    }
}

const savePdfToStorage = (bookId, chapterId, fileId, pdfFile, callback) => {
    const rootStoragePath = "books/" + bookId + "/" + chapterId + "/" + fileId + "/"
    const storagePath = rootStoragePath + "pdfFile.pdf"
    storageRef.child(storagePath).put(pdfFile).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then((pdfUrl) => {
            callback(pdfUrl)
        })
    }, function (err) {
        console.log("Could not save pdf file")
    })
}
const saveNewChapterFile = (chapterId, section, fileId, fileName, pdfUrl, callback) => {
    const newFile = {
        contentType: "application/pdf",
        fileId: fileId,
        fileSection: section,
        file_name: fileName,
        firebase_storage_url: pdfUrl
    };
    const refChapter = db.collection("chapters_v2").doc(chapterId);
    refChapter.update({
        file_array: firebase.firestore.FieldValue.arrayUnion(newFile)
    })
        .then(function () {
            callback(newFile)
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}


export const saveReorderedFiles = (chapter, sectionsFilesObj) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })
        const newFileArray = getFileArrFromObj(getState().books.sectionsOriginal, sectionsFilesObj)
        updateFileArrayFS(newFileArray, chapter.chapterId, (updateSuccess) => {
            chapter.file_array = newFileArray
            if (updateSuccess) {
                dispatch({ type: "UPDATE_CHAPTER", chapter })
            }
            // dispatch({ type: "HIDE_SPINNER" })
        })
    }
}
const getFileArrFromObj = (sectionsOriginal, sectionsFilesObj) => {
    let newFileArray = []
    sectionsOriginal.forEach((section) => {
        if (sectionsFilesObj[section]) {
            newFileArray = newFileArray.concat(sectionsFilesObj[section])
        }
    })
    return newFileArray
}

export const markTeacherFile = (chapter, chapterFile) => {
    return (dispatch, getState) => {
        // // dispatch({ type: "SHOW_SPINNER" })
        const newChapterFile = updateFileAccess(chapterFile)
        const newFileArray = replaceChapterFileInArray(chapter, newChapterFile)
        updateFileArrayFS(newFileArray, chapter.chapterId, (updateSuccess) => {
            chapter.file_array = newFileArray
            if (updateSuccess) {
                dispatch({ type: "UPDATE_CHAPTER", chapter })
            }
            // dispatch({ type: "HIDE_SPINNER" })
        })
    }
}
const updateFileAccess = (chapterFile) => {
    const accessLevel = chapterFile.fileAccessLevel ?
        chapterFile.fileAccessLevel :
        "Student"
    if (accessLevel === "Teacher") {
        chapterFile.fileAccessLevel = "Student"
    } else {
        chapterFile.fileAccessLevel = "Teacher"
    }
    return chapterFile
}


export const createChapter = (book, chapterTitle) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        createChapterFS(book, chapterTitle, (chapter, book) => {
            dispatch({ type: "UPDATE_BOOK", book })
            dispatch({ type: "UPDATE_CHAPTER", chapter })
            dispatch({ type: "NEW_CHAPTER_CREATED", chapter })
            dispatch({ type: "HIDE_SPINNER" })
        })
    }
}

const createChapterFS = (book, chapterTitle, callback) => {
    // Create a batch to perform an atomic operation
    const batch = db.batch()
    const chapterRef = db.collection("chapters_v2").doc()
    const chapterId = chapterRef.id
    const dateCreated = new Date()
    const chapter = {
        bookId: book.bookId,
        title: chapterTitle,
        file_array: [],
        journalPrompt: "",
        createTime: dateCreated,
        updatedOn: dateCreated,
    }
    batch.set(chapterRef, chapter) // Add create chapter to the batch

    const bookRef = db.collection("books").doc(book.bookId)
    batch.update( // Add update the book chapterOrderArr to the batch
        bookRef,
        { chapterOrderArr: firebase.firestore.FieldValue.arrayUnion(chapterId) }
    )

    batch.commit() // Execute the batch
        .then(function () {
            chapter.chapterId = chapterId
            book.chapterOrderArr.push(chapterId)
            callback(chapter, book)
        })
        .catch(function (error) {
            console.error("Error creating new chapter: ", error);
        });
}


export const getPublishers = () => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        const publishers = []
        var usersRef = db.collection("users");
        var usersQuery = usersRef.where("role", "in", ["Publisher", "Admin"]);
        usersQuery.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                publishers.push({
                    publisherUid: doc.id,
                    ...doc.data()
                })
            })
            publishers.sort((a, b) => (a.user_name > b.user_name) ? 1 : -1)
            dispatch({ type: "SET_PUBLISHERS", publishers })
            dispatch({ type: "HIDE_SPINNER" })
        })
            .catch((error) => {
                dispatch({ type: "HIDE_SPINNER" })
                console.log("Error getting publishers: ", error);
            });
    }
}


const getChapter = (chaptersByBook, bookId, chapterId) => {
    const chapter = chaptersByBook[bookId] ?
        chaptersByBook[bookId][chapterId] ?
            chaptersByBook[bookId][chapterId] :
            null :
        null
    return chapter
}

export const copyBook = (copiedBook, bookTitle, publisherUid) => {
    return (dispatch, getState) => {
        console.log("COPYING")
        dispatch({ type: "SHOW_SPINNER" })

        const batch = db.batch()

        const dateCreated = new Date()

        const bookRef = db.collection("books").doc()
        const newBookId = bookRef.id

        const newBook = {
            ...copiedBook,
            bookIdCopied: copiedBook.bookId,
            dateCopied: dateCreated,
            publisherUid: publisherUid,
            title: bookTitle,
            createTime: dateCreated,
            updatedOn: dateCreated
        }
        delete newBook.bookId

        const chapterOrderArrOld = copiedBook.chapterOrderArr
        const chapterOrderArrNew = []
        const chapterObjsArr = []

        chapterOrderArrOld.forEach((chapterIdOld) => {
            const chapterObjectOld = getChapter(getState().books.chaptersByBook, copiedBook.bookId, chapterIdOld)
            if (chapterObjectOld === null) { return }
            const chapterRef = db.collection("chapters_v2").doc()
            const chapterIdNew = chapterRef.id
            chapterOrderArrNew.push(chapterIdNew)
            const chapterObjNew = {
                ...chapterObjectOld,
                bookId: newBookId,
                createTime: dateCreated,
                updatedOn: dateCreated,
            }
            delete chapterObjNew.chapterId
            chapterObjsArr.push(chapterObjNew)
            batch.set(chapterRef, chapterObjNew)
        })

        newBook.chapterOrderArr = chapterOrderArrNew

        batch.set(bookRef, newBook)

        batch.commit() // Execute the batch
            .then(function () {
                giveBookAccess(newBookId, publisherUid)
                newBook.bookId = newBookId
                const chaptersForBook = {}
                chapterObjsArr.forEach((chapterObj, i) => {
                    const chapterId = chapterOrderArrNew[i]
                    chapterObj["chapterId"] = chapterId
                    chaptersForBook[chapterId] = chapterObj
                })
                const book = newBook
                dispatch({ type: "UPDATE_BOOK", book })
                dispatch({ type: "SET_NEW_CHAPTERS", newBookId, chaptersForBook })
                dispatch({ type: "HIDE_SPINNER" })
                console.log("DONE COPYING")
            })
            .catch(function (error) {
                console.error("Error copying new book: ", error);
            });
    }
}



export const createBook = (bookTitle, publisherUid) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        createBookFS(bookTitle, publisherUid, (chapter, book) => {
            giveBookAccess(book.bookId, publisherUid)
            dispatch({ type: "UPDATE_CHAPTER", chapter })
            dispatch({ type: "UPDATE_BOOK", book })
            // dispatch({ type: "NEW_CHAPTER_CREATED", chapter })
            dispatch({ type: "HIDE_SPINNER" })
        })
    }
}
const createBookFS = (bookTitle, publisherUid, callback) => {
    // Create a batch to perform an atomic operation
    const chapterRef = db.collection("chapters_v2").doc()
    const chapterId = chapterRef.id
    const bookRef = db.collection("books").doc()
    const bookId = bookRef.id
    const dateCreated = new Date()

    const chapter = {
        bookId: bookId,
        title: "Chapter 1",
        file_array: [],
        journalPrompt: "",
        createTime: dateCreated,
        updatedOn: dateCreated,
    }

    const book = {
        bookContentType: "Book",
        book_author: "",
        chapterOrderArr: [chapterId],
        createTime: dateCreated,
        is_public: false,
        language: "",
        languageLevel: "1",
        publisherUid: publisherUid,
        title: bookTitle,
        updatedOn: dateCreated
    }

    const batch = db.batch()
    batch.set(chapterRef, chapter)
    batch.set(bookRef, book)

    batch.commit() // Execute the batch
        .then(function () {
            chapter.chapterId = chapterId
            book.bookId = bookId
            callback(chapter, book)
        })
        .catch(function (error) {
            console.error("Error creating new chapter: ", error);
        });
}
const giveBookAccess = (bookId, publisherUid) => {
    const payerUid = [
        "9dVqqVAwzGb0svaCUOYzNjAH3s02",  //Aaron
        "xrsENL073ted7CB5VrorXCBMipw2",  //Brook
        publisherUid  //Publisher
    ]
    const purchaseOrderId = "publisher"
    const books = [
        bookId,
    ]
    recordOrderInFSforPuids(payerUid, purchaseOrderId, books)
}
const recordOrderInFSforPuids = (payerUid, purchaseOrderId, books) => {
    const paRef = db.collection("purchaseAccess")
    const newDate = new Date()
    let i = 0
    payerUid.forEach(pUid => {
        const docs = books.map(itemId => {
            return {
                createTime: newDate,
                daysPurchased: 365,
                itemId: itemId,
                payerUid: pUid,
                purchaseOrderId: purchaseOrderId,
                quantity: 1,
                updatedOn: newDate
            }
        })
        docs.forEach(doc => {
            paRef.add(doc)
                .then(function (docRef) {
                    i = i + 1
                    // console.log("pUid: " + pUid)
                    console.log("Saved: " + i);
                    // console.log("Document written with ID: ", docRef.id);
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
        })
    })

}





export const resetNewChapterCreated = () => {
    return (dispatch, getState) => {
        dispatch({ type: "RESET_NEW_CHAPTER_CREATED" })
    }
}

// For ModalSetBookDetails
export const saveBookDetails = (book, chapters, newBookTitle, newChapterTitles) => {
    return (dispatch, getState) => {
        if (book.title !== newBookTitle) {
            saveNewBookTitle(dispatch, book, newBookTitle)
        }
        chapters.forEach((chapter) => {
            if (chapter.title !== newChapterTitles[chapter.chapterId]) {
                saveNewChapterTitle(dispatch, chapter, newChapterTitles[chapter.chapterId])
            }
        })
    }
}
const saveNewBookTitle = (dispatch, book, newBookTitle) => {
    const refBook = db.collection("books").doc(book.bookId);
    refBook.update({
        title: newBookTitle
    })
        .then(function () {
            book.title = newBookTitle
            dispatch({ type: "UPDATE_BOOK", book })
        })
        .catch(function (error) {
            console.error("Error writing new book title: ", error);
        });

}
const saveNewChapterTitle = (dispatch, chapter, newChapterTitle) => {
    const refChapter = db.collection("chapters_v2").doc(chapter.chapterId);
    refChapter.update({
        title: newChapterTitle
    })
        .then(function () {
            chapter.title = newChapterTitle
            dispatch({ type: "UPDATE_CHAPTER", chapter })
        })
        .catch(function (error) {
            console.error("Error writing new chapter title: ", error);
        });
}
export const uploadCover = (book, file) => {
    return (dispatch, getState) => {
        dispatch({ type: "SHOW_SPINNER" })
        saveImageToStorage(book.bookId, file, (coverUrl) => {
            saveNewCoverUrl(book.bookId, coverUrl, () => {
                console.log("url saved")
                book.bookCoverImage.firebase_storage_url = coverUrl
                dispatch({ type: "UPDATE_BOOK", book })
                dispatch({ type: "HIDE_SPINNER" })
            })
        })
    }
}
const saveImageToStorage = (bookId, file, callback) => {
    let re = /(?:\.([^.]+))?$/;
    const extension = re.exec(file.name)[0];
    const storagePath = "books/" + bookId + "/cover" + extension
    storageRef.child(storagePath).put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then((coverUrl) => {
            callback(coverUrl)
        })
    }, function (err) {
        console.log("Could not save pdf file")
    })
}
const saveNewCoverUrl = (bookId, coverUrl, callback) => {
    const refChapter = db.collection("books").doc(bookId);
    refChapter.update({
        bookCoverImage: {
            firebase_storage_url: coverUrl
        }
    })
        .then(function () {
            callback()
        })
        .catch(function (error) {
            console.error("Error saving cover url: ", error);
        });
}
export const updateBookChapterOrderArr = (book) => {
    return (dispatch, getState) => {
        // dispatch({ type: "SHOW_SPINNER" })
        const chapterOrderArr = book.chapterOrderArr
        const refBook = db.collection("books").doc(book.bookId);
        refBook.update({
            chapterOrderArr: chapterOrderArr
        })
            .then(function () {
                dispatch({ type: "UPDATE_BOOK", book })
            })
            .catch(function (error) {
                console.error("Error saving new chapterOrderArr: ", error);
            });
    }
}
