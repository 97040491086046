import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeTitle } from '../../../store/actions/changeAction'
import { Redirect, NavLink } from 'react-router-dom'
import ShowPdf from "./ShowPdf"
import ShowLink from "./ShowLink"
import ShowImage from './ShowImage'
import ShowVideo from './ShowVideo'
import ShowEpub from './ShowEpub'
import ShowEpubPub from './ShowEpubPub'
import ShowQuill from './ShowQuill'
import ShowQuillPub from './ShowQuillPub'
import ModalJournal from '../modals/ModalJournal'
import ModalLoading from '../modals/ModalLoading'
import SectionsModal from './ModalSections'
import ModalUploadEpub from './ModalUploadEpub'
import PublisherFeatures from './PublisherFeatures'
import ModalRenameChapterFile from './ModalRenameChapterFile'
import ModalReorderFiles from './ModalReorderFiles'
import ModalMarkTeacherFiles from './ModalMarkTeacherFiles'
import ModalAddLink from './ModalAddLink'
import ModalUpdateLink from './ModalUpdateLink'
import ModalAddPdf from './ModalAddPdf'
import ModalUpdateComment from './ModalUpdateComment'
import ModalUpdateJournalPrompt from './ModalUpdateJournalPrompt'
import ModalCreateChapter from './ModalCreateChapter'
import ModalSetBookCover from './ModalSetBookCover'
import ModalCreateBook from './ModalCreateBook'
import ModalCopyBook from './ModalCopyBook'
import { toggleDone } from '../../../store/actions/statsActions'
import { recordActivityFile, recordActivityLeave } from '../../../store/actions/userActivityActions'
import { getDetailsForBook } from '../../../store/actions/storyActions'
import { getTeacherBooks } from '../../../store/actions/myClassroomsActions'
import { removeDuplicates } from '../../../store/actions/temporaryActions'
import { setSections } from '../../../store/actions/temporaryActions'
import { uploadEpub } from '../../../store/actions/epubActions'
import { deleteChapterFile, deleteChapter } from '../../../store/actions/publisherActions'
import { resetNewChapterCreated } from '../../../store/actions/publisherActions'
import { ResponsiveSideNavExpand } from './SideNavExpandResponsive'

class StoryPage extends Component {
    state = {
        component: ShowImage,
        content: "no content",
        showJournal: false,
        isAaron: false,
        chapter: null,
        showChapterFiles: true,
        chapterFile: "",
        showComments: false,
        showLoading: false,
        showSections: false,
        showUploadEpub: false,
        showConfirmDeleteFile: false,
        showConfirmDeleteChapter: false,
        showModalRenameChapterFile: false,
        showModalReorderFiles: false,
        showModalMarkTeacherFiles: false,
        showModalSetBookCover: false,
        showModalUpdateJournalPrompt: false,
        showAddLink: false,
        showUpdateLink: false,
        showAddPdf: false,
        showUpdateComment: false,
        showCreateChapter: false,
        showCreateBook: false,
        showCopyBook: false,
        sectionsOriginal: [
            "Introduce",
            "Interact",
            "Story",
            "Play",
            "Measure"
        ],
    }

    componentDidMount() {
        this.dragElement(this.dragRef.current);

        const authFB = this.props.state.firebase.auth
        const booksLoaded = this.props.state.books.booksLoaded

        if (authFB.uid) { //If this is true, then the user is logged in 
            if (!authFB.isAnonymous) { //The user is a teacher
                if (booksLoaded) { //The books are loaded                    console.log("111")
                    this.getBookChapters()
                    const userEmail = this.props.state.firebase.auth.email
                    if (userEmail == "ajstephens@gmail.com") {
                        this.setState({ isAaron: true })
                        // } else if (userEmail == "bwentzel@gmail.com") {
                        //     this.setState({ isAaron: true })
                        // } else if (userEmail == "hginni@comcast.net") {
                        //     this.setState({ isAaron: true })
                        // } else if (userEmail == "laura@cencis.com") {
                        //     this.setState({ isAaron: true })
                        // } else if (userEmail == "aolimpi@hebronlions.org") {
                        //     this.setState({ isAaron: true })
                    } else {
                        this.setState({ isAaron: false })
                    }
                } else { //The teacher profile is not loaded
                    this.props.getTeacherBooks()
                }
            } else { //The user is a student
                if (booksLoaded) { //The user profile is loaded
                    this.getBookChapters()
                } else { //The student profile is not loaded

                }
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const prevAuthSL = prevProps.state.auth
        const currAuthSL = this.props.state.auth
        if (prevAuthSL.uid === undefined && currAuthSL.uid !== undefined) {
            const bookId = this.props.match.params.book_id;
            this.props.getDetailsForBook(bookId)
        } else {
            const newChapterCreated = this.props.state.books.newChapterCreated
            const prevNewChapterCreated = prevProps.state.books.newChapterCreated
            if (prevNewChapterCreated === false && newChapterCreated !== false) {
                this.clickedChapter(newChapterCreated)
                this.props.resetNewChapterCreated()
            } else {
                this.updateChapter(prevProps)
            }
        }
        this.checkIfWebLinkUpdated(prevState)

        // Load the Audio file
        if (this.state.chapterFile === null || prevState.chapterFile === null) { return }
        const currentId = this.state.chapterFile.fileId
        const prevId = prevState.chapterFile.fileId
        if (currentId !== prevId) {
            if (this.state.chapterFile.hasOwnProperty("audioUrl")) { this.player.load() }
        }
    }

    checkIfWebLinkUpdated = (prevState) => {
        const prevFileId = prevState.chapterFile ?
            prevState.chapterFile.fileId ?
                prevState.chapterFile.fileId :
                "" :
            ""
        const fileId = this.state.chapterFile ?
            this.state.chapterFile.fileId ?
                this.state.chapterFile.fileId :
                "" :
            ""
        const contentType = this.state.chapterFile ?
            this.state.chapterFile.contentType ?
                this.state.chapterFile.contentType :
                "" :
            ""
        if (!(prevFileId === fileId && contentType === "webLink")) {
            return
        }
        const stateChapterFile = this.state.chapterFile
        const updatedChapter = this.getChapter()
        if (updatedChapter === null) { return }
        const updatedFileArray = updatedChapter.file_array
        const updatedChapterFile = updatedFileArray.find(({ fileId }) => fileId === stateChapterFile.fileId);
        if (!updatedChapterFile) { return }
        if (updatedChapterFile.firebase_storage_url !== stateChapterFile.firebase_storage_url) {
            this.clickedChapterFile2(updatedChapterFile)
        } else if (updatedChapterFile.file_name !== stateChapterFile.file_name) {
            this.setState({
                chapterFile: updatedChapterFile
            })
        }
    }
    componentWillUnmount() {
        console.log("STORY will unmount")
        // this.props.recordActivityFile("leaving")


        // const db = firebase.firestore()

        // const authSL = this.props.state.auth
        // let activityCollection = ""
        // if (authSL.uid) {
        //     activityCollection = db.collection("activityTeacher")
        // } else {
        //     activityCollection = db.collection("activityStudent")
        // }
        // const activityId = this.props.state.activity.activityFile.docId

        // console.log("ABOUT TO SAVE IN FS")
        // activityCollection.doc(activityId).update({
        //     timeLeave: new Date()
        // })
        // .then(function() {
        //     console.log("STORY WILL UNMOUNT Activity leave time updated in FS!");
        // })
        // .catch(function(error) {
        //     // The document probably doesn't exist.
        //     console.error("STORY WILL UNMOUNT Error updating leave time: ", error);
        // });           

        this.props.recordActivityLeave()
    }


    dragRef = React.createRef()

    getBookChapters = () => {
        const bookId = this.props.match.params.book_id;
        const bookChapters = this.props.state.books.chaptersByBook[bookId]
        if (bookChapters === undefined) {
            this.props.getDetailsForBook(bookId)
        } else {
            const chapterId = this.props.match.params.chapter_id;
            const fileArray = bookChapters[chapterId] ?
                bookChapters[chapterId].file_array ?
                    bookChapters[chapterId].file_array :
                    false :
                false
            if (fileArray) {
                // this.goToEpub(fileArray)
                this.goToFirstFile(fileArray)
            }
        }
    }
    returnBookChapters = () => {
        let chapters = []
        const bookId = this.props.match.params.book_id;
        const book = this.props.state.books.booksPurchased[bookId]
        const chapterOrderArr = book.chapterOrderArr ? book.chapterOrderArr : []
        const bookChapters = this.props.state.books.chaptersByBook[bookId]
        chapterOrderArr.forEach((chapterId) => {
            if (bookChapters[chapterId]) {
                chapters.push(bookChapters[chapterId])
            }
        })
        return chapters
    }

    updateChapter = (prevProps) => {

        const bookId = this.props.match.params.book_id;
        const chapterId = this.props.match.params.chapter_id;
        const chapter = this.props.state.books.chaptersByBook[bookId] ?
            this.props.state.books.chaptersByBook[bookId][chapterId] ?
                this.props.state.books.chaptersByBook[bookId][chapterId] :
                null :
            null

        // This checks if a valid chapterId is in the address bar. If the chapterId is not valid, then
        if (this.selectFirstChapter()) { return }

        const prevBookChapters = prevProps.state.books.chaptersByBook[bookId]
        if (prevBookChapters === undefined && chapter !== null) {
            this.setState({
                chapter
            })
            if (chapter && chapter.file_array) {
                // this.goToEpub(chapter.file_array)
                this.goToFirstFile(chapter.file_array)
            }
        } else {
            const prevChapterId = prevProps.match.params.chapter_id;
            const prevChapter = this.props.state.books.chaptersByBook[bookId] ?
                this.props.state.books.chaptersByBook[bookId][prevChapterId] ?
                    this.props.state.books.chaptersByBook[bookId][prevChapterId] :
                    null :
                null
            if (chapter != prevChapter) {
                this.setState({
                    chapter
                })
                if (chapter.file_array) {
                    // this.goToEpub(chapter.file_array)
                    this.goToFirstFile(chapter.file_array)
                }
            }
        }
    }

    fileInArray = (chapter) => {
        const fileId = this.state.chapterFile.fileId
        const fileArray = chapter.file_array
        for (let i = 0; i < fileArray.length; i++) {
            if (fileArray[i].fileId === fileId) {
                return true
            }
        }
        return false
    }

    goToFirstFile = (fileArray) => {
        for (let j = 0; j < this.state.sectionsOriginal.length; j++) {
            const section = this.state.sectionsOriginal[j]
            for (let k = 0; k < fileArray.length; k++) {
                const file = fileArray[k]
                if (file.fileSection === section) {
                    const isStudentFile = file.fileAccessLevel ? file.fileAccessLevel === "Student" : true
                    if (isStudentFile) {
                        this.clickedChapterFile2(file)
                        return
                    }
                }
            }
        }
        this.clickedChapterFile2(null)
    }

    // Not used as of 7/31/2021. Replaced with goToFirstFile
    goToEpub = (fileArray) => {
        let j;
        for (j = 0; j < fileArray.length; j++) {
            //filter for student files
            if (!(fileArray[j].fileAccessLevel && fileArray[j].fileAccessLevel !== "Student")) {
                if (fileArray[j].firebase_storage_url && fileArray[j].firebase_storage_url.includes("epub")) {
                    this.clickedChapterFile2(fileArray[j])
                    return
                }
            }
        }
        for (j = 0; j < fileArray.length; j++) {
            //filter for student files
            if (!(fileArray[j].fileAccessLevel && fileArray[j].fileAccessLevel !== "Student")) {
                this.clickedChapterFile2(fileArray[j])
                return
            }
        }
        this.clickedChapterFile2(null)
    }

    // This function checks if a valid chapterId is in the address bar
    selectFirstChapter = () => {
        const bookId = this.props.match.params.book_id;
        // If the chapter objects haven't been loaded, then return. We can't check for a valid chapterId if we don't have the chapter objects. This function will be called again after the chanpter objects are retrieved.
        if (!this.props.state.books.chaptersByBook[bookId]) { return false }
        // If the chapter exists, then return. The chapterId is valid.
        if (this.getChapter() !== null) { return false }


        const chapterOrderArr = this.props.state.books.booksPurchased[bookId].chapterOrderArr ?
            this.props.state.books.booksPurchased[bookId].chapterOrderArr :
            []
        if (chapterOrderArr.length === 0) { return false } // There are no chapters in this book

        this.clickedChapter(chapterOrderArr[0]) // The chapterId is not valid, so go to the first chapter in the book
        return true
    }

    clickedChapter = (chapterId) => {
        const bookId = this.props.match.params.book_id;
        const chapterIdParam = this.props.match.params.chapter_id;

        if (chapterId != chapterIdParam) {
            this.setState({ showChapterFiles: true })
        } else {
            const newShowFiles = !this.state.showChapterFiles
            this.setState({ showChapterFiles: newShowFiles })
        }

        this.props.history.push("/Story/" + bookId + "/" + chapterId)
    }


    clickedChapterFile2 = (chapterFile) => {
        if (chapterFile === null) {
            this.setState({
                component: ShowQuill,
                content: "",
                chapterFile: null,
                fileComment: "",
            });
            return
        }

        this.trackClick(chapterFile)

        const fileUrl = chapterFile.firebase_storage_url;

        let fileCommentText = chapterFile.fileComment ?
            chapterFile.fileComment :
            ""
        if (fileCommentText.substring(0, 1) === "\"") {
            fileCommentText = fileCommentText.substring(1); // removes beginning "
            fileCommentText = fileCommentText.slice(0, -1); // removes ending "   
        }
        fileCommentText = fileCommentText.replace(/\\n/g, "<br />")
        fileCommentText = fileCommentText.replace(/\\t/g, "")
        fileCommentText = fileCommentText.replace(/\\"/g, "&quot")
        // console.log("CHAPTER FILE")
        // console.log(chapterFile)
        const contentType = chapterFile.contentType ?
            chapterFile.contentType :
            ""
        if (contentType == "quillHtml") {
            const content = chapterFile.htmlText;
            this.setState({
                component: ShowQuill,
                content,
                chapterFile
            });
        } else if (chapterFile.hasOwnProperty('quillJsText_docIdArr')) {
            const quillArray = chapterFile.quillJsText_docIdArr;
            const content = quillArray[quillArray.length - 1]
            this.setState({
                component: ShowQuill,
                content,
                chapterFile
            })
        } else if (contentType.includes("pdf")) {
            this.setState({
                component: ShowPdf,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes('png') || fileUrl.includes('jpg')) {
            this.setState({
                component: ShowImage,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes('epub')) {
            this.setState({
                component: ShowEpub,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes("youtube")) {
            console.log(chapterFile)
            this.setState({
                component: ShowVideo,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (contentType.includes("video")) {
            console.log(chapterFile)
            this.setState({
                component: ShowVideo,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else {
            //Show other websites, ie quizlet and textivate
            this.setState({
                component: ShowPdf,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        }
        this.setState({
            fileComment: fileCommentText,
        })
    };

    clickedChapterFile = (e, chapterFile, isToggle) => {
        e.preventDefault()
        this.trackClick(chapterFile)
        const fileUrl = chapterFile.firebase_storage_url;
        let fileCommentText = chapterFile.fileComment ?
            chapterFile.fileComment :
            ""
        if (fileCommentText.substring(0, 1) === "\"") {
            fileCommentText = fileCommentText.substring(1); // removes beginning "
            fileCommentText = fileCommentText.slice(0, -1); // removes ending "   
        }
        fileCommentText = fileCommentText.replace(/\\n/g, "<br />")
        fileCommentText = fileCommentText.replace(/\\t/g, "")
        fileCommentText = fileCommentText.replace(/\\"/g, "&quot")
        // console.log("CHAPTER FILE")
        // console.log(chapterFile)
        const contentType = chapterFile.contentType ?
            chapterFile.contentType :
            ""
        const fileName = chapterFile.file_name
        if (fileName.includes("$") || fileName.includes("#")) {
            const externalLink = chapterFile.firebase_storage_url ?
                chapterFile.firebase_storage_url :
                "No External Link"
            if (isToggle) {
                this.toggleCheck(chapterFile)
                this.setState({
                    component: ShowLink,
                    content: externalLink,
                    chapterFile
                });
                return
            }
            // console.log("CLICKED EXTERNAL LINK")
            // console.log(chapterFile)
            const win = window.open(externalLink, '_blank');
            win.focus();
            this.setState({
                component: ShowLink,
                content: externalLink,
                chapterFile
            });
        } else if (contentType == "quillHtml") {
            const content = chapterFile.htmlText;
            this.setState({
                component: ShowQuill,
                content,
                chapterFile
            });
        } else if (chapterFile.hasOwnProperty('quillJsText_docIdArr')) {
            const quillArray = chapterFile.quillJsText_docIdArr;
            const content = quillArray[quillArray.length - 1]
            this.setState({
                component: ShowQuill,
                content,
                chapterFile
            })
        } else if (contentType.includes("pdf")) {
            this.setState({
                component: ShowPdf,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes('png') || fileUrl.includes('jpg')) {
            this.setState({
                component: ShowImage,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes('epub')) {
            this.setState({
                component: ShowEpub,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (fileUrl.includes("youtube")) {
            console.log(chapterFile)
            this.setState({
                component: ShowVideo,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else if (contentType.includes("video")) {
            console.log(chapterFile)
            this.setState({
                component: ShowVideo,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        } else {
            //Show other websites, ie quizlet and textivate
            this.setState({
                component: ShowPdf,
                content: chapterFile.firebase_storage_url,
                chapterFile: chapterFile
            })
        }
        this.setState({
            fileComment: fileCommentText,
        })

        if (isToggle) {
            this.toggleCheck(chapterFile)
        }
    }


    trackClick = (chapterFile) => {
        const bookId = this.props.match.params.book_id
        const chapterId = this.props.match.params.chapter_id
        const fileId = chapterFile.fileId
        this.props.recordActivityFile(bookId, chapterId, fileId)
    }

    toggleCheck = (chapterFile) => {
        const bookId = this.props.match.params.book_id
        const chapterId = this.props.match.params.chapter_id;
        const fileId = chapterFile.fileId
        this.props.toggleDone(bookId, chapterId, fileId)
    }

    showJournal = () => {
        this.setState({ showJournal: true });
    };

    hideJournal = () => {
        this.setState({ showJournal: false });
    };

    showLoading = () => {
        this.setState({ showLoading: true })
    }

    hideLoading = () => {
        this.setState({ showLoading: false })
    }

    showQuillStudentVersion = () => {
        this.setState({ component: ShowQuill })
    }

    showQuillPublisherVersion = () => {
        this.setState({ component: ShowQuillPub })
    }

    showEpubStudentVersion = () => {
        this.setState({ component: ShowEpub })
    }

    showEpubPublisherVersion = () => {
        this.setState({ component: ShowEpubPub })
    }

    getRemoveDuplicates = () => {
        if (this.state.isAaron) {
            return (
                <div className="removeduplicates-button">
                    <button className="pub-btncreate" onClick={this.removeDuplicates}>
                        <span>Remove Duplicates</span>
                    </button>
                </div>
            )
        } else {
            return null
        }
    }

    toggleShowConfirmDeleteFile = () => {
        this.setState({ showConfirmDeleteFile: !this.state.showConfirmDeleteFile })
    }

    deleteFile = () => {
        this.props.deleteChapterFile(this.state.chapterFile, this.state.chapter)
        this.setState({ showConfirmDeleteFile: !this.state.showConfirmDeleteFile })
    }

    toggleShowConfirmDeleteChapter = () => {
        this.setState({ showConfirmDeleteChapter: !this.state.showConfirmDeleteChapter })
    }

    deleteChapter = () => {
        const bookId = this.props.match.params.book_id
        const book = this.props.state.books.booksPurchased[bookId]
        this.props.deleteChapter(book, this.state.chapter)
        this.setState({ showConfirmDeleteChapter: !this.state.showConfirmDeleteChapter })
    }

    toggleShowModalRenameChapterFile = () => {
        this.setState({ showModalRenameChapterFile: !this.state.showModalRenameChapterFile })
    }

    toggleShowModalReorderFiles = () => {
        this.setState({ showModalReorderFiles: !this.state.showModalReorderFiles })
    }

    toggleShowModalMarkTeacherFiles = () => {
        this.setState({ showModalMarkTeacherFiles: !this.state.showModalMarkTeacherFiles })
    }

    toggleShowModalSetBookCover = () => {
        this.setState({ showModalSetBookCover: !this.state.showModalSetBookCover })
    }

    toggleShowModalUpdateJournalPrompt = () => {
        this.setState({ showModalUpdateJournalPrompt: !this.state.showModalUpdateJournalPrompt })
    }

    toggleShowUpdateComment = () => {
        this.setState({ showUpdateComment: !this.state.showUpdateComment })
    }

    toggleShowCreateChapter = () => {
        this.setState({ showCreateChapter: !this.state.showCreateChapter })
    }

    toggleShowCreateBook = () => {
        this.setState({ showCreateBook: !this.state.showCreateBook })
    }
    toggleShowCopyBook = () => {
        this.setState({ showCopyBook: !this.state.showCopyBook })
    }

    setPublisherButton = () => {
        if (this.state.isAaron) {
            switch (this.state.component) {
                case ShowQuill:
                    return (
                        <div className="publisher-button">
                            <button className="pub-btncreate" type="button" onClick={this.showQuillPublisherVersion}>
                                <span>Publisher Version</span>
                            </button>
                        </div>
                    )
                case ShowQuillPub:
                    return (
                        <div className="publisher-button">
                            <button className="btn" onClick={this.showQuillStudentVersion}>
                                <span>Student Version</span>
                            </button>
                        </div>
                    )
                case ShowEpub:
                    return (
                        <div className="publisher-button">
                            <button className="btn" onClick={this.showEpubPublisherVersion}>
                                <span>Publisher Version</span>
                            </button>
                        </div>
                    )
                case ShowEpubPub:
                    return (
                        <div className="publisher-button">
                            <button className="btn" onClick={this.showEpubStudentVersion}>
                                <span>Student Version</span>
                            </button>
                        </div>
                    )
                default:
                    return null
            }
        }
        return null
    }


    getModalUploadEpub = () => {
        let modalUploadEpub = null
        if (this.state.showUploadEpub) {
            modalUploadEpub = (
                <div>
                    <ModalUploadEpub
                        toggleShowUploadEpub={this.toggleShowUploadEpub}
                        uploadEpub={this.uploadEpub}
                        sectionsSaved={this.getSections()}
                    />
                </div>
            )
        }
        return modalUploadEpub
    }

    getModalAddLink = () => {
        if (this.state.showAddLink) {
            return (
                <div>
                    <ModalAddLink
                        toggleShowAddLink={this.toggleShowAddLink}
                        sectionsSaved={this.getSections()}
                        bookId={this.props.match.params.book_id}
                        chapterId={this.props.match.params.chapter_id}
                    />
                </div>
            )
        }
        return null
    }

    getModalUpdateLink = () => {
        if (this.state.showUpdateLink) {
            const chapter = this.getChapter()
            return (
                <div>
                    <ModalUpdateLink
                        toggleShowUpdateLink={this.toggleShowUpdateLink}
                        chapterFile={this.state.chapterFile}
                        chapter={chapter}
                    />
                </div>
            )
        }
        return null
    }

    getModalAddPdf = () => {
        if (this.state.showAddPdf) {
            return (
                <div>
                    <ModalAddPdf
                        toggleShowAddPdf={this.toggleShowAddPdf}
                        sectionsSaved={this.getSections()}
                        bookId={this.props.match.params.book_id}
                        chapterId={this.props.match.params.chapter_id}
                    />
                </div>
            )
        }
        return null
    }

    getPublisherFeatures = () => {
        if (this.props.state.books.showPublisherFeatures) {
            let isEpub = false
            if (this.state.component === ShowEpub) { isEpub = true }
            return (
                <PublisherFeatures
                    toggleShowSections={this.toggleShowSections}
                    toggleShowUploadEpub={this.toggleShowUploadEpub}
                    toggleShowConfirmDeleteFile={this.toggleShowConfirmDeleteFile}
                    toggleShowConfirmDeleteChapter={this.toggleShowConfirmDeleteChapter}
                    toggleShowModalRenameChapterFile={this.toggleShowModalRenameChapterFile}
                    toggleShowModalReorderFiles={this.toggleShowModalReorderFiles}
                    toggleShowModalMarkTeacherFiles={this.toggleShowModalMarkTeacherFiles}
                    toggleShowModalSetBookCover={this.toggleShowModalSetBookCover}
                    toggleShowModalUpdateJournalPrompt={this.toggleShowModalUpdateJournalPrompt}
                    toggleShowAddLink={this.toggleShowAddLink}
                    toggleShowUpdateLink={this.toggleShowUpdateLink}
                    toggleShowAddPdf={this.toggleShowAddPdf}
                    toggleShowUpdateComment={this.toggleShowUpdateComment}
                    toggleShowCreateChapter={this.toggleShowCreateChapter}
                    toggleShowCreateBook={this.toggleShowCreateBook}
                    toggleShowCopyBook={this.toggleShowCopyBook}
                    isEpub={isEpub}
                    bookId={this.props.match.params.book_id}
                    chapter={this.state.chapter}
                    chapterFile={this.state.chapterFile}
                />
            )
        }
        return null
    }

    getModalRenameChapterFile = () => {
        if (this.state.showModalRenameChapterFile) {
            return (
                <ModalRenameChapterFile
                    chapterFile={this.state.chapterFile}
                    bookId={this.props.match.params.book_id}
                    chapterId={this.props.match.params.chapter_id}
                    toggleShowModalRenameChapterFile={this.toggleShowModalRenameChapterFile}
                />
            )
        }
        return null
    }

    getModalReorderFiles = () => {
        const bookId = this.props.match.params.book_id;
        const chapter = this.getChapter()
        if (this.state.showModalReorderFiles && chapter !== null) {
            return (
                <ModalReorderFiles
                    book={this.props.state.books.booksPurchased[bookId]}
                    chapter={chapter}
                    toggleShowModalReorderFiles={this.toggleShowModalReorderFiles}
                />
            )
        }
        return null
    }

    getModalMarkTeacherFiles = () => {
        const bookId = this.props.match.params.book_id;
        const chapter = this.getChapter()
        if (this.state.showModalMarkTeacherFiles && chapter !== null) {
            return (
                <ModalMarkTeacherFiles
                    book={this.props.state.books.booksPurchased[bookId]}
                    chapter={chapter}
                    toggleShowModalMarkTeacherFiles={this.toggleShowModalMarkTeacherFiles}
                />
            )
        }
        return null
    }

    getModalSetBookCover = () => {
        const chapter = this.getChapter()
        if (this.state.showModalSetBookCover && chapter !== null) {
            const bookId = this.props.match.params.book_id;
            const book = this.props.state.books.booksPurchased[bookId]
            const chapters = this.returnBookChapters()
            return (
                <ModalSetBookCover
                    book={book}
                    chapters={chapters}
                    toggleShowModalSetBookCover={this.toggleShowModalSetBookCover}
                />
            )
        }
        return null
    }

    getModalUpdateJournalPrompt = () => {
        const chapter = this.getChapter()
        if (this.state.showModalUpdateJournalPrompt && chapter !== null) {
            return (
                <ModalUpdateJournalPrompt
                    chapter={chapter}
                    toggleShowModalUpdateJournalPrompt={this.toggleShowModalUpdateJournalPrompt}
                />
            )
        }
    }

    getModalUpdateComment = () => {
        const chapter = this.getChapter()
        if (this.state.showUpdateComment && chapter !== null) {
            return (
                <ModalUpdateComment
                    chapterFile={this.state.chapterFile}
                    chapter={chapter}
                    toggleShowUpdateComment={this.toggleShowUpdateComment}
                />
            )
        }
        return null
    }

    getModalCreateChapter = () => {
        const bookId = this.props.match.params.book_id;
        const book = this.props.state.books.booksPurchased[bookId]
        if (this.state.showCreateChapter) {
            return (
                <ModalCreateChapter
                    book={book}
                    toggleShowCreateChapter={this.toggleShowCreateChapter}
                />
            )
        }
        return null
    }

    getModalCreateBook = () => {
        if (this.state.showCreateBook) {
            return (
                <ModalCreateBook
                    toggleShowCreateBook={this.toggleShowCreateBook}
                />
            )
        }
        return null
    }

    getModalCopyBook = () => {
        if (this.state.showCopyBook) {
            const bookId = this.props.match.params.book_id
            const book = this.props.state.books.booksPurchased[bookId]
            return (
                <ModalCopyBook
                    toggleShowCopyBook={this.toggleShowCopyBook}
                    book={book}
                />
            )
        }
        return null
    }

    getChapter = () => {
        const bookId = this.props.match.params.book_id;
        const chapterId = this.props.match.params.chapter_id;
        const chapter = this.props.state.books.chaptersByBook[bookId] ?
            this.props.state.books.chaptersByBook[bookId][chapterId] ?
                this.props.state.books.chaptersByBook[bookId][chapterId] :
                null :
            null
        return chapter
    }

    getConfirmDeleteFile = () => {
        if (this.state.showConfirmDeleteFile) {
            return (
                <div className="ajs-modal">
                    <div className="modalcontent-sections">
                        <div className="pubfeat-close" onClick={this.toggleShowConfirmDeleteFile}></div>
                        {/* <div className="story-sections-title">Choose section to upload to:</div> */}
                        <div className="epubupload-file-label">Delete File?</div>
                        <div className="deletfile-subheading">(This cannot be undone)</div>
                        <div>
                            <button className="btn-oneclassroom btnkeepclass-myclassrooms deletefile-confirmbutton" onClick={this.deleteFile} >
                                <span>Delete File</span>
                            </button>
                        </div>
                        <div>
                            <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.toggleShowConfirmDeleteFile} >
                                <span>Keep File</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    getConfirmDeleteChapter = () => {
        let keepButton = (
            <button className="btn-oneclassroom btnkeepclass-myclassrooms deletefile-confirmbutton btndeletekeepchapters" onClick={this.deleteChapter} >
                <span>Delete Chapter</span>
            </button>
        )

        const userEmail = this.props.state.firebase.auth.email
        if (userEmail == "bwentzel@gmail.com") {
            keepButton = (
                <div className="brooke-delete-container">
                    <img className="brooke-delete" src="../../../../../img/BrookeDelete.png" alt="" onClick={this.deleteChapter} />
                </div>
            )
        }

        if (this.state.showConfirmDeleteChapter) {
            return (
                <div className="ajs-modal">
                    <div className="modalcontent-sections">
                        <div className="pubfeat-close" onClick={this.toggleShowConfirmDeleteChapter}></div>
                        {/* <div className="story-sections-title">Choose section to upload to:</div> */}
                        <div className="epubupload-file-label">Are you sure you want to delete this chapter?</div>
                        <div>
                            {keepButton}
                        </div>
                        <div>
                            <button className="btn-oneclassroom btnkeepclass-myclassrooms btndeletekeepchapters" onClick={this.toggleShowConfirmDeleteChapter} >
                                <span>Keep Chapter</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    uploadEpub = (epubFile, label, section) => {
        const bookId = this.props.match.params.book_id;
        const chapterId = this.props.match.params.chapter_id;
        // console.log("hey hey hey")
        this.props.uploadEpub(epubFile, label, section, bookId, chapterId)
        this.toggleShowUploadEpub()
    }


    toggleComments = show => e => {
        e.preventDefault()
        this.setState({
            showComments: show
        })
    }


    clickedHideComments = (e) => {
        e.preventDefault()
    }


    // Drag notes box
    dragElement = (elmnt) => {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        // elmnt.onmousedown = dragMouseDown;
        //uncomment the following block if you want to move the div by only by clicking on the header
        if (document.getElementById(elmnt.id + "header")) {
            // if present, the header is where you move the DIV from:
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV: 
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            console.log(e)
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    removeDuplicates = () => {
        const bookId = this.props.match.params.book_id;
        const chapterId = this.props.match.params.chapter_id;
        this.props.removeDuplicates(bookId, chapterId)
    }

    toggleShowSections = () => {
        this.setState(
            { showSections: !this.state.showSections }
        )
    }

    toggleShowUploadEpub = () => {
        this.setState(
            { showUploadEpub: !this.state.showUploadEpub }
        )
    }

    toggleShowAddLink = () => {
        this.setState(
            { showAddLink: !this.state.showAddLink }
        )
    }

    toggleShowUpdateLink = () => {
        this.setState(
            { showUpdateLink: !this.state.showUpdateLink }
        )
    }

    toggleShowAddPdf = () => {
        this.setState(
            { showAddPdf: !this.state.showAddPdf }
        )
    }

    saveSections = (sectionsToSet) => {
        // console.log(sectionsToSet)
        const bookId = this.props.match.params.book_id;
        this.props.setSections(bookId, sectionsToSet)
        this.setState(
            { showSections: false }
        )
    }

    getSections = () => {
        const sectionsDefault = this.props.state.books.sectionsDefault
        const bookId = this.props.match.params.book_id;
        const book = this.props.state.books.booksPurchased[bookId]
        const sectionHeadings = book.chapterSections ? book.chapterSections : sectionsDefault
        return sectionHeadings
    }

    getSectionsModal = () => {
        let sectionsModal = null
        if (this.state.showSections) {
            sectionsModal = (
                <div>
                    <SectionsModal
                        toggleShowSections={this.toggleShowSections}
                        saveSections={this.saveSections}
                        sectionsSaved={this.getSections()}
                    />
                </div>
            )
        }
        return sectionsModal
    }

    getAudioControls = () => {
        const hasAudioFile = this.state.chapterFile ? this.state.chapterFile.hasOwnProperty("audioUrl") : false
        if (!hasAudioFile) { return null }
        const ttsAudio = this.state.chapterFile.audioUrl ? this.state.chapterFile.audioUrl : "hohoho"
        const audioControls = (
            <div className="audio-controls-center">
                <audio className="epub-audiocontrols" controls controlsList="nodownload" ref={ref => (this.player = ref)}>
                    <source src={ttsAudio} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        )
        return audioControls
    }

    getSpinner = () => {
        if (this.props.state.books.showSpinner) {
            return (
                <div className="ajs-modal-spinner">
                    <div className="loader"></div>
                </div>
            )
        }
        return null
    }

    render() {
        // console.log("STORY PAGE STATE")
        // console.log(this.props.state)
        const { auth, user } = this.props;

        const audioControls = this.getAudioControls()

        let toShow = (
            <this.state.component
                fileUrl={this.state.content}
                chapterFile={this.state.chapterFile}
                chapter={chapter}
                bookId={bookId}
                hasAudioControls={audioControls != null}
            />
        )

        if (this.state.showModalReorderFiles || this.state.showModalSetBookCover) {
            toShow = (<div className="center-image"></div>)
        }

        if (!auth.uid) return <Redirect to='/SignIn' />

        const classId = "whatever"
        const bookId = this.props.match.params.book_id;
        const chapterId = this.props.match.params.chapter_id;

        let chapter = null;
        let chapters = [];

        const book = this.props.state.books.booksPurchased[bookId]
        const allChapters = this.props.state.books.chaptersByBook[bookId]

        const chapterJournal = this.props.state.books.journalsByBook[bookId] ?
            this.props.state.books.journalsByBook[bookId][chapterId] ?
                this.props.state.books.journalsByBook[bookId][chapterId] :
                {} :
            {}

        const bookCoverUrl = book
            ?
            book.bookCoverImage ?
                book.bookCoverImage.firebase_storage_url :
                "none"
            :
            "none";


        //TO DO - Fix this page for students!!!!


        if (!allChapters) {
            chapters = ['Loading'];
        } else {
            chapter = allChapters[chapterId] ?
                allChapters[chapterId] :
                null
            book.chapterOrderArr.forEach((chapterId) => {
                chapters.push(allChapters[chapterId])
            })
            let chapterFiles = []
            if (allChapters[chapterId] !== undefined) {
                allChapters[chapterId].file_array.forEach((chapterFile) => {
                    if (chapterFile === null) { return }
                    chapterFiles.push(
                        <li key={chapterFile.fileId} onClick={(e) => this.clickedChapterFile(e, chapterFile, false)}>
                            <a href="#!" >{chapterFile.file_name}</a>
                        </li>
                    )
                })
            } else {
                chapterFiles = [<li key={"none"}><a href="#!">{'none'}</a></li>]
            }
        }

        let fileNote = !this.state.chapterFile ?
            "" :
            this.state.chapterFile.fileComment ?
                this.state.chapterFile.fileComment :
                ""
        //Remove the quotation marks surrounding the note
        fileNote = fileNote.substring(1);
        fileNote = fileNote.slice(0, -1);

        let styleDisplay = { display: "none" }
        if (this.state.showComments) {
            styleDisplay = { display: "block" }
        }

        let modalJournal = null
        if (this.state.showJournal) {
            modalJournal = (
                <ModalJournal
                    showJournal={this.state.showJournal}
                    hideJournal={this.hideJournal}
                    chapter={this.state.chapter}
                    chapterJournal={chapterJournal}
                    showLoading={this.showLoading}
                    hideLoading={this.hideLoading}
                    classroomId={classId}
                />
            )
        }



        return (
            <div className="story-page-container">

                {this.getSpinner()}
                {this.getSectionsModal()}
                {this.getModalUploadEpub()}
                {this.getModalAddLink()}
                {this.getModalUpdateLink()}
                {this.getModalAddPdf()}
                {this.getRemoveDuplicates()}
                {/* {this.getChapterSectionsButton()} */}
                {/* {this.getUploadEpubButton()} */}
                {/* {this.setPublisherButton()} */}
                {this.getPublisherFeatures()}
                {this.getConfirmDeleteFile()}
                {this.getConfirmDeleteChapter()}
                {this.getModalRenameChapterFile()}
                {this.getModalReorderFiles()}
                {this.getModalMarkTeacherFiles()}
                {this.getModalUpdateComment()}
                {this.getModalCreateChapter()}
                {this.getModalSetBookCover()}
                {this.getModalUpdateJournalPrompt()}
                {this.getModalCreateBook()}
                {this.getModalCopyBook()}

                <div id="mydiv" ref={this.dragRef} style={styleDisplay}>
                    <div id="mydivheader">
                        Comments
                        <span className="ajs-close2" onClick={this.toggleComments(false)}>&times;</span>
                    </div>

                    <div className="movable-comment" dangerouslySetInnerHTML={{ __html: this.state.fileComment }}></div>
                </div>

                {modalJournal}


                <ModalLoading showLoading={this.state.showLoading} />

                <div className='story-page-main-content'>
                    <ResponsiveSideNavExpand 
                        user={user}
                        chapters={chapters}
                        classId={classId}
                        book={book}
                        bookId={bookId}
                        bookCoverUrl={bookCoverUrl}
                        chapterId={chapterId}
                        progress={this.props.state.progress}
                        showChapterFiles={this.state.showChapterFiles}
                        clickedChapterFile={this.clickedChapterFile}
                        clickedChapter={this.clickedChapter}
                        showJournal={this.showJournal}
                        chapterFile={this.state.chapterFile}
                        toggleComments={this.toggleComments}
                    />

                    <div className="display-container">
                        {audioControls}
                        {toShow}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        state,
        auth: state.firebase.auth,
        user: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeBookName: (bookId) => dispatch(changeTitle(bookId)),
        // getBooks2: () => dispatch(getBooks2()),
        toggleDone: (classroomId, bookId, fileId) => dispatch(toggleDone(classroomId, bookId, fileId)),
        recordActivityFile: (bookId, chapterId, fileId) => dispatch(recordActivityFile(bookId, chapterId, fileId)),
        recordActivityLeave: () => dispatch(recordActivityLeave()),
        getDetailsForBook: (bookId) => dispatch(getDetailsForBook(bookId)),
        getTeacherBooks: () => dispatch(getTeacherBooks()),
        removeDuplicates: (bookId, chapterId) => dispatch(removeDuplicates(bookId, chapterId)),
        setSections: (bookId, sections) => dispatch(setSections(bookId, sections)),
        uploadEpub: (epubFile, label, section, bookId, chapterId) => dispatch(uploadEpub(epubFile, label, section, bookId, chapterId)),
        deleteChapterFile: (chapterFile, chapter) => dispatch(deleteChapterFile(chapterFile, chapter)),
        deleteChapter: (book, chapter) => dispatch(deleteChapter(book, chapter)),
        resetNewChapterCreated: () => dispatch(resetNewChapterCreated())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryPage)
