const initState = {
    authorBooks: {
        isEmpty: true
        // author1: [],
        // author2: []
    },
    cart: {
        bookId1: 1,
        bookId2: 500,
        bookId3: 25
    }
}

const storeReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_STORE":
            return {
                ...state,
                authorBooks: action.store
            }
            case "ADD_BOOK":
            return {
                ...state,
            }
        default:
            return state
    }
    //To alter the state
    // return {
    //     ...state, //This spreads the existing state
    // }
}

export default storeReducer