import React, { Component } from 'react'
import { connect } from 'react-redux'


class ModalUploadEpub extends Component {

// Props passed to this class
// toggleShowUploadEpub - function which will hide the modal
// uploadEpub
// sectionsSaved - array of sections headings which are saved in firestore

    state = {
        sections: ["","","","",""],
        sectionFocus: null,
        fileName: "",
        sectionTitlesOriginal: ["Introduce", "Interact", "Story", "Play", "Measure"]
    }
    
    componentDidMount() {
        const sectionsSavedCopy = this.props.sectionsSaved.map ( (title) => {
            return title
        })
        this.setState({
            sections: sectionsSavedCopy
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }


    handleFocus = (e) => {
        
        const targetId = e.target.id
        // console.log("FOCUSED " + targetId)
        this.setState({
            sectionFocus: targetId
        })
    }

    fileNameChange = (e) => {
        const targetValue = e.target.value
        this.setState({
            fileName: targetValue
        })
    }

    uploadEpub = (e) => {
        // console.log("Hey Hey Hey")
        // console.log(e.target.files)
        // console.log("hey")
        // console.log(e.target.files[0])


        let fileName = e.target.files[0].name
        if (this.state.fileName !== "") {
            fileName = this.state.fileName
        }

        const sectionFocus = this.state.sectionFocus === null ? 0 : this.state.sectionFocus
        const section = this.state.sectionTitlesOriginal[sectionFocus]
        this.props.uploadEpub(e.target.files[0], fileName, section)
    }

    getSections = () => {
        let sectionRows = []
        for (let i=0; i < 5; i++) {
            const title = this.state.sections[i]
            // const savedTitle = this.props.sectionsSaved[i]
            let styleName = "input-myclassrooms input-uploadepub"
            if (i == this.state.sectionFocus) {
                styleName = "input-myclassrooms input-uploadepub input-uploadepubhighlight"
            }
            const row = (
                <tr
                    key={i}
                >
                    <td className="col-50-body">
                        <input
                            readOnly
                            className={styleName}
                            type="text"
                            id={i}
                            onFocus={this.handleFocus}
                            tabIndex={i+1}
                            ref={ref => (this.section0 = ref)}
                            value={title}
                        />
                    </td>
                </tr>
            )
            sectionRows.push(row)
        }

        return (
            <div className="tableWrap-oneClassroom" >
                <form onSubmit={this.handleSubmit} >
                    <table className="table-oneclassroom">
                        <tbody>
                            {sectionRows}
                        </tbody>
                    </table>
                </form>
            </div>
        )
    }

    getFileNameInput = () => {
        let styleName = "input-myclassrooms input-uploadepub"
        if (this.state.fileName !== "") {
            styleName = "input-myclassrooms input-uploadepub input-uploadepubhighlight"
        }
        return (
            <div className="input-filename">
                <form>
                    <input
                        className={styleName}
                        type="text"
                        id="fileName"
                        onChange={this.fileNameChange}
                        tabIndex={6}
                        value={this.state.fileName}
                    />
                </form>
            </div>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowUploadEpub}></div>
                    <div className="story-sections-title">Choose section to upload to:</div>
                    {this.getSections()}

                    <div className="epubupload-file-label">Enter File Label</div>
                    {this.getFileNameInput()}

                    <div className="epubupload-btnwrapper">
                            <label className="btn-oneclassroom epubupload-btn"  >
                                <input className="hide-input" type="file" name="file" accept="application/epub+zip" onChange={this.uploadEpub} />
                                Choose File to Upload
                            </label>
                    </div>
                    {/* <div> */}
                        {/* <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.props.toggleShowUploadEpub} > */}
                            {/* <span>Close</span> */}
                        {/* </button> */}
                    {/* </div> */}
                    {/* <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={(even) => this.props.saveSections(this.state.sections)} >
                        <span>Save</span>
                    </button> */}
                </div>
            </div>
        )
    }
}


export default connect(null, null)(ModalUploadEpub)





