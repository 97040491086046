import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getMostUsedBooks } from './actionsAdmin'

// const chapterId = this.props.match.params.chapter_id;

class AdminUsed extends Component {
    state = { }

    componentDidMount() {
        this.props.getMostUsedBooks()
    }

    componentDidUpdate(prevProps, prevState) {
    }




    //UI Functions


    //Render Functions
    getUserRows = () => {
        let displayRows = []
        const displayArr = this.props.state.adminData.used.toDisplay
        displayArr.forEach( (display, i) => {
            displayRows.push(
                <tr key={i}>
                    <td className="col-20-body row-myclassrooms" >
                        {display.bookTitle}
                    </td>
                    <td className="col-20-body row-myclassrooms" >
                        {display.bookCount}
                    </td>
                    <td className="col-20-body row-myclassrooms" >
                        {display.teacherName}
                    </td>
                    <td className="col-20-body row-myclassrooms" >
                        {display.teacherEmail}
                    </td>
                    <td className="col-20-body row-myclassrooms" >
                        {display.teacherCount}
                    </td>
                </tr>
            )
        })
        return displayRows
    }


    getDisplay = () => {
        return (
            <div className="oneclassrooms-container">
                <div className="header-oneclassroom">
                    {"Most Used Books"}
                </div>
                <div className="tableWrap-adminusers" >
                    <form onSubmit={this.handleSubmit} >
                        <table className="table-oneclassroom">
                            <thead>
                                <tr>
                                    <th className="col-20-header" onClick={() => this.clickedSortField("name")}>
                                        <span className="colhead-adminusers"> Book </span>
                                    </th>
                                    <th className="col-20-header" onClick={() => this.clickedSortField("email")}>
                                        <span className="colhead-adminusers">Count</span>
                                    </th>
                                    <th className="col-20-header" onClick={() => this.clickedSortField("date")}>
                                        <span className="colhead-adminusers">Teacher</span>
                                    </th>
                                    <th className="col-20-header" onClick={() => this.clickedSortField("role")}>
                                        <span className="colhead-adminusers">Email</span>
                                    </th>
                                    <th className="col-20-header" onClick={() => this.clickedSortField("role")}>
                                        <span className="colhead-adminusers">Count</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getUserRows()}
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        )
    }




    render(){

        const userSL = this.props.state.firebase.auth
        if (!userSL.uid) return <Redirect to='/SignIn' />
        if (userSL.uid !== "9dVqqVAwzGb0svaCUOYzNjAH3s02" && userSL.uid !== "xrsENL073ted7CB5VrorXCBMipw2") return <Redirect to="/" />
    
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )
        
        if (this.props.state.adminData.used.toDisplay) {
            toDisplay = this.getDisplay()
        }


        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMostUsedBooks: () =>  dispatch(getMostUsedBooks()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsed)

