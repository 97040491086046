import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAllUsers } from './actionsAdmin'

// const chapterId = this.props.match.params.chapter_id;

class AdminUsers extends Component {
    state = {
        sortBy: "name"
    }

    componentDidMount() {
        this.props.getAllUsers()
    }

    componentDidUpdate(prevProps, prevState) {
    }



    // sorting functions
    // setStudentSortOrder = (studentsObj) => {
    //     const studentsArr = Object.values(studentsObj)
    //     this.sortStudentsByAlpha(studentsArr)
    //     for (let i=0; i < studentsArr.length; i++) {
    //         const studentId = studentsArr[i].studentId
    //         studentsObj[studentId]["sortOrder"] = i
    //     }
    // }


    // sortStudentsByOrder = (studentsArr) => {
    //     function compare(a, b) {
    //         if (a.sortOrder < b.sortOrder) {
    //             return -1;
    //         }
    //         if (a.sortOrder > b.sortOrder) {
    //             return 1;
    //         }
    //         return 0;
    //     }
    //     studentsArr.sort(compare);
    // }

    //UI Functions
    clickedSortField = (sortBy) => {
        this.setState({ sortBy })
    }

    //Helper Functions
    getDateInSeconds = (createTime) => {
        if (createTime === undefined) {
            return Math.round(new Date("1/1/2018").getTime()/1000)
        }
        if (typeof createTime === 'string' || createTime instanceof String) {
            return Math.round(new Date("1/1/2018").getTime()/1000)
        }
        if (createTime.seconds) {
            return createTime.seconds
        }
        return Math.round(new Date("1/1/2018").getTime()/1000)
    }

    getDateString = (dateInSeconds) => {

            var a = new Date(dateInSeconds * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            const fullYear = a.getFullYear();
            const year = fullYear.toString().substr(-2)
            let month = a.getMonth()
            var monthStr = months[a.getMonth()];
            month++ //add 1 to month because it is begins at 0
            let date = a.getDate();
            const dayStr = days[a.getDay()]
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
            // const time = dayStr + " " + month + "/" + date + "/" + year
            const time = month + "/" + date + "/" + year + " - " + dayStr
            return time;
    
    }

    getFormattedUsers = () => {
        let usersAllFormatted = []
        const usersAll = this.props.state.adminData.usersAll        
        usersAll.forEach( (user, i) => {
            const name = user.user_name ?
                user.user_name :
                "No name"
            const nameToSort = name.toLowerCase()
            const email = user.email ?
                user.email :
                "No email"
            const emailToSort = email.toLowerCase()
            const dateInSeconds = this.getDateInSeconds(user.createTime)
            const date = this.getDateString(dateInSeconds)
            const role = user.role ?
                user.role :
                "No role"
            usersAllFormatted.push({
                name,
                nameToSort,
                email,
                emailToSort,
                dateInSeconds,
                date,
                role
            })
        })

        const sortField = this.props.match.params.sortField
        switch(sortField) {
            case "name":
                usersAllFormatted.sort((a, b) => (a.nameToSort > b.nameToSort) ? 1 : -1)
                break
            case "email":
                usersAllFormatted.sort((a, b) => (a.emailToSort > b.emailToSort) ? 1 : -1)
                break
            case "date":
                usersAllFormatted.sort((a, b) => (a.dateInSeconds < b.dateInSeconds) ? 1 : -1)
                break
            case "role":
                usersAllFormatted.sort((a, b) => (a.role > b.role) ? 1 : -1)
                break
            default:
                usersAllFormatted.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }

        return usersAllFormatted
    }

    //Render Functions
    getUserRows = () => {
        let userRows = []
        // const usersAll = this.getSortedUsers()

        let usersAllFormatted = []
        const usersAll = this.props.state.adminData.usersAll        
        usersAll.forEach( (user, i) => {
            const name = user.user_name ?
                user.user_name :
                "No name"
            const nameToSort = name.toLowerCase()
            const email = user.email ?
                user.email :
                "No email"
            const emailToSort = email.toLowerCase()
            const dateInSeconds = this.getDateInSeconds(user.createTime)
            const date = this.getDateString(dateInSeconds)
            const role = user.role ?
                user.role :
                "No role"
            usersAllFormatted.push({
                name,
                nameToSort,
                email,
                emailToSort,
                dateInSeconds,
                date,
                role
            })
        })

        // const sortField = this.props.match.params.sortField
        switch(this.state.sortBy) {
            case "name":
                usersAllFormatted.sort((a, b) => (a.nameToSort > b.nameToSort) ? 1 : -1)
                break
            case "email":
                usersAllFormatted.sort((a, b) => (a.emailToSort > b.emailToSort) ? 1 : -1)
                break
            case "date":
                usersAllFormatted.sort((a, b) => (a.dateInSeconds < b.dateInSeconds) ? 1 : -1)
                break
            case "role":
                usersAllFormatted.sort((a, b) => (a.role > b.role) ? 1 : -1)
                break
            default:
                usersAllFormatted.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }
    
        usersAllFormatted.forEach( (user, i) => {
            userRows.push(
                <tr key={i}>
                    <td className="col-25-body row-myclassrooms" >
                        {user.name}
                    </td>
                    <td className="col-35-body row-myclassrooms" >
                        {user.email}
                    </td>
                    <td className="col-25-body row-myclassrooms" >
                        {user.date}
                    </td>
                    <td className="col-15-body row-myclassrooms" >
                        {user.role}
                    </td>
                </tr>
            )
        })

        return userRows
    }

    getNames = (usersFormatted, field) => {
        let names = []
        usersFormatted.forEach( (user, i) => {
            names.push(
                <tr key={"name" + i} ><td className="column-adminusers">{user[field]}</td></tr>
            )
        })
        return names
    }

    getUsersAllDisplay = () => {
        const totalUsers = this.props.state.adminData.usersAll.length
        const totalUsersFormatted = totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        const usersFormatted = this.getFormattedUsers() 
        return (
            <div className="oneclassrooms-container">
                <div className="header-oneclassroom">
                    {"All Users (" + totalUsersFormatted +")"}
                </div>

                <div className="tableWrap-adminusers" >
                    <form onSubmit={this.handleSubmit} >
                        <table className="table-oneclassroom">
                            <thead>
                                <tr>
                                    <th className="col-25-header" onClick={() => this.clickedSortField("name")}>
                                        <span className="colhead-adminusers"> Name </span>
                                        </th>
                                    <th className="col-35-header" onClick={() => this.clickedSortField("email")}><span className="colhead-adminusers">Email</span></th>
                                    <th className="col-25-header" onClick={() => this.clickedSortField("date")}><span className="colhead-adminusers">Date</span></th>
                                    <th className="col-15-header" onClick={() => this.clickedSortField("role")}><span className="colhead-adminusers">Role</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getUserRows()}
                            </tbody>
                            {/* <tbody >
                                <tr >
                                    <td className="col-25-body ">
                                        <table >
                                            <tbody>
                                            {this.getNames(usersFormatted, "name")}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="col-35-body">
                                        <table >
                                        <tbody>
                                            {this.getNames(usersFormatted, "email")}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="col-25-body">
                                        <table >
                                        <tbody>
                                            {this.getNames(usersFormatted, "date")}
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="col-15-body">
                                        <table >
                                        <tbody>
                                            {this.getNames(usersFormatted, "role")}
                                            </tbody>
                                        </table>
                                    </td>
                                    
                                </tr>
                            </tbody> */}
                        </table>
                    </form>
                </div>
            </div>
        )
    }




    render(){

        const usersAll = this.props.state.adminData.usersAll
        const userSL = this.props.state.firebase.auth
        if (!userSL.uid) return <Redirect to='/SignIn' />
        if (userSL.uid !== "9dVqqVAwzGb0svaCUOYzNjAH3s02" && userSL.uid !== "xrsENL073ted7CB5VrorXCBMipw2") return <Redirect to="/" />
    
        let toDisplay = (
            <div className="myclassrooms-container">
                <div className="loader"></div>
            </div>
        )

        if (usersAll.length > 0) {
            toDisplay = this.getUsersAllDisplay()
        }

        return toDisplay

    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: () =>  dispatch(getAllUsers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)

