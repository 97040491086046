import React, { Component } from 'react'
import { connect } from 'react-redux'
import { renameChapterFile } from '../../../store/actions/publisherActions'

class ModalRenameChapterFile extends Component {

// Props passed to this class
// chapterFile
// chapter
// toggleShowUpdateComment

    state = {
        fileName: ""
    }
    
    componentDidMount() {
        this.setState({ fileName: this.props.chapterFile.file_name })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }



    fileNameChange = (e) => {
        const targetValue = e.target.value
        this.setState({
            fileName: targetValue
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // console.log("PRESSED SUBMIT")
        // console.log(this.props.bookId)
        // console.log(this.props.chapterId)
        this.props.toggleShowModalRenameChapterFile()
        const chapter = this.props.state.books.chaptersByBook[this.props.bookId] ?
            this.props.state.books.chaptersByBook[this.props.bookId][this.props.chapterId] ?
                this.props.state.books.chaptersByBook[this.props.bookId][this.props.chapterId] :
                null :
            null
        // console.log(chapter)
        if (chapter !== null) {
            this.props.renameChapterFile(chapter, this.props.chapterFile, this.state.fileName)
        }
    }



    getFileNameInput = () => {
        let styleName = "input-myclassrooms input-uploadepub"
        if (this.state.fileName !== this.props.chapterFile.file_name) {
            styleName = "input-myclassrooms input-uploadepub input-mcnew"
        }
        return (
            <div className="input-filename">
                <form onSubmit={this.handleSubmit} >
                    <input
                        className={styleName}
                        type="text"
                        id="fileName"
                        onChange={this.fileNameChange}
                        tabIndex={6}
                        value={this.state.fileName}
                    />
                </form>
            </div>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowModalRenameChapterFile}></div>
                    <div className="pubfeat-renametitle">Rename File</div>
                    {this.getFileNameInput()}
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom epubupload-btn" onClick={this.handleSubmit} >
                            <span>Rename File</span>
                        </button>
                    </div>
                    {/* <div> */}
                        {/* <button className="btn-oneclassroom btnkeepclass-myclassrooms deletefile-confirmbutton pubfeat-renameclose " onClick={this.props.toggleShowModalRenameChapterFile} > */}
                            {/* <span>Close</span> */}
                        {/* </button> */}
                    {/* </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        renameChapterFile: (chapter, chapterFile, newChapterFileName) =>  dispatch(renameChapterFile(chapter, chapterFile, newChapterFileName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRenameChapterFile)





