import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveBookDetails } from '../../../store/actions/publisherActions'
import { uploadCover } from '../../../store/actions/publisherActions'
import { updateBookChapterOrderArr } from '../../../store/actions/publisherActions'

class ModalSetBookCover extends Component {

    // Props passed to this class
    // book
    // chapters
    // toggleShowModalSetBookCover

    state = {
        bookTitle: "",
        chapters: {},
        typingTimeout: 0,
    }

    componentDidMount() {
        let chapters = {}
        this.props.chapters.forEach((chapter) => {
            chapters[chapter.chapterId] = chapter.title
        })
        this.setState({
            bookTitle: this.props.book.title,
            chapters
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() { }

    //HELPER FUNCTIONS
    isChanged = () => {
        let isChanged = false
        if (this.props.book.title !== this.state.bookTitle) {
            isChanged = true
        } else {
            for (const chapter of this.props.chapters) {
                if (chapter.title !== this.state.chapters[chapter.chapterId]) {
                    isChanged = true
                    break
                }
            }
        }
        return isChanged
    }
    saveChanges = () => {
        this.props.saveBookDetails(this.props.book, this.props.chapters, this.state.bookTitle, this.state.chapters)
    }

    //EVENT FUNCTIONS
    uploadImage = (e) => {
        this.props.uploadCover(this.props.book, e.target.files[0])
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }
    bookTitleChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        const bookTitle = e.target.value
        this.setState({
            bookTitle,
            typingTimeout: setTimeout(() => {
                this.saveChanges()
            }, 1000)
        })
    }
    chapterChange = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        const chapterId = e.target.id
        const chapterTitle = e.target.value
        this.setState({
            chapters: {
                ...this.state.chapters,
                [chapterId]: chapterTitle
            },
            typingTimeout: setTimeout(() => {
                this.saveChanges()
            }, 1000)
        })
    }


    onDragStart = (e, i) => {
        e.dataTransfer.setData("text/plain", i);
    }
    onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var target = e.currentTarget
        var bounding = target.getBoundingClientRect()
        var offset = bounding.y + (bounding.height / 2);
        if (e.clientY - offset > 0) {
            target.style['border-bottom'] = 'solid 4px blue';
            target.style['border-top'] = '';
        } else {
            target.style['border-top'] = 'solid 4px blue';
            target.style['border-bottom'] = '';
        }
    }
    onDragLeave = (e) => {
        const target = e.currentTarget
        target.style['border-bottom'] = ''
        target.style['border-top'] = ''
    }
    //This function based on this source: https://stackoverflow.com/questions/44415228/list-sorting-with-html5-dragndrop-drop-above-or-below-depending-on-mouse
    onDrop = (e) => {
        e.preventDefault();
        const indexDragged = e.dataTransfer.getData("text/plain");
        const indexDropped = e.currentTarget.id
        const indexTarget = this.getIndexTarget(e.currentTarget, indexDragged, indexDropped)

        if (indexDragged === indexTarget) { return }        
        const book = this.props.book
        const chapterOrderArr = book.chapterOrderArr

        let movedChapterId = chapterOrderArr.splice(indexDragged, 1)[0]
        chapterOrderArr.splice(indexTarget, 0, movedChapterId)

        book.chapterOrderArr = chapterOrderArr
        this.props.updateBookChapterOrderArr(book)
        return
    }
    getIndexTarget = (currentTarget, indexDragged, indexDropped) => {
        let indexTarget = null
        if ( currentTarget.style['border-bottom'] !== '' ) {
            currentTarget.style['border-bottom'] = '';
            indexTarget = parseInt(indexDropped) + 1
        } else {
            currentTarget.style['border-top'] = '';
            indexTarget = indexDropped
        }
        if ( indexDragged < indexTarget ) {
            indexTarget--
        }
        return indexTarget
    }


    //COMPONENT FUNCTIONS
    getBookCoverElement = () => {
        const book = this.props.book
        const bookCoverUrl = book.bookCoverImage ?
            book.bookCoverImage.firebase_storage_url ?
                book.bookCoverImage.firebase_storage_url :
                false :
            false
        let bookCover = null
        if (bookCoverUrl) {
            bookCover = (
                <img className="story-setcover-bookcover" src={bookCoverUrl} alt="Book Cover" />
            )
        }
        return (
            <div className="bd-bookcover">
                <div className="bd-bclabel">Book Cover</div>
                <div className="bd-covercontainer">
                    {bookCover}
                </div>
                <div>
                    <label className="btn-oneclassroom bd-btn">
                        <input className="hide-input" type="file" name="file" accept="image/*" onChange={this.uploadImage} />
                                Upload New Book Cover
                    </label>
                </div>
            </div>
        )
    }


    getBookTitle = () => {
        let styleName = "input-myclassrooms input-uploadepub"
        // if (this.state.fileName !== this.props.chapterFile.file_name) {
        //     styleName = "input-myclassrooms input-uploadepub input-mcnew"
        // }
        return (
            <div>
                <div className="bd-bclabel">Book Title</div>
                <div className="input-filename">
                    <form onSubmit={this.handleSubmit} >
                        <input
                            className={styleName}
                            type="text"
                            id="bookTitle"
                            onChange={this.bookTitleChange}
                            tabIndex={1}
                            value={this.state.bookTitle}
                        />
                    </form>
                </div>
            </div>
        )
    }

    getChapters = () => {
        const dragImage = "../../../../../img/drag.svg"
        let chapterRows = []
        this.props.chapters.forEach((chapter, i) => {
            const chapterTitle = this.state.chapters[chapter.chapterId] ? this.state.chapters[chapter.chapterId] : ""
            const tabIndex = i + 1
            let styleName = "input-myclassrooms"
            const row = (
                <div
                    key={chapter.chapterId}
                    id={i}
                    className="bd-chaprow"
                    draggable="true"
                    onDragStart={(e) => this.onDragStart(e, i)}
                    onDragOver={(e) => this.onDragOver(e)}
                    onDragLeave={(e) => this.onDragLeave(e)}
                    onDrop={(e) => this.onDrop(e)}
                >
                    <div className="bd-chaptitle">
                        <input
                            className={styleName}
                            type="text"
                            id={chapter.chapterId}
                            onChange={this.chapterChange}
                            value={chapterTitle}
                            tabIndex={tabIndex}
                        />
                    </div>
                    <div className="bd-drag" >
                        <img src={dragImage} draggable="false" alt="" />
                    </div>

                </div>
            )
            chapterRows.push(row)
        })
        return (
            <div>
                <div className="bd-bclabel">Chapters</div>
                <div className="bd-chaptable">
                    <form onSubmit={this.handleSubmit} >
                        {chapterRows}
                    </form>
                </div>
            </div>
        )
    }

    render() {
        let status = "all changes saved"
        if (this.isChanged()) {
            status = "saving..."
        }
        return (
            <div className="ajs-modal">
                <div className="bd-modalcontent">
                    <div className="bd-status">{status}</div>
                    <div className="pubfeat-close" onClick={this.props.toggleShowModalSetBookCover}></div>
                    <div className="bd-title">Book Details</div>
                    {this.getBookCoverElement()}
                    {this.getBookTitle()}
                    {this.getChapters()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        saveBookDetails: (book, chapters, newBookTitle, newChapterTitles) => dispatch(saveBookDetails(book, chapters, newBookTitle, newChapterTitles)),
        uploadCover: (book, file) => dispatch(uploadCover(book, file)),
        updateBookChapterOrderArr: (book) => dispatch(updateBookChapterOrderArr(book)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSetBookCover)

