import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveWebLink } from '../../../store/actions/publisherActions'

class ModalAddLink extends Component {

// Props passed to this class
// toggleShowAddLink - function which will hide the modal
// sectionsSaved - array of sections headings which are saved in firestore
// bookId
// chapterId

    state = {
        sections: ["","","","",""],
        sectionFocus: null,
        fileName: "",
        webLink: "",
        sectionTitlesOriginal: ["Introduce", "Interact", "Story", "Play", "Measure"]
    }
    
    componentDidMount() {
        const sectionsSavedCopy = this.props.sectionsSaved.map ( (title) => {
            return title
        })
        this.setState({
            sections: sectionsSavedCopy
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }

    handleFocus = (e) => {
        const targetId = e.target.id
        // console.log("FOCUSED " + targetId)
        this.setState({
            sectionFocus: targetId
        })
    }

    handleChange = (e) => {
        const targetId = e.target.id
        const targetValue = e.target.value
        this.setState({
            [targetId]: targetValue
        })
    }

    saveWebLink = () => {
        if (this.state.fileName === "" || this.state.webLink === "")  { return }
        let webLink = this.state.webLink
        if (webLink.includes("youtube") && !webLink.includes("embed")) {
            webLink = webLink.replace("watch?v=", "embed/")
        }
        if (webLink.includes("quizlet") && !webLink.includes("embed")) {
            webLink = webLink + "/embed"
        }
        if (webLink.includes("edpuzzle") && !webLink.includes("embed")) {
            webLink = webLink.slice(0, 21) + "embed/" + webLink.slice(21);
        }

        this.props.toggleShowAddLink()
        const sectionFocus = this.state.sectionFocus === null ? 0 : this.state.sectionFocus
        const section = this.state.sectionTitlesOriginal[sectionFocus]
        const chapter = this.props.state.books.chaptersByBook[this.props.bookId] ?
            this.props.state.books.chaptersByBook[this.props.bookId][this.props.chapterId] ?
                this.props.state.books.chaptersByBook[this.props.bookId][this.props.chapterId] :
                null :
            null
        if (chapter !== null) {
            // chapter, fileName, webLink, section
            this.props.saveWebLink(chapter, this.state.fileName, webLink, section)
        }
    }

    getSections = () => {
        let sectionRows = []
        for (let i=0; i < 5; i++) {
            const title = this.state.sections[i]
            // const savedTitle = this.props.sectionsSaved[i]
            let styleName = "input-myclassrooms input-uploadepub"
            if (i == this.state.sectionFocus) {
                styleName = "input-myclassrooms input-uploadepub input-uploadepubhighlight"
            }
            const row = (
                <tr
                    key={i}
                >
                    <td className="col-50-body">
                        <input
                            readOnly
                            className={styleName}
                            type="text"
                            id={i}
                            onFocus={this.handleFocus}
                            tabIndex={i+1}
                            ref={ref => (this.section0 = ref)}
                            value={title}
                        />
                    </td>
                </tr>
            )
            sectionRows.push(row)
        }

        return (
            <div className="tableWrap-oneClassroom" >
                <table className="table-oneclassroom">
                    <tbody>
                        {sectionRows}
                    </tbody>
                </table>
            </div>
        )
    }

    getFileNameInput = () => {
        let styleName = "input-myclassrooms input-uploadepub"
        if (this.state.fileName !== "") {
            styleName = "input-myclassrooms input-uploadepub input-uploadepubhighlight"
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="epubupload-file-label">Enter File Label</div>
                <div className="input-filename">
                    <input
                        className={styleName}
                        type="text"
                        id="fileName"
                        onChange={this.handleChange}
                        tabIndex={6}
                        value={this.state.fileName}
                    />
                </div>
                <div className="epubupload-file-label">Enter Web Link</div>
                <div className="input-filename">
                    <input
                        className={styleName}
                        type="text"
                        id="webLink"
                        onChange={this.handleChange}
                        tabIndex={6}
                        value={this.state.webLink}
                    />
                </div>
            </form>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowAddLink}></div>
                    <div className="story-sections-title">Choose section to save to:</div>
                    {this.getSections()}
                    {this.getFileNameInput()}
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom btnkeepclass-myclassrooms" onClick={this.saveWebLink} >
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        saveWebLink: (chapter, fileName, webLink, section) => dispatch(saveWebLink(chapter, fileName, webLink, section)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ModalAddLink)





