import firebase from '../../config/fbConfig'
const db = firebase.firestore()


export const setClassroomId = (classroomId) => {
    return (dispatch, getState) => {              
        dispatch({ type: 'SET_CLASSROOMID', classroomId })
    }
}

export const getReportData = (classroomId) => {
    return (dispatch, getState) => {  
        let promises = [];
        promises.push(getActivity(classroomId))
        promises.push(getTicksOrJournals(classroomId, "ticksStudent"))
        promises.push(getTicksOrJournals(classroomId, "journalsStudent"))
        Promise.all(promises).then((results) => {
            const activityAll = results[0]
            const sortedActivities = sortActivity(activityAll)
            const activityClassroom = {
                    all: activityAll,
                    ...sortedActivities
            }
            const ticks = sortTicksOrJournals(results[1])
            const journals = sortTicksOrJournals(results[2])
            dispatch({ type: 'SET_ALL_USERS', classroomId, activityClassroom, ticks, journals })
            const dropDownBook = getDropDownBookList(getState(), classroomId)
            getChaptersForBooksForJournals(dropDownBook, (chaptersByBook, chaptersById) => {
                dispatch({ type: "SET_CHAPTERS_FOR_JOURNALS", chaptersByBook, chaptersById})
                const dropDownLists = { dropDownBook }
                const dropDownValues = {}
                dispatch({ type: 'UPDATE_DISPLAY', dropDownLists, dropDownValues })
                const userRows = getUserRows(getState(), 1, true)
                dispatch({ type: 'SET_USER_ROWS', userRows })
            })
        }, function (err) {
            console.log("COULD NOT RETRIEVE report data");
        })
    }
}

export const sortActivityReport = (sortBy, sortOrder) => {
    return (dispatch, getState) => {  
        const userRows = getUserRows(getState(), sortBy, sortOrder)
        dispatch({ type: 'SET_USER_ROWS', userRows })
    }
}

export const sortStudentReport = (sortBy, sortOrder) => {
    return (dispatch, getState) => {  
        const oneStudentObj = getState().reports.toDisplayOneStudent
        const classroomTitle = oneStudentObj.classroomTitle
        const studentName = oneStudentObj.studentName
        let rows = oneStudentObj.rows

        if (sortOrder) {
            rows.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1)
        } else {
            rows.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : -1)
        }
    
        dispatch({ type: 'SET_STUDENT_ROWS', classroomTitle, studentName, rows })

        // const userRows = getUserRows(getState(), sortBy, sortOrder)
        // dispatch({ type: 'SET_USER_ROWS', userRows })
    }
}


            //     bookTitle: String - book name
            //     chapterTitle: String - chapter name
            //     fileTitle: String - file name
            //     lastDate: String - date of last time using
            //     totalTime: String - total time using
            //     hasTick: Bool - whether the student checked the file or not
            //     hasJournal: Bool - whether there is a note for the chapter
            //     fileId: String - fileId for use as the key
            //     lastDateSecs: Int - date of last time using in seconds (this is used for sorting the array)
            //     totalTimeSecs: Int - total time in seconds (this is used for sorting the array)


export const setDisplayInfo = (elementToUpdate, elementValue) => {
    return (dispatch, getState) => {  
        //possible values for elementToUpdate
        // selectedBookId
        // selectedChapterId
        // selectedTimePeriod
        let dropDownLists = {
            // dropDownBook: [["all", "All"]],
            // dropDownChapter: [["all", "All"]],
            // dropDownTimePeriod: [
            //     ["all", "All"],
            //     ["1", "Yesterday"],
            //     ["2", "Last Week"],
            //     ["3", "Last 2 Weeks"]
            // ]    
        }
        let dropDownValues = {
            // selectedBookId: "all",
            // selectedChapterId: "all",
            // selectedTimePeriod: "all",
        }
        let dropDownChapter = getState().reports.toDisplay.dropDownLists.dropDownChapter

        //Check if chapters need to be loaded
        const bookChapters = getState().reports.chaptersByBook[elementValue]
        const loadChapters = ( elementToUpdate === "selectedBookId" && elementValue !== "all" && bookChapters === undefined )
        if ( loadChapters ) { //chapters need to be loaded from fs
            getChaptersForBook(elementValue, (chaptersByBook) => {
                dropDownChapter = getDropDownChapter(getState(), elementValue, chaptersByBook[elementValue])
                dropDownLists = { dropDownChapter }
                dropDownValues = { 
                    selectedBookId: elementValue ,
                    selectedChapterId: "all"
                }
                dispatch({ type: 'SET_REPORT_CHAPTERS', chaptersByBook })
                dispatch({ type: 'UPDATE_DISPLAY', dropDownLists, dropDownValues })
                const userRows = getUserRows(getState(), 1, true)
                dispatch({ type: 'SET_USER_ROWS', userRows })
            })
        } else {
            //Chapters don't need to be loaded, so proceed with the rest of the function
            if (elementToUpdate === "selectedBookId") { //since the book is changed, the chapter drop down list will be changed
                dropDownChapter = getDropDownChapter(getState(), elementValue, bookChapters)
                dropDownLists = { dropDownChapter }
                dropDownValues = {
                    selectedBookId: elementValue,
                    selectedChapterId: "all",
                }
            } else {
                dropDownValues = { [elementToUpdate]: elementValue }                
            }
            dispatch({ type: 'UPDATE_DISPLAY', dropDownLists, dropDownValues })
            const userRows = getUserRows(getState(), 1, true)
            dispatch({ type: 'SET_USER_ROWS', userRows })
        }
    }
}

export const getJournals = (classroomId) => {
    return (dispatch, getState) => {              
        dispatch({ type: 'RESET_JOURNALS' })
        let promises = []
        promises.push(getTicksOrJournals(classroomId, "journalsStudent"))
        Promise.all(promises).then((results) => {
            const journals = sortTicksOrJournals(results[0])
            dispatch({ type: 'SET_JOURNALS', classroomId, journals })
            const dropDownBook = getDropDownBookListForJournals(getState(), classroomId)
            
            getChaptersForBooksForJournals(dropDownBook, (chaptersByBook, chaptersById) => {
                dispatch({ type: "SET_CHAPTERS_FOR_JOURNALS", chaptersByBook, chaptersById})
                const dropDownLists = { dropDownBook }
                const dropDownValues = {}
                dispatch({ type: 'UPDATE_DISPLAY', dropDownLists, dropDownValues })
                const journalRows = getJournalRows(getState())
                dispatch({ type: 'SET_JOURNAL_ROWS', journalRows })                
            })
        }, function (err) {
            console.log("COULD NOT RETRIEVE report data");
        })
    }
}

export const setJournalDisplayInfo = (elementToUpdate, elementValue) => {
    return (dispatch, getState) => {  
        // possible values for elementToUpdate
        // selectedBookId
        // selectedChapterId
        // selectedTimePeriod
        let dropDownLists = {
            // dropDownBook: [["all", "All"]],
            // dropDownChapter: [["all", "All"]],
            // dropDownTimePeriod: [
            //     ["all", "All"],
            //     ["1", "Yesterday"],
            //     ["2", "Last Week"],
            //     ["3", "Last 2 Weeks"]
            // ]    
        }
        let dropDownValues = {
            // selectedBookId: "all",
            // selectedChapterId: "all",
            // selectedTimePeriod: "all",
        }
        let dropDownChapter = getState().reports.toDisplay.dropDownLists.dropDownChapter
        const bookChapters = getState().reports.chaptersByBook[elementValue]
        if (elementToUpdate === "selectedBookId") { //since the book is changed, the chapter drop down list will be changed
            dropDownChapter = getDropDownChapter(getState(), elementValue, bookChapters)
            dropDownLists = { dropDownChapter }
            dropDownValues = {
                selectedBookId: elementValue,
                selectedChapterId: "all",
            }
        } else {
            dropDownValues = { [elementToUpdate]: elementValue }
        }
        dispatch({ type: 'UPDATE_DISPLAY', dropDownLists, dropDownValues })
        const journalRows = getJournalRows(getState())
        dispatch({ type: 'SET_JOURNAL_ROWS', journalRows })                
}
}

export const clickedJournalTitle = (studentId, bookId, chapterId) => {
    return (dispatch, getState) => {
        const show = getState().reports.toDisplayJournals.show
        if (chapterId) {
            // show[studentId][bookId][chapterId]["showJournal"] = !show[studentId][bookId][chapterId]["showJournal"]
            if (!show[studentId][bookId][chapterId] || !show[studentId][bookId][chapterId]["showJournal"]) {
                show[studentId][bookId][chapterId] = {showJournal: true}    
            } else {
                show[studentId][bookId][chapterId]["showJournal"] = false
            }
        } else if (bookId) {
            // show[studentId][bookId]["showChapters"] = !show[studentId][bookId]["showChapters"]
            if (!show[studentId][bookId] || !show[studentId][bookId]["showChapters"]) {
                show[studentId][bookId] = {showChapters: true}
            } else {
                show[studentId][bookId]["showChapters"] = false
            }
        } else {
            // show[studentId]["showBooks"] = !show[studentId]["showBooks"]
            if (!show[studentId] || !show[studentId]["showBooks"]) {
                show[studentId] = {showBooks: true}
            } else {
                show[studentId]["showBooks"] = false
            }
        }
        dispatch({ type: 'UPDATE_SHOW_JOURNALS', show })
        const journalRows = getJournalRows(getState())
        dispatch({ type: 'SET_JOURNAL_ROWS', journalRows })
    }
}
export const toggleExpandAllJournals = (isExpanded) => {
    return (dispatch, getState) => {
        const byUser = getState().reports.journals.byUser
        const show = {}
        const userJournals = Object.entries(byUser)
        userJournals.forEach( (userJournal) => {
            const userId = userJournal[0]
            show[userId] = {showBooks: isExpanded}
            const journals = userJournal[1]
            journals.forEach( (journal) => {
                const bookId = journal.bookId
                const chapterId = journal.chapterId
                if ( show[userId][bookId] ) {
                    show[userId][bookId][chapterId] = { showJournal: isExpanded }
                } else {
                    show[userId][bookId] = {
                        showChapters: isExpanded,
                        [chapterId]: {showJournal: isExpanded}
                    }
                }
            })
        })
        dispatch({ type: 'UPDATE_SHOW_JOURNALS', show })
        const journalRows = getJournalRows(getState())
        dispatch({ type: 'SET_JOURNAL_ROWS', journalRows })
    }
}

export const toggleStudentJournals = (userId, isExpanded) => {
    return (dispatch, getState) => {
        const journals = getState().reports.journals.byUser[userId] ? getState().reports.journals.byUser[userId] : {}
        const show = getState().reports.toDisplayJournals.show
        show[userId] = { showBooks: isExpanded }
        journals.forEach((journal) => {
            const bookId = journal.bookId
            const chapterId = journal.chapterId
            if (show[userId][bookId]) {
                show[userId][bookId][chapterId] = { showJournal: isExpanded }
            } else {
                show[userId][bookId] = {
                    showChapters: isExpanded,
                    [chapterId]: { showJournal: isExpanded }
                }
            }
        })        
        dispatch({ type: 'UPDATE_SHOW_JOURNALS', show })
        const journalRows = getJournalRows(getState())
        dispatch({ type: 'SET_JOURNAL_ROWS', journalRows })
    }
}

export const setStudentReportInfo = (classroomId, studentId) => {
    return (dispatch, getState) => {  
        const classroomTitle = getState().myClassrooms.myClassrooms[classroomId] ?
            getState().myClassrooms.myClassrooms[classroomId]["title"] ?
                getState().myClassrooms.myClassrooms[classroomId]["title"] :
                "" :
            ""
        const students = getState().myClassrooms.students[classroomId] ?
            getState().myClassrooms.students[classroomId] :
            {}
        const studentName = students[studentId] ?
            students[studentId].nameFirst :
            ""

        const activityObjs = getState().reports.usage["byUser"][studentId] ?
            getState().reports.usage["byUser"][studentId] :
            []
        const journalObjs = getState().reports.journals.byUser[studentId] ?
            getState().reports.journals.byUser[studentId] :
            []
        const tickObjs = getState().reports.ticks.byUser[studentId] ?
            getState().reports.ticks.byUser[studentId] :
            []

        // classroomBooks = getDropDownBookList(getState(), classroomId)
        const booksPurchased = getState().books.booksPurchased
        const chaptersById = getState().reports.chaptersById

        let bookRowData = {
            // bookId: {
            //     bookTitle,
            //     chapters: {
            //         chapterId: {
            //             chapterTitle,
            //             hasJournal: Boolean,
            //             files: {
            //                 fileId: {
            //                     fileTitle,
            //                     hasTick: Boolean,
            //                     dateTicked,
            //                     activities: [
            //                         activityObj
            //                     ]
            //                 }
            //             }
            //         }
            //     }
            // }
        }
        activityObjs.forEach( (activity) => {
            const bookId = activity.bookId
            if (!booksPurchased[bookId]) {return}
            const chapterId = activity.chapterId
            const fileId = activity.fileId
            if ( !bookRowData[bookId] ) {
                const bookTitle = booksPurchased[bookId].title
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const chapterTitle = chapter.title ? chapter.title : ""
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                const hasJournal = journalObjs.some( journal => journal["chapterId"] === chapterId )
                // const hasTick = tickObjs.some( tick => tick["fileId"] === fileId )
                const hasTick = false
                bookRowData[bookId] = {
                    bookTitle,
                    chapters: {
                        [chapterId]: {
                            chapterTitle,
                            hasJournal,
                            files: {
                                [fileId]: {
                                    fileTitle: file.file_name,
                                    hasTick,
                                    activities: [activity]
                                }
                            }    
                        }
                    }
                }
            } else if (!bookRowData[bookId]["chapters"][chapterId]) {
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const chapterTitle = chapter.title ? chapter.title : ""
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                const hasJournal = journalObjs.some( journal => journal["chapterId"] === chapterId )
                // const hasTick = tickObjs.some( tick => tick["fileId"] === fileId )
                const hasTick = false
                bookRowData[bookId]["chapters"][chapterId] = {
                    chapterTitle,
                    hasJournal,
                    files: {
                        [fileId]: {
                            fileTitle: file.file_name,
                            hasTick,
                            activities: [activity]
                        }
                    }    
                }
            } else if (!bookRowData[bookId]["chapters"][chapterId]["files"][fileId]) {
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                // const hasTick = tickObjs.some( tick => tick["fileId"] === fileId )
                const hasTick = false
                bookRowData[bookId]["chapters"][chapterId]["files"][fileId] = {
                    fileTitle: file.file_name,
                    hasTick,
                    activities: [activity]
                }
            } else {
                bookRowData[bookId]["chapters"][chapterId]["files"][fileId].activities.push(activity)                
            }
        })

        tickObjs.forEach( (activity) => {
            const bookId = activity.bookId
            if (!booksPurchased[bookId]) {return}
            const chapterId = activity.chapterId
            const fileId = activity.fileId
            if ( !bookRowData[bookId] ) {
                const bookTitle = booksPurchased[bookId].title
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const chapterTitle = chapter.title ? chapter.title : ""
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                // const hasJournal = journalObjs.some( journal => journal["chapterId"] === chapterId )
                const hasJournal = false
                const hasTick = true
                const dateTicked = activity.dateTicked.seconds
                bookRowData[bookId] = {
                    bookTitle,
                    chapters: {
                        [chapterId]: {
                            chapterTitle,
                            hasJournal,
                            files: {
                                [fileId]: {
                                    fileTitle: file.file_name,
                                    hasTick,
                                    dateTicked,
                                    activities: []
                                }
                            }    
                        }
                    }
                }
            } else if (!bookRowData[bookId]["chapters"][chapterId]) {
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const chapterTitle = chapter.title ? chapter.title : ""
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                // const hasJournal = journalObjs.some( journal => journal["chapterId"] === chapterId )
                const hasJournal = false
                const hasTick = true
                const dateTicked = activity.dateTicked.seconds
                bookRowData[bookId]["chapters"][chapterId] = {
                    chapterTitle,
                    hasJournal,
                    files: {
                        [fileId]: {
                            fileTitle: file.file_name,
                            hasTick,
                            dateTicked,
                            activities: []
                        }
                    }    
                }
            } else if (!bookRowData[bookId]["chapters"][chapterId]["files"][fileId]) {
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                const hasTick = true
                const dateTicked = activity.dateTicked.seconds
                bookRowData[bookId]["chapters"][chapterId]["files"][fileId] = {
                    fileTitle: file.file_name,
                    hasTick,
                    dateTicked,
                    activities: []
                }
            } else {
                const chapter = chaptersById[chapterId]
                if (!chapter) {return}
                const fileArray = chapter.file_array
                const file = fileArray.find(file => {
                    return file.fileId === fileId
                })
                if (!file) {return}
                bookRowData[bookId]["chapters"][chapterId]["files"][fileId]["hasTick"] = true
                bookRowData[bookId]["chapters"][chapterId]["files"][fileId]["dateTicked"] = activity.dateTicked.seconds
            }
        })


        let rows = [
            // {
            //     bookTitle: String - book name
            //     chapterTitle: String - chapter name
            //     fileTitle: String - file name
            //     lastDate: String - date of last time using
            //     totalTime: String - total time using
            //     hasTick: Bool - whether the student checked the file or not
            //     hasJournal: Bool - whether there is a note for the chapter
            //     fileId: String - fileId for use as the key
            //     lastDateSecs: Int - date of last time using in seconds (this is used for sorting the array)
            //     totalTimeSecs: Int - total time in seconds (this is used for sorting the array)
            // }
        ]

        const bookRowDataArr = Object.values(bookRowData)
        bookRowDataArr.forEach( (bookRow) => {
            const bookTitle = bookRow.bookTitle
            const chapterArr = Object.values(bookRow["chapters"])
            chapterArr.forEach( (chapter) => {
                const chapterTitle = chapter.chapterTitle
                const hasJournal = chapter.hasJournal
                const fileArr = Object.entries(chapter["files"])
                fileArr.forEach( (file) => {
                    const fileId = file[0]
                    const fileTitle = file[1].fileTitle
                    const hasTick = file[1].hasTick
                    let totalTimeSecs = 0
                    let lastDateSecs = 0
                    let lastDate = ""
                    let totalTime = ""
                    if (file[1].activities.length > 0) {
                        file[1].activities.forEach( (activity) => {
                            totalTimeSecs = totalTimeSecs + activity.timeSpent
                            const dateAccess = activity.timeAccess.seconds
                            if (dateAccess > lastDateSecs) {lastDateSecs = dateAccess}
                        })
                        lastDate = getDateString(lastDateSecs)
                        totalTime = getTimeSpentString(totalTimeSecs)    
                    } else {
                        lastDate = getDateString(file[1].dateTicked)
                        totalTime = "-"
                    }
                const newRow = {
                    bookTitle,
                    chapterTitle,
                    fileTitle,
                    lastDate,
                    totalTime,
                    hasTick,
                    hasJournal,
                    fileId,
                    lastDateSecs,
                    totalTimeSecs
                }
                rows.push(newRow)
                })
            })
        })

        console.log("THE ACTIVITY OBJECTS")
        console.log(rows)
        dispatch({ type: 'SET_STUDENT_ROWS', classroomTitle, studentName, rows })
    }
}

//Helper functions
const getChaptersForBooksForJournals = (dropDownBook, callback) => {
    let chaptersByBook = {}
    let chaptersById = {}
    const promises = [];
    const chapterCollection = db.collection("chapters_v2")
    dropDownBook.forEach( (book) => {
        const bookId = book[0]
        const query = chapterCollection.where('bookId', '==', bookId);
        promises.push(query.get())
    })
    Promise.all(promises).then( (results) => {
        results.forEach( (querySnapshot, index) => {
            querySnapshot.forEach( (doc) => {
                chaptersById[doc.id] = doc.data()
                const bookId = doc.data().bookId
                const chapterTitle = doc.data().title ? doc.data().title : ""
                if (bookId) {
                    chaptersByBook[bookId] = {
                        ...chaptersByBook[bookId],
                        [doc.id]: chapterTitle
                    }
                }
            })
        })
        callback(chaptersByBook, chaptersById)
    }, function(err) {
        console.log('COULD NOT RETRIEVE chapter titles for journals', err);
    })
}

const getActivity = (classroomId, callback) => {
    const activityQuery = db.collection("activityStudent").where("classroomId", "==", classroomId)
    return new Promise(function (resolve, reject) {
        activityQuery.get().then(function (querySnapshot) {
            let activityAll = []
            querySnapshot.forEach(function (doc) {
                let docData = doc.data()
                //If timeLeave isn't recorded, then create timeLeave = timeAccess + 10 minutes
                if (!docData.timeLeave) {
                    const timeLeave = docData.timeAccess.seconds + 600
                    docData["timeLeave"] = { seconds: timeLeave }
                }
                let timeSpent = docData.timeLeave.seconds - docData.timeAccess.seconds
                if (timeSpent > 600) { //If time spent is more than 10 minutes, then it is presumed that the user stopped engaging. 
                    const timeLeave = docData.timeAccess.seconds + 600
                    docData["timeLeave"] = { seconds: timeLeave }
                    timeSpent = 600    //In this case, set the time spent to 10 minutes
                }
                if (timeSpent > 6) {//Only record if time spent is greater than 6 seconds
                    docData["timeSpent"] = timeSpent
                    activityAll.push({
                        docId: doc.id,
                        ...docData
                    })
                }
            });
            activityAll.sort((a, b) => (a.timeLeave.seconds < b.timeLeave.seconds) ? 1 : -1) //Sort newest to oldest
            resolve(activityAll)
        }).catch(function (error) {
            console.log("Error getting all activity documents:", error)
            reject({ isError: true })
        })
    })
}


const getTicksOrJournals = (classroomId, collectionName) => {
    const query = db.collection(collectionName).where("classroomId", "==", classroomId)
    return new Promise(function (resolve, reject) {
        query.get().then(function (querySnapshot) {
            let allObjs = []
            querySnapshot.forEach(function (doc) {
                let docData = doc.data()
                docData["docId"] = doc.id
                if (collectionName === "journalsStudent") {
                    const text = doc.data().text ?
                        doc.data().text :
                        ""
                    // const text = ""
                    if (text.replace(/\s/g, '').length && text !== "") { //Filters out journals with no content (ie no content or only spaces, tabs, line breks)
                        allObjs.push(docData)
                    }
                    // if (text !== " ") {
                    //     allObjs.push(docData)
                    // }
                } else {
                    if (docData.ticked) {
                        allObjs.push(docData)
                    }
                }
            });
            resolve(allObjs)
        }).catch(function (error) {
            console.log("Error getting all Ticks or Journals documents:", error);
            reject({ isError: true })
        });
    })
}


const sortActivity = (activityAll) => {
    let byUser = {}
    let byBook = {}
    let byChapter = {}   
    activityAll.forEach( (activity) => {
        //For byUid
        if (byUser[activity.uid]) {
            byUser[activity.uid].push(activity)
        } else {
            byUser[activity.uid] = [activity]
        }
        //For byBook
        if (byBook[activity.bookId]) {
            if (byBook[activity.bookId][activity.uid]) {
                byBook[activity.bookId][activity.uid].push(activity)
            } else {
                byBook[activity.bookId][activity.uid] = [activity]
            }
        } else {
            byBook[activity.bookId] = {
                [activity.uid]: [activity]
            }
        }
        //For byChapter
        if (byChapter[activity.chapterId]) {
            if (byChapter[activity.chapterId][activity.uid]) {
                byChapter[activity.chapterId][activity.uid].push(activity)
            } else {
                byChapter[activity.chapterId][activity.uid] = [activity]
            }
        } else {
            byChapter[activity.chapterId] = {
                [activity.uid]: [activity]
            }
        }
    })
    return {byUser, byBook, byChapter}
}

const sortTicksOrJournals = (allObjs) => {
    let byUser = {}
    let byBook = {}
    let byChapter = {}
    allObjs.forEach( (doc) => {
        const bookId = doc.bookId
        const chapterId = doc.chapterId
        const uid = doc.uid
        //For byUid
        if (byUser[uid]) {
            byUser[uid].push(doc)
        } else {
            byUser[uid] = [doc]
        }
        //For byBook
        if (byBook[bookId]) {
            if (byBook[bookId][uid]) {
                byBook[bookId][uid].push(doc)
            } else {
                byBook[bookId][uid] = [doc]
            }
        } else {
            byBook[bookId] = {
                [uid]: [doc]
            }
        }
        //For byChapter
        if (byChapter[chapterId]) {  
            if (byChapter[chapterId][uid]) {
                byChapter[chapterId][uid].push(doc)
            } else {
                byChapter[chapterId][uid] = [doc]
            }
        } else {
            byChapter[chapterId] = {
                [uid]: [doc]
            }
        }
    })
    return {byUser, byBook, byChapter}
}

const getDropDownBookList = (theState, classroomId) => {
    const booksPurchased = theState.books.booksPurchased
    const booksClassroomObj = theState.books.paByClassroomIdBookId[classroomId] ?
        theState.books.paByClassroomIdBookId[classroomId] :
        {}
    const bookIdsClassroom = Object.keys(booksClassroomObj)

    //get all activity objects and look for books that don't appear in bookIdsClassroom and then add those books to bookIdsClassroom
    const allActivitiesByBook = theState.reports.usage["byBook"] 
    // const allActivitiesByBook = theState.reports.usage[classroomId] ?
    //     theState.reports.usage[classroomId]["byBook"] :
    //     [] 
    const bookIdsFromActivities = Object.keys(allActivitiesByBook)
    bookIdsFromActivities.forEach( (bookId) => {
        if (!bookIdsClassroom.includes(bookId)) {
            bookIdsClassroom.push(bookId)
        }
    }) 

    let dropDownBook = []
    bookIdsClassroom.forEach( (bookId) => {
        const book = booksPurchased[bookId]
        if (book !== undefined) {
            const bookTitle = book.title ?
                book.title :
                ""
            const bookTitleLC = bookTitle.toLowerCase()
            dropDownBook.push([bookId, bookTitle, bookTitleLC])
        }
    })
    dropDownBook.sort((a, b) => (a[2] > b[2]) ? 1 : -1)
    dropDownBook.unshift(["all", "All", "all"])
    return dropDownBook
}

const getDropDownBookListForJournals = (theState, classroomId) => {
    const booksPurchased = theState.books.booksPurchased
    const booksClassroomObj = theState.books.paByClassroomIdBookId[classroomId] ?
        theState.books.paByClassroomIdBookId[classroomId] :
        {}
    const bookIdsClassroom = Object.keys(booksClassroomObj)

    //get all journals and look for books that don't appear in bookIdsClassroom and then add those books to bookIdsClassroom
    const journalsByBook = theState.reports.journals.byBook
    const bookIdsFromJournals = Object.keys(journalsByBook)    
    bookIdsFromJournals.forEach( (bookId) => {
        if (!bookIdsClassroom.includes(bookId)) {
            bookIdsClassroom.push(bookId)
        }
    }) 

    let dropDownBook = []
    bookIdsClassroom.forEach( (bookId) => {
        const book = booksPurchased[bookId]
        if (book !== undefined) {
            const bookTitle = book.title ?
                book.title :
                ""
            const bookTitleLC = bookTitle.toLowerCase()
            dropDownBook.push([bookId, bookTitle, bookTitleLC])
        }
    })
    dropDownBook.sort((a, b) => (a[2] > b[2]) ? 1 : -1)
    dropDownBook.unshift(["all", "All", "all"])
    return dropDownBook
}

const getUserRows = (theState, sortBy, sortOrder) => {

    //Get the selection criteria
    const classroomId = theState.reports.toDisplay.classroomId
    const selectedBookId = theState.reports.toDisplay.dropDownValues.selectedBookId
    const selectedChapterId = theState.reports.toDisplay.dropDownValues.selectedChapterId
    const selectedTimePeriod = theState.reports.toDisplay.dropDownValues.selectedTimePeriod

    //Get the cutoff time
    let cutoffTime = 0
    const now = new Date()
    const secondsSinceMidnight = now.getSeconds() + (60 * now.getMinutes()) + (60 * 60 * now.getHours());
    const secondsSince1970 = Math.floor( now / 1000 )
    if (selectedTimePeriod === "1") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - 86400
    } else if (selectedTimePeriod === "2") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - (86400 * 7)
    } else if (selectedTimePeriod === "3") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - (86400 * 14)
    }
    
    //Get the activityObjs by uid based on the selection criteria
    let activityObjs = {}
    let journalObjs = {}
    let tickObjs = {}
    let journals = theState.reports.journals
    let ticks = theState.reports.ticks
    if (selectedBookId === "all") {
        activityObjs = theState.reports.usage["byUser"]
        // activityObjs = theState.reports.usage[classroomId]["byUser"] ?
        //     theState.reports.usage[classroomId]["byUser"] :
        //         {}
        journalObjs = journals.byUser ?
            journals.byUser :
            {}
        tickObjs = ticks.byUser ?
            ticks.byUser :
            {}
    } else if (selectedChapterId === "all") {
        activityObjs = theState.reports.usage["byBook"][selectedBookId] ?
            theState.reports.usage["byBook"][selectedBookId] :
            {}
        // activityObjs = theState.reports.usage[classroomId]["byBook"][selectedBookId] ?
        //     theState.reports.usage[classroomId]["byBook"][selectedBookId] :
        //     {}
        journalObjs = journals.byBook[selectedBookId] ?
            journals.byBook[selectedBookId] :
            {}
        tickObjs = ticks.byBook[selectedBookId] ?
            ticks.byBook[selectedBookId] :
            {}        
    } else {
        activityObjs = theState.reports.usage["byChapter"][selectedChapterId] ?
            theState.reports.usage["byChapter"][selectedChapterId] :
            {}
        // activityObjs = theState.reports.usage[classroomId]["byChapter"][selectedChapterId] ?
        //     theState.reports.usage[classroomId]["byChapter"][selectedChapterId] :
        //     {}
        journalObjs = journals.byChapter[selectedChapterId] ?
            journals.byChapter[selectedChapterId] :
            {}
        tickObjs = ticks.byChapter[selectedChapterId] ?
            ticks.byChapter[selectedChapterId] :
            {}
    }

    //Get the student objects and sort them
    const students = theState.myClassrooms.students[classroomId] ?
        theState.myClassrooms.students[classroomId] :
        {}
    const studentsArr = Object.values(students)
    studentsArr.forEach((student) => {
        const studentName = student.nameFirst ?
            student.nameFirst :
            ""
        student["nameLC"] = studentName.toLowerCase()
    })
    // studentsArr.sort((a, b) => (a.nameLC > b.nameLC) ? 1 : -1)

    //Get the User Rows
    let userRows = studentsArr.map((student) => {
        const uid = student.studentId
        let lastActivity = "-"
        let totalTime = "-"
        let mastery = 0
        let numJournals = 0
        let lastActivitySeconds = 0
        let totalTimeSeconds = 0
        const studentActivity = activityObjs[student.studentId] ?
            activityObjs[student.studentId] :
            []
        if (studentActivity.length > 0) {
            let totalSeconds = 0
            studentActivity.forEach((activity) => {
                if (activity.timeLeave.seconds > cutoffTime) {
                    totalSeconds = totalSeconds + activity.timeSpent
                }
            })
            totalTime = getTimeSpentString(totalSeconds)

            const timeLeaveSeconds = studentActivity[0].timeLeave.seconds
            lastActivity = getDateString(timeLeaveSeconds)
            totalTimeSeconds = totalSeconds
            lastActivitySeconds = timeLeaveSeconds
        }

        // let studentJournals = []
        // if (selectedChapterId === "all") {
        //     studentJournals = journalObjs[uid] ?
        //         journalObjs[uid] :
        //         []
        // } else {
        //     const studentJournalObj = journalObjs[uid]
        //     if (studentJournals) {
        //         studentJournals = [studentJournalObj]
        //     }
        // }

        const studentJournals = journalObjs[uid] ?
            journalObjs[uid] :
            []
        if (studentJournals.length > 0) { 
            studentJournals.forEach( (journal) => {
                if (journal.updatedOn.seconds > cutoffTime) {
                    numJournals++
                }
            })            
        }
        let numJournalsStr = numJournals.toString()
        if (numJournals === 0) {numJournalsStr = "-"}
        
        const studentTicks = tickObjs[uid] ?
            tickObjs[uid] :
            []
        if (studentTicks.length > 0) { 
            studentTicks.forEach( (tick) => {
                // console.log("ACK")
                // console.log(tick.dateTicked.seconds)
                // console.log(nowSeconds)
                if (tick.dateTicked.seconds > cutoffTime) {
                    mastery++
                }
            })
        }
        let masteryStr = mastery.toString()
        if (mastery === 0) {masteryStr = "-"}

        return (
            [student.studentId, student.nameFirst, lastActivity, totalTime, masteryStr, numJournalsStr, lastActivitySeconds, totalTimeSeconds, mastery, numJournals]
        )
    })

    //Sort the userRows based sortBy
    if (sortOrder) {
        userRows.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1)
    } else {
        userRows.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : -1)
    }
    // console.log("USER ROWS")
    // console.log(userRows)
    return userRows
}


const getJournalRows = (theState) => {
    let journalRows = []
    let journalObjs = {}
    const show = theState.reports.toDisplayJournals.show

    //Get the selection criteria
    const classroomId = theState.reports.toDisplay.classroomId
    const selectedBookId = theState.reports.toDisplay.dropDownValues.selectedBookId
    const selectedChapterId = theState.reports.toDisplay.dropDownValues.selectedChapterId
    const selectedTimePeriod = theState.reports.toDisplay.dropDownValues.selectedTimePeriod
    const classroomBooks = theState.reports.toDisplay.dropDownLists.dropDownBook

    //Get the journals
    const journalsByUser = theState.reports.journals.byUser
    const journalsByBook = theState.reports.journals.byBook
    const journalsByChapter = theState.reports.journals.byChapter

    //Get chaptersById
    const chaptersById = theState.reports.chaptersById

    //Get the cutoff time
    let cutoffTime = 0
    const now = new Date()
    const secondsSinceMidnight = now.getSeconds() + (60 * now.getMinutes()) + (60 * 60 * now.getHours());
    const secondsSince1970 = Math.floor( now / 1000 )
    if (selectedTimePeriod === "1") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - 86400
    } else if (selectedTimePeriod === "2") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - (86400 * 7)
    } else if (selectedTimePeriod === "3") {
        cutoffTime = secondsSince1970 - secondsSinceMidnight - (86400 * 14)
    }
    
    //Get the student objects and sort them
    const students = theState.myClassrooms.students[classroomId] ?
        theState.myClassrooms.students[classroomId] :
        {}
    const studentsArr = Object.values(students)
    studentsArr.forEach((student) => {
        const studentName = student.nameFirst ?
            student.nameFirst :
            ""
        student["nameLC"] = studentName.toLowerCase()
    })
    studentsArr.sort((a, b) => (a.nameLC > b.nameLC) ? 1 : -1)

    //Get the journalObs by uid based on the selected book and chapter
    let journalObjsUnfilteredByTime = {}
    const journals = theState.reports.journals
    if (selectedBookId === "all") {
        journalObjsUnfilteredByTime = journals.byUser ?
            journals.byUser :
            {}
    } else if (selectedChapterId === "all") {
        journalObjsUnfilteredByTime = journals.byBook[selectedBookId] ?
            journals.byBook[selectedBookId] :
            {}
    } else {
        journalObjsUnfilteredByTime = journals.byChapter[selectedChapterId] ?
            journals.byChapter[selectedChapterId] :
            {}
    }
    //Filter out journals based on the selected time period
    if (journalObjsUnfilteredByTime !== {}) {
        if (cutoffTime === 0) {
            journalObjs = journalObjsUnfilteredByTime
        } else {
            const allJournalsArr = Object.entries(journalObjsUnfilteredByTime)
            allJournalsArr.forEach( (uidJournal) => {
                let filteredJournals = []
                uidJournal[1].forEach( (journal) => {
                    const journalTime = journal.updatedOn.seconds
                    if ( journalTime > cutoffTime) {
                        filteredJournals.push(journal)
                    }
                })
                if (filteredJournals.length > 0) {
                    journalObjs[uidJournal[0]] = filteredJournals
                }
            })    
        }
    }


    //Get the Journal Rows
    // journals: {
    //     byUser: {
    //         // uid: [ journalObj ]
    //     },
    //     byBook: {
    //         // bookId: {
    //         //     uid: [ journalObj ]
    //         // }
    //     },
    //     byChapter: {
    //         // chapterId: {
    //         //     uid: journalObj
    //         // }
    //     }
    // show: {
        // studentId: {
        //     showBooks: false,
        //     bookId: {
        //         showChapters: false,
        //         chapterId: {
        //             showJournal: false
        //         }
        //     }
        // }
    // },
    // rows: [
        // {
        //     studentId,
        //     nameFirst,
        //     books: [
        //         {
        //             bookId,
        //             bookTitle,
        //             chapters: [
        //                 {
        //                     chapterId,
        //                     chapterTitle,
        //                     date,
        //                     htmlText
        //                 }
        //             ]
        //         }
        //     ]
        // }
    // ],


    studentsArr.forEach( (student) => {
        const studentId = student.studentId
        //If the student has any journals, create a row for that student
        if (journalObjs[studentId]) {
            const studentRow = {}
            const studentId = student.studentId
            const nameFirst = student.nameFirst
            const studentShow = show[studentId]

            //Set Name Info
            studentRow["studentId"] = studentId
            studentRow["nameFirst"] = nameFirst
            studentRow["books"] = []

            // classroomBooks = dropDownBook = [["all", "All"]],
            //Set Book Info if the student is set to show books
            if (studentShow && studentShow.showBooks) {
                classroomBooks.forEach( (book) => {
                    const bookId = book[0]
                    //Check if this bookId matches the selectedBookId
                    if (selectedBookId !== "all") {
                        if (selectedBookId !== bookId) {
                            return
                        }
                    }
                    //If the student has a journal in that book, then add that book to the list to show
                    if (journalsByBook[bookId] && journalsByBook[bookId][studentId] ) {
                        //Check if any of the journals in this book exceed the cutoff time
                        if (cutoffTime !== 0) {
                            let noJournalsOk = true
                            journalsByBook[bookId][studentId].forEach( (journal) => {
                                const journalTime = journal.updatedOn.seconds
                                if (journalTime > cutoffTime) {
                                    noJournalsOk = false
                                    return
                                }
                            })
                            if (noJournalsOk) { return }
                        }

                        const bookRow = {}
                        //Set Book Info
                        bookRow["bookId"] = bookId
                        bookRow["bookTitle"] = book[1]
                        bookRow["chapters"] = []
                    
                        //Set Chapter Info if the book for this student is set to show chapters
                        if ( studentShow[bookId] && studentShow[bookId]["showChapters"] ) {
                            //Get the list of chaptersIds for that book
                            const chapterIdsForBook = theState.books.booksPurchased[bookId].chapterOrderArr
                            // console.log("CHAPTER IDS FOR BOOKS")
                            // console.log(chapterIdsForBook)
                            chapterIdsForBook.forEach((chapterId) => {
                                // Check if there is a selected chapter
                                if (selectedChapterId !== "all") {
                                    if (selectedChapterId !== chapterId) {
                                        return
                                    }
                                }
            
                                //If the student has a journal in that chapter, then add that chapter to the list to show
                                if (journalsByChapter[chapterId] && journalsByChapter[chapterId][studentId]) {
                                    //Check if any of the journals in this chapter exceed the cutoff time
                                    if (cutoffTime !== 0) {
                                        let noJournalsOk = true
                                        journalsByChapter[chapterId][studentId].forEach((journal) => {
                                            const journalTime = journal.updatedOn.seconds
                                            if (journalTime > cutoffTime) {
                                                noJournalsOk = false
                                                return
                                            }
                                        })
                                        if (noJournalsOk) { return }
                                    }

                                    //Set Chapter Info
                                    const studentJournal = journalsByChapter[chapterId][studentId][0]
                                    console.log("STUDENT JOURNAL")
                                    console.log(studentJournal)
                                    // const dateSeconds = studentJournal.updatedOn.seconds
                                    // const date = getDateString(dateSeconds)
                                    const htmlText = studentJournal.htmlText ? studentJournal.htmlText : ""
                                    console.log(htmlText)
                                    const chapterTitle = chaptersById[chapterId] ? chaptersById[chapterId]["title"] : ""
                                    const chapterRow = {
                                        chapterId,
                                        chapterTitle,
                                    }
                                    //Set the journal text if the journal for this student is set to show
                                    if (studentShow[bookId][chapterId] && studentShow[bookId][chapterId]["showJournal"]) {
                                        // chapterRow["date"] = date
                                        chapterRow["htmlText"] = htmlText
                                    }
                                    bookRow["chapters"].push(chapterRow)
                                }
                            })
                        }
                        studentRow["books"].push(bookRow)
                    }
                })
            }
            journalRows.push(studentRow)
        }
    })

    return journalRows

}

const getDropDownChapter = (theState, selectedBookId, bookChapters) => {
    let dropDownChapter = [["all", "All"]]
    if (selectedBookId === "all") { return dropDownChapter }
    const bookObj = theState.books.booksPurchased[selectedBookId]
    const chapterOrderArr = bookObj.chapterOrderArr
    chapterOrderArr.forEach( (chapterId) => {
        const chapterTitle = bookChapters[chapterId]
        if (chapterTitle) {
            dropDownChapter.push([chapterId, chapterTitle])
        }
    })
    return dropDownChapter
}

const getChaptersForBook = (bookId, callback) => {
    const collection = db.collection('chapters_v2');
    const query = collection.where('bookId', '==', bookId);
    query.get().then( (querySnapshot) => {
        let chapters = {}
        querySnapshot.forEach( (doc) => {
            chapters[doc.id] = doc.data().title ?
                doc.data().title :
                ""            
        })
        const chaptersByBook = { [bookId]: chapters }
        callback(chaptersByBook)
    }, function (err) {
        console.log('COULD NOT RETRIEVE Chapters for Book:' + err);
    })
}

const getDateString = (dateInSeconds) => {
    var a = new Date(dateInSeconds * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    const fullYear = a.getFullYear();
    const year = fullYear.toString().substr(-2)
    let month = a.getMonth()
    var monthStr = months[a.getMonth()];
    month++ //add 1 to month because it is begins at 0
    let date = a.getDate();
    const dayStr = days[a.getDay()]
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let ampm = "am"
    if ( hour > 12 ) {
        hour = hour - 12
        ampm = "pm"
    }
    if ( min < 10 ) { min = "0"+min }
    if ( sec < 10 ) { sec = "0"+sec }
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    // const time = dayStr + " - " + month + "/" + date + "/" + year
    // const time = month + "/" + date + "/" + year + " - " + dayStr
    const time = dayStr + " - " + month + "/" + date + "/" + year + " - " + hour + ':' + min +  " " + ampm

    return time;
}

const getTimeSpentString = (timeInSeconds) => {
    if (timeInSeconds === 0) {
        return "-"
    }
    if (timeInSeconds < 60) {
        const timeFormatted = timeInSeconds + " secs"
        return timeFormatted
    }
    if (timeInSeconds < 3600) {
        const minutes = Math.floor(timeInSeconds / 60)
        // const seconds = totalTimeSpent - (minutes * 60)
        const timeFormatted = minutes + " mins "
        return timeFormatted
    }
    let hourLabel = " hrs "
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60)
    // const seconds = totalTimeSpent - (hours * 3600) - (minutes * 60)
    if (hours === 1) { hourLabel = " hr " }
    const timeFormatted = hours + hourLabel + minutes + " mins "
    return timeFormatted
}


