import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signInStudent } from '../../store/actions/authActions'
import { signOut } from '../../store/actions/authActions'

class SampleESL extends Component {
    state = {
        email: "cmp",
        password: "sample",
    }
    componentDidMount() {
        const uidStudent = this.props.state.auth.uidStudent
        const uidAdult = this.props.state.auth.uid
        if (uidStudent || uidAdult) {
            this.props.signOut()
        } else {
            this.props.signInStudent(this.state)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const uidStudent = this.props.state.auth.uidStudent
        const uidAdult = this.props.state.auth.uid
        if (!(uidStudent || uidAdult)) {
            this.props.signInStudent(this.state)
        } else if (uidStudent) {
            this.props.history.push("/")
        } 
    }
    componentWillUnmount() {
    }

    render() {
        let toDisplay = (
            <div className="myclassrooms-container">
                {/* <div>ha ha ha ha</div> */}
                <div className="loader"></div>
            </div>
        )
        return toDisplay
    }
}

const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInStudent: (creds) => dispatch(signInStudent(creds)),
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleESL)

