import React from 'react'
import StoreBookCard from './StoreBookCard'


const ContainerAuthor = ({books}) => { 


    var booksObj = Object.keys(books).map(function (author) {
        return {
            author: author,
            books: books[author]
        }
    })
    
    // console.log("NEW BOOK OBJECT")
    // console.log(booksObj)

    const authorList = booksObj.map(author => {
        return (
            <div className="row" key={author.author}>
                <h3>{author.author}</h3>
                <StoreBookCard authorBooks={author} />
            </div>
        )
    })

    return (
        <div>
            {authorList}
        </div>
    )
}

export default ContainerAuthor

//Use this version if you want this component to be a class with state.
// class ClassContainer extends Component {
//     render(){
//         let classrooms = this.props.classrooms
//         const classList = classrooms.map(classroom => {
//             return (
//             <div className="row" key={classroom.id}>
//                 <h3>{classroom.title}</h3>
//                 <div className="col s4 l3"> <BookCard books={classroom.books}/> </div>
//             </div>            
//             )
//         })
            
//         return (
//             <div>
//                 {classList}
//             </div>
//         )    
//     }
// }
