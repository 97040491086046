import { actionTypes } from "react-redux-firebase"

const initState = {
    chaptersByBook: {
        // bookId: {
        //     chapterId: chapterTitle
        // }
    },

    chaptersById: {
        // chapterId: chapterObj
    },

    usage: {
            all: [ 
                // activityObj 
            ],
            byUser: {
                // uid: [ activityObjj ]
            },
            byBook: {
                // bookId: {
                //     uid: [ activityObj ]
                // }
            },
            byChapter: {
                // chapterId: {
                //     uid: [ activityObj ]
                // }
            }
        // classroomId: {
        //     all: [ activityObj ],
        //     byUser: {
        //         uid: [ activityObjj ]
        //     },
        //     byBook: {
        //         bookId: {
        //             uid: [ activityObj ]
        //         }
        //     },
        //     byChapter: {
        //         chapterId: {
        //             uid: [ activityObj ]
        //         }
        //     }
        // }
    },

    ticks: {
        byUser: {
            // uid: [ tickObj ]
        },
        byBook: {
            // bookId: {
            //     uid: [ tickObj ]
            // }
        },
        byChapter: {
            // chapterId: {
            //     uid: [ tickObj ]
            // }
        }
    },

    journals: {
        byUser: {
            // uid: [ journalObj ]
        },
        byBook: {
            // bookId: {
            //     uid: [ journalObj ]
            // }
        },
        byChapter: {
            // chapterId: {
            //     uid: journalObj
            // }
        }
    },

    toDisplay: {
        classroomId: "",
        dropDownLists: {
            dropDownBook: [["all", "All"]],
            dropDownChapter: [["all", "All"]],
            dropDownTimePeriod: [
                ["all", "All"],
                ["1", "Yesterday"],
                ["2", "Last Week"],
                ["3", "Last 2 Weeks"]
            ]    
        },
        dropDownValues: {
            selectedBookId: "all",
            selectedChapterId: "all",
            selectedTimePeriod: "all",
        },
        userRows: "loading"
        // userRows: [
        //     ["", "", "", "", "", ""]
        //     // ["uid", "fred", "monday", "total time", "num journals"]
        // ]
    },  

    toDisplayJournals: {
        show: {
            // studentId: {
            //     showBooks: false,
            //     bookId: {
            //         showChapters: false,
            //         chapterId: {
            //             showJournal: false
            //         }
            //     }
            // }
        },
        rows: [
            // {
            //     studentId,
            //     nameFirst,
            //     books: [
            //         {
            //             bookId,
            //             bookTitle,
            //             chapters: [
            //                 {
            //                     chapterId,
            //                     chapterTitle,
            //                     date,
            //                     htmlText
            //                 }
            //             ]
            //         }
            //     ]
            // }
        ],
    },
    
    toDisplayOneStudent: {
        classroomTitle: "Class La Fonda",
        studentName: "Fred",
        rows: [
            // {
            //     bookTitle: String - book name
            //     chapterTitle: String - chapter name
            //     fileTitle: String - file name
            //     lastDate: String - date of last time using
            //     totalTime: String - total time using
            //     hasTick: Bool - whether the student checked the file or not
            //     hasJournal: Bool - whether there is a note for the chapter
            //     fileId: String - fileId for use as the key
            //     lastDateSecs: Int - date of last time using in seconds (this is used for sorting the array)
            //     totalTimeSecs: Int - total time in seconds (this is used for sorting the array)
            // }
        ]
    }
}

const reportReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_CLASSROOMID":
            return {
                ...state,
                toDisplay: {
                    ...state.toDisplay,
                    classroomId: action.classroomId
                }
            }
        case "SET_ALL_USERS":
            return {
                ...state,
                usage: action.activityClassroom,
                ticks: action.ticks,
                journals: action.journals,
            }
        case "SET_DEFAULT_DISPLAY":
            return {
                ...state,
                toDisplay: {
                    ...state.toDisplay,
                    classroomId: action.displayInfo.classroomId,
                    userRows: action.displayInfo.userRows,
                    dropDownLists: {
                        ...state.toDisplay.dropDownLists,
                        dropDownBook: action.displayInfo.dropDownBook,
                    } 
                }
            }
        case "UPDATE_DISPLAY":
            return {
                ...state,
                toDisplay: {
                    ...state.toDisplay,
                    dropDownLists: {
                        ...state.toDisplay.dropDownLists,
                        ...action.dropDownLists
                    }, 
                    dropDownValues: {
                        ...state.toDisplay.dropDownValues,
                        ...action.dropDownValues
                    }
                }
            }
        case "SET_USER_ROWS":
                return {
                    ...state,
                    toDisplay: {
                        ...state.toDisplay,
                        userRows: action.userRows
                    }
                }
        case "SET_REPORT_CHAPTERS":
            console.log("CHAPTERS BY BOOK?")
            console.log(action.chaptersByBook)
            return {
                ...state,
                chaptersByBook: {
                    ...state.chaptersByBook,
                    ...action.chaptersByBook
                }
            }
        case "RESET_JOURNALS":
            return {
                ...state,
                journals: "loading"
            }
        case "SET_JOURNALS":
            return {
                ...state,
                journals: action.journals,
                toDisplay: {
                    ...state.toDisplay,
                    classroomId: action.classroomId,
                }
            }
        case "SET_CHAPTERS_FOR_JOURNALS":
            return {
                ...state,
                chaptersByBook: action.chaptersByBook,
                chaptersById: action.chaptersById
            }
        case "SET_JOURNAL_ROWS":
            // console.log("SET JOURNAL ROWS")
            // console.log(action.journalRows)
            return {
                ...state,
                toDisplayJournals: {
                    ...state.toDisplayJournals,
                    rows: action.journalRows
                }
            }
        case "UPDATE_SHOW_JOURNALS":
            console.log("show2")
            console.log(action.show)
            return {
                ...state,
                toDisplayJournals: {
                    ...state.toDisplayJournals,
                    show: action.show
                }
            }
        case "SET_STUDENT_ROWS":
            return {
                ...state,
                toDisplayOneStudent: {
                    classroomTitle: action.classroomTitle,
                    studentName: action.studentName,
                    rows: action.rows
                }
            }
        default:
            return state
    }
}

export default reportReducer