import firebase from '../../config/fbConfig'
const db = firebase.firestore()


export const getStudents = (classroom) => {
    return (dispatch, getState) => {  
        
        getStudentProfiles(classroom, (profiles) => {
            console.log("GOT PROFILES")
            const studentIds = Object.keys(profiles)
            console.log(studentIds)
            console.log(classroom)
            let promises = [];
            promises.push(getStudentJournals(classroom, studentIds))
            promises.push(getStudentProgress(classroom, studentIds))
            promises.push(getStudentActivity(classroom, studentIds))

            Promise.all(promises).then((results) => {
                processResults(results, profiles, classroom)
                dispatch({ type: "SET_STUDENT_PROFILES", classroom })
            }, function (err) {
                console.log("COULD NOT RETRIEVE student data");
            })
        })
        
        // let promises = []
        // promises.push(getStudentProfiles(classroom))
        // promises.push(getStudentProgress(classroom))
        // promises.push(getStudentJournals(classroom))
        // promises.push(getStudentActivity(classroom))

        // Promise.all(promises).then ( (results) => {
        //     processResults(results, classroom)
        //     dispatch({ type: "SET_STUDENT_PROFILES", classroom })
        // }, function(err) {
        //     console.log("COULD NOT RETRIEVE student data");
        // })
    }
}

const getStudentActivity = (classroom, studentIds) => {
    let promises = [];
    let studentActivity = {}
    const bookIds = getBookIds(classroom)
    const collectionActivity = db.collection("activityFiles")
    for (let i = 0; i < studentIds.length; i++) {
        const queryActivity = collectionActivity.where("uid", "==", studentIds[i])
        promises.push(queryActivity.get());
    }
    return new Promise(function (resolve, reject) {
        Promise.all(promises).then((results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach((querySnapshot, index) => {
                // const purchaseAccesses = [];
                querySnapshot.forEach((doc) => {
                    const bookId = doc.data().bookId
                    if (!bookIds.hasOwnProperty(bookId)) { return }
                    const studentId = doc.data().uid
                    if (studentActivity.hasOwnProperty(studentId)) {
                        studentActivity[studentId].push(doc.data())
                    } else {
                        studentActivity[studentId] = [doc.data()]
                    }                        
                })
            })
            resolve(studentActivity)
        }, function (err) {
            console.log("Error getting Students Activity: ", err);
            reject({isError: true})
        });
    }); 
}

const getStudentProgress = (classroom, studentIds) => {
    let promises = [];
    let studentProgress = {}
    const collectionProgress = db.collection("classroomProgress")
    for (let i = 0; i < studentIds.length; i++) {
        const queryProgress = collectionProgress.where("uid", "==", studentIds[i])
        promises.push(queryProgress.get());
    }

    return new Promise(function (resolve, reject) {
        Promise.all(promises).then((results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach((querySnapshot, index) => {
                // const purchaseAccesses = [];
                querySnapshot.forEach((doc) => {
                    studentProgress[doc.id] = doc.data()
                })
            })
            resolve(studentProgress)
        }, function (err) {
            console.log("Error getting Student Progress: ", err);
            reject({isError: true})
        });
    }); 
}

const getStudentJournals = (classroom, studentIds) => {
    // console.log(classroom)
    let promises = [];
    let studentJournals = {}
    const collectionJournals = db.collection("chapterJournals")
    for (let i = 0; i < studentIds.length; i++) {
        const queryJournals = collectionJournals.where("uid", "==", studentIds[i])
        promises.push(queryJournals.get());
    }

    return new Promise(function (resolve, reject) {
        Promise.all(promises).then((results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach((querySnapshot, index) => {
                // const purchaseAccesses = [];
                querySnapshot.forEach((doc) => {
                    studentJournals[doc.id] = {
                        ...doc.data(),
                        chapterJournalId: doc.id
                    }
                })
            })
            resolve(studentJournals)
        }, function (err) {
            console.log('COULD NOT RETRIEVE Journals');
            reject({isError: true})
        });
    }); 
}

//This function discontinued around 9/30/19
const getStudentActivityOLD = (classroom) => {
    let studentActivity = {}
    const collectionActivity = db.collection("activityFiles")
    const queryActivity = collectionActivity.where("classroomId", "==", classroom.classroomId)
    return new Promise(function(resolve, reject) {
        queryActivity.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                const studentId = doc.data().uid
                if (studentActivity.hasOwnProperty(studentId)) {
                    studentActivity[studentId].push(doc.data())
                } else {
                    studentActivity[studentId] = [doc.data()]
                }
            });
            // console.log("STUDENT PROGRESS")
            // console.log(studentProgress)
            resolve(studentActivity)
        }).catch(function(error) {
            console.log("Error getting Students Activity: ", error);
            reject({isError: true})
        })        
    });
}

const getStudentProgressOLD = (classroom) => {
    // console.log(classroom)
    let studentProgress = {}
    const collectionProgress = db.collection("classroomProgress")
    const queryProgress = collectionProgress.where("classroomId", "==", classroom.classroomId)

    return new Promise(function(resolve, reject) {
        queryProgress.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                studentProgress[doc.id] = doc.data()
            });
            // console.log("STUDENT PROGRESS")
            // console.log(studentProgress)
            resolve(studentProgress)
        }).catch(function(error) {
            console.log("Error getting documents: ", error);
            reject({isError: true})
        })        
    });
}

const getStudentJournalsOLD = (classroom) => {
    // console.log(classroom)
    let studentJournals = {}
    const collectionJournals = db.collection("chapterJournals")
    const queryJournals = collectionJournals.where("classroomId", "==", classroom.classroomId)
    return new Promise(function (resolve, reject) {
        queryJournals.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                studentJournals[doc.id] = doc.data()
            });
            // console.log("STUDENT JOURNALS")
            // console.log(studentJournals)
            resolve(studentJournals)
        }).catch(function (error) {
            console.log("Error getting documents: ", error);
            reject({ isError: true })
        })
    });
}

const getStudentProfiles = (classroom, callback) => {
    // console.log(classroom)
    const registeredStudents = classroom.registered_students
    const studentsArr = Object.entries(registeredStudents)
    let studentsObjs = {};
    let promises = [];
    const collectionUsers = db.collection("users");
    for (let i = 0; i < studentsArr.length; i++) {
        if (studentsArr[i][1]) {
            const queryUsers = collectionUsers.where('email', '==', studentsArr[i][0]);
            promises.push(queryUsers.get());
        }
    }
    Promise.all(promises).then((results) => {
        // returned data is in arguments[0], arguments[1], ... arguments[n]
        // you can process it here
        results.forEach((querySnapshot, index) => {
            // const purchaseAccesses = [];
            querySnapshot.forEach((doc) => {
                studentsObjs[doc.id] = {
                    userId: doc.id,
                    ...doc.data()
                }
            })
        })
        callback(studentsObjs)
    }, function (err) {
        console.log('COULD NOT RETRIEVE purchaseAccesses');
        callback({})
    });
}

const getStudentProfilesOLD = (classroom) => {
    // console.log(classroom)
    const registeredStudents = classroom.registered_students
    const studentsArr = Object.entries(registeredStudents)
    let students = [];
    let studentsObjs = {};
    let promises = [];
    const collectionUsers = db.collection("users");
    for (let i = 0; i < studentsArr.length; i++) {
        if (studentsArr[i][1]) {
            const queryUsers = collectionUsers.where('email', '==', studentsArr[i][0]);
            promises.push(queryUsers.get());
        }
    }
    return new Promise(function (resolve, reject) {
        Promise.all(promises).then((results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach((querySnapshot, index) => {
                // const purchaseAccesses = [];
                querySnapshot.forEach((doc) => {
                    studentsObjs[doc.id] = {
                        userId: doc.id,
                        ...doc.data()
                    }
                })
            })
            resolve(studentsObjs)
        }, function (err) {
            console.log('COULD NOT RETRIEVE purchaseAccesses');
            reject({isError: true})
        });
    }); 
}

const getBookIds = (classroom) => {
    const books = classroom.books ? classroom.books : []
    let bookIds = {}
    books.forEach( (book) => {bookIds[book.bookId] = true} )
    return bookIds
}

const processResults = (results, profiles, classroom) => {
    const studentsByUid = profiles
    const journalsById = results[0]
    const progressById = results[1]
    const studentActivity = results[2]
    console.log("ACTIVITY")
    console.log(studentActivity)

    const bookIds = getBookIds(classroom)
    const journalsByUid = getResultsByUid(journalsById, bookIds)
    const progressByUid = getProgressByUidByBookId(progressById, bookIds)
    classroom["studentProgress"] = progressByUid
    classroom["studentJournals"] = journalsByUid
    classroom["studentProfiles"] = studentsByUid
    classroom["studentActivity"] = studentActivity
}

const processResultsOLD = (results, classroom) => {
    const progressById = results[0]
    let progressByUid = getProgressByUidByBookId(progressById)
    const journalsById = results[1]
    const journalsByUid = getResultsByUid(journalsById)
    const studentsByUid = results[2]
    const studentActivity = results[3]
    classroom["studentProgress"] = progressByUid
    classroom["studentJournals"] = journalsByUid
    classroom["studentProfiles"] = studentsByUid
    classroom["studentActivity"] = studentActivity
}

const getProgressByUidByBookId = (resultsById, bookIds) => {
    const resultsArr = Object.entries(resultsById)
    let resultsByUid = {}
    for (let i = 0; i < resultsArr.length; i++) {
        const thisResult = resultsArr[i]
        const uid = thisResult[1]["uid"]
        const bookId = thisResult[1]["bookId"]
        if (!bookIds.hasOwnProperty(bookId)) {
            continue
        }
        if (resultsByUid.hasOwnProperty(uid)) {
            resultsByUid[uid][bookId] = thisResult[1]
        } else {
            resultsByUid[uid] = {
                [bookId]: thisResult[1]
            }
        }
    }
    return resultsByUid
}


const getResultsByUid = (resultsById, bookIds) => {
    // console.log("GETTING Journals by Uid")
    // console.log(resultsById)
    // console.log(bookIds)
    const resultsArr = Object.entries(resultsById)
    let resultsByUid = {}
    for (let i = 0; i < resultsArr.length; i++) {
        const thisResult = resultsArr[i]
        const thisJournal = thisResult[1]
        const uid = thisResult[1]["uid"]        
        const bookId = thisResult[1]["bookId"]
        const journalId = thisResult[0]
        //only get journals for books that are in the classroom
        if (!bookIds.hasOwnProperty(bookId)) {
            continue
        }
        //filter out journals that have no content
        if (!thisJournal.hasOwnProperty("text")) { continue }
        if (thisJournal.text === "") { continue }
        standardizeUpdateField(thisJournal)
        if (resultsByUid.hasOwnProperty(uid)) {
            resultsByUid[uid][journalId] = thisJournal
        } else {
            resultsByUid[uid] = {
                [journalId]: thisJournal
            }
        }
    }
    // console.log(resultsByUid)
    return resultsByUid
}

//This is required because for a brief time the website recorded the journal timestamp as "webUpdate" whereas the ios app recorded it as "updatedOn"
const standardizeUpdateField = (thisJournal) => {
    if (thisJournal.hasOwnProperty("updatedOn")) {
        const updatedOn = thisJournal.updatedOn
        const typeOfUpdate = typeof updatedOn
        if (typeOfUpdate === "string") {
            const dateString = updatedOn.split(" ")
            const dateMilliseconds = new Date(dateString[0]).getTime()
            const dateSeconds = Math.floor(dateMilliseconds / 1000)
            thisJournal.updatedOn = {
                seconds: dateSeconds
            }
        }
    }

    if (thisJournal.hasOwnProperty("updatedOn") && thisJournal.hasOwnProperty("webUpdate")) {
        if (thisJournal.updatedOn.seconds < thisJournal.webUpdate.seconds) {
            thisJournal["updatedOn"] = thisJournal["webUpdate"]
            return
        }
    }
    // if (!(thisJournal.hasOwnProperty("updatedOn") || thisJournal.hasOwnProperty("webUpdate")) {
    //     thisJournal["updatedOn"] = 0 //this has to be a timestamp
    // }
    if (thisJournal.hasOwnProperty("webUpdate")) {
        thisJournal["updatedOn"] = thisJournal["webUpdate"]
        return
    }
    if (!thisJournal.hasOwnProperty("updatedOn")) {
        thisJournal.updatedOn = {
            seconds: 0
        }
    }
}


//Stopped using 9/24/19
const getResultsByUidOLD = (resultsById) => {
    const resultsArr = Object.entries(resultsById)
    let resultsByUid = {}
    for (let i = 0; i < resultsArr.length; i++) {
        const thisResult = resultsArr[i]
        const uid = thisResult[1]["uid"]        
        if (resultsByUid.hasOwnProperty(uid)) {
            resultsByUid[uid][thisResult[0]] = thisResult[1]
        } else {
            resultsByUid[uid] = {
                [thisResult[0]]: thisResult[1]
            }
        }
    }
    return resultsByUid
}

//this is not used
const getJournalsByUidByBook = (journalsByUid) => {
    const journalsArr = Object.entries(journalsByUid)
    for (let i = 0; i < journalsArr.length; i++) {
        for (let j = 0; i < journalsArr[i][1]; j++) {

        }
    }
}



/* this is not used and can be deleted
const getProgressByUid = (progressById) => {
    const progressArr = Object.entries(progressById)
    let progressByUid = {}
    for (let i = 0; i < progressArr.length; i++) {
        const thisProgress = progressArr[i]
        const uid = thisProgress[1]["uid"]
        if (progressByUid.hasOwnProperty(uid)) {
            progressByUid[uid] = {
                completedDates: {
                    ...progressByUid[uid]["completedDates"],
                    ...thisProgress[1].completedDates 
                },
                completedFiles: {
                    ...progressByUid[uid]["completedFiles"],
                    ...thisProgress[1].completedFiles   
                }

            }
        } else {
            progressByUid[uid] = {
                completedDates: {...thisProgress[1].completedDates},
                completedFiles: {...thisProgress[1].completedFiles}
            }
        }
    }
    return progressByUid
}
*/

export const getStudentsOLD = (classroom) => {
    return (dispatch, getState) => {  
        // console.log("Getting Sutdents for Progress Page")
        // console.log(getState())
        const { progress } = getState()
        const studentFileProgress = getStudentFileProgress(progress, classroom)

        const registeredStudents = classroom.registered_students
        const studentsArr = Object.entries(registeredStudents)
        let students = [];
        let studentsObjs = {};
        let promises = [];
        const collectionUsers = db.collection("users");
        for (let i = 0; i < studentsArr.length; i++) {
            if (studentsArr[i][1]) {
                const queryUsers = collectionUsers.where('email', '==', studentsArr[i][0]);
                promises.push(queryUsers.get());
            }
        }

        Promise.all(promises).then( (results) => {
            // returned data is in arguments[0], arguments[1], ... arguments[n]
            // you can process it here
            results.forEach( (querySnapshot, index) => {
                // const purchaseAccesses = [];
                querySnapshot.forEach( (doc) => {
                    students.push({
                        userId: doc.id,
                        ...doc.data()
                    });
                })
            })
            for (let i = 0; i < students.length; i++) {
                const numActivities = getNumActivities(studentFileProgress, students[i].userId)
                students[i]["lastDate"] = 0
                students[i]["totalTime"] = 1
                students[i]["numActivities"] = numActivities
                students[i]["numJournal"] = 3
                students[i]["streak"] = 4
                studentsObjs[students[i]["userId"]] = { ...students[i] }
            }
            console.log("successfully retrieved")
            console.log(studentsObjs)
            classroom["studentProfiles"] = studentsObjs
            classroom["studentFileProgress"] = studentFileProgress
            dispatch({ type: "SET_STUDENT_PROFILES", classroom })
        }, function(err) {
            console.log('COULD NOT RETRIEVE purchaseAccesses');
        });    
    }
}

const getStudentFileProgress = (progress, classroom) => {
    const classroomId = classroom.classroomId
    const progressArr = Object.values(progress)
    const studentFileProgressArr = progressArr.filter(thisProgress => thisProgress.classroomId === classroomId)
    // console.log(studentFileProgressArr)
    let studentFileProgress = {}
    for (let i = 0; i < studentFileProgressArr.length; i++) {
        const thisProg = studentFileProgressArr[i]
        if (studentFileProgress.hasOwnProperty(thisProg.uid)) {
            studentFileProgress[thisProg.uid] = {
                completedDates: {
                    ...studentFileProgress[thisProg.uid]["completedDates"],
                    ...thisProg.completedDates    
                },
                completedFiles: {
                    ...studentFileProgress[thisProg.uid]["completedFiles"],
                    ...thisProg.completedFiles   
                }

            }
        } else {
            studentFileProgress[thisProg.uid] = {
                completedDates: {...thisProg.completedDates},
                completedFiles: {...thisProg.completedFiles}
            }
        }
    }
    return studentFileProgress
}

const getNumActivities = (studentFileProgress, uid) => {
    const areFilesComplete = studentFileProgress[uid] ?
        studentFileProgress[uid]["completedFiles"] ?
            studentFileProgress[uid]["completedFiles"] :
            0 :
        0
    if (areFilesComplete === 0) {
        return 0
    }
    const areFilesCompleteArr = Object.values(areFilesComplete)
    if (areFilesCompleteArr.length === 0) {
        return 0
    }
    let sum = areFilesCompleteArr.reduce((sum, elem) => sum + elem)
    console.log("COMPLETED FILES")
    console.log(areFilesComplete)
    console.log(sum)
    return sum
}


export const toggleDone = (bookId, chapterId, fileId) => {
    return (dispatch, getState) => {  
        console.log("IN toggleDone")
        const authSL = getState().auth
        let uid = ""
        let ticksCollection = ""
        if (authSL.uid) {
            uid = authSL.uid
            ticksCollection = db.collection("ticksTeacher")
        } else {
            uid = authSL.uidStudent
            ticksCollection = db.collection("ticksStudent")
        }
        const tickObj = getState().books.ticks[fileId] ?
            getState().books.ticks[fileId] :
            {
                bookId,
                chapterId,
                uid,
                fileId,
                dateTicked: new Date(),
                ticked: true
            }
        if (authSL.uidStudent) {
            tickObj["classroomId"] = authSL.classroomId
        }

        if (tickObj.docId) { //the tick object already exists
            const newDate = new Date()
            const fileStatus = tickObj["ticked"] ?
                !tickObj["ticked"] :
                true
            const tickId = tickObj.docId
            ticksCollection.doc(tickId).update({
                dateTicked: newDate,
                ticked: fileStatus
            })
            .then(function() {
                console.log("Tick successfully updated in FS! ", tickObj.docId);
                tickObj.dateTicked = newDate
                tickObj.ticked = fileStatus
                dispatch({ type: "UPDATE_TICK_FOR_BOOK", tickObj })
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating Tick document: ", error);
            });           

        } else { //the tick object doesn't exist. create a new one
            ticksCollection.add(tickObj)
                .then(function (docRef) {
                    console.log("New Tick document written", docRef.id);
                    tickObj["docId"] = docRef.id
                    console.log("NEW TICK ID")
                    console.log(docRef.id)
                    dispatch({ type: "UPDATE_TICK_FOR_BOOK", tickObj })
                })
                .catch(function (error) {
                    console.error("Error adding Tick document: ", error);
                });
        }
    }
}


// This was replaced 4/4/20. can probably be deleted.
// export const toggleDone = (bookId, chapterId, fileId) => {
//     return (dispatch, getState) => {  
//         console.log("IN toggleDone")
//         const authSL = getState().auth
//         let uid = ""
//         let ticksCollection = ""
//         if (authSL.uid) {
//             uid = authSL.uid
//             ticksCollection = db.collection("ticksTeacher")
//         } else {
//             uid = authSL.uidStudent
//             ticksCollection = db.collection("ticksStudent")
//         }
//         const tickObj = getState().books.ticksByBook[bookId][chapterId] ?
//         getState().books.ticksByBook[bookId][chapterId] :
//             {
//                 bookId,
//                 chapterId,
//                 uid,
//                 completedDates: {
//                     [fileId]: new Date(),
//                 },
//                 completedFiles: {
//                     [fileId]: true
//                 }
//             }
        
//         if (authSL.uidStudent) {
//             tickObj["classroomId"] = authSL.classroomId
//         }

//         if (tickObj.docId) { //the tick object already exists
//             const newDate = new Date()
//             const fileStatus = tickObj["completedFiles"][fileId] ?
//                 !tickObj["completedFiles"][fileId] :
//                 true
//             const tickId = tickObj.docId
//             ticksCollection.doc(tickId).update({
//                 ["completedDates."+fileId]: newDate,
//                 ["completedFiles."+fileId]: fileStatus
//             })
//             .then(function() {
//                 console.log("Tick successfully updated in FS!");
//                 tickObj["completedDates"][fileId] = newDate
//                 tickObj["completedFiles"][fileId] = fileStatus
//                 dispatch({ type: "UPDATE_TICK_FOR_BOOK", tickObj })
//             })
//             .catch(function(error) {
//                 // The document probably doesn't exist.
//                 console.error("Error updating Tick document: ", error);
//             });           

//         } else { //the tick object doesn't exist. create a new one
//             ticksCollection.add(tickObj)
//                 .then(function (docRef) {
//                     console.log("New Tick document written", docRef.id);
//                     tickObj["docId"] = docRef.id
//                     console.log("NEW TICK ID")
//                     console.log(docRef.id)
//                     dispatch({ type: "UPDATE_TICK_FOR_BOOK", tickObj })
//                 })
//                 .catch(function (error) {
//                     console.error("Error adding document: ", error);
//                 });
//         }
//     }
// }


//Commented out on 3/28/20. Can be deleted.
// export const toggleDone = (fileId, thisProgress) => {
//     return (dispatch, getState) => {  
//         console.log("IN toggleDone")
//         // console.log(getState())
//         // console.log(fileId)
//         // console.log(thisProgress)
//         // console.log(getState())
//         // const { progress } = getState()
//         console.log(thisProgress)

//         if (thisProgress.hasOwnProperty("completedDates")) {
//             thisProgress["completedDates"][fileId] = new Date()
//         } else {
//             thisProgress["completedDates"] = { [fileId]: new Date() }
//         }

//         if (thisProgress.hasOwnProperty("completedFiles")) {
//             thisProgress["completedFiles"][fileId] = !thisProgress["completedFiles"][fileId]
//         } else {
//             thisProgress["completedFiles"] = { [fileId]: true }
//         }

//         // console.log("UPDATED")
//         console.log(thisProgress)
//         if (thisProgress.hasOwnProperty("progressId")) {
//             updateProgressInFS(fileId, thisProgress, () => {})
//             dispatch({ type: "UPDATE_PROGESS", thisProgress })
//         } else {
//             createProgressInFS(fileId, thisProgress, (classroomProgress) => {
//                 dispatch({ type: "CREATE_PROGESS", classroomProgress })
//             })
//         }        
//     }
// }

//This can probably be deleted (3/28/20)
const updateProgressInFS = (fileId, thisProgress, callback) => {
    const completedDate = "completedDates." + fileId
    const theDate = thisProgress["completedDates"][fileId]
    const completedFile = "completedFiles." + fileId
    const isCompleted = thisProgress["completedFiles"][fileId]
    db.collection("classroomProgress").doc(thisProgress["progressId"]).update({
        [completedDate]: theDate,
        [completedFile]: isCompleted
    })
    .then(function() {
        console.log("Progress successfully updated in FS!");
        callback()
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });   
}

const createProgressInFS = (fileId, thisProgress, callback) => {
    // console.log("IN createProgressInFS")
    // console.log(thisProgress)
    db.collection("classroomProgress").add( thisProgress )
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
        thisProgress["progressId"] = docRef.id
        callback(thisProgress)
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
}