import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createChapter } from '../../../store/actions/publisherActions'


class ModalCreateChapter extends Component {

// Props passed to this class
// book
// toggleShowCreateChapter

    state = {
        chapterTitle: ""
    }
    
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }


    //EVENT FUNCTIONS
    handleChange = (e) => {
        this.setState({
            chapterTitle: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createChapter(this.props.book, this.state.chapterTitle)
        this.props.toggleShowCreateChapter()
    }

    getChapterTitleInput = () => {
        // let styleName = "input-myclassrooms input-uploadepub"
        // if (this.state.fileName !== this.props.chapterFile.file_name) {
        //     styleName = "input-myclassrooms input-uploadepub input-mcnew"
        // }
        return (
            <div className="input-filename">
                <form onSubmit={this.handleSubmit} >
                    <input
                        className="input-myclassrooms input-uploadepub"
                        type="text"
                        id="chapterTitle"
                        onChange={this.handleChange}
                        value={this.state.chapterTitle}
                    />
                </form>
            </div>
        )
    }

    render() {
        return(
            <div className="ajs-modal">
                <div className="modalcontent-sections">
                <div className="pubfeat-close" onClick={this.props.toggleShowCreateChapter}></div>
                    <div className="pubfeat-renametitle">Create New Chapter</div>
                    {this.getChapterTitleInput()}
                    <div className="epubupload-btnwrapper">
                        <button className="btn-oneclassroom epubupload-btn" onClick={this.handleSubmit} >
                            <span>Create Chapter</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        createChapter: (book, chapterTitle) =>  dispatch(createChapter(book, chapterTitle)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateChapter)





