import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getBookCodes } from '../adminPages/actionsAdmin'

class AdminBookCodes extends Component {
    state = {
        bookCodeSearch: "",
        bookCodes: []
    }

    componentDidMount() {
        this.props.getBookCodes()
    }

    componentDidUpdate(prevProps, prevState) {
        this.setBookCodes(prevProps)
    }

    componentWillUnmount() {
    }


    // MARK: - ACTION FUNCTIONS

    handleChange = (e) => {
        const targetId = e.target.id
        const searchText = e.target.value
        this.setState({ 
            [targetId]: searchText, 
        })
        const newBookCodeList = this.props.state.adminData.bookCodes.filter( (bookCodeObj) => {
            const bookCodeLowerCase = bookCodeObj.bookCodeLowerCase
            const searchTextLowerCase = searchText.toLowerCase()
            return bookCodeLowerCase.includes(searchTextLowerCase)
        })
        this.setState({ bookCodes: newBookCodeList })
    }

    handleFocus = (e) => {
        // this.setState({
        //     emailSearch: "",
        //     nameSearch: ""
        // })
        // this.props.clearSearch()
    }

    clickedBookCode = (index) => {
        // const selectedUser = this.props.state.adminData.usersFound[index]
        // this.setState({ 
        //     emailSearch: "",
        //     nameSearch: "",
        //     selectedUser
        // })
        // this.props.clearSearch()
        // this.props.getOrders(selectedUser.userId)
    }


    // MARK: - HELPER FUNCTIONS

    setBookCodes = (prevProps) => {
        const prevBookCodes = prevProps.state.adminData.bookCodes
        const currBookCodes = this.props.state.adminData.bookCodes
        if (prevBookCodes.length === 0 && currBookCodes.length > 0) {
            this.setState({ bookCodes: currBookCodes })
        }
    }


    // MARK: - RENDER FUNCTIONNS

    getSpinner = () => {
        if (this.props.state.books.showSpinner) {
            return (
                <div className="ajs-modal-spinner">
                    <div className="loader"></div>
                </div>
            )
        }
        return null
    }

    getHeader = () => {
        return (
            <div className="header-oneclassroom">
                Search Orders By Book Code
            </div>
        )
    }

    getSearchBar = () => {
        return (
            <div className="ao-search-block">
                <div className="ao-searchline">
                    <span>
                        Search by Book Code
                </span>
                    <div className="ao-search-box">
                        <input
                            autoComplete="off"
                            className="input-myclassrooms"
                            type="text"
                            id="bookCodeSearch"
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            value={this.state.bookCodeSearch}
                        />
                    </div>
                </div>
            </div>
        )
    }
    
    getSearchResults = () => {
        // const bookCodes = this.props.state.adminData.bookCodes
        const bookCodes = this.state.bookCodes
        if ( bookCodes.length === 0 ) { return null }
        
        const userCol1 = bookCodes.map( (bookCode, index) => {
            let bookCodeText = bookCode.bookCode
            return (
                <div 
                    key={index}
                    className="ao-result-line ao-result-line-email"
                    onClick={() => this.clickedBookCode(index)}
                >
                    {bookCodeText}
                </div>
            )
        })

        return (
            <div className="ao-search-resultsbookcodes">
                <div className="ao-email-block">{userCol1}</div>
            </div>
        )
    }

    render(){                
        return (
            <div className="oneclassrooms-container">
                {this.getHeader()}
                {this.getSpinner()}
                {this.getSearchBar()}
                {this.getSearchResults()}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => { return { state } }
const mapDispatchToProps = (dispatch) => {
    return {
        getBookCodes: () =>  dispatch(getBookCodes()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBookCodes)

