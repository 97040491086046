import React from 'react'
import { NavLink } from "react-router-dom"


const Footer = () => {
    return (
        <div>
            <ul>
                <li><NavLink exact to="/Policy/terms" style={{color: 'white', textDecoration: 'none'}} activeStyle={{color: 'white', textDecoration: 'none'}}>Terms & Conditions</NavLink></li>
                <li><NavLink exact to="/Policy/privacy" style={{color: 'white', textDecoration: 'none'}} activeStyle={{color: 'white', textDecoration: 'none'}}>Privacy Policy</NavLink></li>
                <li><NavLink exact to="/Policy/copyright" style={{color: 'white', textDecoration: 'none'}} activeStyle={{color: 'white', textDecoration: 'none'}}>Copyright Policy</NavLink></li>
                <a href="http://www.storylabs.online/"><li className="powered-by help">Powered by Storylabs</li></a>
            </ul>
        </div>
    )
}


export default Footer;