import React from 'react'

const ShowVideo = ({fileUrl, chapterFile}) => {

    const video = chapterFile.file_name.includes('MOV') ? (
        <video className="videosize" controls controlsList="nodownload">
            <source src={fileUrl} type="video/mp4"></source>
        </video> 
        ) : (
        <div className="iframe-container">
            {/* <iframe height='100%' width='100%' scrolling='no' allowFullScreen webkitallowfullscreen="true" src={fileUrl}></iframe> */}
            <iframe src={fileUrl} frameBorder="0" allowFullScreen></iframe>
            {/* <iframe width="853" height="700" src={fileUrl} frameBorder="0" allowFullScreen></iframe> */}
        </div> 
        ) ;

    const videoMov = (
        <video className="responsive-video" controls>
            <source src={fileUrl} type="video/mp4"></source>
        </video>
    )

    const videoYouTube = (
        <div className="video-container">
            {/* <iframe height='100%' width='100%' scrolling='no' allowFullScreen webkitallowfullscreen="true" src={fileUrl}></iframe> */}
            <iframe src={fileUrl} frameBorder="0" allowFullScreen></iframe>
            {/* <iframe width="853" height="700" src={fileUrl} frameBorder="0" allowFullScreen></iframe> */}
        </div>
    )

    return (
        video
        )
}

export default ShowVideo
