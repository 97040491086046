

const initState = {
    isEmpty: true,
    
    // {userEmail: "ajstephens@gmail.com", classrooms: Array(35)}
    // classrooms: Array(35)
    // 0: {classroomId: "new", title: "", classCode: "", purchaseAccesses: Array(0)}
    // 1: {accessCode: "RXBOF", classCode: "coder", classroomBookCount: "45", classroomId: "4U5TtgtoU8DMT3brxboF", key: "4U5TtgtoU8DMT3brxboF", …}
    // 2: {classCode: "Class Coderrrrr", classroomId: "iVC2c23vbj0aipHzUmQx", teacherUid: "9dVqqVAwzGb0svaCUOYzNjAH3s02", timeUpdate: Timestamp, title: "A New Class 01", …}
    // 3: {classCode: "coder", classroomId: "hyWaT6SeJyUqMyZBfRQv", teacherUid: "9dVqqVAwzGb0svaCUOYzNjAH3s02", title: "A New Class 95", purchaseAccesses: Array(0)}
    // 4: {classCode: "coder", classroomId: "WCR9xROJjdcNqxfbr0O1", teacherUid: "9dVqqVAwzGb0svaCUOYzNjAH3s02", title: "A New Class 96", purchaseAccesses: Array(0)}
    // 5: {classCode: "coder", classroomId: "VhQ7AVKyrGLZpAGdQ3QT", teacherUid: "9dVqqVAwzGb0svaCUOYzNjAH3s02", title: "A New Class 97", purchaseAccesses: Array(0)}
    // 6: {clas

    // classrooms: [
    //     {
    //         title: 'Classroom Number 1',
    //         classroomId: "11",
    //         books: [{
    //             title: 'Masterpiece 1',
    //             bookId: '1',
    //             author: 'Maestro',
    //             image: 'img/logo.png'
    //         },
    //         {
    //             title: 'A Pretty Good Book',
    //             bookId: '2',
    //             author: 'A Good Guy',
    //             image: 'img/logo.png'
    //         }
    //         ]
    //     },

    //     {
    //         title: 'Classroom Number 2',
    //         classroomId: "22",
    //         books: [{
    //             title: 'Masterpiece 1',
    //             bookId: '1',
    //             author: 'Maestro',
    //             image: 'img/logo.png'
    //         },
    //         {
    //             title: 'A Pretty Good Book',
    //             bookId: '2',
    //             author: 'A Good Guy',
    //             image: 'img/logo.png'
    //         }
    //         ]
    //     }

    // ]
}


const classReducer = (state = initState, action) => {

    switch (action.type) {
        case 'SET_CLASSROOMS':
            // console.log('IN classReducer SET_CLASSROOMS')
            // return {
            //     ...state,
            //     authError: null
            // }

            const classroomsArray = action.classroomObj.classrooms
            let newClassObjs = {}
            for (var i = 0; i < classroomsArray.length; i++) {
                newClassObjs[classroomsArray[i]["classroomId"]] = {
                    ...classroomsArray[i]
                }
            }
  
            return {
                // userEmail: action.classroomObj.userEmail, //removed
                classrooms: newClassObjs
                // ...action.classroomObj
            }

        case 'RESET_CLASSROOMS':
            console.log("reset classrooms")
            return {
                isEmpty: true
            }

        case "CLASS_SET_NEW":
            let newClassId = action.newClassroom.classroomId
            const newClassroom = action.newClassroom
            return {
                ...state,
                classrooms: {
                    ...state.classrooms,
                    [newClassId]: newClassroom
                }
            }

        case "DELETE_CLASSROOM_FROM_CLASSREDUCER":
            const classDeleteId = action.classroom.classroomId
            return {
                ...state,
                classrooms: {
                    ...state.classrooms,
                    [classDeleteId]: "deleted"
                }
            }


        case "CLASS_UPDATE":
            console.log("IN UPDATE CLASS")
            const classroomId = action.classroom.classroomId
            let thisClassroom = state.classrooms[classroomId]
            if (thisClassroom != null) {
                thisClassroom = {
                    ...thisClassroom,
                    ...action.classroom
                }
                return {
                    ...state,
                    classrooms: {
                        ...state.classrooms,
                        [classroomId]: thisClassroom
                    }
                }
            } else {
                return state
            }
        
        case "CLASS_SET_STUDENTS":
            console.log("IN CLASS_SET_STUDENTS")
            console.log(action)
            const thisClassId = action.classInfo.classroomId
            let classForNewStudents = state.classrooms[thisClassId]
            if (classForNewStudents != null) {
                //AJS Helper
                //create copy of an object
                //https://medium.com/pro-react/a-brief-talk-about-immutability-and-react-s-helpers-70919ab8ae7c
                //https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
                //https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
                //JSON.parse(JSON.stringify(obj))
                const newClassForNewStudents = Object.assign({}, classForNewStudents)
                newClassForNewStudents["students"] = action.classInfo.students
                // console.log(classNewStudents)
                return {
                    ...state,
                    classrooms: {
                        ...state.classrooms,
                        [thisClassId]: newClassForNewStudents
                    }
                }
            } else {
                return state
            }

        case "USER_ADDED_TO_CLASSROOM":
            return {
                ...state,
                newClassroomAdded: true,
            }

        case "INVALID_CLASS_CODE":
            return{
                ...state,
                invalidClassCode: true
            }

        case "RESET_INVALID_CLASS_CODE":
            return{
                ...state,
                invalidClassCode: false
            }

        case "SET_STUDENT_PROFILES":
            const classroom = action.classroom
            const classroomId2 = classroom.classroomId
            console.log("in SET_STUDENT_PROFILES Classroom:")
            console.log(classroom)
            return {
                ...state,
                classrooms: {
                    ...state.classrooms,
                    [classroomId2]: classroom
                }
            }
    
        default:
            return state
    }


    // switch (action.type) {
    //     case 'CREATE_BOOK':
    //         console.log('created book', action.book);
    //         return state;
    //     case 'CREATE_BOOK_ERROR':
    //         console.log('create project error', action.err);
    //         return state;
    //     default:
    //         return state;
    // }
    
}

export default classReducer