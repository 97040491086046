const initState = {
    chapterFiles: {
        chapterFile1: {},
        chapterFile2: {}
    },
    quillFiles: {
        quillId: {},
    }
}

const chapterFileReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_QUILLFILE':
            // console.log("IN chapterFileReducer")
            const quillData = action.quillData
            return {
                ...state,
                quillFiles: {
                    ...state.quillFiles,
                    ...quillData
                }
            }
        default:
            return state
    }
}

export default chapterFileReducer